//Components
import ShowDateAndTime from "../../../components/date-time-format/ShowDateAndTime";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableLoader from "../../../components/table/TableLoader";

// Page Constants
const jobInfoTitle = ["Id", "Name", "Group", "Description", "Is Active", "Last Run", "Next Run"];

//Page Components
function JobInfoTableCell({ jobValue, jobInfoTitleName }) {
  // If job title is Is Active
  if (jobInfoTitleName === "Is Active") {
    return <td>{jobValue ? "Active" : "In Active"}</td>;
  }

  // If job title is Last Run or Next Run
  if (jobInfoTitleName === "Last Run" || jobInfoTitleName === "Next Run") {
    return (
      <td>
        {/* Show Date and Time Component */}
        <ShowDateAndTime timeStamp={jobValue} />
      </td>
    );
  }

  return <td>{jobValue}</td>;
}

function JobInfoTableRow({ jobInfo }) {
  const jobValues = Object.values(jobInfo);

  return (
    <>
      {jobValues.map((jobValue, index) => {
        //Job Information Title
        const jobInfoTitleName = jobInfoTitle[index];

        return (
          <tr key={index}>
            <td scope="row">{jobInfoTitleName}</td>

            {/* Job Info Table Cell */}
            <JobInfoTableCell jobValue={jobValue} jobInfoTitleName={jobInfoTitleName} />
          </tr>
        );
      })}
    </>
  );
}

function JobInfoTableBody({ jobInfo, jobInfoLoading }) {
  //Loader
  if (jobInfoLoading) {
    return <TableLoader colSpan={7} />;
  }

  //if there is no job information
  if (jobInfo.length === 0) {
    return <TableDataNotFound colSpan={7} message="No job information present" />;
  }

  //Job information table row
  return <JobInfoTableRow key={jobInfo.id} jobInfo={jobInfo} />;
}

/**
 * Job Information Table
 */
export default function JobInfoTable({ jobInfo, jobInfoLoading }) {
  return (
    <table className="table">
      <tbody>
        {/* Job Information Table Body */}
        <JobInfoTableBody jobInfoLoading={jobInfoLoading} jobInfo={jobInfo} />
      </tbody>
    </table>
  );
}
