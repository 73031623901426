import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { NICCodeListActions } from "../../redux-slice/nic-code/NICCodeListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getNICCodeListApi } from "../../api/nic-code/NICCodeListAPI";

// Get NIC Code List
function* getNICCodeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getNICCodeListApi(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      NICCodeListActions.getNICCodeListSuccess({
        nicCodeList: items,
        nicCodeListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(NICCodeListActions.getNICCodeListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(NICCodeListActions.getNICCodeList.type, getNICCodeList)]);
}
