import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";

// Actions
import { InstrumentActions } from "../../../redux-slice/instrument/InstrumentSlice";

// Components
import Loader from "../../../components/loader/Loader";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import InstrumentDataColumnListCard from "./InstrumentDataColumnListCard";
import InstrumentDataColumnMappingsCard from "./InstrumentDataColumnMappingsCard";

// Page Components
function InstrumentInfoCard({ instrumentInfo }) {
  const { name, model, code } = instrumentInfo;

  return (
    <div className="card p-3">
      <div className="card-body">
        <h5 className="card-title">Measurement Type Info</h5>
        <div className="row mt-4">
          <div className="col-7 bold">Name</div>
          <div className="col-5">{name}</div>
        </div>
        <div className="row mt-4">
          <div className="col-7 bold">Model</div>
          <div className="col-5">{model}</div>
        </div>
        <div className="row mt-4">
          <div className="col-7 bold">Code</div>
          <div className="col-5">{code}</div>
        </div>
      </div>
    </div>
  );
}

/**
 * Instrument View Page
 */
export default function InstrumentViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { instrumentId = "" } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Data Column Info Selector State
  const dataColumnInfo = useSelector((state) => state.instrument.dataColumnInfo);
  const dataColumnInfoLoading = useSelector((state) => state.instrument.dataColumnInfoLoading);

  // Instrument Info Selector State
  const instrumentInfo = useSelector((state) => state.instrument.instrumentInfo);
  const instrumentInfoLoading = useSelector((state) => state.instrument.instrumentInfoLoading);

  // Page Header
  const { name = "" } = instrumentInfo;
  const pageTitle = !instrumentInfoLoading ? `${name} :: Instrument` : "";

  // use Effect
  useEffect(() => {
    dispatch(InstrumentActions.getDataColumnInfo({ instrumentId }));
    dispatch(InstrumentActions.getInstrumentInfo({ instrumentId }));
  }, [dispatch, instrumentId]);

  const { dataColumnDefsList = [], columnMappings = {} } = dataColumnInfo;

  useEffect(() => {
    setHeaderComponent(<PageHeader title={pageTitle} />);
  }, [name]);

  // Loader
  if (instrumentInfoLoading || dataColumnInfoLoading) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-6">
          {/* Instrument Info Card */}
          <InstrumentInfoCard instrumentInfo={instrumentInfo} />

          {/* Instrument Data Column List Card */}
          <InstrumentDataColumnListCard instrumentInfo={instrumentInfo} dataColumnInfo={dataColumnDefsList} />
        </div>
        <div className="col-6">
          {/* Instrument Data Column Mappings Card */}
          <InstrumentDataColumnMappingsCard columnMappings={columnMappings} />
        </div>
      </div>
    </div>
  );
}
