import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ProductListActions } from "../../redux-slice/product/ProductListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getProductListApi } from "../../api/product/ProductListAPI";

// Get Product List
function* getProductList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getProductListApi(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(ProductListActions.getProductListSuccess({ productList: items, productListPagination: pagination }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ProductListActions.getProductListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(ProductListActions.getProductList.type, getProductList)]);
}
