import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get constant list
  constantList: [],
  constantListLoading: false,
  constantListError: "",
};

// Constant List Slice
const constantListSlice = createSlice({
  name: "ConstantList",
  initialState,
  reducers: {
    // Get Constant List
    getConstantList: (state) => {
      state.constantListLoading = true;
    },

    getConstantListSuccess: (state, action) => {
      state.constantListLoading = false;
      state.constantList = action.payload.constantList;
      state.constantListError = "";
    },

    getConstantListFailure: (state, action) => {
      state.constantListLoading = false;
      state.constantListError = action.payload.error;
    },
  },
});

// Reducer
export const ConstantListReducer = constantListSlice.reducer;

// Actions
export const ConstantListActions = constantListSlice.actions;
