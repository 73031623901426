import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get instrument list
  instrumentList: [],
  instrumentListLoading: false,
  instrumentListPagination: {},
  instrumentListError: "",
};

// Instrument List Slice
const instrumentListSlice = createSlice({
  name: "InstrumentList",
  initialState,
  reducers: {
    // Get Instrument List
    getInstrumentList: (state) => {
      state.instrumentListLoading = true;
    },

    getInstrumentListSuccess: (state, action) => {
      state.instrumentListLoading = false;
      state.instrumentList = action.payload.instrumentList;
      state.instrumentListPagination = action.payload.instrumentListPagination;
    },

    getInstrumentListFailure: (state, action) => {
      state.instrumentListLoading = false;
      state.instrumentListError = action.payload.error;
    },
  },
});

// Reducer
export const InstrumentListReducer = instrumentListSlice.reducer;

// Actions
export const InstrumentListActions = instrumentListSlice.actions;
