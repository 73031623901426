import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";
import { UserActions } from "../../../redux-slice/user/UserSlice";
import { PlantListActions } from "../../../redux-slice/plant/PlantListSlice";

// Utils
import { getIndustryUserFormSchema } from "../userUtils";
import { bcForUserCreatePage } from "../../../utils/BreadcrumbUtils";

// Components
import Form, { FormTypes } from "../../../components/form/Form";
import PageHeader from "../../../components/page-header/PageHeader";

// Page Components
function PageHeaderSection() {
  // Organization Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Organization name and Id
  const { id: orgId = "", name: orgName = "", orgType = "" } = orgInfo;

  return <PageHeader breadcrumbList={bcForUserCreatePage(orgId, orgName, orgType)} className="mt-2" actions={<></>} />;
}

/**
 * Add User Page
 */
export default function UserCreatePage() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Params
  const params = useParams();
  const { orgId } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Plant List Selector State
  const plantList = useSelector((state) => state.plantList.plantList);

  //Add User Selector State
  const addUserLoading = useSelector((state) => state.user.addUserLoading);

  // Organization type
  const { orgType = "" } = orgInfo || {};

  // useEffect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
    dispatch(PlantListActions.getPlantList({ orgId, pageSize: 1000 }));
  }, [dispatch, orgId]);

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  // use Memo
  const { formInputItems, formValidationSchema } = useMemo(() => {
    return getIndustryUserFormSchema(orgType, plantList);
  }, [plantList, orgType, orgId]);

  // Form Submit
  function formSubmit(userData) {
    userData = { ...userData, orgId: parseInt(orgId) };

    dispatch(UserActions.addUser({ userData, navigate }));
  }

  return (
    <div className="page-content">
      {/* Form */}
      <Form
        type={FormTypes.CREATE}
        showCancelButton={false}
        loading={addUserLoading}
        formInputItems={formInputItems}
        formSubmit={formSubmit}
        formValidationSchema={formValidationSchema}
      />
    </div>
  );
}
