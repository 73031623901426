// URLs
import { RedirectTo, URL_ORG_LIST_AUDIT_PARTNERS, URL_ORG_LIST_INDUSTRIES } from "../urls/page-urls/RedirectURL";

/**
 * Sidebar Menu
 */
export const SidebarMenus = {
  PLATFORM: [
    {
      title: "Audit Home",
      items: [
        {
          itemName: "Audit Dashboard",
          itemIconCls: "far fa-list-alt",
          itemLink: RedirectTo.AuditListPageUrl,
        },
        {
          itemName: "Audit Firm",
          itemIconCls: "fas fa-user-tie",
          itemLink: URL_ORG_LIST_AUDIT_PARTNERS,
        },
        {
          itemName: "Industries",
          itemIconCls: "fas fa-warehouse",
          itemLink: URL_ORG_LIST_INDUSTRIES,
        },
      ],
    },
    // {
    //   title: "Administration",
    //   items: [
    //     {
    //       itemName: "Jobs",
    //       itemIconCls: "far fa-clock",
    //       itemLink: RedirectTo.JobListPageUrl,
    //     },
    //     {
    //       itemName: "Users",
    //       itemIconCls: "fas fa-users",
    //       itemLink: RedirectTo.UserListPageUrl,
    //     },
    //     {
    //       itemName: "Roles",
    //       itemIconCls: "fas fa-person-circle-question",
    //       itemLink: RedirectTo.RoleListPageUrl,
    //     },
    //   ],
    // },
    {
      title: "Definitions",
      items: [
        {
          itemName: "Measurements",
          itemIconCls: "fas fa-ruler",
          itemLink: RedirectTo.MeasurementTypeListPageUrl,
        },
        {
          itemName: "Components Types",
          itemIconCls: "fas fa-project-diagram",
          itemLink: RedirectTo.ComponentTypeListPageUrl,
        },
        // {
        //   itemName: "Instruments",
        //   itemIconCls: "fas fa-screwdriver-wrench",
        //   itemLink: RedirectTo.InstrumentListPageUrl,
        // },
        // {
        //   itemName: "Materials",
        //   itemIconCls: "fas fa-sign-in-alt",
        //   itemLink: RedirectTo.MaterialListPageUrl,
        // },
        // {
        //   itemName: "Products",
        //   itemIconCls: "fas fa-sign-out-alt",
        //   itemLink: RedirectTo.ProductListPageUrl,
        // },
        // {
        //   itemName: "Constants",
        //   itemIconCls: "fas fa-lock",
        //   itemLink: RedirectTo.ConstantsListPageUrl,
        // },
        // {
        //   itemName: "HSN Codes",
        //   itemIconCls: "fas fa-ruler",
        //   itemLink: RedirectTo.HSNCodeListPageUrl,
        // },
        // {
        //   itemName: "NIC Codes",
        //   itemIconCls: "fas fa-ruler",
        //   itemLink: RedirectTo.NICCodeListPageUrl,
        // },
      ],
    },
  ],
  AUDIT: [
    {
      title: "Audit Home",
      items: [
        {
          itemName: "All Audits",
          itemIconCls: "far fa-list-alt",
          itemLink: RedirectTo.AuditListPageUrl,
        },
        {
          itemName: "My Audits",
          itemIconCls: "fa fa-clipboard-list",
          itemLink: RedirectTo.AuditListByAuditorPageUrl,
        },
      ],
    },
    {
      title: "Industry",
      items: [
        {
          itemName: "Industries",
          itemIconCls: "fas fa-warehouse",
          itemLink: URL_ORG_LIST_INDUSTRIES,
        },
      ],
    },

    {
      title: "Administration",
      items: [
        {
          itemName: "Users",
          itemIconCls: "fas fa-users",
          itemLink: RedirectTo.UserListPageUrl,
        },
      ],
    },
  ],
  INDUSTRY: [
    {
      title: "Audit Home",
      items: [
        {
          itemName: "My Audits",
          itemIconCls: "far fa-list-alt",
          itemLink: RedirectTo.AuditListPageUrl,
        },
      ],
    },
    {
      title: "Administration",
      items: [
        {
          itemName: "Plants",
          itemIconCls: "fas fa-industry",
          itemLink: RedirectTo.PlantListPageUrl,
        },
        {
          itemName: "Users",
          itemIconCls: "fas fa-users",
          itemLink: RedirectTo.UserListPageUrl,
        },
      ],
    },
  ],
};

// Audit Layout Sidebar Menus
export const AuditLayoutSideBar = {
  sideBarElements: [
    {
      title: "Audit View",
      items: [
        {
          itemName: "Overview",
          itemIconCls: "fas fa-tachometer-alt",
          itemLink: RedirectTo.AuditOverviewPageUrl,
        },
        {
          itemName: "Status",
          itemIconCls: "fas fa-spinner",
          itemLink: RedirectTo.AuditStatusPageUrl,
        },
        {
          itemName: "WalkThrough Remarks",
          itemIconCls: "fas fa-clipboard",
          itemLink: RedirectTo.AuditWalkthroughRemarksPageUrl,
        },
        {
          itemName: "Measurements & Results",
          itemIconCls: "fas fa-ruler",
          itemLink: RedirectTo.AuditMeasurementsPageUrl,
        },
        {
          itemName: "Observations & Recommendations",
          itemIconCls: "fas fa-eye",
          itemLink: RedirectTo.AuditObservationsPageUrl,
        },
        {
          itemName: "Energy Savings",
          itemIconCls: "fas fa-bolt",
          itemLink: RedirectTo.AuditSavingsCalculationPageUrl,
        },
        {
          itemName: "Data",
          itemIconCls: "fas fa-file-excel",
          itemLink: RedirectTo.AuditDataPageUrl,
        },
        {
          itemName: "Reports",
          itemIconCls: "fas fa-file-pdf",
          itemLink: RedirectTo.AuditReportsListPageUrl,
        },
        {
          itemName: "Audit Files",
          itemIconCls: "fas fa-file-arrow-up",
          itemLink: RedirectTo.AuditFileListPageUrl,
        },
      ],
    },
  ],
  defaultSelectedMenu: "Overview",
  backButtonConfig: {
    link: RedirectTo.AuditListPageUrl,
  },
};

// Plant Layout Sidebar Menus
export const PlantLayoutSideBar = {
  sideBarElements: [
    {
      title: "Plant Setup",
      items: [
        {
          itemName: "Overview",
          itemIconCls: "fas fa-info",
          itemLink: RedirectTo.PlantOverviewPageUrl,
        },
        {
          itemName: "Components",
          itemIconCls: "fas fa-project-diagram",
          itemLink: RedirectTo.PlantComponentsPageUrl,
        },
        {
          itemName: "Electricity Bills",
          itemIconCls: "fas fa-file-invoice-dollar",
          itemLink: RedirectTo.PlantElectricityBillPageUrl,
        },
        {
          itemName: "Thermal Bills",
          itemIconCls: "fas fa-file-invoice-dollar",
          itemLink: RedirectTo.PlantThermalBillPageUrl,
        },
        {
          itemName: "Users",
          itemIconCls: "fas fa-user",
          itemLink: RedirectTo.PlantUserListPageUrl,
        },
        {
          itemName: "Plant Files",
          itemIconCls: "fas fa-file-arrow-up",
          itemLink: RedirectTo.PlantFileListPageUrl,
        },
      ],
    },
    {
      title: "Audit",
      items: [
        {
          itemName: "Audits",
          itemIconCls: "fas fa-file-pen",
          itemLink: RedirectTo.PlantAuditListPageUrl,
        },
      ],
    },
  ],
  defaultSelectedMenu: "Overview",
  backButtonConfig: {
    link: RedirectTo.PlantListPageUrl,
  },
};

// User Layout Sidebar Menus
export const UserLayoutSideBar = {
  sideBarElements: [
    {
      title: "User Settings",
      items: [
        {
          itemName: "Profile",
          itemIconCls: "fas fa-user",
          itemLink: RedirectTo.ProfilePageUrl,
        },
      ],
    },
  ],
  defaultSelectedMenu: "Profile",
  backButtonConfig: {
    link: RedirectTo.DashboardPageUrl,
  },
};
