// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// utils
import URLs from "../../app/utils/URLs";

// Urls
import { getPlantComponentListUrl } from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Plant Component List
export async function getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (componentTypeId) {
    params.component_type = componentTypeId;
  }

  const url = URLs.format(getPlantComponentListUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url, params);
}
