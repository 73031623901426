import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Download performance analysis data
  downloadPerfAnalysisLoading: false,
  downloadPerfAnalysisError: "",

  // Download recommendation data
  downloadRecommendationLoading: false,
  downloadRecommendationError: "",
};

// Audit Data Slice
const auditDataSlice = createSlice({
  // Name
  name: "AuditData",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Download performance analysis data
    downloadPerfAnalysisData: (state) => {
      state.downloadPerfAnalysisLoading = true;
    },

    downloadPerfAnalysisDataSuccess: (state) => {
      state.downloadPerfAnalysisLoading = false;
      state.downloadPerfAnalysisError = "";
    },

    downloadPerfAnalysisDataFailure: (state, action) => {
      state.downloadPerfAnalysisLoading = false;
      state.downloadPerfAnalysisError = action.payload.error;
    },

    // Download recommendation data
    downloadRecommendationData: (state) => {
      state.downloadRecommendationLoading = true;
    },

    downloadRecommendationDataSuccess: (state) => {
      state.downloadRecommendationLoading = false;
      state.downloadRecommendationError = "";
    },

    downloadRecommendationDataFailure: (state, action) => {
      state.downloadRecommendationLoading = false;
      state.downloadRecommendationError = action.payload.error;
    },
  },
});

// Reducer
export const AuditDataReducer = auditDataSlice.reducer;

// Actions
export const AuditDataActions = auditDataSlice.actions;
