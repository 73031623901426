// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// utils
import URLs from "../../app/utils/URLs";

// Urls
import { getPlantThermalBillListByIdUrl } from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Plant Thermal Bill List
export async function getPlantThermalBillListByIdApi(plantId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const url = URLs.format(getPlantThermalBillListByIdUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}
