/**
 * Header Component
 */
export default function TopNav({ sidebarCollapsible = false, showSideBar, setShowSideBar, topbarConfig = {} }) {
  // Topbar configuration
  const { topbarContainerClassName = "", topBarMenuComponent = <></> } = topbarConfig;

  return (
    <header className={`topbar ${!showSideBar && "collapsed"} ${topbarContainerClassName}`}>
      <div className="topbar-menu">
        {/** topBar Menu Component */}
        {topBarMenuComponent}
      </div>
    </header>
  );
}
