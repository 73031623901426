import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TablePagination from "../../../components/table/TablePagination";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Website",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function OrganizationListTableActionCell({ organization }) {
  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateOrganizationStatusLoadingMap = useSelector((state) => {
    return state.organization.updateOrganizationStatusLoadingMap;
  });

  // Organization List Selector State
  const organizationListPagination = useSelector((state) => state.organizationList.organizationListPagination);
  const { pageSize, pageNo } = organizationListPagination;

  const { id: orgId, orgType, isActive } = organization;

  // Changing status
  const status = !isActive;

  // Loading status
  const loading = updateOrganizationStatusLoadingMap[orgId] || false;

  // Action Butttons
  const actionButtons = [
    {
      label: "View",
      isIndustry: true,
      redirectUrl: URLs.format(RedirectTo.OrganizationViewPageUrl, { orgType, orgId }),
    },
    {
      label: "View Users",
      isIndustry: true,
      redirectUrl: URLs.format(RedirectTo.UserListPageUrl, { orgId }),
    },
    {
      label: "View Plants",
      isIndustry: orgType === "INDUSTRY",
      redirectUrl: URLs.format(RedirectTo.PlantListPageUrl, { orgId }),
    },
  ];

  return (
    <td>
      <div className="btn-cont">
        {actionButtons.map((btn, idx) => {
          const { label = "", isIndustry = false, redirectUrl = "" } = btn || {};

          if (!isIndustry) {
            return null;
          }

          return (
            <Button label={label} size="sm" key={idx} onClick={() => navigate(redirectUrl)}>
              <i className="fas fa-eye"></i>
            </Button>
          );
        })}

        {/* Button */}
        <Button
          className="ms-3"
          label={isActive ? "Disable" : "Enable"}
          color={isActive ? "danger" : "success"}
          size="sm"
          loading={loading}
          disabled={loading}
          onClick={() =>
            dispatch(
              OrganizationActions.updateOrganizationStatus({ orgId, orgType, status, pageNumber: pageNo, pageSize })
            )
          }
        />
      </div>
    </td>
  );
}

function OrganizationListTableWebsiteCell({ website }) {
  return (
    <td>
      <a href={website} target="_blank" className="text-decoration-none" rel="noreferrer">
        {website}
      </a>
    </td>
  );
}

function OrganizationListTableRow({ organization, serialNo }) {
  const { name, website } = organization;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>

      {/* Organization Table Website Cell */}
      <OrganizationListTableWebsiteCell website={website} />

      {/* Organization Table Action Cell */}
      <OrganizationListTableActionCell organization={organization} />
    </tr>
  );
}

function OrganizationListTableBody({ organizationListLoading, organizationList, pageSize, pageNumber }) {
  //Loader
  if (organizationListLoading) {
    return <TableLoader colSpan={8} />;
  }

  //if there is no organization list
  if (organizationList.length === 0) {
    return <TableDataNotFound message="No organization present" colSpan={8} />;
  }

  //Organization list table row
  return (
    <>
      {organizationList.map((organization, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return <OrganizationListTableRow key={organization.id} organization={organization} serialNo={serialNo} />;
      })}
    </>
  );
}

/**
 * Organization List Table
 */
export default function OrganizationListTable({
  organizationList = [],
  organizationListLoading = false,
  organizationListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize, pageNo } = organizationListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Organization List Table Body */}
          <OrganizationListTableBody
            organizationList={organizationList}
            organizationListLoading={organizationListLoading}
            pageSize={pageSize}
            pageNumber={pageNo}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
