import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditActions } from "../../redux-slice/audit/AuditSlice";
import { AuditComponentActions } from "../../redux-slice/audit/ComponentSlice";

// Constants
import { AuditLayoutSideBar } from "../../constants/LayoutConstants";

// Utils
import { getOrgType } from "../../utils/AuthOrgUtil";
import { constructSideBarUrls } from "../../utils/LayoutUtils";

// URLs
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../button/Button";
import PageHeader from "../page-header/PageHeader";

// Layouts
import Layout from "../../app/layout/Layout";
import SidebarMenu from "./SidebarMenu";
import SiderbarFooter from "./SidebarFooter";

// Sidebar Images
import LOGO_KISEM from "../../assests/images/branding/KISEM_LOGO_1.png";
import LOGO_KISEM_TEXT from "../../assests/images/branding/KISEM_LOGO_2.png";

// Page Components
function PageHeaderSection({ auditInfo, auditId }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Audit Pre Assessment Selector State
  const startAuditPreAssessmentLoading = useSelector((state) => state.audit.startAuditPreAssessmentLoading);

  // Pre Audit Selector State
  const startPreAuditLoading = useSelector((state) => state.audit.startPreAuditLoading);

  // Getting Audit Information
  const { industryOrgId = "", industryOrgName = "", plantId = "", plantName = "", auditStatus = "" } = auditInfo || {};
  const orgType = getOrgType();

  // Content
  const pageTitle = `Energy Audit :: ${industryOrgName || ""} - ${plantName || ""}`;
  const pageActions = <div className="btn-cont"></div>;

  // Audit Status Button
  const auditStatusButton = {
    AUDIT_INIT: {
      label: "Start Pre Assessment",
      action: AuditActions.startAuditPreAssessment,
      allowedOrgs: ["PLATFORM", "INDUSTRY"],
      loading: startAuditPreAssessmentLoading,
    },
    QUESTIONNAIRE: {
      label: "Start Walk Through",
      action: AuditActions.startPreAudit,
      allowedOrgs: ["PLATFORM", "AUDIT"],
      loading: startPreAuditLoading,
    },
  };

  // Getting organizations allowed to see this button
  const { allowedOrgs, label, action, loading } = auditStatusButton[auditStatus] || {};

  // Checking whether that organization has access to audit status button
  const isOrgAllowed = allowedOrgs?.includes(orgType);

  // If allowed, then corresponding button is dispalyed
  if (isOrgAllowed) {
    const pageActions = (
      // TODO :: Enable after fully integrating
      <Button
        className="d-none"
        label={label}
        onClick={() => dispatch(action({ orgId: industryOrgId, plantId, auditId, navigate }))}
        loading={loading}
        disabled={loading}
      />
    );

    return <PageHeader title={pageTitle} actions={pageActions} />;
  }

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

/**
 * Layout Page
 */
export default function AuditLayout() {
  // Dispatch
  const dispatch = useDispatch();

  // Get saved info from the loader
  const { auditInfo } = useLoaderData();

  // Params
  const params = useParams();
  const { auditId } = params;

  // Sidebar Configuration
  const { sideBarElements = [], defaultSelectedMenu = "", backButtonConfig = {} } = AuditLayoutSideBar || {};

  // replace auditId param in all the sidebar urls..
  const paramMap = { auditId: auditId };

  const updatedSideBarElements = constructSideBarUrls(sideBarElements, paramMap);

  // use Effect
  useEffect(() => {
    dispatch(AuditComponentActions.clearAuditComponentInfo());
  }, [auditId]);

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: (
      <div className="d-flex align-items-center justify-content-between">
        {/* Header */}
        <PageHeaderSection auditId={auditId} auditInfo={auditInfo} />
      </div>
    ),
  };

  // Sidebar Config
  const sidebarConfig = {
    menuComponent: (
      <SidebarMenu
        sidebarMenuList={updatedSideBarElements}
        backButtonConfig={backButtonConfig}
        defaultSelectedMenu={defaultSelectedMenu}
      />
    ),
    footerComponent: <SiderbarFooter />,
    headerLogo: { height: 40, logoURL: LOGO_KISEM_TEXT },
    collapsedHeaderLogo: { height: 30, logoURL: LOGO_KISEM },
    redirectURL: RedirectTo.DashboardPageUrl,
    title: "Energy Audit Tool",
  };

  return <Layout sidebarCollapsible={true} topbarConfig={topbarConfig} sidebarConfig={sidebarConfig} />;
}
