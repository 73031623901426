import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

// Constants
import { UserLayoutSideBar } from "../../constants/LayoutConstants";

// URLs
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Layouts
import Layout from "../../app/layout/Layout";
import SidebarMenu from "./SidebarMenu";

// Sidebar Images
import LOGO_KISEM from "../../assests/images/branding/KISEM_LOGO_1.png";
import LOGO_KISEM_TEXT from "../../assests/images/branding/KISEM_LOGO_2.png";

/**
 * Layout Page
 */
export default function UserLayout() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Sidebar Configuration
  const { sideBarElements = [], defaultSelectedMenu = "", backButtonConfig = {} } = UserLayoutSideBar || {};

  const logOutMenu = {
    itemName: "Log out",
    itemIconCls: "fas fa-power-off",
    onClick: () => {
      dispatch(AuthActions.resetLogin({ navigate }));
    },
  };

  // Deep cloning sidebarElements
  const clonedSideBarElements = structuredClone(sideBarElements) || [];

  // Adding log out menu
  if (clonedSideBarElements.length !== 0 && clonedSideBarElements[0]?.items?.length !== 0) {
    clonedSideBarElements[0].items = [...(clonedSideBarElements[0].items || []), logOutMenu];
  }

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: <></>,
  };

  // Sidebar Config
  const sidebarConfig = {
    menuComponent: (
      <SidebarMenu
        sidebarMenuList={clonedSideBarElements}
        backButtonConfig={backButtonConfig}
        defaultSelectedMenu={defaultSelectedMenu}
      />
    ),
    // footerComponent: <SiderbarFooter />,
    headerLogo: { height: 30, logoURL: LOGO_KISEM_TEXT },
    collapsedHeaderLogo: { height: 30, logoURL: LOGO_KISEM },
    redirectURL: RedirectTo.DashboardPageUrl,
    title: "Energy Assessment Tool",
  };

  return <Layout sidebarCollapsible={true} topbarConfig={topbarConfig} sidebarConfig={sidebarConfig} />;
}
