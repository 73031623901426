import React from "react";

// Utils
import { selectFile } from "../../../utils/FileUtils";

// Components
import { Button } from "../../../components/button/Button";

/**
 * Plant Bills Table Action Button
 * @param {*} isFilePresent : Boolean Value
 * @param {*} uploadFile : Function
 * @param {*} fileUploadLoadingStatus : Boolean Value
 */
export default function PlantBillsTableActionButton({ isFilePresent, uploadFile, fileUploadLoadingStatus }) {
  // Function to select file
  async function selectFilehandler() {
    // Getting files
    const files = await selectFile(false);

    // If files are present, upload function is called and the first file is selected
    if (files.length > 0) {
      uploadFile(files[0]);
    }
  }

  const buttonLabel = isFilePresent ? "Update File" : "Upload File";
  const iClassName = isFilePresent ? "fa fa-pen me-2" : "fa fa-upload me-2";

  return (
    <span>
      {/* Button */}
      <Button
        label={buttonLabel}
        color="warning"
        size="sm"
        className="ms-2"
        onClick={selectFilehandler}
        loading={fileUploadLoadingStatus}
        disabled={fileUploadLoadingStatus}
      >
        <i className={iClassName} />
      </Button>
    </span>
  );
}
