import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Create Audit File
  createFileLoading: false,
  createFileError: {},

  // Update Audit File By Id
  updateFileLoading: false,
  updateFileError: {},

  // Download Audit File By Id
  downloadFileLoading: {},
  downloadFileError: {},

  // Delete Audit File By Id
  deleteFileLoading: {},
  deleteFileError: {},
};

// Audit File Slice
const auditFileSlice = createSlice({
  name: "AuditFile",
  initialState,
  reducers: {
    // Create Audit File
    createAuditFile: (state) => {
      state.createFileLoading = true;
    },

    createAuditFileSuccess: (state) => {
      state.createFileLoading = false;
    },

    createAuditFileFailure: (state, action) => {
      state.createFileLoading = false;
      state.createFileError = action.payload.error;
    },

    // Update Audit File By Id
    updateAuditFileById: (state) => {
      state.updateFileLoading = true;
    },

    updateAuditFileByIdSuccess: (state) => {
      state.updateFileLoading = false;
    },

    updateAuditFileByIdFailure: (state, action) => {
      state.updateFileLoading = false;
      state.updateFileError = action.payload.error;
    },

    // Download Audit File By Id
    downloadAuditFileById: (state, action) => {
      const { fileId = "" } = action.payload;

      state.downloadFileLoading = {
        ...state.downloadFileLoading,
        [fileId]: true,
      };
    },

    downloadAuditFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;

      //To get previous state
      const clonedDownloadFileLoading = { ...state.downloadFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDownloadFileLoading[fileId];

      state.downloadFileLoading = clonedDownloadFileLoading;
    },

    downloadAuditFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;

      //To get previous state
      const clonedDownloadFileLoading = { ...state.downloadFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDownloadFileLoading[fileId];

      state.downloadFileLoading = clonedDownloadFileLoading;
      state.downloadFileError = error;
    },

    // Delete Audit File By Id
    deleteAuditFileById: (state, action) => {
      const { fileId = "" } = action.payload;

      state.deleteFileLoading = {
        ...state.deleteFileLoading,
        [fileId]: true,
      };
    },

    deleteAuditFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;

      //To get previous state
      const clonedDeleteFileLoading = { ...state.deleteFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDeleteFileLoading[fileId];

      state.deleteFileLoading = clonedDeleteFileLoading;
    },

    deleteAuditFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;

      //To get previous state
      const clonedDeleteFileLoading = { ...state.deleteFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDeleteFileLoading[fileId];

      state.deleteFileLoading = clonedDeleteFileLoading;
      state.deleteFileError = error;
    },
  },
});

// Reducer
export const AuditFileReducer = auditFileSlice.reducer;

// Actions
export const AuditFileActions = auditFileSlice.actions;
