import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { InstrumentActions } from "../../../redux-slice/instrument/InstrumentSlice";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableLoader from "../../../components/table/TableLoader";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Model",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "File Type",
    },
  },
  {
    title: {
      displayName: "Content Information",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function InstrumentListTableStatusCell({ instrumentId, isActive, pageNumber, pageSize }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  //Selector State
  const updateInstrumentStatusLoading = useSelector((state) => state.instrument.updateInstrumentStatusLoading);

  // Changing Status
  const status = !isActive;

  // Loading Status
  const loading = updateInstrumentStatusLoading[instrumentId];

  // Navigate to instrument view page
  function navigateToInstrumentViewPage() {
    const redirectUrl = URLs.format(RedirectTo.InstrumentViewPageUrl, { instrumentId });
    navigate(redirectUrl);
  }

  //Loader
  return (
    <td>
      {/* Button */}
      <Button
        label={isActive ? "Disable" : "Enable"}
        color={isActive ? "danger" : "success"}
        size="sm"
        loading={loading}
        disabled={loading}
        onClick={() =>
          dispatch(InstrumentActions.updateInstrumentStatus({ instrumentId, status, pageNumber, pageSize }))
        }
      />

      {/* Button */}
      <Button label="View" size="sm" onClick={navigateToInstrumentViewPage}>
        <i className="fa-solid fa-eye"></i>
      </Button>
    </td>
  );
}

function InstrumentListTableRow({ serialNo, instrument, pageNumber, pageSize }) {
  const { id, name, model, code, fileType, contentInfo, active } = instrument;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>
      <td>{model}</td>
      <td>{code}</td>
      <td>{fileType}</td>
      <td>{contentInfo}</td>

      {/* Instrument Table Status Cell */}
      <InstrumentListTableStatusCell instrumentId={id} isActive={active} pageNumber={pageNumber} pageSize={pageSize} />
    </tr>
  );
}

function InstrumentListTableBody({ instrumentListLoading = false, instrumentList = [], pageNumber, pageSize }) {
  //Loader
  if (instrumentListLoading) {
    return <TableLoader colSpan={8} />;
  }

  //if there is no instrument list
  if (instrumentList.length === 0) {
    return <TableDataNotFound message="No instruments present" colSpan={8} />;
  }

  // Instrument list table row
  return (
    <>
      {instrumentList.map((instrument, index) => {
        // Serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <InstrumentListTableRow
            serialNo={serialNo}
            key={instrument.id}
            instrument={instrument}
            pageNumber={pageNumber}
            pageSize={pageSize}
          />
        );
      })}
    </>
  );
}

/**
 * Instrument List Table
 */
export default function InstrumentListTable() {
  // Selector State
  const instrumentList = useSelector((state) => state.instrumentList.instrumentList);
  const instrumentListLoading = useSelector((state) => state.instrumentList.instrumentListLoading);
  const instrumentListPagination = useSelector((state) => state.instrumentList.instrumentListPagination);

  const { totalCount, pageNo, pageSize } = instrumentListPagination || {};

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Instrument List Table Body */}
          <InstrumentListTableBody
            instrumentListLoading={instrumentListLoading}
            instrumentList={instrumentList}
            pageNumber={pageNo}
            pageSize={pageSize}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
