import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get plant thermal bill list
  plantThermalBillList: [],
  plantThermalBillListPagination: {},
  plantThermalBillListLoading: false,
  plantThermalBillListError: "",
};

// Plant Thermal Bill List Slice
const plantThermalBillListSlice = createSlice({
  name: "PlantThermalBillList",
  initialState,
  reducers: {
    // Get plant Thermal bill list
    getPlantThermalBillList: (state) => {
      state.plantThermalBillListLoading = true;
    },

    getPlantThermalBillListSuccess: (state, action) => {
      state.plantThermalBillListLoading = false;
      state.plantThermalBillList = action.payload.plantThermalBillList;
      state.plantThermalBillListPagination = action.payload.plantThermalBillListPagination;
    },

    getPlantThermalBillListFailure: (state, action) => {
      state.plantThermalBillListLoading = false;
      state.plantThermalBillListError = action.payload.error;
    },
  },
});

// Reducer
export const PlantThermalBillListReducer = plantThermalBillListSlice.reducer;

// Actions
export const PlantThermalBillListActions = plantThermalBillListSlice.actions;
