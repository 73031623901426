import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { AuditObservationAndRecommendationListActions } from "../../../../redux-slice/audit/ObservationAndRecommendationListSlice";

// Utils
import { constructParametersFormInputs } from "../../AuditUtils";

// Components
import CardCollapsible from "../../../../components/card-collapsible/CardCollapsible";
import Loader from "../../../../components/loader/Loader";
import TabularForm from "../../../../components/form/TabularForm";

// Sections
import ComponentSelect from "../ComponentSelect";
import AuditObservationListTable from "./AuditObservationListTable";

// Page Constants
const propertiesTableHeaders = [
  {
    title: {
      displayName: "Design Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

const calculationTableHeaders = [
  {
    title: {
      displayName: "Calculation Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

const measurementTableHeaders = [
  {
    title: {
      displayName: "Measurements Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Components
function AuditParametersCollapsibleCard() {
  // Audit Component Info Selector State
  const auditComponentInfo = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Getting details of the component
  const { component: componentInfo = {} } = auditComponentInfo || {};
  const { componentTypeId = "" } = componentInfo || {};

  // Converting audit component info to string
  const auditComponentInfoStr = JSON.stringify(auditComponentInfo);

  // Form inputs for property, measurement and calculation
  // use Memo
  const {
    propertyInputs = [],
    measurementInputs = [],
    calculationInputs = [],
  } = useMemo(() => {
    // Table Config
    const tableConfig = {
      propertyTable: {
        showUnits: true,
      },
      measurementTable: {
        showUnits: true,
      },
      calculationTable: {
        showUnits: true,
      },
    };

    return constructParametersFormInputs(auditComponentInfo, tableConfig);
  }, [auditComponentInfoStr, componentTypeId]);

  return (
    <>
      <CardCollapsible collapsed={false} header={"Properties"}>
        {/* Property  */}
        <TabularForm tableHeaders={propertiesTableHeaders} formInputItems={propertyInputs} showSubmitButton={false} />
      </CardCollapsible>

      <CardCollapsible className="mt-2" collapsed={false} header={"Measurement"}>
        {/* Measurement  */}
        <TabularForm
          tableHeaders={measurementTableHeaders}
          formInputItems={measurementInputs}
          showSubmitButton={false}
        />
      </CardCollapsible>

      <CardCollapsible className="mt-2" collapsed={false} header={"Calculation"}>
        {/* Calculation  */}
        <TabularForm
          tableHeaders={calculationTableHeaders}
          formInputItems={calculationInputs}
          showSubmitButton={false}
        />
      </CardCollapsible>
    </>
  );
}

function AuditObservationsBody() {
  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Audit Observation And Recommendation List Selector State
  const auditObsAndRecListLoading = useSelector((state) => state.auditObsAndRecList.auditObsAndRecListLoading);

  // Loader
  if (auditObsAndRecListLoading) {
    return <Loader />;
  }

  // If component is not selected
  if (Object.keys(componentInfo).length === 0) {
    return <div>Please select a component</div>;
  }

  return (
    <div className="row">
      <div className="col-4">
        {/* Audit Parameters Collapsible Card */}
        <AuditParametersCollapsibleCard />
      </div>

      <div className="col-8">
        {/* Audit Observation List Table */}
        <AuditObservationListTable />
      </div>
    </div>
  );
}

/**
 * Audit Observations Page
 */
export default function AuditObservationsPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Get Path  Params
  const params = useParams();
  const { auditId } = params;

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);
  const auditComponentInfoLoading = useSelector((state) => state.auditComponent.auditComponentInfoLoading);

  // Selected Component Info
  const { id: selectedComponentId = "" } = componentInfo || {};

  // use Effect
  useEffect(() => {
    if (selectedComponentId) {
      dispatch(
        AuditObservationAndRecommendationListActions.getAuditObsAndRecList({
          componentId: selectedComponentId,
          auditId,
        })
      );
    }
  }, [dispatch, auditId, selectedComponentId]);

  return (
    <div div className="page-content">
      {/* Component Select */}
      <ComponentSelect />

      {/* Loading */}
      {auditComponentInfoLoading && <Loader />}

      {!auditComponentInfoLoading && (
        <>
          {/* Audit Observations Body */}
          <AuditObservationsBody />
        </>
      )}
    </div>
  );
}
