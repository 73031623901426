import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Audit Observation And Recommendation List
  auditObsAndRecList: [],
  auditObsAndRecListPagination: {},
  auditObsAndRecListLoading: false,
  auditObsAndRecListError: "",
};

const auditObsAndRecListSlice = createSlice({
  name: "AuditObservationAndRecommendationList",
  initialState,
  reducers: {
    // Get Audit Observation And Recommendation List
    getAuditObsAndRecList: (state) => {
      state.auditObsAndRecListLoading = true;
    },

    getAuditObsAndRecListSuccess: (state, action) => {
      state.auditObsAndRecListLoading = false;
      state.auditObsAndRecList = action.payload.auditObsAndRecList;
      state.auditObsAndRecListPagination = action.payload.auditObsAndRecListPagination;
    },

    getAuditObsAndRecListFailure: (state, action) => {
      state.auditObsAndRecListLoading = false;
      state.auditObsAndRecListError = action.payload.error;
    },
  },
});

// Reducer
export const AuditObservationAndRecommendationListReducer = auditObsAndRecListSlice.reducer;

// Actions
export const AuditObservationAndRecommendationListActions = auditObsAndRecListSlice.actions;
