// Urls
import {
  addPlantUrl,
  updatePlantByIdUrl,
  updatePlantStatusByIdUrl,
  getPlantInfoByIdUrl,
} from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Add Plant
export async function addPlantApi(plantData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(addPlantUrl, {}, plantData);
}

// Update Plant
export async function updatePlantByIdApi(plantData, plantId) {
  const url = URLs.format(updatePlantByIdUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, plantData);
}

// Update Plant status
export async function updatePlantStatusByIdApi(plantId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updatePlantStatusByIdUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Get Plant Info
export async function getPlantInfoByIdApi(plantId) {
  const url = URLs.format(getPlantInfoByIdUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}
