// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getOrganizationListUrl } from "../../urls/api-urls/OrganizationURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Organization List
export async function getOrganizationListApi(orgType, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    type: orgType,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getOrganizationListUrl, params);
}
