import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { PlantComponentPartsActions } from "../../../redux-slice/plant/PartsSlice";

// Constants
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import { getComponentParts, getComponentTypeParameters } from "../../../utils/DataPreLoadUtils";
import {
  DefaultInputRows,
  RemarksInputRow,
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../utils/ComponentUtils";

// Components
import { DefaultFormValidationSchema } from "../../../components/form/Form";
import Modal from "../../../components/modal/Modal";
import TabularForm from "../../../components/form/TabularForm";

// Section
import ViewComponentModal from "./ViewComponentModal";

/**
 * View Plant Component Modal
 */
export default function ViewPlantComponentPartModal({
  componentTypeId,
  selectedComponentIndex = "",
  selectedPartId = "",
  tableHeaders = [],
  showModal = false,
  setShowModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);

  // Add Plant Component Part Selector State
  const addPlantComponentPartLoading = useSelector((state) => state.plantComponentParts.addPlantComponentPartLoading);

  // Update Plant Component Part Selector State
  const updatePlantComponentPartLoading = useSelector(
    (state) => state.plantComponentParts.updatePlantComponentPartLoading
  );

  // Getting parts for that component (Default)
  const parts = getComponentParts(componentTypeId) || [];

  // Getting part info (Default)
  const { id: partId = "", name: partName = "" } = parts.find(({ id = "" }) => id === parseInt(selectedPartId)) || {};

  // Plant Component Information
  const { component: plantComponent = {}, parts: addedParts = [] } = plantComponentList[selectedComponentIndex] || {};
  const { id: componentId = "", name = "", internalId = "" } = plantComponent;

  // Finding component and properties for the selected part
  // Here componentTypeId refers to part id (From plant component list)
  const selectedPartInfo =
    addedParts.find(({ component }) => parseInt(component?.id ?? 0) === parseInt(selectedPartId)) || {};

  // Selected Part Properties Information (Added parts)
  const { component = {}, properties = [] } = selectedPartInfo || {};
  const { componentTypeId: componentPartId = "", id = "" } = component;

  // Getting property list for the selected part or new part
  // For view or editing the part, component part id is used
  const propertyList = useMemo(() => {
    return getComponentTypeParameters(componentPartId || partId) || [];
  }, [componentPartId, partId]);

  // Add parts form data
  const addPartsFormData = useMemo(() => {
    return constructFormDataObject(propertyList);
  }, [propertyList]);

  // Form Inputs
  const formInputItems = useMemo(() => {
    // Table Config
    const tableConfig = {
      showUnits: false,
      isInputPresent: true,
      isUnitConversionPresent: true,
    };

    const formInputRows = getParametersFormInputs(propertyList, properties, tableConfig, id, true) || [];
    return [...DefaultInputRows, ...formInputRows, RemarksInputRow];
  }, [id, properties, propertyList]);

  // Adding part to component form submit
  function addPlantComponentPartFormSubmit(partFormData) {
    const { name = "", internalId = "", remarks = "" } = partFormData;

    // Constructing parts properties
    const propertiesArray = constructParameterValuesArray(propertyList, partFormData);

    // Component object
    const partData = { componentTypeId: partId, name, internalId, remarks, properties: propertiesArray };

    // Dispatch
    dispatch(PlantComponentPartsActions.addPlantComponentPart({ partData, plantId, componentId, componentTypeId }));
  }

  return (
    <>
      {/* View Part Modal */}
      <ViewComponentModal
        modalId={ModalKeys.viewPlantComponentPartModal}
        componentInfo={selectedPartInfo}
        tableHeaders={tableHeaders}
        loading={updatePlantComponentPartLoading}
        dispatchAtn={PlantComponentPartsActions.updatePlantComponentPart}
        formInputItems={formInputItems}
        partId={id}
        isPart={true}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      {/* Add Part Modal */}
      <Modal
        id={ModalKeys.addPlantComponentPartModal}
        title={`${name} (${internalId}) - Add ${partName}`}
        size="xl"
        setShowModal={setShowModal}
      >
        {/* Add Plant Component Part Form */}
        <TabularForm
          tableHeaders={tableHeaders}
          loading={addPlantComponentPartLoading}
          formInputItems={formInputItems}
          formValidationSchema={DefaultFormValidationSchema}
          data={addPartsFormData}
          formSubmit={addPlantComponentPartFormSubmit}
          showModal={showModal}
        />
      </Modal>
    </>
  );
}
