import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Measurement List
  measurementTypeList: [],
  measurementTypeListPagination: {},
  measurementTypeListError: "",
  measurementTypeLoading: false,

  // Get Measurement units list
  measurementUnitsList: [],
  measurementUnitsListPagination: {},
  measurementUnitsListLoading: false,
  measurementUnitsListError: "",
};

// Measurement Type List Slice
const measurementTypeListSlice = createSlice({
  name: "MeasurementTypeList",
  initialState,
  reducers: {
    // Get Measurement Type List
    getMeasurementTypeList: (state) => {
      state.measurementTypeLoading = true;
    },

    getMeasurementTypeListSuccess: (state, action) => {
      state.measurementTypeLoading = false;
      state.measurementTypeList = action.payload.measurementTypeList;
      state.measurementTypeListPagination = action.payload.measurementTypeListPagination;
    },

    getMeasurementTypeListFailure: (state, action) => {
      state.measurementTypeLoading = false;
      state.measurementTypeListError = action.payload.error;
    },

    // Get Measurement Units List
    getMeasurementUnitsList: (state) => {
      state.measurementUnitsListLoading = true;
    },

    getMeasurementUnitsListSuccess: (state, action) => {
      state.measurementUnitsListLoading = false;
      state.measurementUnitsList = action.payload.measurementUnitsList;
      state.measurementUnitsListPagination = action.payload.measurementUnitsListPagination;
    },

    getMeasurementUnitsListFailure: (state, action) => {
      state.measurementUnitsListLoading = false;
      state.measurementUnitsListError = action.payload.error;
    },
  },
});

// Reducer
export const MeasurementTypeListReducer = measurementTypeListSlice.reducer;

// Actions
export const MeasurementTypeListActions = measurementTypeListSlice.actions;
