// Utils
import { formatText } from "../../../utils/StringUtils";

// Components
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeader";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Column Number",
    },
  },
  {
    title: {
      displayName: "Column Name",
    },
  },
  {
    title: {
      displayName: "Sheet Number",
    },
  },
  {
    title: {
      displayName: "Measurement Type",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Components
function DataColumnListTableRow({ dataColumn }) {
  const { colNo, colName, sheetNo, measurementName = "", unitName = "" } = dataColumn;

  return (
    <tr>
      <td>{colNo}</td>
      <td>{colName}</td>
      <td>{sheetNo}</td>
      <td>{formatText(measurementName)}</td>
      <td>{formatText(unitName)}</td>
    </tr>
  );
}

function DataColumnListTableBody({ dataColumnInfo }) {
  // If there is no data present
  if (dataColumnInfo.length === 0) {
    return <TableDataNotFound message="No data column list present" colSpan={6} />;
  }

  return dataColumnInfo.map((dataColumn, index) => {
    return <DataColumnListTableRow key={index} dataColumn={dataColumn} />;
  });
}

/**
 * Instrument Data Column List Card
 */
export default function InstrumentDataColumnListCard({ instrumentInfo, dataColumnInfo }) {
  const { fileType } = instrumentInfo;

  return (
    <div className="card mt-4 p-3">
      <div className="card-body">
        <h5 className="card-title">Output File Setup</h5>

        <div className="row mt-4">
          <div className="col-7 bold">File Type</div>
          <div className="col-5">{fileType}</div>
        </div>

        <div className="fw-semibold my-3">Column Definitions</div>

        <table className="table table-bordered border-end mt-3">
          {/* Table Headers */}
          <TableHeaders tableHeaders={tableHeaders} />

          <tbody>
            {/* Data Column List Table Body */}
            <DataColumnListTableBody dataColumnInfo={dataColumnInfo} />
          </tbody>
        </table>
      </div>
    </div>
  );
}
