import { useDispatch, useSelector } from "react-redux";

// Action
import { ProductActions } from "../../../redux-slice/product/ProductSlice";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Measurement Type",
    },
  },
  {
    title: {
      displayName: "Unit",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ProductListTableStatusCell({ productId, isActive, pageNumber, pageSize }) {
  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateProductStatusLoadingMap = useSelector((state) => state.product.updateProductStatusLoadingMap);

  // Changing Status
  const status = !isActive;

  // Loading Status
  const loading = updateProductStatusLoadingMap[productId];

  //Loader
  return (
    <td>
      {/* Button */}
      <Button
        label={isActive ? "Disable" : "Enable"}
        color={isActive ? "danger" : "success"}
        size="sm"
        loading={loading}
        disabled={loading}
        onClick={() => dispatch(ProductActions.updateProductStatus({ productId, status, pageNumber, pageSize }))}
      />
    </td>
  );
}

function ProductListTableRow({ product, pageNumber, pageSize }) {
  const { id, name, description, measurementTypeName, unitSymbol, isActive } = product;

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{description}</td>
      <td>{measurementTypeName}</td>
      <td>{unitSymbol}</td>

      {/* Product Table Status Cell */}
      <ProductListTableStatusCell productId={id} isActive={isActive} pageNumber={pageNumber} pageSize={pageSize} />
    </tr>
  );
}

function ProductListTableBody({ productListLoading, productList, pageNumber, pageSize }) {
  //Loader
  if (productListLoading) {
    return <TableLoader colSpan={6} />;
  }

  //if there is no product list
  if (productList.length === 0) {
    return <TableDataNotFound message="No products present" colSpan={6} />;
  }

  //Product list table row
  return (
    <>
      {productList.map((product) => {
        return <ProductListTableRow key={product.id} product={product} pageNumber={pageNumber} pageSize={pageSize} />;
      })}
    </>
  );
}

/**
 * Product List Table
 */
export default function ProductListTable({
  productList = [],
  productListLoading = false,
  productListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize } = productListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Product List Table Body */}
          <ProductListTableBody
            pageNumber={pageNumber}
            pageSize={pageSize}
            productListLoading={productListLoading}
            productList={productList}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
