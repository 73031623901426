// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getHSNCodeListUrl } from "../../urls/api-urls/HSNCodeURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get HSN-Code List
export async function getHSNCodeListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getHSNCodeListUrl, params);
}
