// Components
import { Button } from "../button/Button";

// Page Constants
const MODAL_SIZE = {
  sm: "modal-sm",
  lg: "modal-lg",
  xl: "modal-xl",
};

/**
 * Modal Component
 * @param {*} id : To toggle each modal
 * @param {*} title : Modal title
 * @param {*} size : Modal size
 */
export default function Modal({ id = "", title = "", size = "sm", className = "", setShowModal = () => {}, children }) {
  // Modal Size
  const modalSize = MODAL_SIZE[size || ""];

  return (
    <div className={`modal fade`} id={id} data-bs-backdrop="static" aria-hidden="true">
      <div className={`modal-dialog ${className} ${modalSize}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {title}
            </h1>

            {/* Button */}
            <Button
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
}

//
// Other Utilities
// ----------------------------------------------------------------------------

const close = (modalId) => {
  // Selector
  const btnSelector = modalId ? "#" + modalId + " .btn-close" : ".modal .btn-close";

  // Element
  const modalCloseBtn = document.querySelector(btnSelector);

  // Trigger Click
  if (modalCloseBtn) {
    modalCloseBtn.click();
  }
};

export const ModalActions = {
  close,
};
