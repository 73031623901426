import { Link } from "react-router-dom";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

//Components
import Input from "../../components/input/Input";
import Logo from "../../components/logo/Logo";
import { Button } from "../../components/button/Button";

//Images
import Ieac_logo from "../../assests/images/favicon_io/ieac-512x512.png";
import profile from "../../assests/images/login-page/profile-img.png";

//Page Components
function ForgotPasswordCardHeader() {
  return (
    <div className="card overflow-hidden">
      <div className="bg-light-blue">
        <div className="row">
          <div className="col-7">
            <div className="text-primary p-4">
              <h5 className="text-primary">Welcome Back !</h5>
              <p>Sign in to continue to KISEM.</p>
            </div>
          </div>
          <div className="col-5">
            <img src={profile} className="card-img-top" alt="profile" />
          </div>
        </div>
      </div>
    </div>
  );
}

function ForgotPasswordCardBody() {
  return (
    <div className="position-relative">
      {/* Logo Component */}
      <Logo
        containerClassName="avatar-md mb-4 bg-white rounded-circle d-flex align-items-center justify-content-center"
        imageHeight={50}
        imageUrl={Ieac_logo}
      />

      <div className="p-2 pt-5">
        <form className="form-horizontal">
          <div className="mb-3">
            <label>Email</label>

            {/* Input */}
            <Input name="email" className="form-control mt-2" placeholder="Enter email" type="email" />
          </div>
          <div className="row mb-3">
            <div className="col text-end">
              {/* Button Component */}
              <Button className="w-md w-25" color="primary" label="Reset" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

function ForgotPasswordCard() {
  return (
    <div className="card overflow-hidden">
      {/* Forgot Password Card Header */}
      <ForgotPasswordCardHeader />
      <div className="card-body">
        {/* Forgot Password Card Body */}
        <ForgotPasswordCardBody />
      </div>
    </div>
  );
}

function ForgotPasswordFooter() {
  return (
    <div className="mt-5 text-center">
      <p>
        Go back to{" "}
        <Link to={RedirectTo.LoginPageUrl} className="font-weight-medium text-primary text-decoration-none">
          Login
        </Link>{" "}
      </p>
      <p>© {new Date().getFullYear()} KISEM.</p>
    </div>
  );
}

/**
 * Forgot Password Page
 */
export default function ForgotPasswordPage() {
  return (
    <div className="my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            {/* Forgot Password Card */}
            <ForgotPasswordCard />
            {/* Forgot Password Footer */}
            <ForgotPasswordFooter />
          </div>
        </div>
      </div>
    </div>
  );
}
