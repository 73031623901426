/**
 * Abbreviations Page
 */
export default function AbbreviationsPage() {
  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">ABBREVATIONS</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td className="text-center">ECM</td>
            <td className="text-start">Energy Conservation Measures</td>
          </tr>
          <tr>
            <td className="text-center">EC fan</td>
            <td className="text-start">Electronically Commutated fan</td>
          </tr>
          <tr>
            <td className="text-center">INR</td>
            <td className="text-start">Indian Rupee</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
