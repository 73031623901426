// Constants
export const LocalStorageKeys = {
  AUTH_USER: "AUTH_USER",
  AUTH_TOKEN: "AUTH_TOKEN",
  PERMISSIONS: "PERMISSIONS",
  ORG_TYPE: "ORG_TYPE",
  AUTH_ORG: "AUTH_ORG",
};

export const setItem = (key, val) => {
  localStorage.setItem(key, val);
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

// Utils :: AUTH_TOKEN
// ----------------------------------------------------------------------------

export const setAuthToken = (authToken) => {
  localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, authToken);
};

export const getAuthToken = () => {
  return localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
};

export const removeAuthToken = () => {
  localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
};
