import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

// Actions
import { AuditObservationAndRecommendationActions } from "../../../../redux-slice/audit/ObservationAndRecommendationSlice";

// Constants
import { ModalKeys } from "../../../../constants/ModalConstants";
import { DataPreLoadKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";

// Components
import { Button } from "../../../../components/button/Button";
import Modal from "../../../../components/modal/Modal";
import TableHeaders from "../../../../components/table/TableHeader";
import CheckBox from "../../../../components/checkbox/CheckBox";
import TableDataNotFound from "../../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
];

// Page Components
function RecommendationsTableCheckboxCell({ recommendation = {}, recommendationList = [], setRecommendationList }) {
  const { id: recommendationId = "", isChecked = false } = recommendation;

  // Function
  function handleChange({ target = {} }) {
    const { id = "", checked = "" } = target;

    // Cloning recommendation list array
    const clonedRecommendationList = [...recommendationList];

    // Is checkbox selected or not
    const isChecked = checked;

    // Finding the index position of the changed recommendation
    const recommendationListIndex = clonedRecommendationList.findIndex(
      (eachRecommendation) => eachRecommendation.id === parseInt(id)
    );

    // Updating the object by spreading the old value and changing only the ischecked value
    clonedRecommendationList[recommendationListIndex] = {
      ...clonedRecommendationList[recommendationListIndex],
      isChecked,
    };

    // Updating the state
    setRecommendationList(clonedRecommendationList);
  }

  return (
    <td>
      {/* CheckBox */}
      <CheckBox id={recommendationId} checked={isChecked} onChange={handleChange} />
    </td>
  );
}

function RecommendationsTablebody({ recommendationList = [], setRecommendationList }) {
  // If there is no recommendation
  if (recommendationList.length === 0) {
    return <TableDataNotFound message="No Recommendation data present" colSpan={8} />;
  }

  return recommendationList.map((recommendation, index) => {
    const { code, description } = recommendation;

    return (
      <tr key={index}>
        {/* Recommendations Table Checkbox Cell */}
        <RecommendationsTableCheckboxCell
          recommendationList={recommendationList}
          setRecommendationList={setRecommendationList}
          recommendation={recommendation}
        />

        <td>{code}</td>
        <td>{description}</td>
      </tr>
    );
  });
}

function AuditObservationModalBodyRecommendationsTable({ recommendationList, setRecommendationList }) {
  return (
    <div className="table-responsive mx-4 mt-4">
      <h5 className="sec-title mb-3">Recommendations</h5>

      <table className="table">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Recommendations Table body */}
          <RecommendationsTablebody
            recommendationList={recommendationList}
            setRecommendationList={setRecommendationList}
          />
        </tbody>
      </table>
    </div>
  );
}

function AuditObservationModalBodyNotes({ notes, setNotes }) {
  return (
    <div className="mx-4">
      <label className="form-label fw-semibold">Observation Notes</label>

      <textarea
        value={notes}
        onChange={({ target }) => setNotes(target.value)}
        className="d-block form-control shadow-none"
        cols="30"
        rows="5"
      />
    </div>
  );
}

/**
 * Audit Observation Modal
 */
export default function AuditObservationModal({ selectedObservation, showModal = false, setShowModal = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { auditId = "" } = params;

  // State
  const [notes, setNotes] = useState("");
  const [recommendationList, setRecommendationList] = useState([]);

  // Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Update Observation And Recommendation Selector State
  const updateObsAndRecLoading = useSelector((state) => state.auditObsAndRec.updateObsAndRecLoading);

  // Recommendation Codes Data
  const { recommendationCodesByComponent = {} } =
    JSON.parse(getItem(DataPreLoadKeys.RECOMMENDATION_CODES || "{}")) || {};

  // Component Info
  const {
    id: componentId = "",
    name: componentName = "",
    internalId: componentType = "",
    componentTypeId = "",
  } = componentInfo || {};

  // Getting observation details
  const {
    notes: observationNotes = "",
    isObserved = false,
    observationId = "",
    observationCodeId = "",
    observationCode = "",
    description = "",
  } = selectedObservation || {};

  // Loading Status
  const loading = updateObsAndRecLoading[observationId];

  // use Memo
  const recCodeList = useMemo(() => {
    return recommendationCodesByComponent[componentTypeId] || [];
  }, [componentTypeId]);

  // use Effect
  useEffect(() => {
    setNotes(observationNotes);
    setRecommendationList(constructRecommendationCodeList());
  }, [observationNotes, observationId]);

  // Function construct recommendation code list
  function constructRecommendationCodeList() {
    const selectedRecommendations = selectedObservation.recommendations || [];

    return recCodeList.map((recommendation) => {
      const { id = "", code = "", description = "" } = recommendation || {};

      const isChecked = selectedRecommendations.some((recommendation) => recommendation.codeId === parseInt(id));

      return { id, code, description, isChecked };
    });
  }

  // Function save recommendations
  function saveRecommendations() {
    // Getting all the selected recommendation codes
    const recommendationCodeIds = recommendationList.reduce((acc, recommendation) => {
      const { id, isChecked } = recommendation;

      if (isChecked) {
        return [...acc, id];
      }

      return acc;
    }, []);

    const observationData = {
      id: observationId,
      auditInfoId: auditId,
      elementStr: "COMPONENT",
      elementId: componentId,
      elementTypeId: componentTypeId,
      observationCodeId,
      isObserved,
      notes,
      recommendationCodeIds,
    };

    // Dispatch
    dispatch(
      AuditObservationAndRecommendationActions.updateAuditObsAndRec({
        observationData,
        observationId,
      })
    );
  }

  useEffect(() => {
    if (!showModal) {
      setNotes(observationNotes);
      setRecommendationList(constructRecommendationCodeList());
    }
  }, [showModal]);

  return (
    <Modal
      id={ModalKeys.addRecommendationsModal}
      title={`${componentName} (${componentType})`}
      size="xl"
      setShowModal={setShowModal}
    >
      <div className="d-flex mx-4 mb-4 align-items-center justify-content-between">
        <div>
          <b>Observation Code :</b> {observationCode}
          <div className="mt-2">
            <b>Description :</b> {description}
          </div>
        </div>

        {/* Button */}
        <Button
          label="save"
          loading={loading}
          disabled={loading}
          color="success"
          className="mb-2"
          onClick={saveRecommendations}
        />
      </div>

      {/* Audit Observation Modal Body Notes */}
      <AuditObservationModalBodyNotes notes={notes} setNotes={setNotes} />

      {/* Audit Observation Modal Body Recommendations Table */}
      <AuditObservationModalBodyRecommendationsTable
        recommendationList={recommendationList}
        setRecommendationList={setRecommendationList}
      />
    </Modal>
  );
}
