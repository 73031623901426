// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Urls
import {
  createAuditReportUrl,
  deleteAuditReportUrl,
  downloadAuditReportPDFUrl,
  getAuditReportDataUrl,
  getAuditReportHTMLUrl,
  updateAuditReportStatusUrl,
  updateAuditReportUrl,
} from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Audit Report Api
export async function createReportApi(auditId, reportData) {
  const url = URLs.format(createAuditReportUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, reportData);
}

// Update Audit Report Api
export async function updateReportApi(auditId, reportId, reportData) {
  const url = URLs.format(updateAuditReportUrl, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, reportData);
}

// Delete Audit Report Api
export async function deleteReportApi(auditId, reportId) {
  const url = URLs.format(deleteAuditReportUrl, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.delete(url);
}

// Get Audit Report HTML Api
export async function getReportHTMLApi(auditId, reportId) {
  const url = URLs.format(getAuditReportHTMLUrl, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Download Audit Report PDF Api
export async function downloadReportPDFApi(auditId, reportId, reportData) {
  const url = URLs.format(downloadAuditReportPDFUrl, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, reportData, ContentType.APP_JSON, "blob");
}

// Update Audit Report Status Api
export async function updateReportStatusApi(auditId, reportId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateAuditReportStatusUrl, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Get Audit Report Data Api
export async function getAuditReportDataApi(auditId, reportType) {
  const params = {};

  if (reportType) {
    params.ty = reportType;
  }

  const url = URLs.format(getAuditReportDataUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url, params);
}
