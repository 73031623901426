import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { DataPreLoadActions } from "./DataPreLoadSlice";
import { ErrorActions } from "../error/ErrorSlice";

// Utils
import { setItem } from "../LocalStorage";

// Api
import { dataPreLoadApi } from "./DataPreLoadApi";

// Data Pre Load
function* dataPreLoad(action) {
  const { key = "", apiUrl = "" } = action.payload;

  try {
    const data = yield dataPreLoadApi(apiUrl);

    // Setting value in local storage
    setItem(key, JSON.stringify(data));

    // Dispatching Action
    yield put(DataPreLoadActions.dataPreLoadSuccess());
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(DataPreLoadActions.dataPreLoadFailure({ error }));
  }
}

export default function* root() {
  yield all([takeEvery(DataPreLoadActions.dataPreLoad.type, dataPreLoad)]);
}
