import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useRevalidator } from "react-router-dom";

// Utils
import { formatText } from "../../../../utils/StringUtils";

// Components
import { Button } from "../../../../components/button/Button";
import SearchDropdown from "../../../../components/search-dropdown/SearchDropdown";

/**
 * Incharge Information
 */
export default function InchargeInfo({ labelName, inchargeName = "", organizationName, data, action, loading }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { auditId } = params;

  // Revalidator Hook
  const revalidator = useRevalidator();

  // State
  const [name, setName] = useState(inchargeName);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");

  // Label
  const label = name ? `+ Update ${labelName}` : `+ Add ${labelName}`;

  // Constructing options for each data list inputs
  function constructOptions(data = []) {
    // Constructing options array
    const optionsArray = data?.reduce((acc, eachObj) => {
      const { firstName = "", lastName = "" } = eachObj;

      return [...acc, { value: `${firstName} ${lastName}` }];
    }, []);

    return optionsArray;
  }

  // Cancel Edit Function
  function cancelEdit() {
    setName(inchargeName);
    setError("");
    setIsEdit(false);
  }

  // onChange Function
  function addOrUpdateUser({ data, name, setIsEdit }) {
    setName(name);

    const { id = "" } = data.find((user) => `${user.firstName} ${user.lastName}` === name) || {};

    // If the user does not exist
    if (!id) {
      return setError("User not found !!");
    }

    // If user is there, Error is reset
    setError("");

    // Dispatch
    dispatch(action({ auditId, userId: id, setIsEdit, revalidator }));
  }

  return (
    <>
      <div className="col-4">
        <p className="bold">{labelName}</p>
      </div>
      <div className="col-8">
        {isEdit && (
          <SearchDropdown
            id={organizationName}
            value={name}
            options={constructOptions(data)}
            placeholder="Search user..."
            className={isEdit ? "" : "bg-transparent border border-0 p-0"}
            onChange={(target) => setName(target.value)}
            isEdit={!isEdit}
            loading={loading}
            disabled={!isEdit}
            error={error}
          />
        )}
        {/* If Edit is false, previous value is shown */}
        {!isEdit && <div>{formatText(name)}</div>}
        {/* If loading is true, button is not displayed */}
        {isEdit && !loading && (
          <div className="mt-2">
            {/* Button */}
            <Button
              label="Update"
              size="sm"
              color="warning"
              onClick={() => {
                addOrUpdateUser({ data, name, setIsEdit });
              }}
            />
            <Button label="Cancel" size="sm" color="dark" className="ms-2" onClick={cancelEdit} />
          </div>
        )}
        {/* Button */}
        {!isEdit && <Button label={label} className="p-0" color="link" onClick={() => setIsEdit(!isEdit)} />}
      </div>
    </>
  );
}
