import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { PlantElectricityBillActions } from "../../../../redux-slice/plant/ElectricityBillSlice";

// Constants
import { ModalKeys } from "../../../../constants/ModalConstants";

// Utils
import { getMonthName } from "../../../../utils/DateUtils";
import tableSerialNumber from "../../../../utils/TableUtils";

// Components
import { Button } from "../../../../components/button/Button";
import TableHeaders from "../../../../components/table/TableHeader";
import TablePagination from "../../../../components/table/TablePagination";
import TableLoader from "../../../../components/table/TableLoader";
import TableDataNotFound from "../../../../components/table/TableDataNotFound";

// Sections
import PlantBillsTableActionButton from "../PlantBillsTableActionButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Demand Sanctioned",
    },
  },
  {
    title: {
      displayName: "Demand Recorded",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( Solar )",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( IEX )",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( Electricity Board )",
    },
  },
  {
    title: {
      displayName: "Units Consumed",
    },
  },
  {
    title: {
      displayName: "Unit Cost",
    },
  },
  {
    title: {
      displayName: "Total Cost",
    },
  },
  {
    title: {
      displayName: "Power Factor",
    },
  },
  {
    title: {
      displayName: "",
    },
  },
];

// Page Components
function ElectricityBillListTableActionCell({
  setSelectedElectricityBill,
  electricityBill = {},
  setShowModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Upload Electricity Bill Selector State
  const uploadPlantElectricityBillLoading = useSelector(
    (state) => state.plantElecBill.uploadPlantElectricityBillLoading
  );

  // Download Electricity Bill Selector State
  const downloadPlantElectricityBillLoading = useSelector(
    (state) => state.plantElecBill.downloadPlantElectricityBillLoading
  );

  // Modal Id
  const modalId = ModalKeys.viewPlantElectricityBillModal;

  // Electricity Bill Information
  const { id: electricityBillId = "", fileUrl = "" } = electricityBill;

  // Checking whether file is present or not
  const isFilePresent = fileUrl || "";

  // File Upload Loading Status
  const fileUploadLoadingStatus = uploadPlantElectricityBillLoading[electricityBillId];
  const fileDownloadLoadingStatus = downloadPlantElectricityBillLoading[electricityBillId];

  // After selecting the file dispatch is called
  function uploadFile(file) {
    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    dispatch(PlantElectricityBillActions.uploadPlantElectricityBill({ formData, plantId, electricityBillId }));
  }

  // Download Electricity Bill Function
  function downloadPlantElectiricityBill() {
    dispatch(PlantElectricityBillActions.downloadPlantElectricityBill({ electricityBillId, apiUrl: fileUrl }));
  }

  // Open Electricity Bill Edit Modal Function
  function openElectricityBillEditModal() {
    setSelectedElectricityBill(electricityBill);
    setShowModal(true);
  }

  return (
    <td>
      {/* Button */}
      <Button
        label="View"
        size="sm"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        onClick={openElectricityBillEditModal}
      >
        <i className="fa fa-eye me-2" />
      </Button>

      {/* Plant Bills Table Action Button */}
      <PlantBillsTableActionButton
        uploadFile={uploadFile}
        isFilePresent={isFilePresent}
        fileUploadLoadingStatus={fileUploadLoadingStatus}
      />

      {/* Download Button */}
      {isFilePresent && (
        <Button
          label="Download"
          color="dark"
          size="sm"
          onClick={downloadPlantElectiricityBill}
          loading={fileDownloadLoadingStatus}
          disabled={fileDownloadLoadingStatus}
        >
          <i className="fa fa-download" />
        </Button>
      )}
    </td>
  );
}

function ElectricityBillListTableRow({
  electricityBill = {},
  serialNo,
  setSelectedElectricityBill = [],
  setShowModal = () => {},
}) {
  const {
    year = "",
    month = "",
    demandSanctioned = "",
    demandRecorded = "",
    unitsConsumedSolar = "",
    unitsConsumedIEX = "",
    unitsConsumedElectricityBoard = "",
    unitsConsumed = "",
    unitCost = "",
    totalCost = "",
    powerFactor = "",
  } = electricityBill;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{`${getMonthName(month)} , ${year}`}</td>
      <td>{demandSanctioned}</td>
      <td>{demandRecorded}</td>
      <td>{unitsConsumedSolar}</td>
      <td>{unitsConsumedIEX}</td>
      <td>{unitsConsumedElectricityBoard}</td>
      <td>{unitsConsumed}</td>
      <td>{unitCost}</td>
      <td>{totalCost}</td>
      <td>{powerFactor}</td>

      {/* Electricity Bill List Table Action Cell */}
      <ElectricityBillListTableActionCell
        setSelectedElectricityBill={setSelectedElectricityBill}
        electricityBill={electricityBill}
        setShowModal={setShowModal}
      />
    </tr>
  );
}

function ElectricityBillListTableBody({
  plantElectricityBillListLoading,
  plantElectricityBillList,
  setSelectedElectricityBill,
  pageNumber,
  pageSize,
  setShowModal = () => {},
}) {
  // Loader
  if (plantElectricityBillListLoading) {
    return <TableLoader colSpan={16} />;
  }

  // If there is no electricity bill list
  if (plantElectricityBillList.length === 0) {
    return <TableDataNotFound message="No electricity bills present" colSpan={16} />;
  }

  // Electricity bill list table row
  return (
    <>
      {plantElectricityBillList.map((electricityBill, index) => {
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <ElectricityBillListTableRow
            key={electricityBill.id}
            electricityBill={electricityBill}
            serialNo={serialNo}
            setSelectedElectricityBill={setSelectedElectricityBill}
            setShowModal={setShowModal}
          />
        );
      })}
    </>
  );
}

/**
 * Plant Electricity Bill List Table
 */
export default function PlantElectricityBillListTable({ setSelectedElectricityBill, setShowModal = () => {} }) {
  // Thermal Bill Selector State
  const plantElectricityBillList = useSelector((state) => state.plantElecBillList.plantElectricityBillList);
  const plantElectricityBillListLoading = useSelector(
    (state) => state.plantElecBillList.plantElectricityBillListLoading
  );
  const plantElectricityBillListPagination = useSelector(
    (state) => state.plantElecBillList.plantElectricityBillListPagination
  );

  const { totalCount, pageNo, pageSize } = plantElectricityBillListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Electricity Bill List Table Body */}
          <ElectricityBillListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            plantElectricityBillListLoading={plantElectricityBillListLoading}
            plantElectricityBillList={plantElectricityBillList}
            setSelectedElectricityBill={setSelectedElectricityBill}
            setShowModal={setShowModal}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
