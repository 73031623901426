import * as yup from "yup";

// Validation Schema
export const OrganizationFormValidationSchema = yup.object({
  name: yup.string().required("Please fill the organization name"),
  // website: yup.string().required("Please fill the website link"),
  // emailId: yup.string().email("Please provide valid email").required("Please fill the email id"),
  line1: yup.string().required("Please fill the address line 1"),
  // line2: yup.string().required("Please fill the address line 2"),
  city: yup.string().required("Please fill the city name"),
  state: yup.string().required("Please fill the state name"),
  pincode: yup
    .string()
    .required("Please fill the pincode")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

// Form
export const OrganizationFormSchema = [
  {
    title: "Organization Info",
    titleClassName: "fw-bold",
    containerClassName: "row",
    inputItems: [
      {
        label: "Organization Type",
        type: "select",
        key: "orgType",
        inputContainerClassName: "d-none",
        options: [{ value: "PLATFORM" }, { value: "AUDIT" }, { value: "INDUSTRY" }],
      },
      {
        label: "Name",
        key: "name",
        placeholder: "Enter organization name...",
        isMandotary: true,
      },
      {
        label: "Description",
        key: "description",
        placeholder: "Enter description...",
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Website",
        key: "website",
        placeholder: "Enter website...",
      },
      {
        label: "Email",
        key: "emailId",
        type: "email",
        placeholder: "Enter email...",
      },
    ],
  },
  {
    title: "Address",
    titleClassName: "fw-bold",
    containerClassName: "row",
    inputItems: [
      {
        label: "line 1",
        key: "line1",
        placeholder: "Enter first line...",
        isMandotary: true,
      },
      {
        label: "line 2",
        key: "line2",
        placeholder: "Enter second line...",
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "City",
        key: "city",
        placeholder: "Enter city name...",
        isMandotary: true,
      },
      {
        label: "State",
        key: "state",
        placeholder: "Enter state name...",
        isMandotary: true,
      },
      {
        label: "Pincode",
        key: "pincode",
        placeholder: "Enter pincode...",
        isMandotary: true,
      },
    ],
  },
];
