import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { PlantThermalBillListActions } from "../../../../redux-slice/plant/ThermalBillListSlice";
import { PlantThermalBillActions } from "../../../../redux-slice/plant/ThermalBillSlice";
import { MaterialListActions } from "../../../../redux-slice/material/MaterialListSlice";

// Constants
import { QueryParamsKeys } from "../../../../constants/GeneralConstants";
import { ModalKeys } from "../../../../constants/ModalConstants";

// Components
import { Button } from "../../../../components/button/Button";
import Form, { FormTypes } from "../../../../components/form/Form";
import Modal from "../../../../components/modal/Modal";

// Section
import PlantThermalBillListTable from "./PlantThermalBillListTable";

// Form Schema
import { ThermalBillFormSchema, ThermalBillFormValidationSchema } from "../../../../form-schema/ThermalBillFormSchema";

// Page Constants
const MATERIAL_ID_KEY = "materialId";
const UNITS_CONSUMEND_KEY = "unitsConsumed";

// Page Component
function PlantThermalBillsPageHeader({ modalId, setShowModal = () => {} }) {
  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        <h4>Monthly Thermal Bills</h4>
      </div>

      {/* Button */}
      <Button
        label="Add Thermal Bill"
        color="dark"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        onClick={() => setShowModal(true)}
      >
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Plant Thermal Bills Tab Conent
 */
export default function PlantThermalBillsTabContent() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Search Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // State
  const [selectedThermalBill, setSelectedThermalBill] = useState({});
  const [showModal, setShowModal] = useState(false);

  // Material List Selector State
  const materialList = useSelector((state) => state.materialList.materialList);
  const materialListLoading = useSelector((state) => state.materialList.materialListLoading);

  // Create Thermal Bill Selector State
  const createPlantThermalBillLoading = useSelector((state) => state.plantThermalBill.createPlantThermalBillLoading);

  // update Thermal Bill Selector State
  const updatePlantThermalBillLoading = useSelector((state) => state.plantThermalBill.updatePlantThermalBillLoading);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // use Effect
  useEffect(() => {
    dispatch(PlantThermalBillListActions.getPlantThermalBillList({ plantId, pageNumber, pageSize, searchText }));
  }, [dispatch, plantId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    dispatch(MaterialListActions.getMaterialList({ type: "FUEL" }));
  }, [dispatch]);

  useEffect(() => {
    if (!materialListLoading) {
      updateThermalBillFormSchema();
    }
  }, [materialListLoading]);

  // Update Thermal Bill Form Schema
  function updateThermalBillFormSchema(unitSymbol = "") {
    ThermalBillFormSchema[0]?.inputItems.forEach((input) => {
      // Adding options only to material input
      if (input.key === MATERIAL_ID_KEY) {
        input.options = materialList.map(({ id = "", name: value = "" }) => ({ id, value }));
        return;
      }

      // Adding options only to material input
      if (input.key === UNITS_CONSUMEND_KEY && unitSymbol) {
        input.label = `Units Consumed (${unitSymbol})`;
        return;
      }

      return [];
    });
  }

  // Thermal Bill Form OnChange
  function thermalBillFormChange(e) {
    const { value = "", name = "" } = e?.target || {};

    if (name === MATERIAL_ID_KEY && value) {
      const { unitSymbol = "" } = materialList.find(({ id = "" }) => id === parseInt(value));

      updateThermalBillFormSchema(unitSymbol);
    }
  }

  // Function for adding thermal bills
  function createThermalBill(thermalBillData) {
    const formData = { ...thermalBillData, plantId };

    // Dispatch
    dispatch(PlantThermalBillActions.createPlantThermalBill({ formData, plantId }));
  }

  // Function for editing thermal bills
  function editThermalBill(thermalBillData) {
    const formData = { ...thermalBillData, plantId };

    // Dispatch
    dispatch(
      PlantThermalBillActions.updatePlantThermalBill({ formData, plantId, thermalBillId: selectedThermalBill.id })
    );
  }

  return (
    <div className="page-content">
      {/* Plant Thermal Bills Page Header */}
      <PlantThermalBillsPageHeader modalId={ModalKeys.addPlantThermalBillModal} setShowModal={setShowModal} />

      <div className="mt-4 table-responsive">
        {/* Plant Thermal Bill List Table */}
        <PlantThermalBillListTable setSelectedThermalBill={setSelectedThermalBill} setShowModal={setShowModal} />
      </div>

      {/* Create Thermal Bill Modal */}
      <Modal id={ModalKeys.addPlantThermalBillModal} title="View Thermal Bill" size="xl" setShowModal={setShowModal}>
        {/* Create thermal bill form */}
        <Form
          type={FormTypes.CREATE}
          formInputItems={ThermalBillFormSchema}
          formValidationSchema={ThermalBillFormValidationSchema}
          formSubmit={createThermalBill}
          loading={createPlantThermalBillLoading}
          showCancelButton={false}
          onChange={thermalBillFormChange}
          showModal={showModal}
        />
      </Modal>

      {/* View Thermal Bill Modal */}
      <Modal id={ModalKeys.viewPlantThermalBillModal} title="View Thermal Bill" size="xl" setShowModal={setShowModal}>
        {/* Edit thermal bill form */}
        <Form
          data={selectedThermalBill}
          formInputItems={ThermalBillFormSchema}
          formValidationSchema={ThermalBillFormValidationSchema}
          formSubmit={editThermalBill}
          loading={updatePlantThermalBillLoading}
          showModal={showModal}
        />
      </Modal>
    </div>
  );
}
