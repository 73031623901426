import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // User Info
  userInfo: {},
  userInfoLoading: false,
  userInfoError: "",

  // Add user
  addUserLoading: false,
  addUserError: "",

  // Assign role to user
  assignRoleToUserLoading: false,
  assignRoleToUserError: "",

  // Update user
  updateUserLoading: false,
  updateUserError: "",

  // Update user status
  updateUserStatusLoadingMap: {},
  updateUsertStatusError: "",
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    // User Info
    getUserInfo: (state) => {
      state.userInfoLoading = true;
    },

    getUserInfoSuccess: (state, action) => {
      state.userInfoLoading = false;
      state.userInfo = action.payload.userInfo;
    },

    getUserInfoFailure: (state, action) => {
      state.userInfoLoading = false;
      state.userInfoError = action.payload.error;
    },

    // Add user
    addUser: (state) => {
      state.addUserLoading = true;
    },

    addUserSuccess: (state) => {
      state.addUserLoading = false;
    },

    addUserFailure: (state, action) => {
      state.addUserLoading = false;
      state.addUserError = action.payload.error;
    },

    // Assign role to user
    assignUserRole: (state) => {
      state.assignRoleToUserLoading = true;
    },

    assignUserRoleSuccess: (state) => {
      state.assignRoleToUserLoading = false;
    },

    assignUserRoleFailure: (state, action) => {
      state.assignRoleToUserLoading = false;
      state.assignRoleToUserError = action.payload.error;
    },

    // Update user
    updateUser: (state) => {
      state.updateUserLoading = true;
    },

    updateUserSuccess: (state) => {
      state.updateUserLoading = false;
    },

    updateUserFailure: (state, action) => {
      state.updateUserLoading = false;
      state.updateUserError = action.payload.error;
    },

    // Update User status
    updateUserStatus: (state, action) => {
      const { userId } = action.payload;

      state.updateUserStatusLoadingMap = {
        ...state.updateUserStatusLoadingMap,
        [userId]: true,
      };
    },

    updateUserStatusSuccess: (state, action) => {
      const { userId } = action.payload;

      // To get previous state
      const clonedUpdateUserStatusLoadingMap = { ...state.updateUserStatusLoadingMap };

      // To remove the id from state after successfull loading
      delete clonedUpdateUserStatusLoadingMap[userId];

      state.updateUserStatusLoadingMap = clonedUpdateUserStatusLoadingMap;
    },

    updateUserStatusFailure: (state, action) => {
      const { userId, error } = action.payload;

      // To get previous state
      const clonedUpdateUserStatusLoadingMap = { ...state.updateUserStatusLoadingMap };

      // To remove the id from state after successfull loading
      delete clonedUpdateUserStatusLoadingMap[userId];

      state.updateUserStatusLoadingMap = clonedUpdateUserStatusLoadingMap;
      state.updateUsertStatusError = error;
    },
  },
});

// Reducer
export const UserReducer = userSlice.reducer;

// Actions
export const UserActions = userSlice.actions;
