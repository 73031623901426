// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getRoleListUrl, getPrivilegesListUrl } from "../../urls/api-urls/RoleURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Role List Api
export async function getRoleListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getRoleListUrl, params);
}

// Get Privileges List Api
export async function getPrivilegesListApi() {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getPrivilegesListUrl);
}
