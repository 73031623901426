import React from "react";

// Component
import ErrorMessage from "../error-message/ErrorMessage";

/**
 * Textarea Component
 * @param {*} id
 * @param {*} value : Input Value
 * @param {*} name
 * @param {*} className
 * @param {*} onChange : Function
 * @param {*} placeholder
 * @param {*} disabled : Boolean Value
 * @param {*} error : Formik error
 * @param {*} loading : Boolean Value
 */
export default function Textarea({
  id = "",
  name = "",
  value = "",
  className = "",
  placeholder = "",
  onChange = "",
  disabled = false,
  error = "",
  rows = "",
  columns = "",
  ...rest
}) {
  return (
    <>
      <textarea
        id={id}
        name={name}
        value={value}
        className={`form-control shadow-none ${className} ${error && "border-danger"}`}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        rows={rows}
        cols={columns}
        {...rest}
      ></textarea>

      {/* To show error message */}
      {error && <ErrorMessage error={error} />}
    </>
  );
}
