import { createSlice } from "@reduxjs/toolkit";

// Constants
import { OrgTypes } from "../../constants/GeneralConstants";

// Initial State
const initialState = {
  // Get user list
  userList: [],
  userListPagination: {},
  userListLoading: false,
  userListError: "",

  // Industry User List
  industryUserList: [],

  // Audit User List
  auditUserList: [],
};

// User List Slice
const userListSlice = createSlice({
  name: "UserList",
  initialState,
  reducers: {
    // Get user list
    getUserList: (state) => {
      state.userListLoading = true;
    },

    getUserListSuccess: (state, action) => {
      // Action Payload
      const { orgType, userList, userListPagination } = action.payload;

      // Organization Type
      const { id: industry } = OrgTypes.INDUSTRY;
      const { id: audit } = OrgTypes.AUDIT;

      state.userListPagination = userListPagination;
      state.userListLoading = false;

      // If organization type is industry
      if (orgType === industry) {
        state.industryUserList = userList;
        return;
      }

      // If organization type is audit
      if (orgType === audit) {
        state.auditUserList = userList;
        return;
      }

      state.userList = userList;
    },

    getUserListFailure: (state, action) => {
      state.userListLoading = false;
      state.userListError = action.payload.error;
    },
  },
});

// Reducer
export const UserListReducer = userListSlice.reducer;

// Actions
export const UserListActions = userListSlice.actions;
