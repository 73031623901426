/**
 * Audit List Table
 */
export default function Address({ className = "", title, addressObj = {} }) {
  // Data
  const { line1, line2, city, state, pincode } = addressObj;

  return (
    <div className={`cont-address ${className}`}>
      {title && ( //
        <p className="addr-title">{title}</p>
      )}

      <p>{line1}</p>
      {line2 && ( //
        <p>{line2}</p>
      )}
      <p>
        {city}, {state} - {pincode}
      </p>
    </div>
  );
}
