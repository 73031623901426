// Utils
import URLs from "../../app/utils/URLs";

// Urls
import { downloadPerfAnalysisDataUrl, downloadRecommendationDataUrl } from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Download Performance Analysis Data Api
export async function downloadPerfAnalysisDataApi(auditId) {
  const url = URLs.format(downloadPerfAnalysisDataUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadFile(url);
}

// Download Recommendation Data Api
export async function downloadRecommendationDataApi(auditId, w_obv = true) {
  const params = {
    w_obv,
  };

  const url = URLs.format(downloadRecommendationDataUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadFile(url, params);
}
