import { createSlice } from "@reduxjs/toolkit";

// Initial Slice
const initialState = {
  // Add plant component part
  addPlantComponentPartLoading: false,
  addPlantComponentPartError: "",

  // Update plant component part
  updatePlantComponentPartLoading: false,
  updatePlantComponentPartError: "",

  // Delete plant component part
  deletePlantComponentPartLoading: false,
  deletePlantComponentPartError: "",
};

// Plant Component Parts
const plantComponentParts = createSlice({
  name: "PlantComponentParts",
  initialState,
  reducers: {
    // Add plant component part
    addPlantComponentPart: (state) => {
      state.addPlantComponentPartLoading = true;
    },

    addPlantComponentPartSuccess: (state) => {
      state.addPlantComponentPartLoading = false;
    },

    addPlantComponentPartFailure: (state, action) => {
      state.addPlantComponentPartLoading = false;
      state.addPlantComponentPartError = action.payload.error;
    },

    // Update plant component part
    updatePlantComponentPart: (state) => {
      state.updatePlantComponentPartLoading = true;
    },

    updatePlantComponentPartSuccess: (state) => {
      state.updatePlantComponentPartLoading = false;
    },

    updatePlantComponentPartFailure: (state, action) => {
      state.updatePlantComponentPartLoading = false;
      state.updatePlantComponentPartError = action.payload.error;
    },

    // Delete plant component part
    deletePlantComponentPart: (state, action) => {
      const { partId } = action.payload;

      state.deletePlantComponentPartLoading = {
        ...state.deletePlantComponentPartLoading,
        [partId]: true,
      };
    },

    deletePlantComponentPartSuccess: (state, action) => {
      const { partId } = action.payload;

      // To get previous state
      const clonedDeletePlantComponentPartLoading = { ...state.deletePlantComponentPartLoading };

      // To remove the id from state after successfull loading
      delete clonedDeletePlantComponentPartLoading[partId];

      state.deletePlantComponentPartLoading = clonedDeletePlantComponentPartLoading;
    },

    deletePlantComponentPartFailure: (state, action) => {
      const { partId } = action.payload;

      // To get previous state
      const clonedDeletePlantComponentPartLoading = { ...state.deletePlantComponentPartLoading };

      // To remove the id from state after successfull loading
      delete clonedDeletePlantComponentPartLoading[partId];

      state.deletePlantComponentPartLoading = clonedDeletePlantComponentPartLoading;
      state.deletePlantComponentPartError = action.payload.error;
    },
  },
});

// Reducer
export const PlantComponentPartsReducer = plantComponentParts.reducer;

// Actions
export const PlantComponentPartsActions = plantComponentParts.actions;
