import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Audit File List
  fileList: [],
  fileListPagination: {},
  fileListLoading: false,
  fileListError: "",
};

// Audit File List Slice
const auditFileListSlice = createSlice({
  name: "AuditFileList",
  initialState,
  reducers: {
    // Get Audit File List
    getAuditFileList: (state) => {
      state.fileListLoading = true;
    },

    getAuditFileListSuccess: (state, action) => {
      state.fileListLoading = false;
      state.fileList = action.payload.fileList;
      state.fileListPagination = action.payload.fileListPagination;
    },

    getAuditFileListFailure: (state, action) => {
      state.fileListLoading = false;
      state.fileListError = action.payload.error;
    },
  },
});

// Reducer
export const AuditFileListReducer = auditFileListSlice.reducer;

// Actions
export const AuditFileListActions = auditFileListSlice.actions;
