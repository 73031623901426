import { createSlice } from "@reduxjs/toolkit";

// Constants
import { OrgTypes } from "../../constants/GeneralConstants";

// Initial State
const initialState = {
  // Get organization list
  organizationList: [],
  organizationTypeList: { INDUSTRY: [], AUDIT: [] },
  organizationListPagination: {},
  organizationListLoading: false,
  organizationListError: "",
};

// Organization List Slice
const organizationListSlice = createSlice({
  name: "OrganizationList",
  initialState,
  reducers: {
    // Get organization list
    getOrganizationList: (state) => {
      state.organizationListLoading = true;
    },

    getOrganizationListSuccess: (state, action) => {
      // Action Payload
      const { orgType, organizationList, organizationListPagination } = action.payload;

      // Organization Type
      const { id: industry } = OrgTypes.INDUSTRY;
      const { id: audit } = OrgTypes.AUDIT;

      state.organizationListLoading = false;
      state.organizationListPagination = organizationListPagination;

      // If organization type is industry
      if (orgType === industry) {
        // Cloning organization type list
        const clonedOrganizationTypeList = { ...state.organizationTypeList } || {};
        clonedOrganizationTypeList[industry] = organizationList;

        state.organizationTypeList = clonedOrganizationTypeList;
        return;
      }

      // If organization type is audit
      if (orgType === audit) {
        // Cloning organization type list
        const clonedOrganizationTypeList = { ...state.organizationTypeList } || {};
        clonedOrganizationTypeList[audit] = organizationList;

        state.organizationTypeList = clonedOrganizationTypeList;
        return;
      }

      state.organizationList = organizationList;
    },

    getOrganizationListFailure: (state, action) => {
      state.organizationListLoading = false;
      state.organizationListError = action.payload.error;
    },
  },
});

// Reducer
export const OrganizationListReducer = organizationListSlice.reducer;

// Actions
export const OrganizationListActions = organizationListSlice.actions;
