import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Actions
import { ConstantListActions } from "../../redux-slice/constant/ConstantListSlice";

// Components
import Input from "../../components/input/Input";
import PageHeader from "../../components/page-header/PageHeader";

// Sections
import ConstantListTable from "./ConstantListTable";

function ConstantListTableActionSection({}) {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search constants...`} isSearchButton={true} />
    </div>
  );
}

// Constant List Page
export default function ConstantListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // useEffect
  useEffect(() => {
    dispatch(ConstantListActions.getConstantList());
  }, [dispatch]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="Constants" />);
  }, []);
  return (
    <div className="page-content">
      {/* Constant List Table Action Section */}
      <ConstantListTableActionSection />

      {/* Constant List Table */}
      <ConstantListTable />
    </div>
  );
}
