import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "react-headless-pagination";

//Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

/**
 * Table Pagination Component
 * @param {*} pageNumber : Current page number
 * @param {*} pageSize : Number of data to be displayed in table
 * @param {*} totalCount : Total number of data
 * @param {*} containerClassName
 */
export default function TablePagination({
  pageNumber = DefaultPagination.pageNumber,
  pageSize = DefaultPagination.pageSize,
  totalCount = 1,
  containerClassName = "justify-content-center",
}) {
  //Search Params state
  const [searchParams, setSearchParams] = useSearchParams();

  //State
  const [page, setPage] = useState(0);

  useEffect(() => {
    //Updating page number, If the page number is null, it will take 0 as current page.
    setPage(pageNumber ? pageNumber - 1 : page);
  }, [pageNumber]);

  //Function
  function handleChange(pageNo) {
    setPage(pageNo);

    // Getting already present params
    const params = Object.fromEntries([...searchParams]);

    //Updating Query Params
    setSearchParams({
      ...params,
      pn: pageNo + 1,
      ps: pageSize,
    });
  }

  // For calculating total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <ul className={`pagination mt-4 ${containerClassName}`}>
      {/* Pagination */}
      <Pagination
        currentPage={page}
        setCurrentPage={handleChange}
        totalPages={totalPages}
        edgePageCount={2}
        middlePagesSiblingCount={0}
        className="d-flex"
        truncableText="..."
        truncableClassName="disabled me-2"
      >
        {/* Previous Button */}
        <Pagination.PrevButton className="btn btn-secondary me-2 btn-sm">
          <i className="fa-solid fa-angle-left"></i> Previous
        </Pagination.PrevButton>

        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          {/* Current Page Number Display Button */}
          <Pagination.PageButton
            activeClassName="active"
            inactiveClassName=""
            className="text-decoration-none btn btn me-2"
          />
        </div>

        {/* Next Button */}
        <Pagination.NextButton className="btn btn-secondary ms-2 btn-sm">
          Next <i className="fa-solid fa-angle-right"></i>
        </Pagination.NextButton>
      </Pagination>
    </ul>
  );
}

TablePagination.defaultProps = {
  pageNumber: DefaultPagination.pageNumber,
  pageSize: DefaultPagination.pageSize,
  totalCount: DefaultPagination.totalCount,
};
