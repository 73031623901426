import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditInstrumentListActions } from "../../redux-slice/audit/InstrumentListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getAuditInstrumentDataListApi } from "../../api/audit/InstrumentListAPI";

// Get Audit Instrument Data List
function* getAuditInstrumentDataList(action) {
  try {
    const { pageNumber, pageSize, auditId, componentId } = action.payload;

    const { items, pagination } = yield getAuditInstrumentDataListApi(auditId, componentId, pageNumber, pageSize);

    // Dispatching Action
    yield put(
      AuditInstrumentListActions.getAuditInstrumentDataListSuccess({
        auditInstrumentDataList: items,
        auditInstrumentDataListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentListActions.getAuditInstrumentDataListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(AuditInstrumentListActions.getAuditInstrumentDataList.type, getAuditInstrumentDataList)]);
}
