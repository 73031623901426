// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Urls
import { createPlantFileUrl, updatePlantFileByIdUrl, deletePlantFileByIdUrl } from "../../urls/api-urls/PlantURL";

// Utils
import URLs from "../../app/utils/URLs";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Plant File
export async function createPlantFileApi(plantId, formData) {
  // Api Url
  const apiUrl = URLs.format(createPlantFileUrl, { plantId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiUrl, {}, formData, ContentType.MULTIPART_FORM_DATA);
}

// Update Plant File By Id
export async function updatePlantFileByIdApi(plantId, fileId, formData) {
  // Api Url
  const apiUrl = URLs.format(updatePlantFileByIdUrl, { plantId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiUrl, {}, formData);
}

// Download Plant File By Id
export async function downloadPlantFileByIdApi(apiUrl) {
  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.downloadPublicFile(apiUrl);
}

// Delete Plant File By Id
export async function deletePlantFileByIdApi(plantId, fileId) {
  // Api Url
  const apiUrl = URLs.format(deletePlantFileByIdUrl, { plantId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiUrl);
}
