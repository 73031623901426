import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get plant information
  plantInfo: {},
  plantInfoLoading: false,
  plantInfoError: "",

  // Add plant
  addPlantLoading: false,
  addPlantError: "",

  // Update plant
  updatePlantLoading: false,
  updatePlantError: "",

  // Update plant status
  updatePlantStatusLoadingMap: {},
  updatePlantStatusError: "",
};

// Plant Slice
const plantSlice = createSlice({
  name: "Plant",
  initialState,
  reducers: {
    // Get plant information
    getPlantInfo: (state) => {
      state.plantInfoLoading = true;
    },

    getPlantInfoSuccess: (state, action) => {
      state.plantInfoLoading = false;
      state.plantInfo = action.payload.plantInfo;
    },

    getPlantInfoFailure: (state, action) => {
      state.plantInfoLoading = false;
      state.plantInfoError = action.payload.error;
    },

    // Add plant
    addPlant: (state) => {
      state.addPlantLoading = true;
    },

    addPlantSuccess: (state) => {
      state.addPlantLoading = false;
    },

    addPlantFailure: (state, action) => {
      state.addPlantLoading = false;
      state.addPlantError = action.payload.error;
    },

    // Update plant
    updatePlant: (state) => {
      state.updatePlantLoading = true;
    },

    updatePlantSuccess: (state) => {
      state.updatePlantLoading = false;
    },

    updatePlantFailure: (state, action) => {
      state.updatePlantLoading = false;
      state.updatePlantError = action.payload.error;
    },

    // Update Plant status
    updatePlantStatus: (state, action) => {
      const { plantId } = action.payload;

      state.updatePlantStatusLoadingMap = {
        ...state.updatePlantStatusLoadingMap,
        [plantId]: true,
      };
    },

    updatePlantStatusSuccess: (state, action) => {
      const { plantId } = action.payload;

      // To get previous state
      const clonedUpdatePlantStatusLoadingMap = { ...state.updatePlantStatusLoadingMap };

      // To remove the id from state after successfull loading
      delete clonedUpdatePlantStatusLoadingMap[plantId];

      state.updatePlantStatusLoadingMap = clonedUpdatePlantStatusLoadingMap;
    },

    updatePlantStatusFailure: (state, action) => {
      const { plantId } = action.payload;

      // To get previous state
      const clonedUpdatePlantStatusLoadingMap = { ...state.updatePlantStatusLoadingMap };

      // To remove the id from state after successfull loading
      delete clonedUpdatePlantStatusLoadingMap[plantId];

      state.updatePlantStatusLoadingMap = clonedUpdatePlantStatusLoadingMap;
      state.updatePlantStatusError = action.payload.error;
    },
  },
});

// Reducer
export const PlantReducer = plantSlice.reducer;

// Actions
export const PlantActions = plantSlice.actions;
