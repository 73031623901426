import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Audit Reports List
  reportsList: [],
  reportsListLoading: false,
  reportsListError: "",
};

// Audit Reports List Slice
const auditReportsListSlice = createSlice({
  // Name
  name: "AuditReportsList",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Get Audit Reports List
    getReportsList: (state) => {
      state.reportsListLoading = true;
    },

    getReportsListSuccess: (state, action) => {
      state.reportsListLoading = false;
      state.reportsList = action.payload.reportsList;
      state.reportsListError = "";
    },

    getReportsListFailure: (state, action) => {
      state.reportsListLoading = false;
      state.reportsListError = action.payload.error;
    },
  },
});

// Reducer
export const AuditReportsListReducer = auditReportsListSlice.reducer;

// Actions
export const AuditReportsListActions = auditReportsListSlice.actions;
