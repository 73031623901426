//Modal Keys
export const ModalKeys = {
  // Plant Component Modal
  viewPlantComponentModal: "viewPlantComponentModal",
  addPlantComponentModal: "addPlantComponentModal",
  plantComponentSelectModal: "plantComponentSelectModal",
  addPlantComponentImageModal: "addPlantComponentImageModal",

  // Plant Component Part Modal
  addPlantComponentPartModal: "addPlantComponentPartModal",
  viewPlantComponentPartModal: "viewPlantComponentPartModal",

  // Plant Thermal Bill Modal
  viewPlantThermalBillModal: "viewPlantThermalBillModal",
  addPlantThermalBillModal: "addPlantThermalBillModal",

  // Plant Electricity Bill Modal
  viewPlantElectricityBillModal: "viewPlantElectricityBillModal",
  addPlantElectricityBillModal: "addPlantElectricityBillModal",

  // Assign Role Modal
  assignRoleComponentModal: "assignRoleComponentModal",

  // Instrument Data View Modal
  instrumentDataViewModal: "instrumentDataViewModal",

  // Add Recommendation Modal
  addRecommendationsModal: "addRecommendationsModal",

  // File Upload Modal
  fileUploadModal: "fileUploadModal",
};
