// Utils
import URLs from "../../app/utils/URLs";

// Urls
import {
  updateRecommendationInvestmentUrl,
  updateAuditObservationAndRecommendationUrl,
} from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Update Audit Observation And Recommendation Api
export async function updateAuditObservationAndRecommendationApi(auditId, observationData) {
  const url = URLs.format(updateAuditObservationAndRecommendationUrl, { auditId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(url, {}, observationData);
}

// Update Recommendation Investment Api
export async function updateRecommendationInvestmentApi(auditId, recommendationId, investment) {
  const url = URLs.format(updateRecommendationInvestmentUrl, { auditId, recommendationId });

  const params = {};

  if (investment) {
    params.investment = investment;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, params);
}
