// Urls
import {
  addUserUrl,
  assignRoleToUserByIdUrl,
  getUserInfoByIdUrl,
  updateUserByIdUrl,
  updateUserStatusByIdUrl,
} from "../../urls/api-urls/UserURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Add User
export async function addUserApi(userData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(addUserUrl, {}, userData);
}

// Assign Role To User By Id Api
export async function assignRoleToUserByIdApi(userId, roleId) {
  const url = URLs.format(assignRoleToUserByIdUrl, { userId, roleId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Get User Info
export async function getUserInfoByIdApi(userId) {
  const url = URLs.format(getUserInfoByIdUrl, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update User
export async function updateUserByIdApi(userData, userId) {
  const url = URLs.format(updateUserByIdUrl, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, userData);
}

// Update User status
export async function updateUserStatusByIdApi(userId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateUserStatusByIdUrl, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
