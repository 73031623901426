import { toast } from "react-toastify";

// React Related Imports
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ErrorActions } from "../../app/error/ErrorSlice";
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

// Constants & Utils
import { LocalStorageKeys, setAuthToken, setItem } from "../../app/LocalStorage";

//Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// APIs
import { loginApi, resetPasswordApi } from "../../api/auth/AuthAPI";

//
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Login
function* login(action) {
  try {
    //getting email and password from action
    const { loginFormData, navigate } = action.payload;

    const loggedInData = yield loginApi(loginFormData);

    const { org: orgDetails = {}, privileges = [], token } = loggedInData;

    //setting token in localstorage
    setAuthToken(token);

    //store permissions in local storage
    setItem(LocalStorageKeys.PERMISSIONS, privileges);

    //set user details in local storage
    setItem(LocalStorageKeys.AUTH_USER, JSON.stringify(loggedInData));

    // set org info
    setItem(LocalStorageKeys.AUTH_ORG, JSON.stringify(orgDetails));
    setItem(LocalStorageKeys.ORG_TYPE, orgDetails.type);

    // Reload HttpClientFactory
    HttpClientFactory.reload();

    //dispatching action
    yield put(AuthActions.loginSuccess({ loggedInData }));

    // Redirect to dashboard page
    navigate(RedirectTo.DashboardPageUrl);
  } catch (error) {
    yield put(AuthActions.loginFailure({ error: "Login Error" }));
  }
}

// Log Out
function* logOut(action) {
  try {
    const { navigate } = action.payload;

    // Clear LocalStorage
    yield localStorage.clear();

    // Reset HttpClientFactory
    yield HttpClientFactory.reset();

    // Redirect to login page
    navigate(RedirectTo.LoginPageUrl);
  } catch (error) {
    // TODO :: Handle error
    console.log(error);
  }
}

// Reset Password
function* resetPassword(action) {
  try {
    const { email = "", newPassword = "" } = action.payload;

    yield resetPasswordApi(email, newPassword);

    // Dispatching action
    yield put(AuthActions.resetPasswordSuccess());

    // Toaster
    toast.success("Reset Password Success");
  } catch (error) {
    yield put(AuthActions.resetPasswordFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

/**
 * Auth Sagas
 */
export default function* root() {
  yield all([
    takeLatest(AuthActions.login.type, login),
    takeLatest(AuthActions.resetLogin.type, logOut),
    takeLatest(AuthActions.resetPassword.type, resetPassword),
  ]);
}
