import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { AuditFileActions } from "../../../../redux-slice/audit/FileSlice";
import { AuditFileListActions } from "../../../../redux-slice/audit/FileListSlice";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../../constants/GeneralConstants";
import { ModalKeys } from "../../../../constants/ModalConstants";
import { AuditFileTypeOptions } from "../../../../constants/SelectOptionConstants";

// Page Components
import FileUploadAndListSection from "../../../../page-components/file-list/FileListSection";

/**
 * Audit File List Page
 */
export default function AuditFileListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Selector State
  const fileListPagination = useSelector((state) => state.auditFileList.fileListPagination) || {};

  // From Url
  const type = searchParams.get(QueryParamsKeys.type) || "";

  // Pagination
  const { pageNo = DefaultPagination.pageNumber, pageSize = DefaultPagination.pageSize } = fileListPagination || {};

  // Modal id
  const modalId = ModalKeys.fileUploadModal;

  // use Effect
  useEffect(() => {
    dispatch(AuditFileListActions.getAuditFileList({ auditId, pageNumber: pageNo, pageSize, type }));
  }, [dispatch, auditId, pageNo, pageSize, type]);

  // Download Audit File
  function downloadAuditFile(fileId = "", fileName = "", url = "") {
    dispatch(AuditFileActions.downloadAuditFileById({ fileId, fileName, apiUrl: url }));
  }

  // Delete Audit File
  function deleteAuditFile(fileId = "") {
    dispatch(AuditFileActions.deleteAuditFileById({ auditId, fileId, pageNumber: pageNo, pageSize, type }));
  }

  // Upload Audit File
  function uploadAuditFile(formData = "", selectedFileId = "") {
    // Audit dispatch action for update and create file
    const auditDispatchAction = selectedFileId ? "updateAuditFileById" : "createAuditFile";

    dispatch(
      AuditFileActions[auditDispatchAction]({
        auditId,
        pageNumber: pageNo,
        pageSize,
        formData,
        type,
        modalId,
        fileId: selectedFileId,
      })
    );
  }

  // Dispatch Actions
  const dispatchActions = {
    downloadFileFn: downloadAuditFile,
    deleteFileFn: deleteAuditFile,
    uploadFileFn: uploadAuditFile,
  };

  return (
    <div>
      {/* File Upload And List Section */}
      <FileUploadAndListSection
        fileListReducerKey="auditFileList"
        fileReduceKey="auditFile"
        dispatchActions={dispatchActions}
        optionsArray={AuditFileTypeOptions}
      />
    </div>
  );
}
