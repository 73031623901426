// Audit File Type
export const AuditFileTypeOptions = [
  { id: "COMPONENT_IMAGE", value: "Component Images" },
  { id: "COMPONENT_IMAGE_THERMAL", value: "Component Thermal Images" },
  { id: "COMPONENT_VIDEO", value: "Component Videos" },
  { id: "INSTRUMENT_DATA", value: "Instrument Datas" },
  { id: "AUDIT_REPORT", value: "Audit Reports" },
  { id: "OTHER", value: "Others" },
];

// Plant File Type
export const PlantFileTypeOptions = [
  { id: "PLANT_CERT", value: "Plant Certificates" },
  { id: "PLANT_PROCESS_DIAGRAM", value: "Plant Process Diagrams" },
  { id: "PLANT_THERMAL_BILL", value: "Plant Thermal Bills" },
  { id: "PLANT_POWER_BILL", value: "Plant Power Bills" },
  { id: "PLANT_IMAGE", value: "Plant Images" },
  { id: "PLANT_VIDEO", value: "Plant Videos" },
  { id: "COMPONENT_IMAGE", value: "Component Images" },
  { id: "COMPONENT_VIDEO", value: "Component Videos" },
  { id: "OTHER", value: "Others" },
];
