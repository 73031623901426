import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { PlantComponentListActions } from "../../../../redux-slice/plant/ComponentListSlice";

// Constants
import { DataPreLoadKeys, QueryParamsKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";

// Components
import ComponentTypeList from "../../component-type-list/ComponentTypeList";

// Page Constants
function PlantComponentsSidebarTabs({ tabId, updateQueryParams, componentTypeId, componentTypes = [] }) {
  // Function
  function setComponent(componentType) {
    const { id } = componentType;

    const params = {
      tabId,
      componentTypeId: id,
    };

    // Updating query params
    updateQueryParams({ params });
  }

  // filter active
  const activeComponentTypes = React.useMemo(() => {
    return componentTypes.filter((ct) => ct.isActive);
  }, [componentTypes]);

  return (
    <ul className="nav nav-pills flex-column">
      {activeComponentTypes.map((componentType, index) => {
        return (
          <li key={index} className="nav-item" role="presentation">
            <Link
              className={`nav-link ${componentType.id === parseInt(componentTypeId) && "active"}`}
              data-bs-toggle="tab"
              href="#tab-comp-1"
              role="tab"
              onClick={() => setComponent(componentType)}
            >
              {componentType.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

/**
 * Plant List Table
 */
export default function PlantComponentsTabContent() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Search Params state
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Getting parameters and component types from local storage
  const { componentTypes = [] } = JSON.parse(getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  // Setting default component value
  const { id = "" } = componentTypes[0] || {};

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const tabId = searchParams.get(QueryParamsKeys.tabId) || 0;
  const componentTypeId = searchParams.get(QueryParamsKeys.componentTypeId) || id;

  useEffect(() => {
    // Dispatch
    dispatch(PlantComponentListActions.getPlantComponentList({ pageNumber, plantId, componentTypeId }));
  }, [dispatch, pageNumber, plantId, componentTypeId]);

  return (
    <div className="page-content row gx-0" id="tab-content">
      <div className="col-2 left-tab-content">
        {/* Plant Components Sidebar Tabs */}
        <PlantComponentsSidebarTabs
          componentTypes={componentTypes}
          componentTypeId={componentTypeId}
          updateQueryParams={updateQueryParams}
          tabId={tabId}
        />
      </div>
      <div className="col-10">
        <div className="right-tab-content">
          {/* Component Type List */}
          <ComponentTypeList
            pageNumber={pageNumber}
            componentTypeId={componentTypeId}
            componentTypes={componentTypes}
          />
        </div>
      </div>
    </div>
  );
}
