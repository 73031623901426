// Error Reducer
import { ErrorReducer } from "./error/ErrorSlice";

// Data Pre Load Reducer
import { DataPreLoadReducer } from "./data-pre-load/DataPreLoadSlice";

// App Root Reducer
export const AppRootReducer = {
  // Error
  error: ErrorReducer,

  // Data Pre Load
  dataPreLoad: DataPreLoadReducer,
};
