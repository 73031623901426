import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Create plant thermal bill
  createPlantThermalBillLoading: false,
  createPlantThermalBillError: "",

  // Update plant thermal bill
  updatePlantThermalBillLoading: false,
  updatePlantThermalBillError: "",

  // Upload plant thermal bill
  uploadPlantThermalBillLoading: {},
  uploadPlantThermalBillError: "",

  // Download plant thermal bill
  downloadPlantThermalBillLoading: {},
  downloadPlantThermalBillError: "",
};

// Plant Thermal Bill Slice
const plantThermalBill = createSlice({
  name: "PlantThermalBill",
  initialState,
  reducers: {
    // Create plant thermal bill
    createPlantThermalBill: (state) => {
      state.createPlantThermalBillLoading = true;
    },

    createPlantThermalBillSuccess: (state) => {
      state.createPlantThermalBillLoading = false;
    },

    createPlantThermalBillFailure: (state, action) => {
      state.createPlantThermalBillLoading = false;
      state.createPlantThermalBillError = action.payload.error;
    },

    // Update plant thermal bill
    updatePlantThermalBill: (state) => {
      state.updatePlantThermalBillLoading = true;
    },

    updatePlantThermalBillSuccess: (state) => {
      state.updatePlantThermalBillLoading = false;
    },

    updatePlantThermalBillFailure: (state, action) => {
      state.updatePlantThermalBillLoading = false;
      state.updatePlantThermalBillError = action.payload.error;
    },

    // Upload plant thermal bill
    uploadPlantThermalBill: (state, action) => {
      const { thermalBillId } = action.payload;

      state.uploadPlantThermalBillLoading = {
        ...state.uploadPlantThermalBillLoading,
        [thermalBillId]: true,
      };
    },

    uploadPlantThermalBillSuccess: (state, action) => {
      const { thermalBillId } = action.payload;

      // To get previous state
      const cloneduploadPlantThermalBillLoading = { ...state.uploadPlantThermalBillLoading };

      // To remove the id from state after successfull loading
      delete cloneduploadPlantThermalBillLoading[thermalBillId];

      state.uploadPlantThermalBillLoading = cloneduploadPlantThermalBillLoading;
    },

    uploadPlantThermalBillFailure: (state, action) => {
      const { thermalBillId, error } = action.payload;

      // To get previous state
      const cloneduploadPlantThermalBillLoading = { ...state.uploadPlantThermalBillLoading };

      // To remove the id from state after successfull loading
      delete cloneduploadPlantThermalBillLoading[thermalBillId];

      state.uploadPlantThermalBillLoading = cloneduploadPlantThermalBillLoading;
      state.uploadPlantThermalBillError = error;
    },

    // Download plant thermal bill
    downloadPlantThermalBill: (state, action) => {
      const { thermalBillId = "" } = action.payload;

      state.downloadPlantThermalBillLoading = {
        ...state.downloadPlantThermalBillLoading,
        [thermalBillId]: true,
      };
    },

    downloadPlantThermalBillSuccess: (state, action) => {
      const { thermalBillId = "" } = action.payload;

      // To get previous state
      const clonedDownloadPlantThermalBillLoading = { ...state.downloadPlantThermalBillLoading };

      // To remove the id from state after successfull loading
      delete clonedDownloadPlantThermalBillLoading[thermalBillId];

      state.downloadPlantThermalBillLoading = clonedDownloadPlantThermalBillLoading;
    },

    downloadPlantThermalBillFailure: (state, action) => {
      const { thermalBillId = "", error } = action.payload;

      // To get previous state
      const clonedDownloadPlantThermalBillLoading = { ...state.downloadPlantThermalBillLoading };

      // To remove the id from state after successfull loading
      delete clonedDownloadPlantThermalBillLoading[thermalBillId];

      state.downloadPlantThermalBillLoading = clonedDownloadPlantThermalBillLoading;
      state.uploadPlantThermalBillError = error;
    },
  },
});

// Reducer
export const PlantThermalBillReducer = plantThermalBill.reducer;

// Actions
export const PlantThermalBillActions = plantThermalBill.actions;
