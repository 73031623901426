// Urls
import { getConstantListUrl } from "../../urls/api-urls/ConstantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Constant List
export async function getConstantListApi() {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getConstantListUrl);
}
