import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantElectricityBillListActions } from "../../redux-slice/plant/ElectricityBillListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getPlantElectricityBillListByIdApi } from "../../api/plant/ElectricityBillListAPI";

// Get Plant Electricity Bill List By Id
function* getPlantElectricityBillListById(action) {
  try {
    const { pageNumber, pageSize, searchText, plantId } = action.payload;

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      PlantElectricityBillListActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillListActions.getPlantElectricityBillListFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantElectricityBillListActions.getPlantElectricityBillList.type, getPlantElectricityBillListById),
  ]);
}
