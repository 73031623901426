import { useState } from "react";
import { useSelector } from "react-redux";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";
import { DefaultPagination } from "../../constants/GeneralConstants";

// Utils
import tableSerialNumber from "../../utils/TableUtils";

// Components
import { Button } from "../../components/button/Button";
import TableDataNotFound from "../../components/table/TableDataNotFound";
import TableHeaders from "../../components/table/TableHeader";
import TableLoader from "../../components/table/TableLoader";
import TablePagination from "../../components/table/TablePagination";

// Section
import FileUploadModal from "./FileUploadModal";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "File Type",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Size",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function FileListActionCell({ file = {}, setSelectedFile, fileReduceKey, dispatchActions, setShowModal = () => {} }) {
  // Download File Selector State
  const downloadFileLoading = useSelector((state) => state[fileReduceKey].downloadFileLoading);

  // Delete File Selector State
  const deleteFileLoading = useSelector((state) => state[fileReduceKey].deleteFileLoading);

  // File Information
  const { id = "", name = "", url = "" } = file;

  // Dispatch Actions
  const { downloadFileFn = "", deleteFileFn = "" } = dispatchActions;

  // Loading Status
  const downloadBtnLoadingStatus = downloadFileLoading[id] || false;
  const deleteBtnLoadingStatus = deleteFileLoading[id] || false;

  // Open File Upload Modal
  function openFileUploadEditModal() {
    setSelectedFile(file);
    setShowModal(true);
  }

  return (
    <td>
      <div className="btn-cont">
        {/* View File Button */}
        <Button
          label="View"
          size="sm"
          onClick={openFileUploadEditModal}
          data-bs-target={`#${ModalKeys.fileUploadModal}`}
          data-bs-toggle="modal"
        >
          <i className="fas fa-eye"></i>
        </Button>

        {/* Download File Button */}
        {downloadFileFn && (
          <Button
            label="Download"
            color="dark"
            size="sm"
            onClick={() => downloadFileFn(id, name, url)}
            loading={downloadBtnLoadingStatus}
            disabled={downloadBtnLoadingStatus}
          >
            <i className="fas fa-download"></i>
          </Button>
        )}

        {/* Delete File Button */}
        {deleteFileFn && (
          <Button
            label="Delete"
            color="danger"
            size="sm"
            onClick={() => deleteFileFn(id)}
            loading={deleteBtnLoadingStatus}
            disabled={deleteBtnLoadingStatus}
          >
            <i className="fas fa-trash"></i>
          </Button>
        )}
      </div>
    </td>
  );
}

function FileListTableRow({
  serialNo,
  file = {},
  setSelectedFile,
  fileReduceKey,
  dispatchActions,
  setShowModal = () => {},
}) {
  // File Information
  const { type = "", name = "", size = "" } = file;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{type}</td>
      <td>{name}</td>
      <td>{`${size} bytes`}</td>

      {/* File List Action Cell  */}
      <FileListActionCell
        file={file}
        setSelectedFile={setSelectedFile}
        fileReduceKey={fileReduceKey}
        dispatchActions={dispatchActions}
        setShowModal={setShowModal}
      />
    </tr>
  );
}

function FileListTableBody({
  fileList,
  fileListLoading,
  pageSize,
  pageNumber,
  setSelectedFile,
  fileReduceKey,
  dispatchActions,
  setShowModal = () => {},
}) {
  // Loader
  if (fileListLoading) {
    return <TableLoader colSpan={6} />;
  }

  // If there is no file list
  if (fileList.length === 0) {
    return <TableDataNotFound message="No files present" colSpan={6} />;
  }

  // File list table row
  return (
    <>
      {fileList.map((file, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        // File List Table Row
        return (
          <FileListTableRow
            key={file.id}
            serialNo={serialNo}
            file={file}
            setSelectedFile={setSelectedFile}
            fileReduceKey={fileReduceKey}
            dispatchActions={dispatchActions}
            setShowModal={setShowModal}
          />
        );
      })}
    </>
  );
}

function FileListHeader({ setSelectedFile, dispatchActions, setShowModal = () => {} }) {
  const { uploadFileFn = "" } = dispatchActions;

  // Open File Upload Create Modal Function
  function openFileUploadCreateModal() {
    setSelectedFile({});
    setShowModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        <h4>Uploaded Files</h4>
      </div>

      {/* Button */}
      {uploadFileFn && (
        <Button
          label="Upload File"
          color="dark"
          data-bs-toggle="modal"
          data-bs-target={`#${ModalKeys.fileUploadModal}`}
          onClick={openFileUploadCreateModal}
        >
          <i className="fa-solid fa-plus me-2"></i>
        </Button>
      )}
    </div>
  );
}

/**
 * File Upload And List Section
 */
export default function FileUploadAndListSection({
  fileListReducerKey = "",
  fileReduceKey = "",
  dispatchActions = {},
  optionsArray = [],
}) {
  // State
  const [selectedFile, setSelectedFile] = useState({});
  const [showModal, setShowModal] = useState(false);

  // Selector State
  const fileList = useSelector((state) => state[fileListReducerKey].fileList) || [];
  const fileListLoading = useSelector((state) => state[fileListReducerKey].fileListLoading) || false;
  const fileListPagination = useSelector((state) => state[fileListReducerKey].fileListPagination) || {};

  // Pagination
  const {
    pageNo = DefaultPagination.pageNumber,
    pageSize = DefaultPagination.pageSize,
    totalCount = DefaultPagination.totalCount,
  } = fileListPagination || {};

  return (
    <>
      <div className="page-content">
        {/* File List Header */}
        <FileListHeader
          setSelectedFile={setSelectedFile}
          dispatchActions={dispatchActions}
          setShowModal={setShowModal}
        />

        <div className="table-responsive">
          <table className="table table-bordered border-end">
            {/* Table Headers */}
            <TableHeaders tableHeaders={tableHeaders} />

            <tbody>
              {/* File List Table Body */}
              <FileListTableBody
                fileList={fileList}
                fileListLoading={fileListLoading}
                pageNumber={pageNo}
                pageSize={pageSize}
                setSelectedFile={setSelectedFile}
                fileReduceKey={fileReduceKey}
                dispatchActions={dispatchActions}
                setShowModal={setShowModal}
              />
            </tbody>
          </table>

          {/* Table Pagination */}
          <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
        </div>
      </div>

      {/* File Upload Modal */}
      <FileUploadModal
        selectedFile={selectedFile}
        reducerKey={fileReduceKey}
        dispatchActions={dispatchActions}
        optionsArray={optionsArray}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
