import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Audit Create
  auditCreateLoading: false,
  auditCreateError: "",

  // Get Audit Information By Id
  auditInfo: {},
  auditInfoLoading: false,
  auditInfoError: "",

  // Get Audit Detailed Progress By Id
  auditDetailedProgress: {},
  auditDetailedProgressLoading: false,
  auditDetailedProgressError: "",

  // Update Plant Incharge By Id
  updatePlantInchargeLoading: false,
  updatePlantInchargeError: "",

  // Update Audit Incharge By Id
  updateAuditInchargeLoading: false,
  updateAuditInchargeError: "",

  // Start Audit Pre Assessment By Id
  startAuditPreAssessmentLoading: false,
  startAuditPreAssessmentError: "",

  // Start Pre Audit By Id
  startPreAuditLoading: false,
  startPreAuditError: "",

  // Add Auditor By Id
  addAuditorLoading: false,
  addAuditorError: "",

  // Remove Auditor By Id
  removeAuditorLoading: {},
  removeAuditorError: "",
};

// Audit Slice
const auditSlice = createSlice({
  name: "AuditSlice",
  initialState,
  reducers: {
    // Audit Create
    createAudit: (state) => {
      state.auditCreateLoading = true;
    },

    createAuditSuccess: (state) => {
      state.auditCreateLoading = false;
    },

    createAuditFailure: (state, action) => {
      state.auditCreateLoading = false;
      state.auditCreateError = action.payload.error;
    },

    // Get Audit Information
    getAuditInfo: (state) => {
      state.auditInfoLoading = true;
    },

    getAuditInfoSuccess: (state, action) => {
      state.auditInfoLoading = false;
      state.auditInfo = action.payload.auditInfo;
    },

    getAuditInfoFailure: (state, action) => {
      state.auditInfoLoading = false;
      state.auditInfoError = action.payload.error;
    },

    // Get Audit Detailed Progress
    getAuditDetailedProgress: (state) => {
      state.auditDetailedProgressLoading = true;
    },

    getAuditDetailedProgressSuccess: (state, action) => {
      state.auditDetailedProgressLoading = false;
      state.auditDetailedProgress = action.payload.auditDetailedProgress;
    },

    getAuditDetailedProgressFailure: (state, action) => {
      state.auditDetailedProgressLoading = false;
      state.auditDetailedProgressError = action.payload.error;
    },

    // Update Plant Incharge
    updatePlantIncharge: (state) => {
      state.updatePlantInchargeLoading = true;
    },

    updatePlantInchargeSuccess: (state) => {
      state.updatePlantInchargeLoading = false;
    },

    updatePlantInchargeFailure: (state, action) => {
      state.updatePlantInchargeLoading = false;
      state.updatePlantInchargeError = action.payload.error;
    },

    // Update Audit Incharge
    updateAuditIncharge: (state) => {
      state.updateAuditInchargeLoading = true;
    },

    updateAuditInchargeSuccess: (state) => {
      state.updateAuditInchargeLoading = false;
    },

    updateAuditInchargeFailure: (state, action) => {
      state.updateAuditInchargeLoading = false;
      state.updateAuditInchargeError = action.payload.error;
    },

    // Start Audit Pre Assessment
    startAuditPreAssessment: (state) => {
      state.startAuditPreAssessmentLoading = true;
    },

    startAuditPreAssessmentSuccess: (state) => {
      state.startAuditPreAssessmentLoading = false;
    },

    startAuditPreAssessmentFailure: (state, action) => {
      state.startAuditPreAssessmentLoading = false;
      state.startAuditPreAssessmentError = action.payload.error;
    },

    // Start Pre Audit
    startPreAudit: (state) => {
      state.startPreAuditLoading = true;
    },

    startPreAuditSuccess: (state) => {
      state.startPreAuditLoading = false;
    },

    startPreAuditFailure: (state, action) => {
      state.startPreAuditLoading = false;
      state.startPreAuditError = action.payload.error;
    },

    // Add Auditor
    addAuditor: (state) => {
      state.addAuditorLoading = true;
    },

    addAuditorSuccess: (state) => {
      state.addAuditorLoading = false;
    },

    addAuditorFailure: (state, action) => {
      state.addAuditorLoading = false;
      state.addAuditorError = action.payload.error;
    },

    // Remove Auditor
    removeAuditor: (state, action) => {
      const { userId } = action.payload;

      state.removeAuditorLoading = {
        ...state.removeAuditorLoading,
        [userId]: true,
      };
    },

    removeAuditorSuccess: (state, action) => {
      const { userId } = action.payload;

      //To get previous state
      const clonedRemoveAuditorLoading = {
        ...state.removeAuditorLoading,
      };

      //To remove the id from state after successfull loading
      delete clonedRemoveAuditorLoading[userId];

      state.removeAuditorLoading = clonedRemoveAuditorLoading;
    },

    removeAuditorFailure: (state, action) => {
      const { userId, error } = action.payload;

      //To get previous state
      const clonedRemoveAuditorLoading = {
        ...state.removeAuditorLoading,
      };

      //To remove the id from state after successfull loading
      delete clonedRemoveAuditorLoading[userId];

      state.removeAuditorLoading = clonedRemoveAuditorLoading;
      state.removeAuditorError = error;
    },
  },
});

// Reducer
export const AuditReducer = auditSlice.reducer;

// Actions
export const AuditActions = auditSlice.actions;
