// Utils
import { getObservationCodesByComponentType } from "../../../../../utils/ComponentUtils";

// Components
import TableHeaders from "../../../../../components/table/TableHeader";
import TableDataNotFound from "../../../../../components/table/TableDataNotFound";

// Page Constants
const TABLE_HEADERS = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Notes",
    },
  },
];

// Page Components
function ObservationTableRow({ obsCode = {}, preLoadedObsCodeList = [], serialNo = "" }) {
  // Added Observation Information for a component
  const { observationCodeId = "", notes = "--" } = obsCode;

  // Finding code and desccription for that observation from local storage data
  const { code = "", description = "" } = preLoadedObsCodeList.find(({ id = "" }) => id === observationCodeId) || {};

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{code}</td>
      <td>{description}</td>
      <td>{notes}</td>
    </tr>
  );
}

function ObservationListTableBody({ obsCodeList = [], preLoadedObsCodeList = [] }) {
  // If there is no observation codes
  if (obsCodeList.length === 0) {
    return <TableDataNotFound message="No observation added" colSpan={16} />;
  }

  // Observation Table Row
  return (
    <>
      {obsCodeList.map((obsCode, idx) => {
        return (
          <ObservationTableRow
            key={idx}
            obsCode={obsCode}
            serialNo={idx + 1}
            preLoadedObsCodeList={preLoadedObsCodeList}
          />
        );
      })}
    </>
  );
}

/**
 * Observation List Table
 * @param {*} componentTypeId : Component Type Id
 * @param {*} obsCodeList : Array of observation codes
 */
export default function ObservationListTable({ componentTypeId = "", obsCodeList = [] }) {
  // Getting observation codes from local storage
  const preLoadedObsCodeList = getObservationCodesByComponentType(componentTypeId) || [];

  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Observations</h3>

      <div className="table-responsive">
        <table className="table table-bordered border-end">
          {/* Table Headers */}
          <TableHeaders tableHeaders={TABLE_HEADERS} />

          <tbody>
            {/* Observation List Table Body */}
            <ObservationListTableBody obsCodeList={obsCodeList} preLoadedObsCodeList={preLoadedObsCodeList} />
          </tbody>
        </table>
      </div>
    </section>
  );
}
