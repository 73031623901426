import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Update Material Status (Enable/Disable)
  updateMaterialStatusLoadingMap: {},
  updateMaterialStatusError: "",
};

const materialSlice = createSlice({
  name: "Material",
  initialState,
  reducers: {
    // Update Material Status (Enable/Disable)
    updateMaterialStatus: (state, action) => {
      const { materialId } = action.payload;

      state.updateMaterialStatusLoadingMap = {
        ...state.updateMaterialStatusLoadingMap,
        [materialId]: true,
      };
    },

    updateMaterialStatusSuccess: (state, action) => {
      const { materialId } = action.payload;

      //To get previous state
      const clonedUpdateMaterialStatusLoadingMap = { ...state.updateMaterialStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateMaterialStatusLoadingMap[materialId];

      state.updateMaterialStatusLoadingMap = clonedUpdateMaterialStatusLoadingMap;
    },

    updateMaterialStatusFailure: (state, action) => {
      const { materialId, error } = action.payload;

      //To get previous state
      const clonedUpdateMaterialStatusLoadingMap = { ...state.updateMaterialStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateMaterialStatusLoadingMap[materialId];

      state.updateMaterialStatusLoadingMap = clonedUpdateMaterialStatusLoadingMap;
      state.updateMaterialStatusError = error;
    },
  },
});

// Reducer
export const MaterialReducer = materialSlice.reducer;

// Actions
export const MaterialActions = materialSlice.actions;
