import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentListActions } from "../../redux-slice/plant/ComponentListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getPlantComponentListApi } from "../../api/plant/ComponentListAPI";

// Get Plant Component List
function* getPlantComponentList(action) {
  try {
    const { pageNumber, pageSize, plantId, componentTypeId } = action.payload;

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

    // Dispatching Action
    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentListActions.getPlantComponentListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(PlantComponentListActions.getPlantComponentList.type, getPlantComponentList)]);
}
