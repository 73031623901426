import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Constants
import { RedirectTo } from "./urls/page-urls/RedirectURL";

// CSS and JS
import "./App.scss";
import "bootstrap/dist/js/bootstrap.js";

// Pages
import LoginPage from "./pages/auth/LoginPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ErrorPage from "./pages/error/ErrorPage";

// Dev Pages
import BootstrapThemePage from "./pages/dev/BootstrapThemePage";
import CustomComponentsPage from "./pages/dev/CustomComponentsPage";

// Routes
import { MainPageRoutes } from "./routes/MainPageRoutes";
import DefPageRoutes from "./routes/DefPageRoutes";
import { AuditRoutes } from "./routes/AuditRoutes";
import { PlantRoutes } from "./routes/PlantRoutes";
import { UserRoutes } from "./routes/UserRoutes";

//Page Components
const router = createBrowserRouter([
  // Main Page Routes
  MainPageRoutes,

  // Definitions
  DefPageRoutes,

  // Audit Routes
  AuditRoutes,

  // Plant Routes
  PlantRoutes,

  // User Routes
  UserRoutes,

  {
    path: RedirectTo.LoginPageUrl,
    element: <LoginPage />,
  },
  {
    path: RedirectTo.ForgotPasswordPageUrl,
    element: <ForgotPasswordPage />,
  },
  {
    path: RedirectTo.ErrorPageUrl,
    element: <ErrorPage />,
  },

  // Development Pages
  {
    path: "/dev/theme",
    element: <BootstrapThemePage />,
  },
  {
    path: "/dev/component",
    element: <CustomComponentsPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
