import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get HSN Code - List
  hsnCodeList: [],
  hsnCodeListPagination: {},
  hsnCodeListLoading: false,
  hsnCodeListError: "",
};

// HSN Code List
const HSNCodeListSlice = createSlice({
  name: "HSNCodeList",
  initialState,
  reducers: {
    // Get HSN Code - List
    getHSNCodeList: (state) => {
      state.hsnCodeListLoading = true;
    },

    getHSNCodeListSuccess: (state, action) => {
      state.hsnCodeListLoading = false;
      state.hsnCodeList = action.payload.hsnCodeList;
      state.hsnCodeListPagination = action.payload.hsnCodeListPagination;
    },

    getHSNCodeListFailure: (state, action) => {
      state.hsnCodeListLoading = false;
      state.hsnCodeListError = action.payload.error;
    },
  },
});

// Reducer
export const HSNCodeListReducer = HSNCodeListSlice.reducer;

// Actions
export const HSNCodeListActions = HSNCodeListSlice.actions;
