import { useNavigate } from "react-router-dom";

// Constants
import { DateTimeFormat, ValueTypes } from "../../constants/GeneralConstants";

// Utils
import { getFormattedDateTime } from "../../utils/DateUtils";
import tableSerialNumber from "../../utils/TableUtils";
import URLs from "../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Components
import { OutlineButton } from "../../components/button/Button";
import TableHeaders from "../../components/table/TableHeader";
import TableLoader from "../../components/table/TableLoader";
import TableDataNotFound from "../../components/table/TableDataNotFound";
import TablePagination from "../../components/table/TablePagination";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Plant",
    },
  },
  {
    title: {
      displayName: "Plant Incharge",
    },
  },
  {
    title: {
      displayName: "Auditor",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Created On",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function AuditListCell({ mainText, subText, className }) {
  return (
    <td className={className}>
      {mainText}
      <span className="sub-text">{subText}</span>
    </td>
  );
}

function AuditListActionsCell({ auditId }) {
  // Navigate
  const navigate = useNavigate();

  // Initiate new audit
  function navigateToAuditViewPage() {
    const auditViewPageURL = URLs.format(RedirectTo.AuditViewPageUrl, { auditId });
    navigate(auditViewPageURL);
  }

  return (
    <td className="col-1">
      <div className="btn-cont">
        <OutlineButton label="View" size="sm" onClick={navigateToAuditViewPage}>
          <i className="fas fa-eye"></i>
        </OutlineButton>
      </div>
    </td>
  );
}

function AuditListTableRow({ serialNo, audit }) {
  const {
    id,
    plantName,
    industryOrgName,
    plantInchargeName,
    plantAddress,
    auditInchargeName,
    auditOrgName,
    auditStatus,
    startTimestamp,
  } = audit;

  // Ref: https://stackoverflow.com/questions/44971954/how-to-get-am-pm-from-the-date-time-string-using-moment-js
  // lll - To get AM and PM for time format
  const createdOn = getFormattedDateTime(ValueTypes.DURATION, startTimestamp, DateTimeFormat.date_time);

  return (
    <tr>
      <td className="col-1">{serialNo}</td>

      {/* Audit List Cell */}
      <AuditListCell mainText={plantName} subText={plantAddress} className="col-3" />
      <AuditListCell mainText={plantInchargeName} subText={industryOrgName} className="col-2" />
      <AuditListCell mainText={auditInchargeName} subText={auditOrgName} className="col-2" />

      <td className="col-1">{auditStatus}</td>
      <td className="col-2">{createdOn}</td>

      {/* AuditList Action Cell  */}
      <AuditListActionsCell auditId={id} />
    </tr>
  );
}

function AuditListTableBody({ auditListLoading = false, auditList = [], pageSize = 1, pageNumber = 1 }) {
  // Loader
  if (auditListLoading) {
    return <TableLoader colSpan={8} />;
  }

  // If there is no audit list
  if (auditList.length === 0) {
    return <TableDataNotFound message="No audit present" colSpan={8} />;
  }

  // Audit list table row
  return (
    <>
      {auditList.map((audit, index) => {
        // Serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        // Audit List Table Row
        return <AuditListTableRow key={audit.id} serialNo={serialNo} audit={audit} />;
      })}
    </>
  );
}

/**
 * Audit List Table
 */
export default function AuditListTable({ items = [], loading = false, pagination = {}, showPagination = false }) {
  // Pagination
  const { totalCount, pageNo, pageSize } = pagination || {};

  return (
    <div className="table-responsive">
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Audit List Table Body */}
          <AuditListTableBody auditList={items} auditListLoading={loading} pageSize={pageSize} pageNumber={pageNo} />
        </tbody>
      </table>

      {/* Table Pagination */}
      {showPagination && <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />}
    </div>
  );
}
