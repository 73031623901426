import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Actions
import { AuditInstrumentActions } from "../../redux-slice/audit/InstrumentSlice";
import { AuditInstrumentListActions } from "../../redux-slice/audit/InstrumentListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// APIs
import {
  getAuditInstrumentDataInfoApi,
  selectAuditInstrumentDataApi,
  uploadAuditInstrumentDataFileApi,
} from "../../api/audit/InstrumentAPI";

import { getAuditInstrumentDataListApi } from "../../api/audit/InstrumentListAPI";

// Component Action
import { ModalActions } from "../../components/modal/Modal";

// Get Audit Instrument Data Info
function* getAuditInstrumentDataInfo(action) {
  try {
    const { instrumentDataId } = action.payload;

    const auditInstrumentDataInfo = yield getAuditInstrumentDataInfoApi(instrumentDataId);

    // Dispatching Action
    yield put(AuditInstrumentActions.getAuditInstrumentDataInfoSuccess({ auditInstrumentDataInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.getAuditInstrumentDataInfoFailure({ error }));
  }
}

// Select Audit Instrument Data
function* selectAuditInstrumentData(action) {
  const { instrumentDataId, sheetNo, lineNo } = action.payload;

  try {
    yield selectAuditInstrumentDataApi(instrumentDataId, sheetNo, lineNo);

    // Dispatching Action
    yield put(AuditInstrumentActions.selectAuditInstrumentDataSuccess({ lineNo }));

    // Toaster
    toast.success("Measurement Value Selected Successfully");

    // Dispatch to close modal
    ModalActions.close(ModalKeys.instrumentDataViewModal);
  } catch (error) {
    // Dispatch to close modal
    ModalActions.close(ModalKeys.instrumentDataViewModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.selectAuditInstrumentDataFailure({ error, lineNo }));
  }
}

// Upload Audit Instrument Data File
function* uploadAuditInstrumentDataFile(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield uploadAuditInstrumentDataFileApi(formData);

    const { items, pagination } = yield getAuditInstrumentDataListApi(auditId, componentId);

    // Dispatching action to refresh instrument data list table
    yield put(
      AuditInstrumentListActions.getAuditInstrumentDataListSuccess({
        auditInstrumentDataList: items,
        auditInstrumentDataListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(AuditInstrumentActions.uploadAuditInstrumentDataFileSuccess());

    // Toaster
    toast.success("Instrument Data File Uploaded Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.uploadAuditInstrumentDataFileFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditInstrumentActions.getAuditInstrumentDataInfo.type, getAuditInstrumentDataInfo),
    takeLatest(AuditInstrumentActions.selectAuditInstrumentData.type, selectAuditInstrumentData),
    takeLatest(AuditInstrumentActions.uploadAuditInstrumentDataFile.type, uploadAuditInstrumentDataFile),
  ]);
}
