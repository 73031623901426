// Components
import ErrorMessage from "../error-message/ErrorMessage";

// Page Components
function SelectInputOptions({ options }) {
  return options.map((option) => {
    const { id = "", value = "" } = option || {};

    // If id is empty, then value is taken as id and value for option
    const updatedValue = (id ?? "") || value;

    return (
      <option key={updatedValue} value={updatedValue}>
        {value}
      </option>
    );
  });
}

/**
 * Select Input
 * @param {*} className
 * @param {*} selectedValue :  Default value to displayed
 * @param {*} options : Array of strings
 * @param {*} placeholder
 * @param {*} onChange : Function
 * @param {*} disabled : Boolean value
 * @param {*} error : error
 * @returns
 */
export default function SelectInput({
  className = "",
  value = "",
  options = [],
  placeholder = "Enter text....",
  onChange = () => {},
  disabled = false,
  error = "",
  isEdit = false,
  showDefault = true,
  ...rest
}) {
  return (
    <>
      <select
        className={`shadow-none ${className} ${isEdit || disabled ? "form-control" : "form-select"} ${
          error && "border-danger"
        }`}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      >
        {showDefault && <option value="">--</option>}

        {/* Select Input Options */}
        <SelectInputOptions options={options} />
      </select>

      {/* To show error message */}
      {error && <ErrorMessage error={error} />}
    </>
  );
}
