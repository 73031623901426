import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ErrorActions } from "../../app/error/ErrorSlice";
import { AuditReportsListActions } from "../../redux-slice/audit/ReportsListSlice";

// APIs
import { getReportsListApi } from "../../api/audit/ReportsListAPI";

// Get Reports List
function* getReportsList(action) {
  try {
    const { auditId = "" } = action.payload;

    const reportsList = yield getReportsListApi(auditId);

    // Dispatching Action
    yield put(AuditReportsListActions.getReportsListSuccess({ reportsList }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsListActions.getReportsListFailure({ error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([takeLatest(AuditReportsListActions.getReportsList.type, getReportsList)]);
}
