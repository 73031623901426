import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { MeasurementTypeListActions } from "../../redux-slice/measurement-type/MeasurementTypeListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  getMeasurementTypeListApi,
  getMeasurementUnitsListApi,
} from "../../api/measurement-type/MeasurementTypeListAPI";

// Get Measurement Type List
function* getMeasurementTypeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getMeasurementTypeListApi(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      MeasurementTypeListActions.getMeasurementTypeListSuccess({
        measurementTypeList: items,
        measurementTypeListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeListActions.getMeasurementTypeListFailure({ error }));
  }
}

// Get Measurement Units By Id
function* getMeasurementUnitsById(action) {
  try {
    const { measurementTypeId } = action.payload;

    const { items, pagination } = yield getMeasurementUnitsListApi(measurementTypeId);

    // Dispatching Action
    yield put(
      MeasurementTypeListActions.getMeasurementUnitsListSuccess({
        measurementUnitsList: items,
        measurementUnitsListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeListActions.getMeasurementUnitsListFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(MeasurementTypeListActions.getMeasurementTypeList.type, getMeasurementTypeList),
    takeLatest(MeasurementTypeListActions.getMeasurementUnitsList.type, getMeasurementUnitsById),
  ]);
}
