import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { PlantComponentActions } from "../../../redux-slice/plant/ComponentSlice";

// Constants
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import { getComponentTypeParameters } from "../../../utils/DataPreLoadUtils";
import {
  DefaultInputRows,
  RemarksInputRow,
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../utils/ComponentUtils";

// Components
import { Button } from "../../../components/button/Button";
import { DefaultFormValidationSchema } from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import Modal from "../../../components/modal/Modal";
import TabularForm from "../../../components/form/TabularForm";

// Sections
import ComponentTypeListCardView from "./ComponentTypeListCardView";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Design Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Component
function ComponentTypeListPageHeader({
  modalId,
  selectedComponentTypeName = "",
  setSelectedComponentIndex,
  setShowModal = () => {},
}) {
  // Open Component Type Modal Function
  function openComponentTypeModal() {
    setSelectedComponentIndex("");
    setShowModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        {/* Input */}
        <Input className="d-none" placeholder="Search plant components..." />
      </div>

      {/* Button */}
      <Button
        label={`Add ${selectedComponentTypeName}`}
        color="dark"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        onClick={openComponentTypeModal}
      >
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Component Type List
 */
export default function ComponentTypeList({ componentTypeId = "", componentTypes = [] }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // State
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);

  // Add Plant Component Selector State
  const addPlantComponentLoading = useSelector((state) => state.plantComponent.addPlantComponentLoading);

  // Modal Ids
  const { addPlantComponentModal } = ModalKeys;

  // Selected Component Type Name
  const { name: selectedComponentTypeName = "" } =
    componentTypes.find(({ id = "" }) => id === parseInt(componentTypeId)) || {};

  // Getting component property list
  const propertyList = getComponentTypeParameters(componentTypeId);
  const addComponentFormData = constructFormDataObject(propertyList);

  // Getting component info
  const { component = {}, properties = [] } = plantComponentList[selectedComponentIndex] || {};
  const { id = "" } = component;

  // Form inputs for view and add component
  const componentFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = { showUnits: false, isInputPresent: true, isUnitConversionPresent: true };

    const formInputs = getParametersFormInputs(propertyList, properties, tableConfig, id);

    return [...DefaultInputRows, ...formInputs, RemarksInputRow];
  }, [propertyList, properties, id]);

  // Add component form submit function
  function addComponentTypeFormSubmit(componentPropertyData) {
    const { name = "", internalId = "", remarks = "" } = componentPropertyData;

    const propertiesArray = constructParameterValuesArray(propertyList, componentPropertyData);

    const componentData = { componentTypeId, name, internalId, remarks, isParent: true, properties: propertiesArray };

    dispatch(PlantComponentActions.addPlantComponent({ componentData, plantId, componentTypeId }));
  }

  return (
    <>
      {/* Component Type List Page Header */}
      <ComponentTypeListPageHeader
        modalId={addPlantComponentModal}
        setSelectedComponentIndex={setSelectedComponentIndex}
        setShowModal={setShowModal}
        selectedComponentTypeName={selectedComponentTypeName}
      />

      {/* Component Type List Card View */}
      <ComponentTypeListCardView
        componentTypeId={componentTypeId}
        tableHeaders={tableHeaders}
        formInputItems={componentFormInputs}
        selectedComponentIndex={selectedComponentIndex}
        setSelectedComponentIndex={setSelectedComponentIndex}
      />

      {/* Add Component Modal */}
      <Modal
        id={addPlantComponentModal}
        title="Add Component"
        className="modal-dialog-centered"
        size="lg"
        setShowModal={setShowModal}
      >
        <TabularForm
          tableHeaders={tableHeaders}
          loading={addPlantComponentLoading}
          formInputItems={componentFormInputs}
          formValidationSchema={DefaultFormValidationSchema}
          data={addComponentFormData}
          formSubmit={addComponentTypeFormSubmit}
          showModal={showModal}
        />
      </Modal>
    </>
  );
}
