// Urls
import {
  getComponentTypeInfoByIdUrl,
  updateComponentTypeStatusByIdUrl,
  updateObservationCodeStatusByIdUrl,
  updateRecommendationCodeStatusByIdUrl,
  updateParameterStatusByIdUrl,
} from "../../urls/api-urls/ComponentTypeURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Get Component Type Info By Id Api
export async function getComponentTypeInfoByIdApi(componentTypeId) {
  const url = URLs.format(getComponentTypeInfoByIdUrl, { componentTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update ComponentType Status By ID
export async function updateComponentTypeStatusByIdApi(componentTypeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateComponentTypeStatusByIdUrl, { componentTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Update Observation Code Status By ID
export async function updateObservationCodeStatusByIdApi(obsCodeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateObservationCodeStatusByIdUrl, { obsCodeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Update Recommendation Code Status By ID
export async function updateRecommendationCodeStatusByIdApi(recCodeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateRecommendationCodeStatusByIdUrl, { recCodeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Update Parameter status
export async function updateParameterStatusByIdApi(parameterId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateParameterStatusByIdUrl, { parameterId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
