import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { UserListActions } from "../../redux-slice/user/UserListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getUserListApi } from "../../api/user/UserListAPI";

// Get User List
function* getUserList(action) {
  try {
    const { orgId, orgType, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getUserListApi(orgId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      UserListActions.getUserListSuccess({
        orgType,
        userList: items,
        userListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserListActions.getUserListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeEvery(UserListActions.getUserList.type, getUserList)]);
}
