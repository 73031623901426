import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { PlantComponentPartsActions } from "../../../redux-slice/plant/PartsSlice";

// Constants
import { ModalKeys } from "../../../constants/ModalConstants";

// Components
import { Button } from "../../../components/button/Button";

// Page Constants
function ComponentTypeListAddedParts({
  componentIndex = 0,
  id = "",
  setSelectedPartId,
  setSelectedComponentIndex,
  setShowModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);

  // Component Part Selector State
  const deletePlantComponentPartLoading = useSelector(
    (state) => state.plantComponentParts.deletePlantComponentPartLoading
  );

  // Getting component info
  const { component = {}, parts: allAddedParts = [] } = plantComponentList[componentIndex] || {};
  const { componentTypeId = "" } = component;

  // Get added parts of the current Part Type
  const addedParts = allAddedParts?.filter(({ component = {} }) => id === component?.componentTypeId);

  // Open View Plant Component Part Modal Function
  function openViewPlantPartModal(partId) {
    setSelectedComponentIndex(componentIndex);
    setSelectedPartId(partId);
    setShowModal(true);
  }

  return (
    <div className="ps-3 mb-3">
      {addedParts.map(({ component = {} }) => {
        const { name = "", internalId = "", id: partId = "", parentId = "" } = component;

        // Loading Status
        const loadingStatus = deletePlantComponentPartLoading[partId] || false;

        return (
          <div className="d-flex align-items-center my-2" key={partId}>
            <p className="mb-1">
              - {name} ({internalId})
            </p>

            <div className="d-flex align-items-center justify-content-center">
              {/* View or Edit part Button */}
              <Button
                label="View"
                size="sm"
                className="ms-2"
                color="success"
                data-bs-toggle="modal"
                data-bs-target={`#${ModalKeys.viewPlantComponentPartModal}`}
                onClick={() => {
                  openViewPlantPartModal(partId);
                }}
              >
                <i className="fas fa-eye"></i>
              </Button>

              {/* Delete part Button */}
              <Button
                label="Delete"
                size="sm"
                className="ms-2"
                color="danger"
                onClick={() => {
                  dispatch(
                    PlantComponentPartsActions.deletePlantComponentPart({
                      plantId,
                      partId,
                      componentId: parentId,
                      componentTypeId,
                    })
                  );
                }}
                loading={loadingStatus}
                disabled={loadingStatus}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

/**
 * Parts List
 * @param {*} parts : Array
 * @param {*} componentIndex
 * @param {*} setSelectedPartId
 * @param {*} setSelectedComponentIndex
 */
export default function PartsList({
  parts = [],
  componentIndex,
  setSelectedPartId,
  setSelectedComponentIndex,
  setShowModal = () => {},
}) {
  // Checking whether parts is there or not
  const isPartsAvailable = parts.length === 0;

  // Modal Id
  const modalId = ModalKeys.addPlantComponentPartModal;

  // Open add part modal function
  function openAddPartModal(id) {
    setSelectedPartId(id);
    setShowModal(true);

    setSelectedComponentIndex(componentIndex);
  }

  // If there are no parts
  if (isPartsAvailable) {
    return <div className="text-center">No parts available</div>;
  }

  return parts.map((eachPart, index) => {
    const { id = "", name = "", code = "" } = eachPart || {};

    return (
      <div key={index}>
        <div className="mb-1 d-flex justify-content-between">
          <span>
            {name} ( {code} )
          </span>

          {/* Add Parts Button */}
          <Button
            label={`Add`}
            size="sm"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
            onClick={() => {
              openAddPartModal(id);
            }}
          >
            <i className="fa-solid fa-plus me-2"></i>
          </Button>
        </div>

        {/* Component Type List Added Parts */}
        <ComponentTypeListAddedParts
          componentIndex={componentIndex}
          id={id}
          setSelectedPartId={setSelectedPartId}
          setSelectedComponentIndex={setSelectedComponentIndex}
          setShowModal={setShowModal}
        />
      </div>
    );
  });
}
