import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { UserListActions } from "../../../../redux-slice/user/UserListSlice";
import { AuditActions } from "../../../../redux-slice/audit/AuditSlice";

// Section
import InchargeInfo from "./InchargeInfo";

/**
 * Audit Plant Info Card
 */
export default function AuditPlantInfoCard({ auditInfo = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // User List Selector State
  const industryUserList = useSelector((state) => state.userList.industryUserList);
  const userListLoading = useSelector((state) => state.userList.userListLoading);

  // Update Plant Incharge Selector State
  const updatePlantInchargeLoading = useSelector((state) => state.audit.updatePlantInchargeLoading);

  const { industryOrgName = "", industryOrgId = "", plantInchargeName = "" } = auditInfo;

  // use Effect
  useEffect(() => {
    if (industryOrgId) {
      dispatch(UserListActions.getUserList({ orgId: industryOrgId, orgType: "INDUSTRY" }));
    }
  }, [dispatch, industryOrgId]);

  return (
    <div className="row">
      {/* Incharge Information */}
      <InchargeInfo
        labelName="Plant Incharge"
        inchargeName={plantInchargeName}
        organizationName={industryOrgName}
        data={industryUserList || []}
        action={AuditActions.updatePlantIncharge}
        loading={updatePlantInchargeLoading || userListLoading}
      />
    </div>
  );
}
