import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { AuditListActions } from "../../../../redux-slice/audit/AuditListSlice";

// Constants
import { QueryParamsKeys } from "../../../../constants/GeneralConstants";

// Page Components
import AuditListTable from "../../../../page-components/audit-list/AuditListTable";

/**
 * Plant Audit List Page
 */
export default function PlantAuditListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Page Params
  const [searchParams] = useUpdateQueryParams();

  // Selector State
  const auditList = useSelector((state) => state.auditList.auditList);
  const auditListLoading = useSelector((state) => state.auditList.auditListLoading);
  const auditListPagination = useSelector((state) => state.auditList.auditListPagination);

  // From url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);

  //use Effect
  useEffect(() => {
    dispatch(AuditListActions.getAuditList({ plantId, pageNumber, pageSize }));
  }, [dispatch, plantId, pageNumber, pageSize]);

  return (
    <div className="page-content">
      {/* Header */}
      <h3 className="mb-4">Audits</h3>

      {/* Audit List Table */}
      <AuditListTable items={auditList} pagination={auditListPagination} loading={auditListLoading} />
    </div>
  );
}
