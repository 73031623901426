import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get job list
  jobList: [],
  jobListPagination: {},
  jobListLoading: false,
  jobListError: "",
};

// Job List Slice
const jobListSlice = createSlice({
  name: "JobList",
  initialState,
  reducers: {
    // Get job list
    getJobList: (state) => {
      state.jobListLoading = true;
    },

    getJobListSuccess: (state, action) => {
      state.jobListLoading = false;
      state.jobList = action.payload.jobList;
      state.jobListPagination = action.payload.jobListPagination;
    },

    getJobListFailure: (state, action) => {
      state.jobListLoading = false;
      state.jobListError = action.payload.error;
    },
  },
});

// Reducer
export const JobListReducer = jobListSlice.reducer;

// Actions
export const JobListActions = jobListSlice.actions;
