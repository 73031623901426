import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Audit instrument Data Info
  auditInstrumentDataInfo: {},
  auditInstrumentDataInfoLoading: false,
  auditInstrumentDataInfoError: "",

  // Select Audit Instrument Data
  selectAuditInstrumentDataLoading: {},
  selectAuditInstrumentDataError: "",

  // Upload Audit Instrument Data File
  uploadAuditInstrumentDataFileLoading: false,
  uploadAuditInstrumentDataFileError: "",
};

const auditInstrumentSlice = createSlice({
  name: "AuditInstrument",
  initialState,
  reducers: {
    // Get Audit instrument Data Info
    getAuditInstrumentDataInfo: (state) => {
      state.auditInstrumentDataInfoLoading = true;
    },

    getAuditInstrumentDataInfoSuccess: (state, action) => {
      state.auditInstrumentDataInfoLoading = false;
      state.auditInstrumentDataInfo = action.payload.auditInstrumentDataInfo;
    },

    getAuditInstrumentDataInfoFailure: (state, action) => {
      state.auditInstrumentDataInfoLoading = false;
      state.auditInstrumentDataInfoError = action.payload.error;
    },

    // Select Audit Instrument Data
    selectAuditInstrumentData: (state, action) => {
      const { lineNo } = action.payload;

      state.selectAuditInstrumentDataLoading = {
        ...state.selectAuditInstrumentDataLoading,
        [lineNo]: true,
      };
    },

    selectAuditInstrumentDataSuccess: (state, action) => {
      const { lineNo } = action.payload;

      //To get previous state
      const clonedSelectAuditInstrumentDataLoading = { ...state.selectAuditInstrumentDataLoading };

      //To remove the id from state after successfull loading
      delete clonedSelectAuditInstrumentDataLoading[lineNo];

      state.selectAuditInstrumentDataLoading = clonedSelectAuditInstrumentDataLoading;
    },

    selectAuditInstrumentDataFailure: (state, action) => {
      const { lineNo, error } = action.payload;

      //To get previous state
      const clonedSelectAuditInstrumentDataLoading = { ...state.selectAuditInstrumentDataLoading };

      //To remove the id from state after successfull loading
      delete clonedSelectAuditInstrumentDataLoading[lineNo];

      state.selectAuditInstrumentDataLoading = clonedSelectAuditInstrumentDataLoading;
      state.selectAuditInstrumentDataError = error;
    },

    // Upload Audit Instrument Data File
    uploadAuditInstrumentDataFile: (state) => {
      state.uploadAuditInstrumentDataFileLoading = true;
    },

    uploadAuditInstrumentDataFileSuccess: (state) => {
      state.uploadAuditInstrumentDataFileLoading = false;
    },

    uploadAuditInstrumentDataFileFailure: (state, action) => {
      state.uploadAuditInstrumentDataFileLoading = false;
      state.uploadAuditInstrumentDataFileError = action.payload.error;
    },
  },
});

// Reducer
export const AuditInstrumentReducer = auditInstrumentSlice.reducer;

// Actions
export const AuditInstrumentActions = auditInstrumentSlice.actions;
