import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// Components
import { Button } from "../../components/button/Button";
import Label from "../../components/label/Label";
import SelectInput from "../../components/select-input/SelectInput";
import Textarea from "../../components/textarea/Textarea";
import Modal from "../../components/modal/Modal";
import Input from "../../components/input/Input";

// Page Constants
const DEFAULT_INPUT_VALUES = {
  type: "",
  notes: "",
  file: "",
};

const INPUT_KEYS = {
  type: "type",
  notes: "notes",
  file: "file",
};

/**
 * File Upload Modal
 */
export default function FileUploadModal({
  selectedFile = {},
  dispatchActions = {},
  reducerKey = "",
  optionsArray = [],
  showModal = false,
  setShowModal = () => {},
}) {
  // Selector State
  const createFileLoading = useSelector((state) => state[reducerKey].createFileLoading);
  const updateFileLoading = useSelector((state) => state[reducerKey].updateFileLoading);

  // State
  const [values, setValues] = useState(DEFAULT_INPUT_VALUES);
  const [errors, setErrors] = useState(DEFAULT_INPUT_VALUES);

  const fileInputRef = useRef(null);

  // Values
  const { type = "", notes = "", file = "" } = values;

  // Selected File Information
  const { id: selectedFileId = "", notes: selectedFileNotes = "", type: selectedFileType = "" } = selectedFile;

  // Loading Status
  const loadingStatus = createFileLoading || updateFileLoading || false;

  // Errors
  const { type: typeError = "", file: fileError = "" } = errors || {};

  // Modal id
  const modalId = ModalKeys.fileUploadModal;

  const { uploadFileFn = () => {} } = dispatchActions;

  // use Effect
  useEffect(() => {
    // Updating file information
    setValues({ type: selectedFileType, notes: selectedFileNotes });

    // Clearing Errors
    setErrors(DEFAULT_INPUT_VALUES);
  }, [selectedFileId, selectedFileNotes, selectedFileType]);

  // Handle Change Function
  function HandleChange({ target }, key) {
    // For File Selection
    if (key === INPUT_KEYS.file) {
      setValues((prev) => ({ ...prev, file: target.files[0] }));
      return;
    }

    setValues((prev) => ({ ...prev, [key]: target.value }));
  }

  // Submit Action
  function submitAction() {
    // Uploaded File Information
    const { name = "", type: fileType = "" } = file || {};

    // If type is not selected
    if (!type) {
      setErrors((prev) => ({ ...prev, type: "Please select a type !!" }));
    } else {
      setErrors(DEFAULT_INPUT_VALUES);
    }

    // If it's update file modal, file is not required
    // if it's create file modal, file is required
    if (!selectedFileId && (!name || !fileType)) {
      setErrors((prev) => ({ ...prev, file: "Please select a file !!" }));
      return;
    }

    // Clear Errors
    setErrors(DEFAULT_INPUT_VALUES);

    // Form Data
    const formData = new FormData();
    formData.append(INPUT_KEYS.type, type);
    formData.append(INPUT_KEYS.notes, notes);

    // For updating, file is not required
    // Only type & notes is required
    if (!selectedFileId) {
      formData.append(INPUT_KEYS.file, file);
    }

    // Upload File Dispatch Function
    uploadFileFn(formData, selectedFileId);
  }

  // Reset Form Modal Function
  function resetFormModal() {
    // return if show modal state is true
    if (showModal) {
      return;
    }
    // reseting error to default
    setErrors(DEFAULT_INPUT_VALUES);

    // if previous data is present, reseting the form with previous data
    if (selectedFile.id) {
      setValues({ type: selectedFileType, notes: selectedFileNotes });
      return;
    }

    // reseting the form with default values
    setValues(DEFAULT_INPUT_VALUES);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }

  useEffect(() => {
    // Function call
    resetFormModal();
  }, [showModal]);

  return (
    <Modal id={modalId} title="Upload File" size="lg" setShowModal={setShowModal}>
      <div className="page-content">
        {/* Select File Type Input */}
        <Label title="Type" isMandatory={true} />
        <SelectInput
          value={type}
          options={optionsArray}
          placeholder="Select file type"
          onChange={(e) => HandleChange(e, INPUT_KEYS.type)}
          error={typeError}
        />

        {/* Notes Textarea */}
        <Label title="Notes" className="mt-3" />
        <Textarea value={notes} onChange={(e) => HandleChange(e, INPUT_KEYS.notes)} />

        {/* File Select Input */}
        {!selectedFileId && (
          <>
            <Label title="Files" isMandatory={true} className="mt-3" />
            <Input
              inputRef={fileInputRef}
              type="file"
              onChange={(e) => HandleChange(e, INPUT_KEYS.file)}
              error={fileError}
            />
          </>
        )}

        {/* Submit Button */}
        <Button
          label="Submit"
          color="success"
          className="mt-3 float-end mb-3"
          onClick={submitAction}
          loading={loadingStatus}
          disabled={loadingStatus}
        />
      </div>
    </Modal>
  );
}
