// Page Components
const NUMBER_DATA_TYPES = ["INTEGER", "DOUBLE"];

// Format Text Util Function
export function formatText(value) {
  // Converting to string
  const string = `${value ?? ""}`;

  return string.length !== 0 ? string : "--";
}

// Convert String To Boolean Or Number
export function convertStringToBooleanOrNumber(dataValueType = "", value = "") {
  // If value is empty
  if (value.length === 0) {
    return null;
  }

  // If boolean, True/False string is converted to boolean True/False, Example : "true" => true
  // Otherwise if its a number then string is converted to number, Example : "5" => 5
  // Otherwise string is returned.
  const correctedValue =
    dataValueType === "BOOLEAN"
      ? value === "true"
      : NUMBER_DATA_TYPES.includes(dataValueType)
      ? parseFloat(value)
      : value;

  return correctedValue;
}
