// Urls
import {
  getInstrumentInfoByIdUrl,
  getDataColumnInfoByIdUrl,
  updateInstrumentStatusByIdUrl,
} from "../../urls/api-urls/InstrumentURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Get Instrument Info By Id
export async function getInstrumentInfoByIdApi(instrumentId) {
  const url = URLs.format(getInstrumentInfoByIdUrl, { instrumentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Data Column Info By Id
export async function getDataColumnInfoByIdApi(instrumentId) {
  const params = {
    instrument: instrumentId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getDataColumnInfoByIdUrl, params);
}

// Update Instrument Status By Id
export async function updateInstrumentStatusByIdApi(instrumentId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateInstrumentStatusByIdUrl, { instrumentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
