import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { RoleListActions } from "../../redux-slice/role/RoleListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getRoleListApi, getPrivilegesListApi } from "../../api/role/RoleListAPI";

// Get Role List
function* getRoleList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getRoleListApi(pageNumber, pageSize, searchText);

    // Dispatching action
    yield put(RoleListActions.getRoleListSuccess({ roleList: items, roleListPagination: pagination }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleListActions.getRoleListFailure({ error }));
  }
}

// Get Privileges List
function* getPrivilegesList() {
  try {
    const privilegesList = yield getPrivilegesListApi();

    // Dispatching action
    yield put(RoleListActions.getPrivilegesListSuccess({ privilegesList }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleListActions.getPrivilegesListFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(RoleListActions.getRoleList.type, getRoleList),
    takeLatest(RoleListActions.getPrivilegesList.type, getPrivilegesList),
  ]);
}
