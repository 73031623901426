import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Upsert Parameter Values
  upsertParameterValuesLoading: false,
  upsertParameterValuesError: "",
};

// Audit Parameter Values Slice
const auditParameterValuesSlice = createSlice({
  name: "AuditParameterValues",
  initialState,
  reducers: {
    // Upsert Parameter Values
    upsertParameterValues: (state) => {
      state.upsertParameterValuesLoading = true;
    },

    upsertParameterValuesSuccess: (state) => {
      state.upsertParameterValuesLoading = false;
    },

    upsertParameterValuesFailure: (state, action) => {
      state.upsertParameterValuesLoading = false;
      state.upsertParameterValuesError = action.payload.error;
    },
  },
});

// Reducer
export const AuditParameterValuesReducer = auditParameterValuesSlice.reducer;

// Actions
export const AuditParameterValuesActions = auditParameterValuesSlice.actions;
