import { useSelector } from "react-redux";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";

//Components
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Parent Code",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
];

//Page Components
function HSNCodeListTableRow({ serialNo, hsnCode }) {
  const { code, description, parentCode } = hsnCode;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{parentCode}</td>
      <td>{code}</td>
      <td>{description}</td>
    </tr>
  );
}

function HSNCodeListTableBody({ hsnCodeListLoading = false, hsnCodeList = [], pageSize, pageNumber }) {
  //Loader
  if (hsnCodeListLoading) {
    return <TableLoader colSpan={5} />;
  }

  //if there is no hsn list
  if (hsnCodeList.length === 0) {
    return <TableDataNotFound message="No hsn codes present" colSpan={5} />;
  }

  //HSN list table row
  return (
    <>
      {hsnCodeList.map((hsnCode, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return <HSNCodeListTableRow key={hsnCode.id} serialNo={serialNo} hsnCode={hsnCode} />;
      })}
    </>
  );
}

/**
 * HSN Code List Table
 */
export default function HSNCodeListTable() {
  // Selector States
  const hsnCodeList = useSelector((state) => state.hsnCodeList.hsnCodeList);
  const hsnCodeListLoading = useSelector((state) => state.hsnCodeList.hsnCodeListLoading);
  const hsnCodeListPagination = useSelector((state) => state.hsnCodeList.hsnCodeListPagination);

  const { totalCount, pageNo, pageSize } = hsnCodeListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* HSN Code List Table Body */}
          <HSNCodeListTableBody
            hsnCodeListLoading={hsnCodeListLoading}
            hsnCodeList={hsnCodeList}
            pageSize={pageSize}
            pageNumber={pageNo}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
