import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";

// Actions
import { PlantComponentActions } from "../../../redux-slice/plant/ComponentSlice";

// Constants
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import { formatText } from "../../../utils/StringUtils";
import { getComponentParts, getComponentTypeParameters } from "../../../utils/DataPreLoadUtils";

// Components
import { Button } from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";

// Section
import AddPlantComponentImage from "./AddPlantComponentImage";
import PartsList from "./PartsList";
import ViewPlantComponentPartModal from "./PlantComponentPartModal";
import ViewComponentModal from "./ViewComponentModal";

// Page Components
function ComponentTypeListCardPropertyBody({ properties = [], propertyList = [] }) {
  return properties.map((eachProperty, index) => {
    const { parameterId = "", value = "" } = eachProperty || {};

    const { name = "", unitSymbol = "" } =
      propertyList.find(({ id = "" }) => parseInt(id) === parseInt(parameterId)) || {};

    // Displaying only the first five properties
    if (index > 5) {
      return null;
    }

    return (
      <div key={index} className="row mb-2">
        <div className="col-8 bold">
          {name} <small className="text-secondary fst-italic">{unitSymbol && `(${unitSymbol})`}</small>
        </div>

        <div className={"col-4"}>{formatText(value)}</div>
      </div>
    );
  });
}

function ComponentTypeListPropertyCardBody({
  properties = [],
  propertyList = [],
  setSelectedComponentIndex,
  componentIndex,
  setShowModal = () => {},
}) {
  // Open Component Type List View More Function
  function openViewPlantComponentModal() {
    setSelectedComponentIndex(componentIndex);
    setShowModal(true);
  }

  return (
    <div className="col-4">
      <div className="card-body">
        <h5 className="card-title">Properties</h5>

        {/* ComponentType List Card Property Body */}
        <ComponentTypeListCardPropertyBody properties={properties} propertyList={propertyList} />

        <div
          className="cursor-pointer text-primary"
          data-bs-toggle="modal"
          data-bs-target={`#${ModalKeys.viewPlantComponentModal}`}
          onClick={openViewPlantComponentModal}
        >
          + view more
        </div>
      </div>
    </div>
  );
}

function ComponentTypeListHorizontalCardImage({
  component = {},
  setSelectedComponentIndex,
  index,
  setShowModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const downloadPlantComponentImageLoading = useSelector(
    (state) => state.plantComponent.downloadPlantComponentImageLoading
  );

  // Component Information
  const { imageURL = "", id = "", name = "" } = component;

  // Loading Status
  const loading = downloadPlantComponentImageLoading[id] || false;

  // Download Component Image
  function downloadComponentImage() {
    dispatch(PlantComponentActions.downloadPlantComponentImage({ componentId: id, name, apiUrl: imageURL }));
  }

  // Open Component Type List Image Upload Modal Function
  function openAddPlantComponentImageModal() {
    setSelectedComponentIndex(index);
    setShowModal(true);
  }

  // If there is no image uploaded
  if (!imageURL) {
    return (
      <div className="text-center pt-2">
        {/* Add Image Button */}
        <Button
          label="Add Image"
          color="link"
          data-bs-toggle="modal"
          data-bs-target={`#${ModalKeys.addPlantComponentImageModal}`}
          onClick={openAddPlantComponentImageModal}
        >
          <i className="fas fa-plus" />
        </Button>
      </div>
    );
  }

  return (
    <>
      <img src={imageURL} className="img-fluid rounded-start p-2 component-image" alt="" />

      <div className="d-flex justify-content-between p-2">
        {/* Download Image Button */}
        <Button
          label="Download"
          color="success"
          size="sm"
          onClick={downloadComponentImage}
          loading={loading}
          disabled={loading}
        >
          <i className="fas fa-download" />
        </Button>

        {/* Update Image Button */}
        <Button
          label="Update"
          color="warning"
          size="sm"
          data-bs-toggle="modal"
          data-bs-target={`#${ModalKeys.addPlantComponentImageModal}`}
          onClick={() => {
            setSelectedComponentIndex(index);
          }}
        >
          <i className="fas fa-pen" />
        </Button>
      </div>
    </>
  );
}

function ComponentTypeListHorizontalCard({
  componentTypeId = "",
  parts = [],
  setSelectedComponentIndex,
  setSelectedPartId,
  setShowModal = () => {},
}) {
  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);

  // Checking whether data is there or not
  const isDataAvailable = plantComponentList.length !== 0 && componentTypeId;

  // Getting property list for the component type
  const propertyList = getComponentTypeParameters(componentTypeId) || [];

  // If there is no data
  if (!isDataAvailable) {
    return <div className="text-center p-3">No Components available</div>;
  }

  return plantComponentList.map((eachComponent, index) => {
    // Getting component information
    const { component = {}, properties = [] } = eachComponent || {};

    const { name = "", internalId = "", remarks = "" } = component;

    return (
      <div key={index} className="card mb-3">
        <div className="row g-0">
          <div className="col-2">
            {/* Component Type List Horizontal Card Image */}
            <ComponentTypeListHorizontalCardImage
              component={component}
              setSelectedComponentIndex={setSelectedComponentIndex}
              index={index}
              setShowModal={setShowModal}
            />
          </div>

          <div className="col-3">
            <div className="card-body">
              <h5 className="card-title">
                {name} ({internalId})
              </h5>
              <p className="card-text">{remarks}</p>
            </div>
          </div>

          {/* ComponentType List Property Card Body */}
          <ComponentTypeListPropertyCardBody
            propertyList={propertyList}
            properties={properties}
            componentIndex={index}
            setSelectedComponentIndex={setSelectedComponentIndex}
            setShowModal={setShowModal}
          />

          <div className="col-3">
            <div className="card-body">
              <h5 className="card-title">Parts</h5>

              {/* Parts List */}
              <PartsList
                parts={parts}
                setSelectedPartId={setSelectedPartId}
                componentIndex={index}
                setSelectedComponentIndex={setSelectedComponentIndex}
                setShowModal={setShowModal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
}

/**
 * Component Type List Card
 */
export default function ComponentTypeListCardView({
  componentTypeId = "",
  tableHeaders = [],
  formInputItems = [],
  selectedComponentIndex,
  setSelectedComponentIndex,
}) {
  // Params
  const { plantId = "" } = useParams();

  // State
  const [selectedPartId, setSelectedPartId] = useState("");
  const [showModal, setShowModal] = useState(false);

  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);
  const plantComponentListLoading = useSelector((state) => state.plantComponentList.plantComponentListLoading);

  // Update Plant Component Selector State
  const updatePlantComponentLoading = useSelector((state) => state.plantComponent.updatePlantComponentLoading);

  // Modal Id
  const modalId = ModalKeys.viewPlantComponentModal;

  // Getting parts for that component
  const parts = getComponentParts(componentTypeId) || [];

  // Getting component info
  const { id: componentId = "" } = plantComponentList[selectedComponentIndex]?.component || {};

  // Loader
  if (plantComponentListLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          {/* Component Type List Horizontal Card */}
          <ComponentTypeListHorizontalCard
            componentTypeId={componentTypeId}
            parts={parts}
            selectedComponentIndex={selectedComponentIndex}
            setSelectedComponentIndex={setSelectedComponentIndex}
            setSelectedPartId={setSelectedPartId}
            setShowModal={setShowModal}
          />
        </div>
      </div>

      {/* View Plant Component Modal */}
      <ViewComponentModal
        modalId={modalId}
        componentInfo={plantComponentList[selectedComponentIndex]}
        tableHeaders={tableHeaders}
        loading={updatePlantComponentLoading}
        dispatchAtn={PlantComponentActions.updatePlantComponent}
        formInputItems={formInputItems}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      {/* View Plant Component Part Modal */}
      <ViewPlantComponentPartModal
        selectedComponentIndex={selectedComponentIndex}
        tableHeaders={tableHeaders}
        selectedPartId={selectedPartId}
        componentTypeId={componentTypeId}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      {/* Add Component Image Modal */}
      <Modal size="lg" id={ModalKeys.addPlantComponentImageModal} title={"Add Image"} setShowModal={setShowModal}>
        <AddPlantComponentImage
          plantId={plantId}
          componentId={componentId}
          componentTypeId={componentTypeId}
          showModal={showModal}
        />
      </Modal>
    </>
  );
}
