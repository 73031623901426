// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getInstrumentListUrl } from "../../urls/api-urls/InstrumentURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Instrument List
export async function getInstrumentListApi(componentId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    component: componentId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getInstrumentListUrl, params);
}
