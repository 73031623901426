import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { PlantElectricityBillListActions } from "../../../../redux-slice/plant/ElectricityBillListSlice";
import { PlantElectricityBillActions } from "../../../../redux-slice/plant/ElectricityBillSlice";

// Constants
import { QueryParamsKeys } from "../../../../constants/GeneralConstants";
import { ModalKeys } from "../../../../constants/ModalConstants";

// Components
import { Button } from "../../../../components/button/Button";
import Form, { FormTypes } from "../../../../components/form/Form";
import Modal from "../../../../components/modal/Modal";

// Section
import PlantElectricityBillListTable from "./PlantElectricityBillListTable";

// Form Schema
import {
  ElectricityBillFormSchema,
  ElectricityBillFormValidationSchema,
} from "../../../../form-schema/ElectricityBillFormSchema";

// Page Component
function PlantElectricityBillsPageHeader({ modalId, setShowModal = () => {} }) {
  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        <h4>Monthly Electricity Bills</h4>
      </div>

      {/* Button */}
      <Button
        label="Add Electricity Bill"
        color="dark"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        onClick={() => setShowModal(true)}
      >
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Plant Electricity Bills Tab Conent
 */
export default function PlantElectricityBillsTabContent() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Search Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // State
  const [selectedElectricityBill, setSelectedElectricityBill] = useState({});
  const [showModal, setShowModal] = useState(false);

  // Create Electricity Bill Selector State
  const createPlantElectricityBillLoading = useSelector(
    (state) => state.plantElecBill.createPlantElectricityBillLoading
  );

  // update Electricity Bill Selector State
  const updatePlantElectricityBillLoading = useSelector(
    (state) => state.plantElecBill.updatePlantElectricityBillLoading
  );

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // use Effect
  useEffect(() => {
    dispatch(
      PlantElectricityBillListActions.getPlantElectricityBillList({ plantId, pageNumber, pageSize, searchText })
    );
  }, [dispatch, plantId, pageNumber, pageSize, searchText]);

  // Function for adding electricity bills
  function createElectricityBill(electricityBillData) {
    const formData = { ...electricityBillData, plantId };

    // Dispatch
    dispatch(PlantElectricityBillActions.createPlantElectricityBill({ formData, plantId }));
  }

  // Function for editing electricity bills
  function editElectricityBill(electricityBillData) {
    const formData = { ...electricityBillData, plantId };

    // Dispatch
    dispatch(
      PlantElectricityBillActions.updatePlantElectricityBill({
        formData,
        plantId,
        electricityBillId: selectedElectricityBill.id,
      })
    );
  }

  return (
    <div className="page-content">
      {/* Plant Electricity Bills Page Header */}
      <PlantElectricityBillsPageHeader modalId={ModalKeys.addPlantElectricityBillModal} setShowModal={setShowModal} />

      <div className="mt-4 table-responsive">
        {/* Plant Electricity Bill List Table */}
        <PlantElectricityBillListTable
          setSelectedElectricityBill={setSelectedElectricityBill}
          setShowModal={setShowModal}
        />
      </div>

      {/* Create Electricity Bill Modal */}
      {/* {showModal && ( */}
      <Modal
        id={ModalKeys.addPlantElectricityBillModal}
        title="View Electricity Bill"
        size="xl"
        setShowModal={setShowModal}
      >
        {/* Create electricity bill form */}
        <Form
          type={FormTypes.CREATE}
          formInputItems={ElectricityBillFormSchema}
          formValidationSchema={ElectricityBillFormValidationSchema}
          formSubmit={createElectricityBill}
          loading={createPlantElectricityBillLoading}
          showCancelButton={false}
          showModal={showModal}
        />
      </Modal>
      {/* )} */}

      {/* View Electricity Bill Modal */}
      <Modal
        id={ModalKeys.viewPlantElectricityBillModal}
        title="View Electricity Bill"
        size="xl"
        setShowModal={setShowModal}
      >
        {/* Edit Electricity bill form */}
        <Form
          data={selectedElectricityBill}
          formInputItems={ElectricityBillFormSchema}
          formValidationSchema={ElectricityBillFormValidationSchema}
          formSubmit={editElectricityBill}
          loading={updatePlantElectricityBillLoading}
          showModal={showModal}
        />
      </Modal>
    </div>
  );
}
