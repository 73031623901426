import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// Utils
import FormUtils from "./FormUtils";

// Components
import { Button } from "../button/Button";

// Sections
import FormInput from "./FormInput";

//
// ----------------------------------------------------------------------------

function FormSection({ formInputItems, formik, isReadMode }) {
  return (
    <>
      {formInputItems?.map((input, index) => {
        // Input
        const { label, inputContainerClassName = "col-4", isMandotary = false } = input || {};

        return (
          <div className={`${inputContainerClassName} mb-3`} key={index}>
            {label && (
              <label className="mb-2">
                {label} {!isReadMode && isMandotary && <span className="text-danger">*</span>}
              </label>
            )}

            {/* Form Input */}
            <FormInput input={input} formik={formik} isReadMode={isReadMode} />
          </div>
        );
      })}
    </>
  );
}

/**
 * Form
 * @param {*} loading : Boolean
 * @param {*} showCancelButton : To show or Hide cancel button
 * @param {*} isReadMode : Boolean
 * @param {*} data : User Data to be displayed in form
 * @param {*} formInputItems : Input items for form
 * @param {*} formSubmit : onSubmit function for formik
 * @param {*} formValidationSchema : Validation Schema for form
 */
export default function Form({
  type = FormTypes.EDIT,
  containerClassName = "",
  showCancelButton = true,
  data = {},
  formInputItems = [],
  formValidationSchema = {},
  formSubmit = () => {},
  onChange = () => {},
  loading = false,
  showModal = false,
}) {
  // state
  const [isReadMode, setReadMode] = useState(type === FormTypes.CREATE ? false : true);

  function wrappedFormSubmit(data) {
    formSubmit(data);
  }

  // Formik
  const formik = useFormik({
    initialValues: FormUtils.constructFormikValues({ formInputItems, data }),
    validationSchema: formValidationSchema,
    enableReinitialize: true, // To change initial values
    onSubmit: wrappedFormSubmit,
  });

  function cancelEdit() {
    // After editing if the user clicks cancel, form data is reset to original data.
    formik.setValues(data);
    setReadMode(true);
  }

  const { handleSubmit } = formik;

  useEffect(() => {
    if (!showModal) {
      formik.resetForm();
    }
  }, [showModal]);

  return (
    <>
      {/* Form Edit Button */}
      {/* Will be rendered only in Read Mode.. */}
      {isReadMode && (
        <div className="d-flex justify-content-end">
          <Button label="Edit" color="warning" size="sm" onClick={() => setReadMode(false)}>
            <i className="fa-solid fa-pencil me-2"></i>
          </Button>
        </div>
      )}

      <form
        className={`${isReadMode && "form-readonly"} ${containerClassName}`}
        onSubmit={handleSubmit}
        onChange={onChange}
      >
        {formInputItems?.map((formGroup, index) => {
          const { title = "", titleClassName, containerClassName = "row", inputItems = [] } = formGroup || {};

          return (
            <div key={index} className="mt-3">
              {title && <label className={`${titleClassName} form-label mt-4 mb-2`}>{title}</label>}

              <div className={containerClassName}>
                {/* Form Section */}
                <FormSection formik={formik} isReadMode={isReadMode} formInputItems={inputItems} />
              </div>
            </div>
          );
        })}

        {/* Form Submit And Cancel Button */}
        {/* Will be rendered only in the Edit mode.. */}
        {!isReadMode && (
          <div className="mt-4">
            {/* Button */}
            <Button
              label="Cancel"
              color="dark"
              className={`me-2 ${!showCancelButton && "d-none"}`}
              onClick={cancelEdit}
            />

            {/* Button */}
            <Button type="submit" label="Submit" color="success" loading={loading} disabled={loading} />
          </div>
        )}
      </form>
    </>
  );
}

// Form Type constants
export const FormTypes = {
  EDIT: "Edit",
  CREATE: "Create",
};

// Form validation
export const DefaultFormValidationSchema = yup.object({
  name: yup.string().required("Please fill the name"),
  internalId: yup.string().required("Please fill the internal id"),
});
