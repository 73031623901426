import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Actions
import { RoleListActions } from "../../../redux-slice/role/RoleListSlice";
import { RoleActions } from "../../../redux-slice/role/RoleSlice";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Loader from "../../../components/loader/Loader";

// Section
import RoleForm from "../RoleForm";

/**
 * Role Create Page
 */
export default function RoleCreatePage() {
  // Dispatch
  const dispatch = useDispatch();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Privileges List Selector State
  const privilegesListLoading = useSelector((state) => state.roleList.privilegesListLoading);

  // Create Role Selector State
  const createRoleLoading = useSelector((state) => state.role.createRoleLoading);

  // use Effect
  useEffect(() => {
    dispatch(RoleListActions.getPrivilegesList());
  }, [dispatch]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="Create Role" />);
  }, []);

  // Loader
  if (privilegesListLoading) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      {/* Role Form */}
      <RoleForm loading={createRoleLoading} dispatchAction={RoleActions.createRole} buttonName="Create Role" />
    </div>
  );
}
