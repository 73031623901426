import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Update Product Status (Enable/Disable)
  updateProductStatusLoadingMap: {},
  updateProductStatusError: "",
};

// Product Slice
const productSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    // Update Product Status (Enable/Disable)
    updateProductStatus: (state, action) => {
      const { productId } = action.payload;

      state.updateProductStatusLoadingMap = { ...state.updateProductStatusLoadingMap, [productId]: true };
    },

    updateProductStatusSuccess: (state, action) => {
      const { productId } = action.payload;

      //To get previous state
      const clonedUpdateProductStatusLoadingMap = { ...state.updateProductStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateProductStatusLoadingMap[productId];

      state.updateProductStatusLoadingMap = clonedUpdateProductStatusLoadingMap;
    },

    updateProductStatusFailure: (state, action) => {
      const { productId, error } = action.payload;

      //To get previous state
      const clonedUpdateProductStatusLoadingMap = { ...state.updateProductStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateProductStatusLoadingMap[productId];

      state.updateProductStatusLoadingMap = clonedUpdateProductStatusLoadingMap;
      state.updateProductStatusError = error;
    },
  },
});

// Reducer
export const ProductReducer = productSlice.reducer;

// Actions
export const ProductActions = productSlice.actions;
