// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getAuditInstrumentDataListByIdUrl } from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Audit Instrument Data List By Id Api
export async function getAuditInstrumentDataListApi(auditId, componentId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    audit: auditId,
    component: componentId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getAuditInstrumentDataListByIdUrl, params);
}
