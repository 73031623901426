// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getPlantFileListUrl } from "../../urls/api-urls/PlantURL";

// Utils
import URLs from "../../app/utils/URLs";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Plant File List
export async function getPlantFileListApi(plantId, pageNumber, pageSize, type) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (type) {
    params.type = type;
  }

  // Api Url
  const apiUrl = URLs.format(getPlantFileListUrl, { plantId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiUrl, params);
}
