import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditDataActions } from "../../redux-slice/audit/DataSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// APIs
import { downloadPerfAnalysisDataApi, downloadRecommendationDataApi } from "../../api/audit/DataAPI";

// Download Performance Analysis Data
function* downloadPerfAnalysisData(action) {
  try {
    const { auditId } = action.payload;

    const data = yield downloadPerfAnalysisDataApi(auditId);

    // Downloading File
    saveFile(`Audit_${auditId}_Performance_Analysis_Data.xlsx`, data);

    // Dispatching Action
    yield put(AuditDataActions.downloadPerfAnalysisDataSuccess());
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditDataActions.downloadPerfAnalysisDataFailure({ error }));
  }
}

// Download Recommendation Data
function* downloadRecommendationData(action) {
  try {
    const { auditId } = action.payload;

    const data = yield downloadRecommendationDataApi(auditId);

    // Downloading File
    saveFile(`Audit_${auditId}_Recommendation_Data.xlsx`, data);

    // Dispatching Action
    yield put(AuditDataActions.downloadRecommendationDataSuccess());
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditDataActions.downloadRecommendationDataFailure({ error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(AuditDataActions.downloadPerfAnalysisData.type, downloadPerfAnalysisData),
    takeLatest(AuditDataActions.downloadRecommendationData.type, downloadRecommendationData),
  ]);
}
