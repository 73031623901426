import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Add plant component
  addPlantComponentLoading: false,
  addPlantComponentError: "",

  // Upload plant component
  updatePlantComponentLoading: false,
  updatePlantComponentError: "",

  // Upload plant component image
  uploadPlantComponentImageLoading: false,
  uploadPlantComponentImageError: "",

  // Download plant component image
  downloadPlantComponentImageLoading: {},
  downloadPlantComponentImageError: "",
};

// Plant Component Slice
const plantComponentSlice = createSlice({
  name: "PlantComponent",
  initialState,
  reducers: {
    // Add plant component
    addPlantComponent: (state) => {
      state.addPlantComponentLoading = true;
    },

    addPlantComponentSuccess: (state) => {
      state.addPlantComponentLoading = false;
    },

    addPlantComponentFailure: (state, action) => {
      state.addPlantComponentLoading = false;
      state.addPlantComponentError = action.payload.error;
    },

    // Update plant component
    updatePlantComponent: (state) => {
      state.updatePlantComponentLoading = true;
    },

    updatePlantComponentSuccess: (state) => {
      state.updatePlantComponentLoading = false;
    },

    updatePlantComponentFailure: (state, action) => {
      state.updatePlantComponentLoading = false;
      state.updatePlantComponentError = action.payload.error;
    },

    // Upload plant component image
    uploadPlantComponentImage: (state) => {
      state.uploadPlantComponentImageLoading = true;
    },

    uploadPlantComponentImageSuccess: (state) => {
      state.uploadPlantComponentImageLoading = false;
    },

    uploadPlantComponentImageFailure: (state, action) => {
      state.uploadPlantComponentImageLoading = false;
      state.uploadPlantComponentImageError = action.payload.error;
    },

    // Download plant component image
    downloadPlantComponentImage: (state, action) => {
      const { componentId = "" } = action.payload;

      state.downloadPlantComponentImageLoading = { ...state.downloadPlantComponentImageLoading, [componentId]: true };
    },

    downloadPlantComponentImageSuccess: (state, action) => {
      const { componentId = "" } = action.payload;

      // To get previous state
      const clonedDownloadPlantComponentImageLoading = { ...state.downloadPlantComponentImageLoading };

      // To remove the id from state after successfull loading
      delete clonedDownloadPlantComponentImageLoading[componentId];

      state.downloadPlantComponentImageLoading = clonedDownloadPlantComponentImageLoading;
    },

    downloadPlantComponentImageFailure: (state, action) => {
      const { componentId = "" } = action.payload;

      // To get previous state
      const clonedDownloadPlantComponentImageLoading = { ...state.downloadPlantComponentImageLoading };

      // To remove the id from state after successfull loading
      delete clonedDownloadPlantComponentImageLoading[componentId];

      state.downloadPlantComponentImageLoading = clonedDownloadPlantComponentImageLoading;
      state.downloadPlantComponentImageError = action.payload.error;
    },
  },
});

// Reducer
export const PlantComponentReducer = plantComponentSlice.reducer;

// Actions
export const PlantComponentActions = plantComponentSlice.actions;
