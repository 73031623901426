import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Create Plant File
  createFileLoading: false,
  createFileError: {},

  // Update Plant File By Id
  updateFileLoading: false,
  updateFileError: {},

  // Download Plant File By Id
  downloadFileLoading: {},
  downloadFileError: {},

  // Delete Plant File By Id
  deleteFileLoading: false,
  deleteFileError: {},
};

// Plant File Slice
const plantFileSlice = createSlice({
  name: "PlantFile",
  initialState,
  reducers: {
    // Create Plant File
    createPlantFile: (state) => {
      state.createFileLoading = true;
    },

    createPlantFileSuccess: (state) => {
      state.createFileLoading = false;
    },

    createPlantFileFailure: (state, action) => {
      state.createFileLoading = false;
      state.createFileError = action.payload.error;
    },

    // Update Plant File By Id
    updatePlantFileById: (state) => {
      state.updateFileLoading = true;
    },

    updatePlantFileByIdSuccess: (state) => {
      state.updateFileLoading = false;
    },

    updatePlantFileByIdFailure: (state, action) => {
      state.updateFileLoading = false;
      state.updateFileError = action.payload.error;
    },

    // Download Plant File By Id
    downloadPlantFileById: (state, action) => {
      const { fileId = "" } = action.payload;

      state.downloadFileLoading = {
        ...state.downloadFileLoading,
        [fileId]: true,
      };
    },

    downloadPlantFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;

      //To get previous state
      const clonedDownloadFileLoading = { ...state.downloadFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDownloadFileLoading[fileId];

      state.downloadFileLoading = clonedDownloadFileLoading;
    },

    downloadPlantFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;

      //To get previous state
      const clonedDownloadFileLoading = { ...state.downloadFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDownloadFileLoading[fileId];

      state.downloadFileLoading = clonedDownloadFileLoading;
      state.downloadFileError = error;
    },

    // Delete Plant File By Id
    deletePlantFileById: (state, action) => {
      const { fileId = "" } = action.payload;

      state.deleteFileLoading = {
        ...state.deleteFileLoading,
        [fileId]: true,
      };
    },

    deletePlantFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;

      //To get previous state
      const clonedDeleteFileLoading = { ...state.deleteFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDeleteFileLoading[fileId];

      state.deleteFileLoading = clonedDeleteFileLoading;
    },

    deletePlantFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;

      //To get previous state
      const clonedDeleteFileLoading = { ...state.deleteFileLoading };

      //To remove the id from state after successfull loading
      delete clonedDeleteFileLoading[fileId];

      state.deleteFileLoading = clonedDeleteFileLoading;
      state.deleteFileError = error;
    },
  },
});

// Reducer
export const PlantFileReducer = plantFileSlice.reducer;

// Actions
export const PlantFileActions = plantFileSlice.actions;
