import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Update Audit Observation And Recommendation
  updateObsAndRecLoading: {},
  updateObsAndRecError: "",

  // Update Recommendation Investment
  updateRecInvestmentLoading: {},
  updateRecInvestmentError: "",
};

// Audit Observation And Recommendation Slice
const auditObsAndRecSlice = createSlice({
  name: "AuditObservationAndRecommendation",
  initialState,
  reducers: {
    // Update Audit Observation And Recommendation
    updateAuditObsAndRec: (state, action) => {
      const { observationId } = action.payload;

      state.updateObsAndRecLoading = {
        ...state.updateObsAndRecLoading,
        [observationId]: true,
      };
    },

    updateAuditObsAndRecSuccess: (state, action) => {
      const { observationId } = action.payload;

      //To get previous state
      const clonedUpdateObservationAndRecommendationLoading = { ...state.updateObsAndRecLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateObservationAndRecommendationLoading[observationId];

      state.updateObsAndRecLoading = clonedUpdateObservationAndRecommendationLoading;
    },

    updateAuditObsAndRecFailure: (state, action) => {
      const { observationId, error } = action.payload;

      //To get previous state
      const clonedUpdateObservationAndRecommendationLoading = { ...state.updateObsAndRecLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateObservationAndRecommendationLoading[observationId];

      state.updateObsAndRecLoading = clonedUpdateObservationAndRecommendationLoading;
      state.updateObsAndRecError = error;
    },

    // Update Recommendation Investment
    updateAuditRecInvestment: (state, action) => {
      const { recommendationId } = action.payload;

      state.updateRecInvestmentLoading = {
        ...state.updateRecInvestmentLoading,
        [recommendationId]: true,
      };
    },

    updateAuditRecInvestmentSuccess: (state, action) => {
      const { recommendationId } = action.payload;

      //To get previous state
      const clonedUpdateRecInvestmentLoading = { ...state.updateRecInvestmentLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateRecInvestmentLoading[recommendationId];

      state.updateRecInvestmentLoading = clonedUpdateRecInvestmentLoading;
    },

    updateAuditRecInvestmentFailure: (state, action) => {
      const { recommendationId, error } = action.payload;

      //To get previous state
      const clonedUpdateRecInvestmentLoading = { ...state.updateRecInvestmentLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateRecInvestmentLoading[recommendationId];

      state.updateRecInvestmentLoading = clonedUpdateRecInvestmentLoading;
      state.updateRecInvestmentError = error;
    },
  },
});

// Reducer
export const AuditObservationAndRecommendationReducer = auditObsAndRecSlice.reducer;

// Actions
export const AuditObservationAndRecommendationActions = auditObsAndRecSlice.actions;
