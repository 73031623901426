// Utils
import { getMonthName } from "../../../../../utils/DateUtils";
import DecimalUtils from "../../../../../utils/DecimalUtils";

// Components
import TableDataNotFound from "../../../../../components/table/TableDataNotFound";
import TableHeaders from "../../../../../components/table/TableHeader";

// Page Constants
const TABLE_HEADERS = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Demand Sanctioned",
    },
  },
  {
    title: {
      displayName: "Demand Recorded",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( Solar )",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( IEX )",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( Electricity Board )",
    },
  },
  {
    title: {
      displayName: "Units Consumed",
    },
  },
  {
    title: {
      displayName: "Unit Cost",
    },
  },
  {
    title: {
      displayName: "Total Cost",
    },
  },
  {
    title: {
      displayName: "Power Factor",
    },
  },
];

// Page Components
function ElectricityBillListTableRow({ electricityBill = {}, serialNo }) {
  const {
    year = "",
    month = "",
    demandSanctioned = "",
    demandRecorded = "",
    unitsConsumedSolar = "",
    unitsConsumedIEX = "",
    unitsConsumedElectricityBoard = "",
    unitsConsumed = "",
    unitCost = "",
    totalCost = "",
    powerFactor = "",
  } = electricityBill;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{`${getMonthName(month)} , ${year}`}</td>
      <td>{DecimalUtils.fixDecimal(demandSanctioned, 2)}</td>
      <td>{DecimalUtils.fixDecimal(demandRecorded, 2)}</td>
      <td>{DecimalUtils.fixDecimal(unitsConsumedSolar, 2)}</td>
      <td>{DecimalUtils.fixDecimal(unitsConsumedIEX, 2)}</td>
      <td>{DecimalUtils.fixDecimal(unitsConsumedElectricityBoard, 2)}</td>
      <td>{DecimalUtils.fixDecimal(unitsConsumed, 2)}</td>
      <td>{DecimalUtils.fixDecimal(unitCost, 2)}</td>
      <td>{DecimalUtils.fixDecimal(totalCost, 2)}</td>
      <td>{powerFactor}</td>
    </tr>
  );
}

function ElectricityBillListTableBody({ electricityBills = [] }) {
  // If there is no electricity bill list
  if (electricityBills.length === 0) {
    return <TableDataNotFound message="No electricity bills present" colSpan={16} />;
  }

  // Electricity bill list table row
  return (
    <>
      {electricityBills.map((electricityBill, idx) => {
        return <ElectricityBillListTableRow key={idx} electricityBill={electricityBill} serialNo={idx + 1} />;
      })}
    </>
  );
}

/**
 * Electricity Bill List Table
 * @param {*} powerBills : Electricity Bill List (array)
 */
export default function ElectricityBillListTable({ electricityBills = [] }) {
  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Electricity Bills</h3>

      <div className="table-responsive">
        <table className="table table-bordered border-end">
          {/* Table Headers */}
          <TableHeaders tableHeaders={TABLE_HEADERS} />

          <tbody>
            {/* Electricity Bill List Table Body */}
            <ElectricityBillListTableBody electricityBills={electricityBills} />
          </tbody>
        </table>
      </div>
    </section>
  );
}
