import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { ComponentTypeListActions } from "../../../redux-slice/component-type/ComponentTypeListSlice";
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Input from "../../../components/input/Input";

// Sections
import ObservationCodeListTable from "./ObservationCodeListTable";

// Page Components
function ObservationCodeListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search shadow-none" placeholder={`Search observation codes...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Observation Code List Page
 */
export default function ObservationCodeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { componentTypeId } = params;

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // Component Type Info Selector State
  const componentTypeInfo = useSelector((state) => state.componentType.componentTypeInfo);
  const componentTypeInfoLoading = useSelector((state) => state.componentType.componentTypeInfoLoading);

  // Page Header
  const { name = "" } = componentTypeInfo;
  const pageTitle = !componentTypeInfoLoading ? `${name} :: Observation Codes` : "";

  // use Effect
  useEffect(() => {
    dispatch(ComponentTypeActions.getComponentTypeInfo({ componentTypeId }));
    dispatch(ComponentTypeListActions.getObsCodeList({ componentTypeId, pageNumber, pageSize, searchText }));
  }, [dispatch, componentTypeId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={pageTitle} />);
  }, [name]);

  return (
    <div className="page-content">
      {/* Observation Code List Table Actions Section */}
      <ObservationCodeListTableActionsSection />

      {/* Observation Code List Table */}
      <ObservationCodeListTable pageNumber={pageNumber} />
    </div>
  );
}
