// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import {
  getJobInfoByIdUrl,
  getJobSessionByIdUrl,
  runJobUrl,
  resumeJobByIdUrl,
  pauseJobByIdUrl,
} from "../../urls/api-urls/JobURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Get Job Info
export async function getJobInfoByIdApi(jobId) {
  const url = URLs.format(getJobInfoByIdUrl, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Job Session
export async function getJobSessionByIdApi(jobId, pageNumber = 1, pageSize = 8) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  const url = URLs.format(getJobSessionByIdUrl, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Run job
export async function runJobApi(job, jobId) {
  const url = URLs.format(runJobUrl, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, job);
}

// Resume job
export async function resumeJobByIdApi(jobId) {
  const url = URLs.format(resumeJobByIdUrl, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Pause job
export async function pauseJobByIdApi(jobId) {
  const url = URLs.format(pauseJobByIdUrl, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}
