import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get job information
  jobInfo: {},
  jobInfoLoading: false,
  jobInfoError: "",

  // Get job session
  jobSessions: [],
  jobSessionsLoading: false,
  jobSessionsError: "",

  // Run job
  runJobSuccess: null,
  runJobLoading: false,
  runJobError: "",

  // Resume job
  resumeJobSuccess: null,
  resumeJobLoading: false,
  resumeJobError: "",

  // Pause job
  pauseJobSuccess: null,
  pauseJobLoading: false,
  pauseJobError: "",
};

// Job Slice
const jobSlice = createSlice({
  name: "Job",
  initialState,
  reducers: {
    // Get job information
    getJobInfo: (state) => {
      state.jobInfoLoading = true;
    },

    getJobInfoSuccess: (state, action) => {
      state.jobInfoLoading = false;
      state.jobInfo = action.payload.jobInfo;
    },

    getJobInfoFailure: (state, action) => {
      state.jobInfoLoading = false;
      state.jobInfoError = action.payload.error;
    },

    // Get job session
    getJobSession: (state) => {
      state.jobSessionsLoading = true;
    },

    getJobSessionSuccess: (state, action) => {
      state.jobSessionsLoading = false;
      state.jobSessions = action.payload.jobSessions;
    },

    getJobSessionFailure: (state, action) => {
      state.jobSessionsLoading = false;
      state.jobSessionsError = action.payload.error;
    },

    // Run job
    runJob: (state) => {
      state.runJobLoading = true;
    },

    runJobSuccess: (state) => {
      state.runJobLoading = false;
      state.runJobSuccess = true;
    },

    runJobFailure: (state, action) => {
      state.runJobLoading = false;
      state.runJobSuccess = false;
      state.runJobError = action.payload.error;
    },

    // Resume job
    resumeJob: (state) => {
      state.resumeJobLoading = true;
    },

    resumeJobSuccess: (state) => {
      state.resumeJobLoading = false;
    },

    resumeJobFailure: (state, action) => {
      state.resumeJobLoading = false;
      state.resumeJobError = action.payload.error;
    },

    // Pause job
    pauseJob: (state) => {
      state.pauseJobLoading = true;
    },

    pauseJobSuccess: (state) => {
      state.pauseJobLoading = false;
    },

    pauseJobFailure: (state, action) => {
      state.pauseJobLoading = false;
      state.pauseJobError = action.payload.error;
    },
  },
});

// Reducer
export const JobReducer = jobSlice.reducer;

// Actions
export const JobActions = jobSlice.actions;
