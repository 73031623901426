import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { ProductListActions } from "../../../redux-slice/product/ProductListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import ProductListTable from "./ProductListTable";

// Page Components
function ProductListTableActionsSection({}) {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search products...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Product List Page
 */
export default function ProductListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Search Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector States
  const productList = useSelector((state) => state.productList.productList);
  const productListLoading = useSelector((state) => state.productList.productListLoading);
  const productListPagination = useSelector((state) => state.productList.productListPagination);

  // Page Number from Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(ProductListActions.getProductList({ pageNumber, searchText }));
  }, [dispatch, pageNumber, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="Products" />);
  }, []);

  return (
    <div className="page-content">
      {/* Product List Table Actions Section */}
      <ProductListTableActionsSection />

      {/* Product List Table */}
      <ProductListTable
        productList={productList}
        productListLoading={productListLoading}
        productListPagination={productListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
