// Urls
import { upsertParameterValuesUrl } from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Upsert Parameter Value Api
export async function upsertParameterValuesApi(parameterValuesData, auditId, componentId) {
  //TODO : Add comp_cals req param
  const apiUrl = URLs.format(upsertParameterValuesUrl, { auditId, componentId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiUrl, {}, parameterValuesData);
}
