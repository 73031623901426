import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditReportsActions } from "../../redux-slice/audit/ReportsSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// APIs
import {
  createReportApi,
  deleteReportApi,
  downloadReportPDFApi,
  getAuditReportDataApi,
  getReportHTMLApi,
  updateReportApi,
  updateReportStatusApi,
} from "../../api/audit/ReportsAPI";
import URLs from "../../app/utils/URLs";

// Create Report
function* createReport(action) {
  const { auditId = "", reportData = {}, navigate = () => {} } = action.payload;
  const { reportType = "" } = reportData;

  try {
    const { id: reportId = "" } = yield createReportApi(auditId, reportData);

    // Dispatching Action
    yield put(AuditReportsActions.createReportSuccess({ reportType }));

    // Toaster
    toast.success("Report Created Successfully");

    // Navigate
    const url = URLs.format(RedirectTo.AuditReportEditorPageUrl, { auditId, reportId });
    navigate(url);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.createReportFailure({ error, reportType }));
  }
}

// Update Report
function* updateReport(action) {
  const { auditId = "", reportId = "", reportData = {} } = action.payload;

  try {
    yield updateReportApi(auditId, reportId, reportData);

    const reportHTML = yield getReportHTMLApi(auditId, reportId);

    // Dispatching Action
    yield put(AuditReportsActions.getReportHTMLSuccess({ reportHTML }));

    // Dispatching Action
    yield put(AuditReportsActions.updateReportSuccess());

    // Toaster
    toast.success("Report Updated Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.updateReportFailure({ error }));
  }
}

// Delete Report
function* deleteReport(action) {
  const { auditId = "", reportId = "" } = action.payload;

  try {
    yield deleteReportApi(auditId, reportId);

    // Dispatching Action
    yield put(AuditReportsActions.deleteReportSuccess({ reportId }));

    // Toaster
    toast.success("Report Deleted Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.deleteReportFailure({ error, reportId }));
  }
}

// Get Report HTML
function* getReportHTML(action) {
  const { auditId = "", reportId = "" } = action.payload;

  try {
    const reportHTML = yield getReportHTMLApi(auditId, reportId);

    // Dispatching Action
    yield put(AuditReportsActions.getReportHTMLSuccess({ reportHTML }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.getReportHTMLFailure({ error }));
  }
}

// Download Report PDF
function* downloadReportPDF(action) {
  const { auditId = "", reportId = "", reportData = {} } = action.payload;

  try {
    const data = yield downloadReportPDFApi(auditId, reportId, reportData);

    // Downloading File
    saveFile(`Audit_Report.pdf`, data);

    // Dispatching Action
    yield put(AuditReportsActions.downloadReportPDFSuccess({ reportId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.downloadReportPDFFailure({ error, reportId }));
  }
}

// Update Report Status
function* updateReportStatus(action) {
  const { auditId = "", reportId = "", status = false } = action.payload;

  try {
    yield updateReportStatusApi(auditId, reportId, status);

    // Dispatching Action
    yield put(AuditReportsActions.updateReportStatusSuccess({ reportId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.updateReportStatusFailure({ error, reportId }));
  }
}

// Get Report Data
function* getReportData(action) {
  const { auditId = "", reportType = "" } = action.payload;

  try {
    const reportData = yield getAuditReportDataApi(auditId, reportType);

    // Dispatching Action
    yield put(AuditReportsActions.getReportDataSuccess({ reportData }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportsActions.getReportDataFailure({ error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(AuditReportsActions.createReport.type, createReport),
    takeLatest(AuditReportsActions.updateReport.type, updateReport),
    takeLatest(AuditReportsActions.deleteReport.type, deleteReport),
    takeLatest(AuditReportsActions.getReportHTML.type, getReportHTML),
    takeLatest(AuditReportsActions.downloadReportPDF.type, downloadReportPDF),
    takeLatest(AuditReportsActions.updateReportStatus.type, updateReportStatus),
    takeLatest(AuditReportsActions.getReportData.type, getReportData),
  ]);
}
