// Urls
import { getRoleInfoByIdUrl, createRoleByIdUrl, updateRoleByIdUrl } from "../../urls/api-urls/RoleURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";
import URLs from "../../app/utils/URLs";

// Get Role Information By Id
export async function getRoleInfoByIdApi(roleId) {
  const url = URLs.format(getRoleInfoByIdUrl, { roleId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Create Role By Id
export async function createRoleByIdApi(roleData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(createRoleByIdUrl, {}, roleData);
}

// Update Role By Id
export async function updateRoleByIdApi(roleId, roleData) {
  const url = URLs.format(updateRoleByIdUrl, { roleId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, roleData);
}
