import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { InstrumentListActions } from "../../../redux-slice/instrument/InstrumentListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Input from "../../../components/input/Input";

// Sections
import InstrumentListTable from "./InstrumentListTable";

// Page Components
function InstrumentListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search instruments...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Instrument List Page
 */
export default function InstrumentListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Search Params State
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // use Effect
  useEffect(() => {
    dispatch(InstrumentListActions.getInstrumentList({ pageNumber, pageSize, searchText }));
  }, [dispatch, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="Instruments" />);
  }, []);

  return (
    <div className="page-content">
      {/* Instrument List Table Actions Section */}
      <InstrumentListTableActionsSection />

      {/* Instrument List Table */}
      <InstrumentListTable />
    </div>
  );
}
