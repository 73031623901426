import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Audit instrument Data List
  auditInstrumentDataList: [],
  auditInstrumentDataListPagination: {},
  auditInstrumentDataListLoading: false,
  auditInstrumentDataListError: "",
};

// Audit Instrument Slice
const auditInstrumentListSlice = createSlice({
  name: "AuditInstrumentList",
  initialState,
  reducers: {
    // Get Audit instrument Data List
    getAuditInstrumentDataList: (state) => {
      state.auditInstrumentDataListLoading = true;
    },

    getAuditInstrumentDataListSuccess: (state, action) => {
      state.auditInstrumentDataListLoading = false;
      state.auditInstrumentDataList = action.payload.auditInstrumentDataList;
      state.auditInstrumentDataListPagination = action.payload.auditInstrumentDataListPagination;
    },

    getAuditInstrumentDataListFailure: (state, action) => {
      state.auditInstrumentDataListLoading = false;
      state.auditInstrumentDataListError = action.payload.error;
    },
  },
});

// Reducer
export const AuditInstrumentListReducer = auditInstrumentListSlice.reducer;

// Actions
export const AuditInstrumentListActions = auditInstrumentListSlice.actions;
