import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantListActions } from "../../redux-slice/plant/PlantListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getPlantListApi } from "../../api/plant/PlantListAPI";

// Get Plant List
function* getPlantList(action) {
  try {
    const { orgId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getPlantListApi(orgId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      PlantListActions.getPlantListSuccess({
        plantList: items,
        plantListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantListActions.getPlantListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(PlantListActions.getPlantList.type, getPlantList)]);
}
