import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { InstrumentActions } from "../../redux-slice/instrument/InstrumentSlice";
import { InstrumentListActions } from "../../redux-slice/instrument/InstrumentListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getInstrumentListApi } from "../../api/instrument/InstrumentListAPI";
import {
  getInstrumentInfoByIdApi,
  getDataColumnInfoByIdApi,
  updateInstrumentStatusByIdApi,
} from "../../api/instrument/InstrumentAPI";

// Get Instrument Info By Id
function* getInstrumentInfoById(action) {
  try {
    const { instrumentId } = action.payload;

    const instrumentInfo = yield getInstrumentInfoByIdApi(instrumentId);

    // Dispatching Action
    yield put(InstrumentActions.getInstrumentInfoSuccess({ instrumentInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.getInstrumentInfoFailure({ error }));
  }
}

// Get Data Column Info By Id
function* getDataColumnInfoById(action) {
  try {
    const { instrumentId } = action.payload;

    const dataColumnInfo = yield getDataColumnInfoByIdApi(instrumentId);

    // Dispatching Action
    yield put(InstrumentActions.getDataColumnInfoSuccess({ dataColumnInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.getDataColumnInfoFailure({ error }));
  }
}

// Update Instrument Status By Id
function* updateInstrumentStatusById(action) {
  try {
    const { instrumentId, status, pageNumber, pageSize } = action.payload;

    yield updateInstrumentStatusByIdApi(instrumentId, status);

    const { items, pagination } = yield getInstrumentListApi("", pageNumber, pageSize);

    // Dispatch to refresh data
    yield put(
      InstrumentListActions.getInstrumentListSuccess({ instrumentList: items, instrumentListPagination: pagination })
    );

    // Dispatching Action
    yield put(InstrumentActions.updateInstrumentStatusSuccess({ instrumentId }));

    // Toaster
    toast.success("Instrument Status Updated Successfully");
  } catch (error) {
    const { instrumentId } = action.payload;

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.updateInstrumentStatusFailure({ instrumentId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(InstrumentActions.getInstrumentInfo.type, getInstrumentInfoById),
    takeLatest(InstrumentActions.getDataColumnInfo.type, getDataColumnInfoById),
    takeEvery(InstrumentActions.updateInstrumentStatus.type, updateInstrumentStatusById),
  ]);
}
