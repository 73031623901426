// App Components
import ErrorHandler from "../app/error/ErrorHandler";
import ProtectedRoute from "../app/ProtectedRoute";

// Constants
import { RedirectTo } from "../urls/page-urls/RedirectURL";
import { DataPreLoadObj } from "../constants/DataPreLoadConstants";

// Pages :: Definitions
import MeasurementTypeListPage from "../pages/measurement-type/measurement-type-list/MeasurementTypeListPage";
import MeasurementTypeViewPage from "../pages/measurement-type/measurement-type-view/MeasurementTypeViewPage";

import ComponentTypeListPage from "../pages/component-type/component-type-list/ComponentTypeListPage";
import ParameterListPage from "../pages/component-type/parameter-list/ParameterListPage";
import ObservationCodeListPage from "../pages/component-type/observation-code-list/ObservationCodeListPage";
import RecommendationCodeListPage from "../pages/component-type/recommendation-code-list/RecommendationCodeListPage";

import InstrumentListPage from "../pages/instrument/instrument-list/InstrumentListPage";
import InstrumentViewPage from "../pages/instrument/instrument-view/InstrumentViewPage";

import MaterialListPage from "../pages/material/material-list/MaterialListPage";
import ProductListPage from "../pages/product/product-list/ProductListPage";

import ConstantListPage from "../pages/constant/ConstantListPage";

import HSNCodeListPage from "../pages/hsn-code/hsn-code-list/HSNCodeListPage";
import NICCodeListPage from "../pages/nic-code/nic-code-list/NICCodeListPage";

// Layout
import MainPageLayout from "../components/layout/MainPageLayout";

// Main Pages and Layouts
const DefPageRoutes = {
  path: RedirectTo.DashboardPageUrl,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute dataPreLoadObj={DataPreLoadObj}>
        {/* Layout */}
        <MainPageLayout topbar={true} sidebar={true} sidebarCollapsible={true} />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      path: RedirectTo.MeasurementTypeListPageUrl,
      element: <MeasurementTypeListPage />,
    },
    {
      path: RedirectTo.MeasurementTypeViewPageUrl,
      element: <MeasurementTypeViewPage />,
    },

    {
      path: RedirectTo.ComponentTypeListPageUrl,
      element: <ComponentTypeListPage />,
    },

    {
      path: RedirectTo.ParameterListPageUrl,
      element: <ParameterListPage />,
    },
    {
      path: RedirectTo.ObservationCodeListPageurl,
      element: <ObservationCodeListPage />,
    },
    {
      path: RedirectTo.RecommendationCodeListPageUrl,
      element: <RecommendationCodeListPage />,
    },

    {
      path: RedirectTo.InstrumentListPageUrl,
      element: <InstrumentListPage />,
    },
    {
      path: RedirectTo.InstrumentViewPageUrl,
      element: <InstrumentViewPage />,
    },

    {
      path: RedirectTo.MaterialListPageUrl,
      element: <MaterialListPage />,
    },

    {
      path: RedirectTo.ProductListPageUrl,
      element: <ProductListPage />,
    },
    {
      path: RedirectTo.ConstantsListPageUrl,
      element: <ConstantListPage />,
    },
    {
      path: RedirectTo.HSNCodeListPageUrl,
      element: <HSNCodeListPage />,
    },

    {
      path: RedirectTo.NICCodeListPageUrl,
      element: <NICCodeListPage />,
    },
  ],
};

export default DefPageRoutes;
