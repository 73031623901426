import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get product list
  productList: [],
  productListPagination: {},
  productListLoading: false,
  productListError: "",
};

// Product List Slice
const productListSlice = createSlice({
  name: "ProductList",
  initialState,
  reducers: {
    // Get Product List
    getProductList: (state) => {
      state.productListLoading = true;
    },

    getProductListSuccess: (state, action) => {
      state.productListLoading = false;
      state.productList = action.payload.productList;
      state.productListPagination = action.payload.productListPagination;
    },

    getProductListFailure: (state, action) => {
      state.productListLoading = false;
      state.productListError = action.payload.error;
    },
  },
});

// Reducer
export const ProductListReducer = productListSlice.reducer;

// Actions
export const ProductListActions = productListSlice.actions;
