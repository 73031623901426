import axios from "axios";

//Utils
import { getAuthToken } from "../LocalStorage";

// Page Constants
const DEFAULT_HEADER_PARAMS = {
  "Content-Type": "application/json",
};

function serializeQueryParams(params) {
  let options = "";
  for (const key in params) {
    if (typeof params[key] !== "object" && params[key]) {
      options += `${key}=${params[key]}&`;
    } else if (typeof params[key] === "object" && params[key] && params[key].length) {
      params[key].forEach((el) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
}

export async function deleteRequest(
  url,
  data = {},
  needsAuth = true,
  params = {},
  incomingHeaders = DEFAULT_HEADER_PARAMS
) {
  const headers = needsAuth ? { ...incomingHeaders, Authorization: "Bearer " + getAuthToken() } : incomingHeaders;

  const response = await axios({
    url,
    data,
    method: "DELETE",
    params,
    headers,
  });
  return response.data;
}

export async function sendGetRequest(url, params = {}, needsAuth = true, incomingHeaders = DEFAULT_HEADER_PARAMS) {
  const headers = needsAuth ? { ...incomingHeaders, Authorization: "Bearer " + getAuthToken() } : incomingHeaders;

  const response = await axios({
    url,
    method: "GET",
    params,
    headers,
    paramsSerializer: serializeQueryParams,
  });

  return response.data;
}

export async function sendCreateOrUpdateRequest(
  url,
  data = {},
  needsAuth = true,
  method = "POST",
  params = {},
  incomingHeaders = DEFAULT_HEADER_PARAMS
) {
  const headers = needsAuth ? { ...incomingHeaders, Authorization: "Bearer " + getAuthToken() } : incomingHeaders;

  const response = await axios({
    url,
    method,
    params,
    data,
    headers,
  });

  return response.data;
}

export async function downloadFile(
  url,
  needsAuth = true,
  incomingHeaders = DEFAULT_HEADER_PARAMS,
  params = {},
  responseType = "blob"
) {
  const headers = needsAuth ? { ...incomingHeaders, Authorization: "Bearer " + getAuthToken() } : incomingHeaders;
  const response = await axios({
    url,
    method: "GET",
    params,
    headers,
    responseType,
  });
  return response.data;
}
