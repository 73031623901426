// Urls
import { RedirectTo } from "../urls/page-urls/RedirectURL";

//utils
import URLs from "../app/utils/URLs";
import { getItem } from "../app/LocalStorage";

const organizationListPageLabel = {
  label: "Industries",
  navigateTo: URLs.format(RedirectTo.OrganizationListPageUrl, { orgType: "INDUSTRY" }),
};

const partnerListPageLabel = {
  label: "Audit Firms",
  navigateTo: URLs.format(RedirectTo.OrganizationListPageUrl, { orgType: "AUDIT" }),
};

const getPageListUrlLabel = (orgId, orgName) => {
  return { label: `${orgName}`, navigateTo: URLs.format(RedirectTo.PlantListPageUrl, { orgId }) };
};

const getUserListUrlLabel = (orgId, orgName) => {
  return {
    label: `${orgName}`,
    navigateTo: URLs.format(RedirectTo.UserListPageUrl, { orgId }),
  };
};

export const bcForPlantListPage = (plantName) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [organizationListPageLabel, { label: `${plantName}` }]
    : [{ label: `${plantName}` }];
};

export const bcForPlantViewPage = (orgId, orgName, plantName) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [organizationListPageLabel, getPageListUrlLabel(orgId, orgName), { label: `${plantName}` }]
    : [getPageListUrlLabel(orgId, orgName), { label: `${plantName}` }];
};

export const bcForPlantCreatePage = (orgId, orgName) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [organizationListPageLabel, getPageListUrlLabel(orgId, orgName), { label: "Create new Plant" }]
    : [getPageListUrlLabel(orgId, orgName), { label: "Create new Plant" }];
};

export const bcForOrganizationViewPage = (orgName, orgType) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [orgType === "AUDIT" ? partnerListPageLabel : organizationListPageLabel, { label: `${orgName}` }]
    : [{ label: `${orgName}` }];
};

export const bcForOrganizationCreatePage = (orgTypeName, orgType) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [orgType === "AUDIT" ? partnerListPageLabel : organizationListPageLabel, { label: `Create New ${orgTypeName}` }]
    : [{ label: `Create New ${orgTypeName}` }];
};

export const bcForUsersListPage = (orgName, orgType) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [orgType === "AUDIT" ? partnerListPageLabel : organizationListPageLabel, { label: `${orgName}` }]
    : [{ label: `${orgName}` }];
};

export const bcForUsersViewPage = (orgId, orgName, userName, orgType) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [
        orgType === "AUDIT" ? partnerListPageLabel : organizationListPageLabel,
        getUserListUrlLabel(orgId, orgName),
        { label: `${userName}` },
      ]
    : [getUserListUrlLabel(orgId, orgName), { label: `${userName}` }];
};

export const bcForUserCreatePage = (orgId, orgName, orgType) => {
  const authOrgType = getItem("ORG_TYPE");
  return authOrgType === "PLATFORM"
    ? [
        orgType === "AUDIT" ? partnerListPageLabel : organizationListPageLabel,
        getUserListUrlLabel(orgId, orgName),
        { label: "Create new User" },
      ]
    : [getUserListUrlLabel(orgId, orgName), { label: "Create new User" }];
};
