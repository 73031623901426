//Components
import TableHeaders from "../../../components/table/TableHeader";
import ShowDateAndTime from "../../../components/date-time-format/ShowDateAndTime";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Session ID",
    },
  },
  {
    title: {
      displayName: "Triggered By",
    },
  },
  {
    title: {
      displayName: "Triggered On",
    },
  },
  {
    title: {
      displayName: "Duration",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Message",
    },
  },
];

//Page Components
function JobSessionsTableCell({ timeStamp }) {
  return (
    <td>
      {/* Show Date and Time */}
      <ShowDateAndTime timeStamp={timeStamp} />
    </td>
  );
}

function JobSessionsTableRow({ jobSession }) {
  const { id, jobId, triggeredBy, triggeredOn, totalDuration, status, statusMsg } = jobSession;

  return (
    <tr key={id}>
      <th>{id}</th>
      <td>{jobId}</td>
      <td>{triggeredBy}</td>

      {/* Job Session Table Cell */}
      <JobSessionsTableCell timeStamp={triggeredOn} />

      <td>{totalDuration}</td>
      <td>{status}</td>
      <td>{statusMsg}</td>
    </tr>
  );
}

function JobSessionsTableBody({ jobSessionsLoading, jobSessions }) {
  //Loader
  if (jobSessionsLoading) {
    return <TableLoader colSpan={7} />;
  }

  //if there is no job Sessions
  if (jobSessions.length === 0) {
    return <TableDataNotFound colSpan={7} message="No job sessions found" />;
  }

  //Job sessions table row
  return (
    <>
      {jobSessions.map((jobSession) => {
        return <JobSessionsTableRow key={jobSession.id} jobSession={jobSession} />;
      })}
    </>
  );
}

/**
 * Job Sessions Table
 */
export default function JobSessionsTable({ jobSessions, jobSessionsLoading }) {
  return (
    <table className="table table-bordered border-secondary-subtle mt-3 border-end">
      {/* Table Headers */}
      <TableHeaders tableHeaders={tableHeaders} />

      <tbody>
        {/* Job Sessions Table Body */}
        <JobSessionsTableBody jobSessionsLoading={jobSessionsLoading} jobSessions={jobSessions} />
      </tbody>
    </table>
  );
}
