import { Link } from "react-router-dom";

// CSS
import "./Breadcrumb.scss";

/**
 * Breadcrumb
 * @param {*} items : Array of items to be displayed
 * @param {*} containerClassName : Class name
 */
export default function Breadcrumb({ items = [], containerClassName = "" }) {
  const links = items.map((item, index) => {
    const { navigateTo = "", label = "" } = item || {};

    if (navigateTo) {
      return (
        <li key={index}>
          <Link to={navigateTo} className="text-decoration-none fs-5">
            {label}
          </Link>
        </li>
      );
    }

    return (
      <li key={index}>
        <span className="breadcrumb__text fs-5">{label}</span>
      </li>
    );
  });

  return (
    <nav aria-label="breadcrumb" className={containerClassName} data-html2canvas-ignore="true">
      <ol className="breadcrumb__container">{links}</ol>
    </nav>
  );
}
