// Constants & Utils
import { OrgTypes } from "../constants/GeneralConstants";
import { LocalStorageKeys, getItem } from "../app/LocalStorage";

export const getOrgInfo = () => {
  const authOrgStr = getItem(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  return authOrg;
};

export const getUserInfo = () => {
  const authUserStr = getItem(LocalStorageKeys.AUTH_USER) || "{}";
  const authUser = JSON.parse(authUserStr);
  return authUser;
};

export const getOrgId = () => {
  const authOrgStr = getItem(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  return authOrg.id;
};

export const getOrgType = () => {
  const authOrgStr = getItem(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  const authOrgType = authOrg?.type;
  return authOrgType;
};

export const getIndustryOrAuditOrgId = () => {
  const authOrgStr = getItem(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  const authOrgType = authOrg?.type;
  const orgId = authOrgType === OrgTypes.PLATFORM.id ? null : authOrg.id;
  return orgId;
};
