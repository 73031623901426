import { useSelector } from "react-redux";

// Components
import TableHeaders from "../../components/table/TableHeader";
import TableLoader from "../../components/table/TableLoader";
import TableDataNotFound from "../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Unit",
    },
  },
];

// Page Components
function ConstantListTableRow({ constant, id }) {
  const { name = "", code = "", description = "", value = "", unit = "" } = constant;

  return (
    <tr>
      <td>{id + 1}</td>
      <td>{name}</td>
      <td>{code}</td>
      <td>{description}</td>
      <td>{value}</td>
      <td>{unit}</td>
    </tr>
  );
}

function ConstantListTableBody({ constantListLoading, constantList }) {
  // Loader
  if (constantListLoading) {
    return <TableLoader colSpan={6} />;
  }

  // if there is no constant list
  if (constantList.length === 0) {
    return <TableDataNotFound message="No constants present" colSpan={6} />;
  }

  // Constant list table row
  return (
    <>
      {constantList.map((constant, index) => {
        return <ConstantListTableRow key={index} id={index} constant={constant} />;
      })}
    </>
  );
}

/**
 * Constant List Table
 * @return
 */
export default function ConstantListTable() {
  // Selector States
  const constantList = useSelector((state) => state.constantList.constantList);
  const constantListLoading = useSelector((state) => state.constantList.constantListLoading);

  const consList = Object.values(constantList || {});

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />
        <tbody>
          {/* Constant List Table Body */}
          <ConstantListTableBody constantListLoading={constantListLoading} constantList={consList} />
        </tbody>
      </table>
    </>
  );
}
