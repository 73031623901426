import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { OrganizationListActions } from "../../redux-slice/organization/OrganizationListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getOrganizationListApi } from "../../api/organization/OrganizationListAPI";

// Get Organization List
function* getOrganizationList(action) {
  try {
    const { orgType, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getOrganizationListApi(orgType, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      OrganizationListActions.getOrganizationListSuccess({
        orgType,
        organizationList: items,
        organizationListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(OrganizationListActions.getOrganizationListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeEvery(OrganizationListActions.getOrganizationList.type, getOrganizationList)]);
}
