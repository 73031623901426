import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { MaterialListActions } from "../../redux-slice/material/MaterialListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getMaterialListApi } from "../../api/material/MaterialListAPI";

// Get Material List
function* getMaterialList(action) {
  try {
    const { pageNumber, pageSize, searchText, type } = action.payload;

    const { items, pagination } = yield getMaterialListApi(pageNumber, pageSize, searchText, type);

    // Dispatching Action
    yield put(MaterialListActions.getMaterialListSuccess({ materialList: items, materialListPagination: pagination }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MaterialListActions.getMaterialListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(MaterialListActions.getMaterialList.type, getMaterialList)]);
}
