// Component Type List
export const getComponentTypeListUrl = "/api/v1/defs/component_type/search";
export const getParameterListByComponentTypeIdUrl = "/api/v1/defs/parameter/search";
export const getRecommendationCodeListByComponentTypeIdUrl = "/api/v1/defs/recommendation_code/search";
export const getObservationCodeListByComponentTypeIdUrl = "/api/v1/defs/observation_code/search";

// Component Type
export const getComponentTypeInfoByIdUrl = "/api/v1/defs/component_type/:componentTypeId";

// Update Component-Type Status(Enable/Disable)
export const updateComponentTypeStatusByIdUrl = "/api/v1/defs/component_type/:componentTypeId/status";

// Update Observation Code Status(Enable/Disable)
export const updateObservationCodeStatusByIdUrl = "/api/v1/defs/observation_code/:obsCodeId/status";

// Update Recommnedation Code Status(Enable/Disable)
export const updateRecommendationCodeStatusByIdUrl = "/api/v1/defs/recommendation_code/:recCodeId/status";

// Update Parameter Status By id
export const updateParameterStatusByIdUrl = "/api/v1/defs/parameter/:parameterId/status";
