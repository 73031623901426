import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

// Utils
import { LocalStorageKeys, getItem } from "../../app/LocalStorage";

// Components
import { Button } from "../../components/button/Button";
import Label from "../../components/label/Label";
import Input from "../../components/input/Input";

// Page Component
function ProfileCard({ data = {} }) {
  // User Information
  const { firstName = "", lastName = "", phoneNo = "", emailId = "", designation = "" } = data || {};

  return (
    <>
      <h2>Profile</h2>

      <div className="row mt-3">
        <div className="col-6">
          <Label title="Name" className="fw-bold" />
          <div>
            {firstName} {lastName}
          </div>
        </div>
        <div className="col-6">
          <Label title="Designation" className="fw-bold" />
          <div>{designation}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt-4">
          <Label title="Email" className="fw-bold" />
          <div>{emailId}</div>
        </div>
        <div className="col-6 mt-4">
          <Label title="Phone Number" className="fw-bold" />
          <div>{phoneNo}</div>
        </div>
      </div>
    </>
  );
}

// Reset Password Component
function ResetPassword({ authUserInfo = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [values, setValues] = useState({});
  const [error, setError] = useState({});

  // Selector State
  const resetPasswordLoading = useSelector((state) => state.auth.resetPasswordLoading);

  // Values
  const { newPassword = "", confirmPassword = "" } = values || {};

  // Error
  const { newPasswordErr = "", confirmPasswordErr = "" } = error || {};

  // Auth User Information
  const { emailId = "" } = authUserInfo;

  // Handle Change Function
  function handleChange({ target }, key) {
    setValues((prev) => ({ ...prev, [key]: target.value }));
  }

  // Reset Password Function
  function resetPassword() {
    if (!newPassword) {
      setError({ newPasswordErr: "Please enter password !!" });
    }

    if (!confirmPassword) {
      setError((prev) => ({ ...prev, confirmPasswordErr: "Please enter password !!" }));
      return;
    }

    if (newPassword !== confirmPassword) {
      setError({ confirmPasswordErr: "Password Mismatch !!" });
      return;
    }

    // Clear State
    setError({});

    // Dispatch
    dispatch(AuthActions.resetPassword({ email: emailId, newPassword }));
  }

  return (
    <div className="mt-2">
      {/* New Password Input */}
      <Label title="New Password" isMandatory={true} />
      <Input value={newPassword} onChange={(e) => handleChange(e, "newPassword")} error={newPasswordErr} />

      {/* Confirm Password Input */}
      <Label title="Confirm Password" isMandatory={true} className="mt-2" />
      <Input value={confirmPassword} onChange={(e) => handleChange(e, "confirmPassword")} error={confirmPasswordErr} />

      {/* Reset Password Button */}
      <Button
        label="Reset"
        color="warning"
        className="mt-3 float-end"
        onClick={resetPassword}
        loading={resetPasswordLoading}
        disabled={resetPasswordLoading}
      />
    </div>
  );
}

/**
 * Profile Page
 */
export default function ProfilePage() {
  // Getting user information
  const authUserInfo = JSON.parse(getItem(LocalStorageKeys.AUTH_USER) || "{}") || {};

  return (
    <div className="container-fluid mt-4">
      <div className="row page-content gx-4">
        <div className="col-2"></div>
        <div className="col-4 card p-4">
          <div className="card-body p-0">
            <ProfileCard data={authUserInfo} />
          </div>
        </div>

        <div className="col-4">
          <div className="card p-4">
            <h2>Reset Password</h2>

            {/* Reset Password */}
            <ResetPassword authUserInfo={authUserInfo} />
          </div>
        </div>
      </div>
    </div>
  );
}
