import React, { useId, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from "jodit-react";

// Actions
import { AuditComponentActions } from "../../../../../redux-slice/audit/ComponentSlice";

// Utils
import { getComponentTypeParameters } from "../../../../../utils/DataPreLoadUtils";
import DecimalUtils from "../../../../../utils/DecimalUtils";

// Components
import { Button } from "../../../../../components/button/Button";
import TableHeaders from "../../../../../components/table/TableHeader";
import ObservationListTable from "./ObservationListTable";
import RecommendationListTable from "./RecommendationListTable";

// Page Constants
const TABLE_HEADERS = [
  {
    title: {
      displayName: "Parameter",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Unit",
    },
  },
];

const PARAMETER_TABLE_LIST = [
  { id: "properties", value: "PROPERTY" },
  { id: "measurements", value: "MEASUREMENT" },
  { id: "calculations", value: "CALCULATION" },
];

// Page Components
function ParametersTableRow({ defaultParameters = [], parameters = [] }) {
  return defaultParameters.map((eachParameter = {}) => {
    // Generating random id
    const randomId = useId();

    // Default Parameter Information
    const { id = "", name = "", noOfDecimals = "" } = eachParameter;

    // Parameter Value
    const { value = "--", unitSymbol = "--" } = parameters.find(({ parameterId = "" }) => parameterId === id) || {};

    return (
      <tr key={randomId}>
        <td>{name}</td>
        <td>{DecimalUtils.fixDecimal(value, noOfDecimals)}</td>
        <td>{unitSymbol}</td>
      </tr>
    );
  });
}

function ParametersTableBody({ componentInfo = {}, parameterObj = "" }) {
  // Audit Component Information
  const { component = {} } = componentInfo;
  const { id: componentId = "", componentTypeId = "", name = "", internalId = "" } = component;

  // Parameter Key
  const { id = "", value = "" } = parameterObj;

  // Parameters Information
  const parameters = componentInfo[id] || [];

  // Default Parameters
  const defaultParameters = getComponentTypeParameters(componentTypeId, value) || [];

  return (
    <React.Fragment key={componentId}>
      {/* Title */}
      <tr className="text-center fw-semibold">
        <td colSpan={4} className="bg-secondary-subtle">
          {name} ({internalId})
        </td>
      </tr>

      {/* Parameters Table Row */}
      <ParametersTableRow defaultParameters={defaultParameters} parameters={parameters} />
    </React.Fragment>
  );
}

function ParametersTable({ componentInfo = {}, parameterObj = {} }) {
  // Audit Component Information
  const { parts = [] } = componentInfo;

  // Parameter Key
  const { value = "" } = parameterObj;

  return (
    <div className="table-responsive">
      <h3 className="py-2 fs-5 fw-semibold">{value}</h3>

      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={TABLE_HEADERS} />

        {/* Parameters TableBody */}
        <tbody>
          <ParametersTableBody componentInfo={componentInfo} parameterObj={parameterObj} />

          {parts.map((part = {}) => {
            const { component = {} } = part;

            return <ParametersTableBody key={component?.id || ""} componentInfo={part} parameterObj={parameterObj} />;
          })}
        </tbody>
      </table>
    </div>
  );
}

function ComponentParametersTable({ componentInfo = {} }) {
  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Design Data</h3>

      {PARAMETER_TABLE_LIST.map((eachObj, idx) => {
        return <ParametersTable key={idx} parameterObj={eachObj} componentInfo={componentInfo} />;
      })}
    </section>
  );
}

function RemarksEditor({ componentId = "", reportRemark = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // State
  const [content, setContent] = useState(reportRemark);

  // Selector State
  const addComponentRemarksLoading = useSelector((state) => state.auditComponent.addComponentRemarksLoading);

  // Loading Status
  const loading = addComponentRemarksLoading[componentId];

  // Function to save audit component remarks
  function saveAuditComponentRemarks() {
    // Form Data
    const formData = { remark: content };

    // Dispatch
    dispatch(AuditComponentActions.addComponentRemarks({ auditId, componentId, formData }));
  }

  return (
    <section>
      <div className="py-2">
        <div className="mb-2 fw-semibold">Notes:</div>
        <div>
          <p className="remarks d-none" dangerouslySetInnerHTML={{ __html: content }} />

          {/* Jodit Editor */}
          <div className="report-editor">
            <JoditEditor value={content} onChange={(newContent) => setContent(newContent)} />

            {/* Save Notes Button */}
            <Button
              label="Save Notes"
              className="mt-3 float-end"
              loading={loading}
              disabled={loading}
              onClick={saveAuditComponentRemarks}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function ComponentTitleAndDescription({ component = {}, componentFiles = {} }) {
  // Component Information
  const { name = "", internalId = "", remarks = "--" } = component || {};

  // Component Image
  const { preAuditImage = "" } = componentFiles;

  return (
    <section>
      <h3 className="text-center mb-4 fw-bold">
        {name} ({internalId})
      </h3>

      <h5 className="fw-semibold">Description:</h5>
      <p>{remarks}</p>

      {preAuditImage && (
        <div className="text-center">
          <img alt={name} src={preAuditImage} className="img-fluid" />
        </div>
      )}
    </section>
  );
}

function ComponentThermalImages({ componentFiles = {} }) {
  // Component Thermal Images
  const { auditImagesThermal = [] } = componentFiles;

  // If there are no images
  if (auditImagesThermal.length === 0) {
    return null;
  }

  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Thermal Images</h3>

      <div className="row">
        {auditImagesThermal.map((thermalImg, idx) => {
          const { url = "", notes = "" } = thermalImg || {};

          return (
            <div className="col-4 mb-4" key={idx}>
              <img alt="Thermal Image" src={url} className="img-fluid" />
              <div className="mt-1">{notes}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

/**
 * Component Data
 */
export default function ComponentData({ components = [] }) {
  // Components Detail Selector State
  const { auditComponents = [] } = useSelector((state) => state.auditComponent.componentsDetail);

  return (
    <>
      {components.map((componentObj = {}) => {
        // Audit Component Information
        const { componentInfo = {}, componentFiles = {}, obs = [], recos = [] } = componentObj;
        const { component = {} } = componentInfo || {};
        const { id = "", componentTypeId = "" } = component;

        // Report Remark
        const { reportRemark = "" } = auditComponents.find(({ elementId = "" }) => elementId === id) || {};

        return (
          <React.Fragment key={id}>
            {/* Component Title And Description */}
            <ComponentTitleAndDescription component={component} componentFiles={componentFiles} />

            {/* Component Parameters Table */}
            <ComponentParametersTable componentInfo={componentInfo} />

            {/* Observation List Table */}
            <ObservationListTable componentTypeId={componentTypeId} obsCodeList={obs || []} />

            {/* Recommendation List Table */}
            <RecommendationListTable componentTypeId={componentTypeId} recCodeList={recos || []} />

            {/* Component Thermal Images */}
            <ComponentThermalImages componentFiles={componentFiles} />

            {/* Remarks Editor */}
            <RemarksEditor reportRemark={reportRemark} componentId={id} />
          </React.Fragment>
        );
      })}
    </>
  );
}
