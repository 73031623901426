import * as yup from "yup";

// Utils
import { generateYears } from "../utils/DateUtils";

// Validation Schema
export const ThermalBillFormValidationSchema = yup.object({
  month: yup.string().required("Please select month !!"),
  year: yup.string().required("Please select year !!"),
  materialId: yup.string().required("Please select material !!"),
  fuelGCV: yup.string().required("Please fill the fuel GCV !!"),
  unitsConsumed: yup.string().required("Please fill the units consumed !!"),
  unitCost: yup.string().required("Please fill the unit cost !!"),
});

// Form Inputs
export const ThermalBillFormSchema = [
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Year",
        key: "year",
        type: "select",
        options: generateYears(2020),
        placeholder: "Enter year...",
        isMandotary: true,
      },
      {
        label: "Month",
        key: "month",
        type: "select",
        options: [...Array(12).fill(null)].map((_, index) => ({ value: index + 1 })), // For 12 months
        placeholder: "Select month...",
        isMandotary: true,
      },
      {
        label: "Material",
        key: "materialId",
        type: "select",
        options: [],
        placeholder: "Select Material...",
        isMandotary: true,
      },
      {
        label: "Units Consumed",
        key: "unitsConsumed",
        placeholder: "Enter units consumed...",
        isMandotary: true,
      },
      {
        label: "Fuel GCV",
        key: "fuelGCV",
        placeholder: "Enter fuel GCV...",
        isMandotary: true,
      },

      {
        label: "Unit Cost",
        key: "unitCost",
        placeholder: "Enter unit cost...",
        isMandotary: true,
      },
    ],
  },
];
