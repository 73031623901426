import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get material list
  materialList: [],
  materialListPagination: {},
  materialListLoading: false,
  materialListError: "",
};

// Material List Slice
const materialListSlice = createSlice({
  name: "MaterialList",
  initialState,
  reducers: {
    // Get Material List
    getMaterialList: (state) => {
      state.materialListLoading = true;
    },

    getMaterialListSuccess: (state, action) => {
      state.materialListLoading = false;
      state.materialList = action.payload.materialList;
      state.materialListPagination = action.payload.materialListPagination;
    },

    getMaterialListFailure: (state, action) => {
      state.materialListLoading = false;
      state.materialListError = action.payload.error;
    },
  },
});

// Reducer
export const MaterialListReducer = materialListSlice.reducer;

// Actions
export const MaterialListActions = materialListSlice.actions;
