import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { PlantThermalBillActions } from "../../../../redux-slice/plant/ThermalBillSlice";

// Constants
import { ModalKeys } from "../../../../constants/ModalConstants";

// Utils
import { getMonthName } from "../../../../utils/DateUtils";
import tableSerialNumber from "../../../../utils/TableUtils";

// Components
import { Button } from "../../../../components/button/Button";
import TableHeaders from "../../../../components/table/TableHeader";
import TablePagination from "../../../../components/table/TablePagination";
import TableLoader from "../../../../components/table/TableLoader";
import TableDataNotFound from "../../../../components/table/TableDataNotFound";

// Sections
import PlantBillsTableActionButton from "../PlantBillsTableActionButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Material Name",
    },
  },
  {
    title: {
      displayName: "Fuel GCV",
    },
  },
  {
    title: {
      displayName: "Units Consumed",
    },
  },
  {
    title: {
      displayName: "Unit Cost",
    },
  },
  {
    title: {
      displayName: "Total Cost",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ThermalBillListTableActionCell({ setSelectedThermalBill, thermalBill = {}, setShowModal = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Upload Thermal Bill Selector State
  const uploadPlantThermalBillLoading = useSelector((state) => state.plantThermalBill.uploadPlantThermalBillLoading);

  // Download Thermal Bill Selector State
  const downloadPlantThermalBillLoading = useSelector(
    (state) => state.plantThermalBill.downloadPlantThermalBillLoading
  );

  // Modal Id
  const modalId = ModalKeys.viewPlantThermalBillModal;

  // Thermal Bill Information
  const { id: thermalBillId = "", fileUrl = "" } = thermalBill;

  // Checking whether file is present or not
  const isFilePresent = fileUrl || "";

  // File Loading Status
  const fileUploadLoadingStatus = uploadPlantThermalBillLoading[thermalBillId];
  const fileDownloadLoadingStatus = downloadPlantThermalBillLoading[thermalBillId];

  // After selecting the file dispatch is called
  function uploadFile(file) {
    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    dispatch(PlantThermalBillActions.uploadPlantThermalBill({ formData, plantId, thermalBillId }));
  }

  // Download Thermal Bill Function
  function downloadPlantThermalBill() {
    dispatch(PlantThermalBillActions.downloadPlantThermalBill({ thermalBillId, apiUrl: fileUrl }));
  }

  // Open Thermal Bill Edit Modal Function
  function openThermalBillEditModal() {
    setSelectedThermalBill(thermalBill);
    setShowModal(true);
  }

  return (
    <td>
      {/* Button */}
      <Button
        label="View"
        size="sm"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
        onClick={openThermalBillEditModal}
      >
        <i className="fa fa-eye me-2" />
      </Button>

      {/* Plant Bills Table Action Button */}
      <PlantBillsTableActionButton
        uploadFile={uploadFile}
        isFilePresent={isFilePresent}
        fileUploadLoadingStatus={fileUploadLoadingStatus}
      />

      {/* Download Button */}
      {isFilePresent && (
        <Button
          label="Download"
          color="dark"
          size="sm"
          onClick={downloadPlantThermalBill}
          loading={fileDownloadLoadingStatus}
          disabled={fileDownloadLoadingStatus}
        >
          <i className="fa fa-download" />
        </Button>
      )}
    </td>
  );
}

function ThermalBillListTableRow({ thermalBill, serialNo, setSelectedThermalBill, setShowModal = () => {} }) {
  const {
    year = "",
    month = "",
    materialName = "",
    fuelGCV = "",
    unitsConsumed = "",
    unitCost = "",
    totalCost = "",
  } = thermalBill || {};

  return (
    <tr>
      <td>{serialNo}</td>

      <td>{`${getMonthName(month)} , ${year}`}</td>

      <td>{materialName}</td>
      <td>{fuelGCV}</td>
      <td>{unitsConsumed}</td>
      <td>{unitCost}</td>
      <td>{totalCost}</td>

      {/* Thermal Bill List Table Action Cell */}
      <ThermalBillListTableActionCell
        setSelectedThermalBill={setSelectedThermalBill}
        thermalBill={thermalBill}
        setShowModal={setShowModal}
      />
    </tr>
  );
}

function ThermalBillListTableBody({
  plantThermalBillListLoading,
  materialListLoading,
  plantThermalBillList,
  setSelectedThermalBill,
  pageNumber,
  pageSize,
  setShowModal = () => {},
}) {
  // Loader
  if (plantThermalBillListLoading || materialListLoading) {
    return <TableLoader colSpan={6} />;
  }

  // If there is no thermal bill list
  if (plantThermalBillList.length === 0) {
    return <TableDataNotFound message="No thermal bills present" colSpan={6} />;
  }

  // Thermal bill list table row
  return (
    <>
      {plantThermalBillList.map((thermalBill, index) => {
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <ThermalBillListTableRow
            key={thermalBill.id}
            thermalBill={thermalBill}
            serialNo={serialNo}
            setSelectedThermalBill={setSelectedThermalBill}
            setShowModal={setShowModal}
          />
        );
      })}
    </>
  );
}

/**
 * Plant Thermal Bill List Table
 */
export default function PlantThermalBillListTable({ setSelectedThermalBill, setShowModal = () => {} }) {
  // Thermal Bill Selector State
  const plantThermalBillList = useSelector((state) => state.plantThermalBillList.plantThermalBillList);
  const plantThermalBillListLoading = useSelector((state) => state.plantThermalBillList.plantThermalBillListLoading);
  const plantThermalBillListPagination = useSelector(
    (state) => state.plantThermalBillList.plantThermalBillListPagination
  );

  // Material List Selector State
  const materialListLoading = useSelector((state) => state.materialList.materialListLoading);

  const { totalCount, pageNo, pageSize } = plantThermalBillListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Thermal Bill List Table Body */}
          <ThermalBillListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            materialListLoading={materialListLoading}
            plantThermalBillListLoading={plantThermalBillListLoading}
            plantThermalBillList={plantThermalBillList}
            setSelectedThermalBill={setSelectedThermalBill}
            setShowModal={setShowModal}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
