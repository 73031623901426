/**
 * Report Info Page
 * @param {*} reportNo : Report Number
 * @param {*} fromDate : From Date
 * @param {*} toDate : To Date
 * @param {*} plantInfo : Plant Information (object)
 * @param {*} auditors : Auditors (array)
 */
export default function ReportInfoPage({
  reportNo = "",
  fromDate = "",
  toDate = "",
  reportDate = "",
  plantInfo = {},
  auditors = [],
}) {
  // Plant Infomation
  const {
    name = "",
    addrLine1 = "",
    addrLine2 = "",
    city = "",
    state = "",
    pincode = "",
    principalActivity = "",
  } = plantInfo;

  const plantAddress = `${addrLine1} ${addrLine2}, ${city}, ${state} - ${pincode}`;

  return (
    <section className="text-center">
      <div className="fw-semibold fs-5">ENERGY ASSESSMENT & SUSTAINABILITY STUDY REPORT</div>
      <div className="fst-italic my-5 fw-semibold">prepared by</div>
      <div className="fw-semibold fs-5">Kotak - IIT Madras Save Energy Mission (KISEM)</div>
      <div className="mt-3">(Department of Applied Mechanics)</div>
      <div className="fs-5 my-4">
        <span className="fw-semibold">Indian Institute of Technology, Madras</span> <br />
        Chennai - 600036
      </div>
      <div className="fw-semibold my-4">Report Number: {reportNo}</div>
      <div className="my-4">
        Assessment Date: {fromDate} to {toDate}
      </div>

      {/* Plant Details */}
      <div className="my-4">
        <span className="fw-semibold fs-5">Plant: {name}</span> <br />
        Location: {plantAddress}
      </div>

      <div className="fw-semibold my-4">Plant Principal Activity: {principalActivity}</div>

      {/* Report Date */}
      <div className="my-4">Report Date: {reportDate}</div>

      {/* Auditors */}
      <div className="fw-semibold">ASSESSMENT PARTICIPANTS</div>
      <div className="mb-4">
        {auditors.map(({ fullName = "" }, idx) => (
          <div key={idx}>{fullName}</div>
        ))}
      </div>

      {/* Contact Details */}
      <div className="fw-semibold">
        Contact :{" "}
        <span>
          <a href="mailto:ieac2@icsrpis.iitm.ac.in">ieac2@icsrpis.iitm.ac.in</a>
        </span>
      </div>
    </section>
  );
}
