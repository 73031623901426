import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions

// Components
import { Button, OutlineButton } from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import { PlantComponentActions } from "../../../redux-slice/plant/ComponentSlice";

/**
 * Add Plant Component Image
 * @param {*} plantId
 * @param {*} componentId
 * @param {*} componentTypeId
 */
export default function AddPlantComponentImage({
  plantId = "",
  componentId = "",
  componentTypeId = "",
  showModal = false,
}) {
  // Dispatch
  const disptach = useDispatch();

  // State
  const [selectedImage, setSelectedImage] = useState({ src: "", file: "" });
  const [error, setError] = useState("");

  const fileInputRef = useRef(null);

  // Selector State
  const uploadPlantComponentImageLoading = useSelector(
    (state) => state.plantComponent.uploadPlantComponentImageLoading
  );

  const { src = "", file = "" } = selectedImage || {};

  // Handle Change Function
  function handleChange({ target }) {
    const file = target.files[0];

    const src = URL.createObjectURL(file);

    setSelectedImage({ src, file });
  }

  // Clear Function
  function clear() {
    setSelectedImage({});
    setError("");
  }

  // Handle Submit Function
  function handleSubmit() {
    if (!file?.name || !file?.type) {
      setError("Please Select a image !!!");
      return;
    }

    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    disptach(PlantComponentActions.uploadPlantComponentImage({ componentTypeId, componentId, plantId, formData }));
  }

  // Reset Form Modal Function
  function resetFormModal() {
    // return if show modal state is true
    if (showModal) {
      return;
    }

    // reset the form to default values
    setSelectedImage({ src: "", file: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }

  useEffect(() => {
    // Function call
    resetFormModal();
  }, [showModal]);

  return (
    <>
      <div className="row">
        <div className="col-7">
          {/* Image Input */}
          <Input
            inputRef={fileInputRef}
            type="file"
            accept="image/*"
            className="input-search"
            onChange={handleChange}
            error={error}
          />
        </div>
        <div className="col-5">
          {/* Clear Button */}
          <OutlineButton label="clear" onClick={clear} disabled={uploadPlantComponentImageLoading} />

          {/* Submit Button */}
          <Button
            label="submit"
            className="ms-3"
            color="success"
            onClick={handleSubmit}
            loading={uploadPlantComponentImageLoading}
            disabled={uploadPlantComponentImageLoading}
          />
        </div>
      </div>

      <h5 className="my-3">Preview :</h5>

      {!src && <div>Please select a image for preview</div>}

      <img id="frame" alt="" className="img-fluid" src={src} />
    </>
  );
}
