import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get plant list
  plantList: [],
  plantListPagination: {},
  plantListLoading: false,
  plantListError: "",
};

// Plant List Slice
const plantListSlice = createSlice({
  name: "PlantList",
  initialState,
  reducers: {
    // Get Plant List
    getPlantList: (state) => {
      state.plantListLoading = true;
    },
    getPlantListSuccess: (state, action) => {
      state.plantListLoading = false;
      state.plantList = action.payload.plantList;
      state.plantListPagination = action.payload.plantListPagination;
    },
    getPlantListFailure: (state, action) => {
      state.plantListLoading = false;
      state.plantListError = action.payload.error;
    },
  },
});

// Reducer
export const PlantListReducer = plantListSlice.reducer;

// Actions
export const PlantListActions = plantListSlice.actions;
