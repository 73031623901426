// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getMaterialListUrl } from "../../urls/api-urls/MaterialURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Material List
export async function getMaterialListApi(pageNumber, pageSize, searchText, type) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  if (type) {
    params.type = type;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getMaterialListUrl, params);
}
