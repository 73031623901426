// Urls
import {
  getMeasurementTypeInfoByIdUrl,
  updateMeasurementTypeStatusByIdUrl,
} from "../../urls/api-urls/MeasurementTypeURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Get Measurement Type Info
export async function getMeasurementTypeInfoByIdApi(measurementTypeId) {
  const url = URLs.format(getMeasurementTypeInfoByIdUrl, { measurementTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update Measurement Type status
export async function updateMeasurementTypeStatusByIdApi(measurementTypeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateMeasurementTypeStatusByIdUrl, { measurementTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
