import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Role Information
  roleInfo: {},
  roleInfoLoading: false,
  roleInfoError: "",

  // Create Role
  createRoleLoading: false,
  createRoleError: "",

  // Update Role
  updateRoleLoading: false,
  updateRoleError: "",
};

// Role Slice
const roleSlice = createSlice({
  name: "RoleSlice",
  initialState,
  reducers: {
    // Get Role Information
    getRoleInfo: (state) => {
      state.roleInfoLoading = true;
    },

    getRoleInfoSuccess: (state, action) => {
      state.roleInfoLoading = false;
      state.roleInfo = action.payload.roleInfo;
    },

    getRoleInfoFailure: (state, action) => {
      state.roleInfoLoading = false;
      state.roleInfoError = action.payload.error;
    },

    // Create Role
    createRole: (state) => {
      state.createRoleLoading = true;
    },

    createRoleSuccess: (state) => {
      state.createRoleLoading = false;
    },

    createRoleFailure: (state, action) => {
      state.createRoleLoading = false;
      state.createRoleError = action.payload.error;
    },

    // Update Role
    updateRole: (state) => {
      state.updateRoleLoading = true;
    },

    updateRoleSuccess: (state) => {
      state.updateRoleLoading = false;
    },

    updateRoleFailure: (state, action) => {
      state.updateRoleLoading = false;
      state.updateRoleError = action.payload.error;
    },
  },
});

// Reducer
export const RoleReducer = roleSlice.reducer;

// Actions
export const RoleActions = roleSlice.actions;
