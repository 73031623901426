import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

//Components
import Input from "../input/Input";

//Page Components
function SelectOptions({ options }) {
  return (
    <>
      {options?.map((option, index) => {
        const { value = "" } = option || {};

        return <option key={index}>{value}</option>;
      })}
    </>
  );
}

/**
 * Auto Complete
 * @param {*} id : Unique id to link input and datalist
 * @param {*} options : List of options to be displayed
 * @param {*} value : Default input value
 * @param {*} placeholder
 * @param {*} className
 * @param {*} onChange : Function
 * @param {*} isEdit : Boolean
 * @param {*} loading : Boolean Value
 * @param {*} disabled : Boolean Value
 * @param {*} error : error message to be displayed
 */
export default function AutoComplete({
  id = "",
  value = "",
  options = [],
  placeholder = "Search Text...",
  className = "",
  onChange = () => {},
  isEdit = false,
  loading = false,
  disabled = false,
  error = "",
  ...rest
}) {
  // State
  const [text, setText] = useState("");

  // If its add form it will show placeholder, otherwise it will check in the data for value, if value is not there, it will give "--"
  const placeholderText = isEdit ? "--" : placeholder;

  // use Effect
  useEffect(() => {
    return () => setText("");
  }, []);

  // Function to set input value for auto complete
  // This function is used to set pre defined value passed from outside to auto complete input
  // The value passed from outside can either be id or value of that option
  // If value is matched, value of that option is returned otherwise id is returned
  function setInputText() {
    const { id: selectedId = "", value: selectedValue = "" } =
      options.find((option) => {
        const { id: optionId = "", value: optionValue = "" } = option || {};

        // Checking whether value is present or not
        // If value is present and if value matches with value sent from outside, that option is returned
        if (optionValue && `${optionValue}` === value) {
          return option;
        }

        // Checking whether id is present or not
        // If id is present and if id matches with value sent from outside, that option is returned
        if (optionId && `${optionId}` === value) {
          return option;
        }

        // If nothing is matched empty object is sent
        return {};
      }) || {};

    return selectedValue || selectedId;
  }

  // Function handle change
  function handleChange(e) {
    const searchedText = e.target.value;

    setText(searchedText);

    // Finding whether searched text is present or not
    const { id = "", value = "" } = options.find((option) => `${option.value}` === searchedText) || {};

    // If searched text is not present, value is removed and onchange function is called
    // Value is removed because, user may type correct value at first and then change the value before submitting the form
    // So to update the changed text , value is set to empty string so that form validation can be done
    if (!id && !value) {
      e.target.value = "";
      onChange(e);
      return;
    }

    e.target.value = id || value;
    onChange(e);
  }

  return (
    <>
      {/* Input */}
      <Input
        list={id}
        // On mounting, text will be empty string and it will check whether any value is passed from outside or not
        // If value is passed from outside then that value is displayed for the first time only
        // After that, for every change text state is updated and that value is shown
        value={text || (value && setInputText())}
        className={`shadow-none ${className}`}
        placeholder={placeholderText}
        onChange={handleChange}
        disabled={disabled}
        error={error}
        loading={loading}
        spinnerClassName="spinner-border-sm"
        {...rest}
      />

      <datalist id={id}>
        {/* Select Options */}
        <SelectOptions options={options} />
      </datalist>
    </>
  );
}
