// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getMeasurementTypeListUrl, getMeasurementUnitsByIdUrl } from "../../urls/api-urls/MeasurementTypeURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Measurement Type List
export async function getMeasurementTypeListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getMeasurementTypeListUrl, params);
}

// Get Measurement Units
export async function getMeasurementUnitsListApi(measurementTypeId) {
  const params = {
    measurement_type: measurementTypeId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getMeasurementUnitsByIdUrl, params);
}
