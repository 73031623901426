// Urls
import { updateProductStatusByIdUrl } from "../../urls/api-urls/ProductURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Update Product Status By ID
export async function updateProductStatusByIdApi(productId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateProductStatusByIdUrl, { productId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
