import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { RoleListActions } from "../../../redux-slice/role/RoleListSlice";
import { RoleActions } from "../../../redux-slice/role/RoleSlice";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Loader from "../../../components/loader/Loader";

// Section
import RoleForm from "../RoleForm";

/**
 * Role Edit Page
 */
export default function RoleEditPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { roleId } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Role Info Selector State
  const roleInfo = useSelector((state) => state.role.roleInfo);
  const roleInfoLoading = useSelector((state) => state.role.roleInfoLoading);

  // Privileges List Selector State
  const privilegesListLoading = useSelector((state) => state.roleList.privilegesListLoading);

  // Update Role Selector State
  const updateRoleLoading = useSelector((state) => state.role.updateRoleLoading);

  // Getting Role Name
  const { name = "" } = roleInfo;

  // use Effect
  useEffect(() => {
    dispatch(RoleListActions.getPrivilegesList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(RoleActions.getRoleInfo({ roleId }));
  }, [dispatch, roleId]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={`Edit Role :: ${name}`} />);
  }, [name]);

  // Loader
  if (roleInfoLoading || privilegesListLoading) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      {/* Role Form */}
      <RoleForm
        data={roleInfo}
        roleId={roleId}
        loading={updateRoleLoading}
        dispatchAction={RoleActions.updateRole}
        buttonName="Update Role"
      />
    </div>
  );
}
