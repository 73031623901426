// Components
import TableDataNotFound from "../../../../../components/table/TableDataNotFound";
import TableHeaders from "../../../../../components/table/TableHeader";

// Page Constants
const TABLE_HEADERS = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Designation",
    },
  },
];

// Page Components
function AuditorsInfoPageTableBody({ auditors = [] }) {
  // If there is no auditors
  if (auditors.length === 0) {
    return <TableDataNotFound message="No auditors present" colSpan={16} />;
  }

  return auditors.map((auditor = {}, idx) => {
    const { fullName = "", designation = "" } = auditor;

    return (
      <tr key={idx}>
        <td>{idx + 1}</td>
        <td>{fullName}</td>
        <td>{designation}</td>
      </tr>
    );
  });
}

function AuditorsInfoPageTable({ auditors = [] }) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={TABLE_HEADERS} />

        <tbody>
          {/* Auditors Info Page Table Body */}
          <AuditorsInfoPageTableBody auditors={auditors} />
        </tbody>
      </table>
    </div>
  );
}
/**
 * Auditors Info Page
 * @param {*} auditors : Auditors List (array)
 */
export default function AuditorsInfoPage({ auditors = [] }) {
  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Details of Energy Audit Team</h3>
      <p>
        The following team was involved in the audit process, conducting measurements, collecting data, and carrying out
        field studies.
      </p>

      <div className="fw-semibold my-5">KISEM – IIT Madras Team</div>

      {/* Auditors Info Page Table */}
      <AuditorsInfoPageTable auditors={auditors} />
    </section>
  );
}
