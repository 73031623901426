import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { MaterialListActions } from "../../../redux-slice/material/MaterialListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import MaterialListTable from "./MaterialListTable";

// Page Components
function MaterialListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search materials...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Material List Page
 */
export default function MaterialListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Search Params State
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  //Selector States
  const materialList = useSelector((state) => state.materialList.materialList);
  const materialListLoading = useSelector((state) => state.materialList.materialListLoading);
  const materialListPagination = useSelector((state) => state.materialList.materialListPagination);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(MaterialListActions.getMaterialList({ pageNumber, searchText }));
  }, [dispatch, pageNumber, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="Materials" />);
  }, []);

  return (
    <div className="page-content">
      {/* Material List Table Actions Section */}
      <MaterialListTableActionsSection />

      {/* Material List Table */}
      <MaterialListTable
        materialList={materialList}
        materialListLoading={materialListLoading}
        materialListPagination={materialListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
