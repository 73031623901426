// Components
import TableHeaders from "../../components/table/TableHeader";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Symbol",
    },
  },
  {
    title: {
      displayName: "Round Off Value",
    },
  },
  {
    title: {
      displayName: "Actual Value",
    },
  },
];

// Unit Conversion Funtion
function unitConversion(value = 0, from = {}, to = {}) {
  // Information : From Unit
  const { id, srcOffset, srcMultiplier, destOffset } = from;

  // Information : To Unit
  const { id: toId = "", srcOffset: toSrcOffset, srcMultiplier: toSrcMultiplier, destOffset: toDestOffset } = to;

  const inputValue = value === "" ? 0 : parseFloat(value);

  // if both from and to unit id are same then return the srcValue
  if (id === toId) {
    return inputValue;
  }

  // Converting the source unit to base unit
  const srcToBase = (inputValue + srcOffset) * srcMultiplier - destOffset;

  // Converting the base unit to destination unit
  return (srcToBase + toDestOffset) / toSrcMultiplier - toSrcOffset;
}

// Unit Conversion Tool Table Body Function
function UnitConversionToolTableBody({ unitList = [], fromUnit = {}, srcValue = "" }) {
  return (
    <>
      {unitList.map((unit, index) => {
        // Unit Information
        const { id = "", name = "", symbol = "" } = unit;
        // Unit Conversion Result
        const result = unitConversion(srcValue, fromUnit, unit);

        return (
          <tr key={id}>
            <td>{index + 1}</td>
            <td>{name}</td>
            <td>{symbol}</td>
            <td>{parseFloat(result.toFixed(2))}</td>
            <td>{result}</td>
          </tr>
        );
      })}
    </>
  );
}

/**
 * Unit Conversion Tool Table
 * @param {*} unitList : Array
 * @param {*} fromUnit : Object
 * @param {*} srcValue : String
 * @returns
 */
export default function UnitConversionToolTable({ unitList = [], fromUnit = {}, srcValue = "" }) {
  return (
    <table className="table table-bordered border-end">
      {/* Table Headers */}
      <TableHeaders tableHeaders={tableHeaders} />

      <tbody>
        {/* Unit Conversion Tool Table Body */}
        <UnitConversionToolTableBody unitList={unitList} fromUnit={fromUnit} srcValue={srcValue} />
      </tbody>
    </table>
  );
}
