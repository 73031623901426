import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { MeasurementTypeActions } from "../../redux-slice/measurement-type/MeasurementTypeSlice";
import { MeasurementTypeListActions } from "../../redux-slice/measurement-type/MeasurementTypeListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  getMeasurementTypeInfoByIdApi,
  updateMeasurementTypeStatusByIdApi,
} from "../../api/measurement-type/MeasurementTypeAPI";

import { getMeasurementTypeListApi } from "../../api/measurement-type/MeasurementTypeListAPI";

// Get Measurement Type Info By Id
function* getMeasurementTypeInfoById(action) {
  try {
    const { measurementTypeId } = action.payload;

    const measurementTypeInfo = yield getMeasurementTypeInfoByIdApi(measurementTypeId);

    // Dispatching Action
    yield put(MeasurementTypeActions.getMeasurementTypeInfoSuccess({ measurementTypeInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeActions.getMeasurementTypeInfoFailure({ error }));
  }
}

// Update Measurement Type Status By Id
function* updateMeasurementTypeStatusById(action) {
  try {
    const { measurementTypeId, status, pageNumber, pageSize } = action.payload;

    yield updateMeasurementTypeStatusByIdApi(measurementTypeId, status);

    const { items, pagination } = yield getMeasurementTypeListApi(pageNumber, pageSize);

    // Dispatch to refresh data
    yield put(
      MeasurementTypeListActions.getMeasurementTypeListSuccess({
        measurementTypeList: items,
        measurementTypeListPagination: pagination,
      })
    );

    yield put(MeasurementTypeActions.updateMeasurementTypeStatusSuccess({ measurementTypeId }));

    // Toaster
    toast.success("Measurement Status Updated Successfully");
  } catch (error) {
    const { measurementTypeId } = action.payload;

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeActions.updateMeasurementTypeStatusFailure({ measurementTypeId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(MeasurementTypeActions.getMeasurementTypeInfo.type, getMeasurementTypeInfoById),
    takeEvery(MeasurementTypeActions.updateMeasurementTypeStatus.type, updateMeasurementTypeStatusById),
  ]);
}
