// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getAuditObservationAndRecommendationListByIdUrl } from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";
import URLs from "../../app/utils/URLs";

// Get Audit Observation And Recommendation List
export async function getAuditObsAndRecListApi(auditId, componentId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem: "COMPONENT",
    elem_id: componentId,
  };

  const url = URLs.format(getAuditObservationAndRecommendationListByIdUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}
