/**
 * Loader Component
 * @param {*} containerClassName
 * @param {*} spinnerClassName
 */
export default function Loader({ containerClassName = "", spinnerClassName = "" }) {
  return (
    <span className={`d-flex justify-content-center align-items-center ${containerClassName}`}>
      <span className={`spinner-border ${spinnerClassName}`} role="status"></span>
    </span>
  );
}
