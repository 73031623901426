import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  dataPreLoading: false,
  dataPreLoadError: "",
};

// Data Pre Load Slice
const dataPreLoadSlice = createSlice({
  name: "DataPreLoad",
  initialState,
  reducers: {
    // Data Pre Load
    dataPreLoad: (state) => {
      state.dataPreLoading = true;
    },

    dataPreLoadSuccess: (state) => {
      state.dataPreLoading = false;
    },

    dataPreLoadFailure: (state, action) => {
      state.dataPreLoading = false;
      state.dataPreLoadError = action.payload.error;
    },
  },
});

// Actions
export const DataPreLoadActions = dataPreLoadSlice.actions;

// Reducer
export const DataPreLoadReducer = dataPreLoadSlice.reducer;
