import { createSlice } from "@reduxjs/toolkit";

// Initial Slice
const initialState = {
  // Get Measurement type information
  measurementTypeInfo: {},
  measurementTypeInfoLoading: false,
  measurementTypeInfoError: "",

  // Update Measurement Type Status (Enable/Disable)
  updateMeasurementTypeStatusLoadingMap: {},
  updateMeasurementTypeStatusError: "",
};

// Measurement Type Slice
const measurementTypeSlice = createSlice({
  name: "MeasurementType",
  initialState,
  reducers: {
    // Get Measurement Info
    getMeasurementTypeInfo: (state) => {
      state.measurementTypeInfoLoading = true;
    },

    getMeasurementTypeInfoSuccess: (state, action) => {
      state.measurementTypeInfoLoading = false;
      state.measurementTypeInfo = action.payload.measurementTypeInfo;
    },

    getMeasurementTypeInfoFailure: (state, action) => {
      state.measurementTypeInfoLoading = false;
      state.measurementTypeInfoError = action.payload.error;
    },

    // Update Measurement Type Status (Enable/Disable)
    updateMeasurementTypeStatus: (state, action) => {
      const { measurementTypeId } = action.payload;

      state.updateMeasurementTypeStatusLoadingMap = {
        ...state.updateMeasurementTypeStatusLoadingMap,
        [measurementTypeId]: true,
      };
    },

    updateMeasurementTypeStatusSuccess: (state, action) => {
      const { measurementTypeId } = action.payload;

      //To get previous state
      const clonedUpdateMeasurementTypeStatusLoadingMap = { ...state.updateMeasurementTypeStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateMeasurementTypeStatusLoadingMap[measurementTypeId];

      state.updateMeasurementTypeStatusLoadingMap = clonedUpdateMeasurementTypeStatusLoadingMap;
    },

    updateMeasurementTypeStatusFailure: (state, action) => {
      const { measurementTypeId, error } = action.payload;

      //To get previous state
      const clonedUpdateMeasurementTypeStatusLoadingMap = { ...state.updateMeasurementTypeStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateMeasurementTypeStatusLoadingMap[measurementTypeId];

      state.updateMeasurementTypeStatusLoadingMap = clonedUpdateMeasurementTypeStatusLoadingMap;
      state.updateMeasurementTypeStatusError = error;
    },
  },
});

// Reducer
export const MeasurementTypeReducer = measurementTypeSlice.reducer;

// Actions
export const MeasurementTypeActions = measurementTypeSlice.actions;
