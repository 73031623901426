// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getAuditPlantComponentListByIdUrl } from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Get Audit Plant Component List
export async function getAuditPlantComponentListApi(auditId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: 1000 || DefaultPagination.pageSize, // TODO
  };

  const url = URLs.format(getAuditPlantComponentListByIdUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url, params);
}
