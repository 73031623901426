//CSS
import "./DataPreLoader.scss";

/**
 * Data Pre Loader
 */
export default function DataPreLoader({ title = "" }) {
  return (
    <div className="data-loader d-flex align-items-center justify-content-center vh-100">
      <div>
        <h1 className="loader-name text-center">{title}</h1>
        <div className="loader">
          <div className="loaderBar"></div>
        </div>
      </div>
    </div>
  );
}
