import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get component type List
  componentTypeList: [],
  componentTypeListPagination: {},
  componentTypeListError: "",
  componentTypeListLoading: false,

  // Get Parameter List By Component-Type-Id
  parameterList: [],
  parameterListPagination: {},
  parameterListLoading: false,
  parameterListError: "",

  // Get Recommendation Code List By Component-Type-Id
  recCodeList: [],
  recommendationCodeListPagination: {},
  recommendationCodeListLoading: false,
  recommendationCodeListError: "",

  // Get Observation Code List By Component-Type-Id
  observationCodeList: [],
  observationCodeListPagination: {},
  observationCodeListLoading: false,
  observationCodeListError: "",
};

// Component Type List Slice
const componentTypeListSlice = createSlice({
  name: "ComponentTypeList",
  initialState,
  reducers: {
    // Component type list
    getComponentTypeList: (state) => {
      state.componentTypeListLoading = true;
    },

    getComponentTypeListSuccess: (state, action) => {
      state.componentTypeListLoading = false;
      state.componentTypeList = action.payload.componentTypeList;
      state.componentTypeListPagination = action.payload.componentTypeListPagination;
    },

    getComponentTypeListFailure: (state, action) => {
      state.componentTypeListLoading = false;
      state.componentTypeListError = action.payload.error;
    },

    // Parameter list
    getParameterList: (state) => {
      state.parameterListLoading = true;
    },

    getParameterListSuccess: (state, action) => {
      state.parameterListLoading = false;
      state.parameterList = action.payload.parameterList;
      state.parameterListPagination = action.payload.parameterListPagination;
    },

    getParameterListFailure: (state, action) => {
      state.parameterListLoading = false;
      state.parameterListError = action.payload.error;
    },

    // Recommendation code list
    getRecCodeList: (state) => {
      state.recommendationCodeListLoading = true;
    },

    getRecCodeListSuccess: (state, action) => {
      state.recommendationCodeListLoading = false;
      state.recCodeList = action.payload.recCodeList;
      state.recommendationCodeListPagination = action.payload.recommendationCodeListPagination;
    },

    getRecCodeListFailure: (state, action) => {
      state.recommendationCodeListLoading = false;
      state.recommendationCodeListError = action.payload.error;
    },

    // Observation code list
    getObsCodeList: (state) => {
      state.observationCodeListLoading = true;
    },

    getObsCodeListSuccess: (state, action) => {
      state.observationCodeListLoading = false;
      state.observationCodeList = action.payload.observationCodeList;
      state.observationCodeListPagination = action.payload.observationCodeListPagination;
    },

    getObsCodeListFailure: (state, action) => {
      state.observationCodeListLoading = false;
      state.observationCodeListError = action.payload.error;
    },
  },
});

// Reducer
export const ComponentTypeListReducer = componentTypeListSlice.reducer;

// Actions
export const ComponentTypeListActions = componentTypeListSlice.actions;
