import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditFileListActions } from "../../redux-slice/audit/FileListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getAuditFileListApi } from "../../api/audit/FileListAPI";

// Get Audit File List
function* getAuditFileList(action) {
  try {
    const { auditId, pageNumber, pageSize, type = "" } = action.payload;

    const { items, pagination } = yield getAuditFileListApi(auditId, pageNumber, pageSize, type);

    // Dispatching Action
    yield put(
      AuditFileListActions.getAuditFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileListActions.getAuditFileListFailure({ error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([takeLatest(AuditFileListActions.getAuditFileList.type, getAuditFileList)]);
}
