import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Constants
import { SidebarMenus } from "../../constants/LayoutConstants";

// Utils
import { getOrgInfo, getOrgType, getUserInfo } from "../../utils/AuthOrgUtil";
import URLs from "../../app/utils/URLs";

/**
 * {
 *   itemName: "Some text",
 *   itemIconCls: "far fa-icon",
 *   itemLink: "/link",
 *   childItems: [
 *     {
 *       itemName: "",
 *       itemLink: ""
 *     }
 *   ]
 * }
 */
function MenuItem({ menuItem, authUserInfo, isSidebarCollapsed, selectedMenu, setSelectedMenu }) {
  // State
  const [showSubMenu, setShowSubMenu] = useState(false);

  // Data
  const { itemName, itemIconCls, itemLink, onClick = () => {}, childItems = [] } = menuItem;
  const { orgId = "", userId = "" } = authUserInfo;

  // Item URL
  const menuItemURL = itemLink ? URLs.format(itemLink, { orgId, userId }) : "#";

  // Check if it has sub-menu
  const hasSubMenu = childItems && childItems.length > 0;

  // ClassName for selected menu
  const selectedMenuClassName = selectedMenu === itemName ? "selected-menu" : "";

  // ClassName for item name
  const itemNameClassName = `link-name ${selectedMenuClassName}`;

  function selectMenu(menuName) {
    setSelectedMenu(menuName);
  }

  function handleClick(itemName) {
    selectMenu(itemName);
    onClick();
  }

  return (
    <li className={`menu-items ${showSubMenu ? "show-sub-menu" : ""}`}>
      <div className="menu-link" onClick={() => setShowSubMenu((prev) => !prev)}>
        <Link to={menuItemURL} className={`menu-link`} onClick={() => handleClick(itemName)}>
          <i className={`menu-icons ${itemIconCls} ${selectedMenuClassName}`}></i>
          <span className={`${itemNameClassName} ${!isSidebarCollapsed && "d-none"}`}>{itemName}</span>
        </Link>

        {hasSubMenu && (
          <i className={`menu-icons arrow fa-solid fa-chevron-down fa-2xs ${!isSidebarCollapsed && "d-none"}`} />
        )}
      </div>
      <ul className={`sub-menu ${showSubMenu ? "d-block" : ""}`}>
        <li>
          <Link
            to={menuItemURL}
            className={`${itemNameClassName} ${isSidebarCollapsed && "d-none"} `}
            onClick={() => handleClick(itemName)}
          >
            {itemName}
          </Link>
        </li>

        {childItems.map((childItem, index) => {
          const childItemName = childItem["itemName"];
          const childItemLink = childItem["itemLink"];
          const childItemURL = childItemLink ? URLs.format(childItemLink, { orgId }) : "#";

          return (
            <li key={index} className="mb-2">
              <Link to={childItemURL}>{childItemName}</Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

/**
 * {
 *   title: "Some text",
 *   items: []
 * }
 */
export function Menu({
  menu,
  index,
  isSidebarCollapsed,
  authUserInfo,
  selectedMenu,
  setSelectedMenu,
  backButtonConfig,
}) {
  // Navigate
  const navigate = useNavigate();

  const { title = "", items = [] } = menu || {};

  // Back button config
  const {
    link = "",
    menuName = "Back",
    containerClassName = "",
    iconClassName = "fa-solid fa-chevron-left fa-sm",
  } = backButtonConfig || {};

  // Back button is present only for first menu
  const isBackBtnPresent = link && index === 0;

  return (
    <>
      {/* Back Button */}
      {isBackBtnPresent && (
        <div className="cursor-pointer menu-title pe-auto text-white fs-5" onClick={() => navigate(link)}>
          <i className={`me-2 ${iconClassName}`}></i>
          <span className={isSidebarCollapsed ? "" : "d-none"}>{menuName}</span>
        </div>
      )}

      {/* Menu Title */}
      <li className="menu-title fs-5 text-white" key="t-menu">
        {isSidebarCollapsed ? title : "-"}
      </li>

      {/* Menu Items */}
      {items.map((menuItem, index) => {
        return (
          <MenuItem
            key={index}
            menuItem={menuItem}
            authUserInfo={authUserInfo}
            isSidebarCollapsed={isSidebarCollapsed}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
        );
      })}
    </>
  );
}

/**
 * Sidebar Menus
 * @param {*} sidebarMenuList : Array of menus
 * @param {*} backButtonConfig : Object
 * @param {*} defaultSelectedMenu : String
 * @param {*} showSideBar : Boolean Value
 */
export default function SidebarMenu({
  sidebarMenuList = [],
  backButtonConfig = {},
  defaultSelectedMenu = "",
  showSideBar = false,
}) {
  // State
  const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu);

  // Authenticated User Info
  const authUserInfo = getUserInfo();
  const authOrgType = getOrgType();

  // Menus
  const menuList = sidebarMenuList.length !== 0 ? sidebarMenuList : SidebarMenus[authOrgType] || [];

  return (
    <ul className={`nav-links ${showSideBar ? "" : "overflow-hidden"}`}>
      {/* Itetrate over Menu group list */}
      {menuList.map((menuGroup, idx) => {
        return (
          <Menu
            key={idx}
            index={idx}
            menu={menuGroup}
            isSidebarCollapsed={showSideBar}
            authUserInfo={authUserInfo}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            backButtonConfig={backButtonConfig}
          />
        );
      })}
    </ul>
  );
}
