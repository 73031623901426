import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditObservationAndRecommendationListActions } from "../../redux-slice/audit/ObservationAndRecommendationListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getAuditObsAndRecListApi } from "../../api/audit/ObservationAndRecommendationListAPI ";

// Get Audit Observation And Recommendation List
function* getAuditObsAndRecList(action) {
  try {
    const { auditId, componentId, pageNumber, pageSize } = action.payload;

    const { items, pagination } = yield getAuditObsAndRecListApi(auditId, componentId, pageNumber, pageSize);

    // Dispatching Action
    yield put(
      AuditObservationAndRecommendationListActions.getAuditObsAndRecListSuccess({
        auditObsAndRecList: items,
        auditObsAndRecListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditObservationAndRecommendationListActions.getAuditObsAndRecListFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditObservationAndRecommendationListActions.getAuditObsAndRecList.type, getAuditObsAndRecList),
  ]);
}
