import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Create plant electricty bill
  createPlantElectricityBillLoading: false,
  createPlantElectricityBillError: "",

  // Update plant electricty bill
  updatePlantElectricityBillLoading: false,
  updatePlantElectricityBillError: "",

  // Upload plant electricty bill
  uploadPlantElectricityBillLoading: {},
  uploadPlantElectrictyBillError: "",

  // Download plant electricty bill
  downloadPlantElectricityBillLoading: {},
  downloadPlantElectrictyBillError: "",
};

// Plant Electricity Bill Slice
const plantElectricityBill = createSlice({
  name: "PlantElectricityBill",
  initialState,
  reducers: {
    // Create plant electricty bill
    createPlantElectricityBill: (state) => {
      state.createPlantElectricityBillLoading = true;
    },

    createPlantElectricityBillSuccess: (state) => {
      state.createPlantElectricityBillLoading = false;
    },

    createPlantElectricityBillFailure: (state, action) => {
      state.createPlantElectricityBillLoading = false;
      state.createPlantElectricityBillError = action.payload.error;
    },

    // Update plant electricty bill
    updatePlantElectricityBill: (state) => {
      state.updatePlantElectricityBillLoading = true;
    },

    updatePlantElectricityBillSuccess: (state) => {
      state.updatePlantElectricityBillLoading = false;
    },

    updatePlantElectricityBillFailure: (state, action) => {
      state.updatePlantElectricityBillLoading = false;
      state.updatePlantElectricityBillError = action.payload.error;
    },

    // Upload plant electricty bill
    uploadPlantElectricityBill: (state, action) => {
      const { electricityBillId } = action.payload;

      state.uploadPlantElectricityBillLoading = {
        ...state.uploadPlantElectricityBillLoading,
        [electricityBillId]: true,
      };
    },

    uploadPlantElectricityBillSuccess: (state, action) => {
      const { electricityBillId } = action.payload;

      // To get previous state
      const clonedUploadPlantElectricityBillLoading = { ...state.uploadPlantElectricityBillLoading };

      // To remove the id from state after successfull loading
      delete clonedUploadPlantElectricityBillLoading[electricityBillId];

      state.uploadPlantElectricityBillLoading = clonedUploadPlantElectricityBillLoading;
    },

    uploadPlantElectricityBillFailure: (state, action) => {
      const { electricityBillId, error } = action.payload;

      // To get previous state
      const clonedUploadPlantElectricityBillLoading = { ...state.uploadPlantElectricityBillLoading };

      // To remove the id from state after successfull loading
      delete clonedUploadPlantElectricityBillLoading[electricityBillId];

      state.uploadPlantElectricityBillLoading = clonedUploadPlantElectricityBillLoading;
      state.uploadPlantElectrictyBillError = error;
    },

    // Download plant electricty bill
    downloadPlantElectricityBill: (state, action) => {
      const { electricityBillId = "" } = action.payload;

      state.downloadPlantElectricityBillLoading = {
        ...state.downloadPlantElectricityBillLoading,
        [electricityBillId]: true,
      };
    },

    downloadPlantElectricityBillSuccess: (state, action) => {
      const { electricityBillId = "" } = action.payload;

      // To get previous state
      const clonedDownloadPlantElectricityBillLoading = { ...state.downloadPlantElectricityBillLoading };

      // To remove the id from state after successfull loading
      delete clonedDownloadPlantElectricityBillLoading[electricityBillId];

      state.downloadPlantElectricityBillLoading = clonedDownloadPlantElectricityBillLoading;
    },

    downloadPlantElectricityBillFailure: (state, action) => {
      const { electricityBillId = "", error } = action.payload;

      // To get previous state
      const clonedDownloadPlantElectricityBillLoading = { ...state.downloadPlantElectricityBillLoading };

      // To remove the id from state after successfull loading
      delete clonedDownloadPlantElectricityBillLoading[electricityBillId];

      state.downloadPlantElectricityBillLoading = clonedDownloadPlantElectricityBillLoading;
      state.uploadPlantElectrictyBillError = error;
    },
  },
});

// Reducer
export const PlantElectricityBillReducer = plantElectricityBill.reducer;

// Actions
export const PlantElectricityBillActions = plantElectricityBill.actions;
