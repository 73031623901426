import { useSelector } from "react-redux";

// Components
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Symbol",
    },
  },
  {
    title: {
      displayName: "Short Form",
    },
  },
  {
    title: {
      displayName: "isBase",
    },
  },
  {
    title: {
      displayName: "Src Offset",
    },
  },
  {
    title: {
      displayName: "Src Multiplier",
    },
  },
  {
    title: {
      displayName: "Dest Offset",
    },
  },
];

// Page Components
function MeasurementUnitsTableRow({ measurementUnit = {} }) {
  // Data
  const {
    name = "",
    symbol = "",
    shortForm = "",
    isBase = false,
    srcOffset = "",
    srcMultiplier = "",
    destOffset = "",
  } = measurementUnit;

  return (
    <tr>
      <td>{name}</td>
      <td>{symbol}</td>
      <td>{shortForm}</td>
      <td>{isBase ? "YES" : "NO"}</td>
      <td>{srcOffset}</td>
      <td>{srcMultiplier}</td>
      <td>{destOffset}</td>
    </tr>
  );
}

function MeasurementUnitsTableBody() {
  // Measurement Units Selector State
  const measurementUnitsList = useSelector((state) => state.measurementTypeList.measurementUnitsList);
  const measurementUnitsListLoading = useSelector((state) => state.measurementTypeList.measurementUnitsListLoading);

  // Loader
  if (measurementUnitsListLoading) {
    return <TableLoader colSpan={8} />;
  }

  // If there is no measurement units
  if (measurementUnitsList.length === 0) {
    return <TableDataNotFound colSpan={8} message="No measurement units found" />;
  }

  // Measurement units table row
  return (
    <>
      {measurementUnitsList.map((measurementUnit) => (
        <MeasurementUnitsTableRow key={measurementUnit.id} measurementUnit={measurementUnit} />
      ))}
    </>
  );
}

/**
 * Measurement Units Table
 */
export default function MeasurementUnitsTable() {
  return (
    <table className="table table-bordered border-secondary-subtle mt-3 border-end">
      {/* Table Headers */}
      <TableHeaders tableHeaders={tableHeaders} />

      <tbody>
        {/* Measurement Units Table Body */}
        <MeasurementUnitsTableBody />
      </tbody>
    </table>
  );
}
