export const inputType = {
  select: "select",
  autoComplete: "auto-complete",
  booleanSelect: "boolean-select",
  date: "date",
  textarea: "textarea",
};

export const booleanSelectOptions = [
  { id: "true", value: "YES" },
  { id: "false", value: "NO" },
];

// For date picker styles
export const datePickerStyleObject = {
  buttonClassName: "bg-white form-control",
  border: true,
  showDropdowns: true,
};

// Form Type constants
export const FormTypes = {
  EDIT: "Edit",
  CREATE: "Create",
};

// Boolean Options Value
export const booleanOptionsValue = { true: "Yes", false: "No" };
