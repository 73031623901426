import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { JobActions } from "../../redux-slice/job/JobSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  getJobInfoByIdApi,
  getJobSessionByIdApi,
  runJobApi,
  resumeJobByIdApi,
  pauseJobByIdApi,
} from "../../api/job/JobAPI";

// Get Job Info By Id
function* getJobInfoById(action) {
  try {
    const { jobId } = action.payload;

    const jobInfo = yield getJobInfoByIdApi(jobId);

    // Dispatching Action
    yield put(JobActions.getJobInfoSuccess({ jobInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.getJobInfoFailure({ error }));
  }
}

// Get Job Session By Id
function* getJobSessionById(action) {
  try {
    const { jobId } = action.payload;

    const { items } = yield getJobSessionByIdApi(jobId);

    // Dispatching Action
    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.getJobSessionFailure({ error }));
  }
}

// Run Job
function* runJob(action) {
  try {
    const { job, jobId } = action.payload;

    yield runJobApi(job, jobId);

    const jobInfo = yield getJobInfoByIdApi(jobId);

    const { items } = yield getJobSessionByIdApi(jobId);

    // Dispatching Action to refresh job info table
    yield put(JobActions.getJobInfoSuccess({ jobInfo }));

    // Dispatching Action to refresh job sessions table
    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));

    // Dispatching Action
    yield put(JobActions.runJobSuccess());

    // Toaster
    toast.success("Run Job Success");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.runJobFailure());
  }
}

// Resume Job By Id
function* resumeJobById(action) {
  try {
    const { jobId } = action.payload;

    yield resumeJobByIdApi(jobId);

    const jobInfo = yield getJobInfoByIdApi(jobId);

    const { items } = yield getJobSessionByIdApi(jobId);

    // Dispatching Action to refresh job info table
    yield put(JobActions.getJobInfoSuccess({ jobInfo }));

    // Dispatching Action to refresh job sessions table
    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));

    // Dispatching Action to change active status
    yield put(JobActions.resumeJobSuccess());

    // Toaster
    toast.success("Resume Job Success");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.resumeJobFailure());
  }
}

// Pause Job By Id
function* pauseJobById(action) {
  try {
    const { jobId } = action.payload;

    yield pauseJobByIdApi(jobId);

    const jobInfo = yield getJobInfoByIdApi(jobId);

    const { items } = yield getJobSessionByIdApi(jobId);

    // Dispatching Action to refresh job info table
    yield put(JobActions.getJobInfoSuccess({ jobInfo }));

    // Dispatching Action to refresh job sessions table
    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));

    // Dispatching Action to change active status
    yield put(JobActions.pauseJobSuccess());

    // Toaster
    toast.success("Pause Job Success");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.pauseJobFailure());
  }
}

export default function* root() {
  yield all([
    takeLatest(JobActions.getJobInfo.type, getJobInfoById),
    takeLatest(JobActions.getJobSession.type, getJobSessionById),
    takeLatest(JobActions.runJob.type, runJob),
    takeLatest(JobActions.resumeJob.type, resumeJobById),
    takeLatest(JobActions.pauseJob.type, pauseJobById),
  ]);
}
