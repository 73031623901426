import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Actions
import { AuditComponentActions } from "../../redux-slice/audit/ComponentSlice";
import { AuditParameterValuesActions } from "../../redux-slice/audit/ParameterValuesSlice";
import { AuditObservationAndRecommendationListActions } from "../../redux-slice/audit/ObservationAndRecommendationListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { upsertParameterValuesApi } from "../../api/audit/ParameterValueAPI";

import { getAuditComponetInfoApi } from "../../api/audit/ComponentAPI";

import { getAuditObsAndRecListApi } from "../../api/audit/ObservationAndRecommendationListAPI ";

// Upsert Parameter Values
function* upsertParameterValues(action) {
  try {
    const { parameterValuesData, auditId, componentId, pageNumber = "", pageSize = "", type = "" } = action.payload;

    yield upsertParameterValuesApi(parameterValuesData, auditId, componentId);

    const auditComponentInfo = yield getAuditComponetInfoApi(auditId, componentId);

    if (type === "savings") {
      const { items, pagination } = yield getAuditObsAndRecListApi(auditId, componentId, pageNumber, pageSize);

      // Dispatching action to refresh table
      yield put(
        AuditObservationAndRecommendationListActions.getAuditObsAndRecListSuccess({
          auditObsAndRecList: items,
          auditObsAndRecListPagination: pagination,
        })
      );
    }

    // Dispatching Action
    yield put(AuditComponentActions.getAuditComponentInfoSuccess({ auditComponentInfo }));

    // Toaster
    toast.success("Parameters Updated Successfully");

    // Dispatching Action
    yield put(AuditParameterValuesActions.upsertParameterValuesSuccess());
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditParameterValuesActions.upsertParameterValuesFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(AuditParameterValuesActions.upsertParameterValues.type, upsertParameterValues)]);
}
