import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get role list
  roleList: [],
  roleListPagination: {},
  roleListLoading: false,
  roleListError: "",

  // Get privileges list
  privilegesList: [],
  privilegesListLoading: false,
  privilegesListError: "",
};

// Role List Slice
const roleListSlice = createSlice({
  name: "RoleList",
  initialState,
  reducers: {
    // Get role list
    getRoleList: (state) => {
      state.roleListLoading = true;
    },

    getRoleListSuccess: (state, action) => {
      state.roleListLoading = false;
      state.roleList = action.payload.roleList;
      state.roleListPagination = action.payload.roleListPagination;
    },

    getRoleListFailure: (state, action) => {
      state.roleListLoading = false;
      state.roleListError = action.payload.error;
    },

    // Get privileges list
    getPrivilegesList: (state) => {
      state.privilegesListLoading = true;
    },

    getPrivilegesListSuccess: (state, action) => {
      state.privilegesListLoading = false;
      state.privilegesList = action.payload.privilegesList;
    },

    getPrivilegesListFailure: (state, action) => {
      state.privilegesListLoading = false;
      state.privilegesListError = action.payload.error;
    },
  },
});

// Reducer
export const RoleListReducer = roleListSlice.reducer;

// Actions
export const RoleListActions = roleListSlice.actions;
