/**
 * Static Content
 */
export default function StaticContent() {
  return (
    <>
      <section>
        <h3 className="fw-semibold text-center mb-5">ACKNOWLEDGEMENT</h3>
        <p>
          Kotak - IIT Madras Save Energy Mission (KISEM), Indian Institute of Technology (IIT) – Madras express its
          sincere thanks for the initiative, support, involvement and cooperation provided by all members of M/s – ABC
          India Ltd who participated with us in the Energy Assessment activities, in sharing the details as required and
          providing all the valuable inputs required for carrying out energy assessment inclusive of pre-assessment and
          post assessment activities, data analysis and the report generation. <br />
          The Study team is indebted to Mr. ABC for showing keen interest in the study and also thankful to the M/s –
          ABC India Ltd. management for their wholehearted support and cooperation in the preparation of the energy
          audit report, without which the study would not have steered to its successful completion.
        </p>

        <p>We also acknowledge all the transparency and courtesies extended during the assessment activities.</p>

        <p>
          It is well worth mentioning that the efforts being taken, and the enthusiasm shown by all the plant personnel
          towards energy conservation and sustainable growth were admirable. We found all the personnel keen to
          implement the possible energy conservation aspects.
        </p>
      </section>

      <section>
        <h3 className="fw-semibold text-center mb-5">PREFACE</h3>

        <p>
          KISEM – IIT Madras was established in the year 2018 with the motto of "research and technological service to
          the nation towards sustainable energy and resource management". As a part of this, we conduct walk-through and
          detailed energy assessment in typical process industries to explore, evaluate and recommend the hidden
          potentials for reducing their specific energy consumption in all possible ways.
        </p>

        <p>
          TWe have performed energy audits and energy conservation potential studies in various sectors like Textile,
          Iron & Steel, Automotive, Aluminium, FMCG, Rubber, Pulp & Paper, Plastic, Printing, etc.
        </p>

        <p>
          In total we have identified annual savings potential of about 6,73,933 Metric Tonnes of oil equivalent and
          41,190 Tonnes of CO2. The overall monetary savings to the tune of Rs. 15.92 Cr. <br />
          The overall implementation rate of our recommendations by the customer is more than 50% and our post
          assessment follow up with the customers have helped a lot for them to comfortably get into action and witness
          the savings. On an average basis, the identified savings accounts to 15% to 20% of their annual energy bill.
        </p>

        <p>
          KISEM have a team of professors from various domains to guide and evaluate our work. Our assessment team
          comprises of a Bureau of Energy Efficiency (BEE) - certified energy auditor and couple of sector experts to
          carry out energy assessments.
        </p>

        <p>
          The work described in this report was performed by the KISEM – IIT Madras Team. The objective of KISEM is to
          identify, evaluate, and recommend – through analyses of industrial plant operations – opportunities to
          conserve energy, minimize waste, and reduce the overall cost of operations. Our recommendations are based upon
          observations and measurements made at your plant. As our time was limited, we do not claim to have complete
          detail on every aspect of the plant's operations. At all times, we try to offer specific and quantitative
          recommendations of cost savings, energy conservation, and waste minimization of the plants we serve. However,
          we do not attempt to prepare engineering designs or otherwise perform services that you would expect from an
          engineering firm, a vendor, or a manufacturer's representative. When the need for that kind of assistance
          arrives, we urge you to consult them directly. If, however, you would like to discuss the contents of this
          report, or if you have other questions about energy use and/or waste minimization, please feel free to contact
          us.
        </p>
      </section>

      <section>
        <h3 className="fw-semibold text-center mb-5">DISCLAIMER</h3>
        <p>
          The contents of this report are offered as guidance. All technical sources referenced in this reportdo not:
        </p>
        <ol type="a">
          <li>
            Make any warranty or representation, expressed or implied, with respect to the accuracy, completeness, or
            usefulness of the information contained in this report, or that the use of any information, apparatus,
            method, or process disclosed in this report may not infringe on privately owned rights.
          </li>
          <li>
            Make any warranty or representation, expressed or implied, with respect to the accuracy, completeness, or
            usefulness of the information contained in this report, or that the use of any information, apparatus,
            method, or process disclosed in this report may not infringe on privately owned rights.
          </li>
        </ol>
        <p className="fw-semibold">Recommendations:</p>
        <p>
          All the assessment recommendations are described in detail in the respective sections in this report. Further,
          the suggested actions are also indicated. The annual cost savings and implementation costs represent our best
          estimates. You may want to consult other sources to verify these estimates before making a final decision for
          implementing these recommendations. We welcome inquiries and further discussion on any information or data
          contained in this report. As previously discussed, we will contact you in the future regarding the
          implementation of our energy assessment recommendations.
        </p>
        <p className="fw-semibold">Follow-up actions:</p>
        <p>
          It is upon your Management to prioritize and plan to take the necessary steps based on the recommendations and
          observations. IITM will revert to the management once it is confirmed that the suggested recommendations are
          implemented. After the implementation, the management will observe and record the energy usages and details
          that are required to assess the savings for a period of three months, and the same will be reviewed by IITM.
          In case of any major discrepancies, IITM will analyse the implementation, review the energy saving
          calculations, and suggest any further action based on the findings. Depending upon management’s decision, IITM
          will discuss and plan for further re-assessment as and when it may be required.
        </p>
      </section>

      <section>
        <h3 className="text-center fw-semibold mb-5">CERTIFICATION</h3>
        <p>
          This is to certify that the data collection has been carried out diligently and truthfully; All data
          monitoring devices are in good working condition and have been calibrated or certified by approved agencies
          authorized and no tampering of such devices has occurred; All reasonable professional skill, care and
          diligence had been taken in preparing the energy audit report and the contents thereof are a true
          representation of the facts; Adequate training provided to personnel involved in daily operations after
          implementation of recommendations; and The energy audit has been carried out in accordance with the Bureau of
          Energy Efficiency (Manner and Intervals of Time for the Conduct of Energy Audit) Regulations, 2010.
        </p>
      </section>
    </>
  );
}
