import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// Actions
import { AuditComponentActions } from "../../../../redux-slice/audit/ComponentSlice";

// Utils
import { constructParametersFormInputs } from "../../AuditUtils";

// Components
import { Button } from "../../../../components/button/Button";
import TabularForm from "../../../../components/form/TabularForm";
import Loader from "../../../../components/loader/Loader";

// Section
import ComponentSelect from "../ComponentSelect";
import Input from "../../../../components/input/Input";

// Page Constants
const propertiesTableHeaders = [
  {
    title: {
      displayName: "Design Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Components
function WalkThoughImage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // State
  const [selectedImage, setSelectedImage] = useState({});
  const [error, setError] = useState("");

  // Selector State
  const addWalkthroughImageLoading = useSelector((state) => state.auditComponent.addWalkthroughImageLoading);

  // Audit Component Info Selector State
  const { component: componentInfo = {}, auditComponent = {} } = useSelector(
    (state) => state.auditComponent.auditComponentInfo
  );

  // Getting details of the plant component
  const { id: componentId = "" } = componentInfo;

  // Getting details of the audit component
  const { imageURL = "" } = auditComponent;

  // Select Image Function
  function selectImage({ target }) {
    const file = target.files[0];

    setSelectedImage(file);
  }

  // Upload Image Function
  function uploadImage() {
    // Image Information

    if (!selectedImage?.name || !selectedImage?.type) {
      setError("Please Select a image !!!");
      return;
    } else {
      setError("");
    }

    // Creating form data
    const formData = new FormData();
    formData.append("file", selectedImage);

    // Dispatch
    dispatch(AuditComponentActions.addWalkthroughImage({ auditId, componentId, formData }));
  }

  return (
    <>
      <h4>Image :</h4>

      {!imageURL && <p>No Image is uploaded</p>}

      {/* Image Input */}
      <Input type="file" accept="image/*" onChange={selectImage} error={error} />

      {/* Submit Button */}
      <div className="d-flex justify-content-end ">
        <Button
          label="Upload"
          color="success"
          className="mt-3"
          loading={addWalkthroughImageLoading}
          disabled={addWalkthroughImageLoading}
          onClick={uploadImage}
        />
      </div>

      {imageURL && <img alt="Walkthrough" className="img-fluid w-100 mb-4" src={imageURL} />}
    </>
  );
}

function AddWalkThroughRemarks() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // State
  const [remarks, setRemarks] = useState("");

  // Selector State
  const addWalkThorughRemarksLoading = useSelector((state) => state.auditComponent.addWalkthroughRemarksLoading);

  // Audit Component Info Selector State
  const { component: componentInfo = {}, auditComponent = {} } = useSelector(
    (state) => state.auditComponent.auditComponentInfo
  );

  // Getting details of the plant component
  const { id: componentId = "", componentTypeId = "" } = componentInfo;

  // Getting details of the audit component
  const { notes = "" } = auditComponent;

  // use Effect
  useEffect(() => {
    setRemarks(notes);
  }, [notes]);

  // Add Remarks Function
  function addRemarks() {
    const formData = {
      auditInfoId: parseInt(auditId),
      element: "COMPONENT",
      elementId: componentId,
      elementTypeId: componentTypeId,
      imageURL: "",
      notes: remarks,
    };

    // Dispatch
    dispatch(AuditComponentActions.addWalkthroughRemarks({ auditId, componentId, formData }));
  }

  return (
    <>
      <h4>Walkthough Remarks :</h4>

      {/* Audit Walkthrough Remarks Textarea */}
      <div className="form-floating">
        <textarea
          value={remarks}
          onChange={({ target }) => {
            setRemarks(target.value);
          }}
          className="form-control shadow-none rounded-2"
          placeholder="Remarks"
        />
        <label>Comments</label>
      </div>

      {/* Submit Button */}
      <Button
        label="Submit"
        color="success"
        className="float-end mt-3"
        loading={addWalkThorughRemarksLoading}
        disabled={addWalkThorughRemarksLoading}
        onClick={addRemarks}
      />
    </>
  );
}

function PropertiesTabularForm({ parametersFormInputs = {} }) {
  // Form inputs for property
  const { propertyInputs = [] } = parametersFormInputs;

  return (
    <>
      <h4>Properties</h4>

      {/* Property  */}
      <TabularForm tableHeaders={propertiesTableHeaders} formInputItems={propertyInputs} showSubmitButton={false} />
    </>
  );
}

/**
 * Audit Walkthrough Remarks Page
 */
export default function AuditWalkThroughRemarksPage() {
  // Audit Component Info Selector State
  const auditComponentInfo = useSelector((state) => state.auditComponent.auditComponentInfo);
  const auditComponentInfoLoading = useSelector((state) => state.auditComponent.auditComponentInfoLoading);

  // Getting details of the component
  const { component: componentInfo = {} } = auditComponentInfo || {};
  const { componentTypeId = "", remarks = "" } = componentInfo;

  // Converting audit component info to string
  const auditComponentInfoStr = JSON.stringify(auditComponentInfo);

  // use Memo
  const parametersFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = {
      propertyTable: {
        showUnits: true,
      },
    };

    return constructParametersFormInputs(auditComponentInfo, tableConfig);
  }, [auditComponentInfoStr, componentTypeId]);

  return (
    <div className="page-content row">
      {/* Component Select */}
      <ComponentSelect />

      {/* Loading */}
      {auditComponentInfoLoading && <Loader />}

      {/* If no component is selected */}
      {!componentTypeId && <div>Select a component !!</div>}

      {!auditComponentInfoLoading && componentTypeId && (
        <>
          <div className="col-4">
            {/* Properties Tabular Form */}
            <PropertiesTabularForm
              parametersFormInputs={parametersFormInputs}
              selectedComponentTypeId={componentTypeId}
            />

            <div className="mt-3">
              <h4>Plant Remarks :</h4>

              <div className="card">
                <div className="card-body">{remarks ? remarks : "No remarks given !!"}</div>
              </div>
            </div>
          </div>

          <div className="col-4">
            {/* Add WalkThrough Remarks */}
            <AddWalkThroughRemarks />
          </div>

          <div className="col-4">
            {/* Walk Though Image */}
            <WalkThoughImage />
          </div>
        </>
      )}
    </div>
  );
}
