import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { JobListActions } from "../../redux-slice/job/JobListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getJobListApi } from "../../api/job/JobListAPI";

// Get Job List
function* getJobList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getJobListApi(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(JobListActions.getJobListSuccess({ jobList: items, jobListPagination: pagination }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobListActions.getJobListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(JobListActions.getJobList.type, getJobList)]);
}
