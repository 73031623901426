// Constants
import { DataTypes, DataPreLoadKeys } from "../constants/GeneralConstants";

// Utils
import { getItem } from "../app/LocalStorage";
import { convertStringToBooleanOrNumber } from "./StringUtils";
import DecimalUtils from "./DecimalUtils";
import { booleanOptionsValue } from "../components/form/FormConstants";

// Default Input Items
export const DefaultInputRows = [
  {
    inputItems: [
      {
        label: "Name",
        isMandotary: true,
      },
      {
        key: "name",
        placeholder: "Enter name...",
      },
    ],
  },
  {
    inputItems: [
      {
        label: "Internal Id",
        isMandotary: true,
      },
      {
        key: "internalId",
        placeholder: "Enter internal id...",
      },
    ],
  },
];

// Remark Input
export const RemarksInputRow = {
  inputItems: [
    {
      label: "Remarks",
    },
    {
      key: "remarks",
      type: "textarea",
      placeholder: "Enter remarks...",
    },
  ],
};

// Get Form Input Type
export function getFormInputType(dataValueType = "", isAllowedValuesPresent = false) {
  return (
    (DataTypes.includes(dataValueType) && "number") ||
    (dataValueType === "BOOLEAN" && "boolean-select") ||
    (isAllowedValuesPresent && "select") ||
    "text"
  );
}

// Constructing options for measurements units
export function constructUnitsOptions(unitId = "") {
  const measurementUnitsArray = getMeasurementUnits(unitId) || [];

  // Options Array
  return measurementUnitsArray.map((eachUnit) => {
    const { id = "", symbol = "" } = eachUnit || {};

    return { id, value: symbol };
  });
}

// Gives the list of units for the measurement
export function getMeasurementUnits(unitId = "") {
  // From localstorage
  const { unitToMeasurementType = {}, unitsByMeasurementTypeId = {} } =
    JSON.parse(getItem(DataPreLoadKeys.MEASUREMENTS_AND_UNITS) || "{}") || {};

  const measurementTypeId = unitToMeasurementType[unitId] || "";

  const measurementUnits = unitsByMeasurementTypeId[measurementTypeId] || [];

  return measurementUnits;
}

// Constructing options array for select input
export function constructOptionsArray(options = []) {
  return options.map((option = "") => {
    return { id: option, value: option };
  });
}

// Constructing form data object
export function constructFormDataObject(defaultParameter = [], componentParameter = [], elementId = "") {
  return defaultParameter.reduce((acc, eachParameter) => {
    const { id = "", unitId: defaultUnitId = "" } = eachParameter || {};

    const { value = "", unitId = "" } =
      componentParameter.find(({ parameterId }) => parseInt(parameterId) === parseInt(id)) || {};

    return { ...acc, [`${id}-${elementId}`]: value, [`${id}-${elementId}-${defaultUnitId}`]: unitId || defaultUnitId };
  }, {});
}

// Get Parameters Form Inputs
export function getParametersFormInputs(
  defaultParameter,
  componentParameter,
  tableConfig = {},
  elementId = "",
  isPart = false
) {
  // Table Configuration
  const {
    showUnits = false,
    isInputPresent = false,
    isUnitConversionPresent = false,
    titleKey = "",
  } = tableConfig || {};

  return defaultParameter.reduce((acc, eachParameter) => {
    const {
      id = "",
      name = "",
      dataValueType = "",
      allowedValues = [],
      unitId = "",
      unitSymbol: defaultUnitSymbol = "",
      noOfDecimals = "",
      isActiveWhenPart = false,
    } = eachParameter || {};

    // If its a part and that parameter is not mapped for part
    // Then that parameter is not added as form input
    if (isPart && !isActiveWhenPart) {
      return acc;
    }

    // Finding the value of the measurement using parameter id
    const { unitSymbol = "", value = "" } =
      componentParameter?.find(({ parameterId = "" }) => parseInt(parameterId) === parseInt(id)) || {};

    // Checking whether allowed values is there or not
    const isAllowedValuesPresent = allowedValues.length !== 0;

    // Input type
    const type = getFormInputType(dataValueType, isAllowedValuesPresent);

    // If value is any number (including zero and negative number) other than empty string and null, then value is added.
    const updatedValue = value ?? "";
    const roundedValue =
      typeof value === "boolean" ? booleanOptionsValue[value] : DecimalUtils.fixDecimal(updatedValue, noOfDecimals);

    // Unit options
    const unitOptions = constructUnitsOptions(unitId);

    // Constructing options list from allowed values for select option
    const options = constructOptionsArray(allowedValues);

    // Adding fields
    const valueField = isInputPresent ? { key: `${id}-${elementId}`, type, options } : { label: `${roundedValue}` };

    const unitsSelectionField = {
      key: `${id}-${elementId}-${unitId}`,
      type: "select",
      options: unitOptions,
      disabled: !unitId,
    };

    const unitField = { label: unitSymbol || defaultUnitSymbol };

    const title = eachParameter[titleKey] || "";

    // Input Items
    const inputItems = [{ label: name, subText: defaultUnitSymbol && `(${defaultUnitSymbol})`, title }];

    inputItems.push(valueField);

    // If unit conversion is present
    if (isUnitConversionPresent) {
      inputItems.push(unitsSelectionField);
    }

    // Show units
    // For properties, user selected unit is displayed
    // For calculation, default unit is shown
    if (showUnits) {
      // Adding units field in which value is given
      inputItems.push(unitField);
    }

    return [...acc, { inputItems }];
  }, []);
}

// Constructing parameter values array for form submission
export function constructParameterValuesArray(defaultParameters = [], formData = {}, elementId = "") {
  return defaultParameters.reduce((acc, eachParameter) => {
    // Parameter Information
    const { unitId = "", id = "", elementTypeId = "", dataValueType = "" } = eachParameter || {};

    // Getting parameter value for the given data type
    const parameterValue = convertStringToBooleanOrNumber(dataValueType, formData[`${id}-${elementId}`]) ?? "";

    // Getting the id of the selected unit
    const selectedUnitId = formData[`${id}-${elementId}-${unitId}`] || "";

    // If parameter value is not there, then its skipped
    if (parameterValue.length === 0) {
      return acc;
    }

    const paramValObj = {
      element: "COMPONENT",
      elementId,
      elementTypeId, // TODO :: Remove this once milli fixes the backend (elementTypeId is redundant)
      parameterId: id,
      value: parameterValue,
      unitId: parseInt(selectedUnitId) || "",
    };

    return [...acc, paramValObj];
  }, []);
}

// Getting Parameters for the given Component Type
export function getPrametersByComponentType(componentTypeId = "") {
  // Local Storage Data
  const { parametersByCompType = {} } = JSON.parse(getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  return parametersByCompType[componentTypeId] || {};
}

// Getting Observation codes for the given Component Type
export function getObservationCodesByComponentType(componentTypeId = "") {
  // Local Storage Data
  const { observationCodesByComponent = {} } = JSON.parse(getItem(DataPreLoadKeys.OBSERVATION_CODES) || "{}");

  return observationCodesByComponent[componentTypeId] || {};
}

// Getting Recommendation codes for the given Component Type
export function getRecommendationCodesByComponentType(componentTypeId = "") {
  // Local Storage Data
  const { recommendationCodesByComponent = {} } = JSON.parse(getItem(DataPreLoadKeys.RECOMMENDATION_CODES) || "{}");

  return recommendationCodesByComponent[componentTypeId] || {};
}
