import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantFileListActions } from "../../redux-slice/plant/FileListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getPlantFileListApi } from "../../api/plant/FileListAPI";

// Get Plant File List
function* getPlantFileList(action) {
  try {
    const { plantId, pageNumber, pageSize, type = "" } = action.payload;

    const { items, pagination } = yield getPlantFileListApi(plantId, pageNumber, pageSize, type);

    // Dispatching Action
    yield put(
      PlantFileListActions.getPlantFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileListActions.getPlantFileListFailure({ error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([takeLatest(PlantFileListActions.getPlantFileList.type, getPlantFileList)]);
}
