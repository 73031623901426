import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Actions
import { AuditActions } from "../../redux-slice/audit/AuditSlice";
import { PlantActions } from "../../redux-slice/plant/PlantSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Utils
import URLs from "../../app/utils/URLs";

// APIs
import {
  auditCreateApi,
  getAuditInfoApi,
  getAuditDetailedProgressApi,
  updateAuditInchargeApi,
  startAuditPreAssessmentApi,
  startPreAuditApi,
  addAuditorApi,
  removeAuditorApi,
  updatePlantInchargeApi,
} from "../../api/audit/AuditAPI";

import { getPlantInfoByIdApi } from "../../api/plant/PlantAPI";

// Audit Create
function* auditCreate(action) {
  try {
    const { auditData, navigate } = action.payload;

    yield auditCreateApi(auditData);

    // Dispatching Action
    yield put(AuditActions.createAuditSuccess());

    // Toaster
    toast.success("Audit Created Successfully");

    //Navigate
    navigate(RedirectTo.AuditListPageUrl);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.createAuditFailure({ error }));
  }
}

// Get Audit Info By Id
function* getAuditInfoById(action) {
  try {
    const { auditId } = action.payload;

    const auditInfo = yield getAuditInfoApi(auditId);

    // Getting industry and audit org id
    const { plantId } = auditInfo;

    // Getting plant info
    const plantInfo = yield getPlantInfoByIdApi(plantId);

    // Updating plant information
    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));

    // Dispatching Action
    yield put(AuditActions.getAuditInfoSuccess({ auditInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditInfoFailure({ error }));
  }
}

// Get Audit Detailed Progress By Id
function* getAuditDetailedProgressById(action) {
  try {
    const { auditId } = action.payload;

    const auditDetailedProgress = yield getAuditDetailedProgressApi(auditId);

    // Dispatching Action
    yield put(AuditActions.getAuditDetailedProgressSuccess({ auditDetailedProgress }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditDetailedProgressFailure({ error }));
  }
}

// Update Plant Incharge By Id
function* updatePlantInchargeById(action) {
  try {
    const { auditId, userId, setIsEdit } = action.payload;

    yield updatePlantInchargeApi(auditId, userId);

    // Dispatching Action
    yield put(AuditActions.updatePlantInchargeSuccess());

    // Toaster
    toast.success("Plant Incharge Updated Successfully");

    setIsEdit(false);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updatePlantInchargeFailure({ error }));
  }
}

// Update Audit Incharge By Id
function* updateAuditInchargeById(action) {
  try {
    const { auditId, userId, setIsEdit, revalidator } = action.payload;

    yield updateAuditInchargeApi(auditId, userId);

    // To revalidate audit information
    yield revalidator.revalidate();

    // Toaster
    toast.success("Audit Incharge Updated Sucessfully");

    // Dispatching Action
    yield put(AuditActions.updateAuditInchargeSuccess());

    setIsEdit(false);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updateAuditInchargeFailure({ error }));
  }
}

// Start Audit Pre Assessment By Id
function* startAuditPreAssessmentById(action) {
  try {
    const { orgId, plantId, auditId, navigate } = action.payload;

    yield startAuditPreAssessmentApi(auditId);

    // Toaster
    toast.success("Started Audit Pre Assessment Successfully");

    // Dispatching Action
    yield put(AuditActions.startAuditPreAssessmentSuccess());

    // Navigate
    const url = URLs.format(RedirectTo.PlantSetupPageUrl, { orgId, plantId });
    navigate(url);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.startAuditPreAssessmentFailure({ error }));
  }
}

// Start Pre Audit By Id
function* startPreAuditById(action) {
  try {
    const { orgId, plantId, auditId, navigate } = action.payload;

    yield startPreAuditApi(auditId);

    // Toaster
    toast.success("Started Pre Audit Successfully");

    // Dispatching Action
    yield put(AuditActions.startPreAuditSuccess());

    // Navigate
    const url = URLs.format(RedirectTo.PlantSetupPageUrl, { orgId, plantId });
    navigate(url);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.startPreAuditFailure({ error }));
  }
}

// Add Auditor By Id
function* addAuditorById(action) {
  try {
    const { auditId, userId, setIsEdit, revalidator } = action.payload;

    yield addAuditorApi(auditId, userId);

    // To revalidate audit information
    yield revalidator.revalidate();

    // Toaster
    toast.success("Auditor Added Successfully");

    // Dispatching Action
    yield put(AuditActions.addAuditorSuccess());

    // Disabling Form
    setIsEdit(false);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.addAuditorFailure({ error }));
  }
}

// Remove Auditor By Id
function* removeAuditorById(action) {
  const { auditId, userId, revalidator } = action.payload;

  try {
    yield removeAuditorApi(auditId, userId);

    // To revalidate audit information
    yield revalidator.revalidate();

    // Toaster
    toast.success("Auditor Removed Successfully");

    // Dispatching Action
    yield put(AuditActions.removeAuditorSuccess({ userId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.removeAuditorFailure({ error, userId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditActions.createAudit.type, auditCreate),
    takeLatest(AuditActions.getAuditInfo.type, getAuditInfoById),
    takeLatest(AuditActions.getAuditDetailedProgress.type, getAuditDetailedProgressById),
    takeLatest(AuditActions.updatePlantIncharge.type, updatePlantInchargeById),
    takeLatest(AuditActions.updateAuditIncharge.type, updateAuditInchargeById),
    takeLatest(AuditActions.startAuditPreAssessment.type, startAuditPreAssessmentById),
    takeLatest(AuditActions.startPreAudit.type, startPreAuditById),
    takeLatest(AuditActions.addAuditor.type, addAuditorById),
    takeLatest(AuditActions.removeAuditor.type, removeAuditorById),
  ]);
}
