// Report PDF Style
export const ReportPDFStyle = `
      <style>
       .remarks {
          display: block !important;
        }

        section{
          page-break-after: always !important;
        }

        p {
          text-align: justify;
        }

        .table-responsive {
          overflow: visible !important;
        }
      </style>
`;

// Report PDF Print Style
export const ReportPDFPrintStyle = `
      <style>
        /* Print CSS */
        @page {
          margin: 0.75in;
          size: A4 portrait;
        }

        @media print {
          table {
            thead {
              border: 1px solid black !important;
            }

            th {
              padding: 4px !important;
              background-color: #f3f7ff !important;
            }

            td {
              padding: 0px 4px !important;
            }
        
            tr {
              border-left: 1px solid black !important;
              border-right: 1px solid black !important;
        
              &:last-child {
                border-bottom: 1px solid black !important;
              }
            }
        
            td,
            th {
              border: 1px solid black !important;
            }
          }

          table,
          img {
            max-width: 100%;

            /* page breaks before the current element: Automatic page breaks (neither forced nor forbidden). */
            page-break-before: auto;
          }
        }
      </style>
`;

// Report HTML Head
export const ReportHTMLHead = `
    <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Report - KISEM</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" 
    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" 
    integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
    </head>
`;
