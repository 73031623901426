import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentListActions } from "../../redux-slice/plant/ComponentListSlice";
import { PlantComponentActions } from "../../redux-slice/plant/ComponentSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// Utils
import { saveFile } from "../../utils/FileUtils";

// Components
import { ModalActions } from "../../components/modal/Modal";

// APIs
import {
  addPlantComponentByIdApi,
  downloadPlantComponentImageByIdApi,
  updatePlantComponentByIdApi,
  uploadPlantComponentImageByIdApi,
} from "../../api/plant/ComponentAPI";

import { getPlantComponentListApi } from "../../api/plant/ComponentListAPI";

// Add Plant Component By Id
function* addPlantComponentById(action) {
  try {
    const { componentData, plantId, componentTypeId, pageNumber, pageSize } = action.payload;

    yield addPlantComponentByIdApi(componentData, plantId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

    // Dispatching Action to refresh plant component list
    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    // Dispatching Action
    yield put(PlantComponentActions.addPlantComponentSuccess());

    // Toaster
    toast.success("Added Plant Component Successfully");

    // Close modal
    ModalActions.close(ModalKeys.addPlantComponentModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.addPlantComponentModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.addPlantComponentFailure({ error }));
  }
}

// Update Plant Component By Id
function* updatePlantComponentById(action) {
  try {
    const { componentData, componentId, plantId, pageNumber, pageSize } = action.payload;
    const { componentTypeId } = componentData;

    yield updatePlantComponentByIdApi(componentData, componentId, plantId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

    // Dispatching Action to refresh plant component list
    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    // Dispatching Action
    yield put(PlantComponentActions.updatePlantComponentSuccess());

    // Toaster
    toast.success("Updated Plant Component Successfully");
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.viewPlantComponentModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.updatePlantComponentFailure({ error }));
  }
}

// Upload Plant Component Image By Id
function* uploadPlantComponentImageById(action) {
  try {
    const { formData, componentTypeId, componentId, plantId } = action.payload;

    yield uploadPlantComponentImageByIdApi(formData, plantId, componentId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId);

    // Dispatching Action to refresh plant component list
    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    // Dispatching Action
    yield put(PlantComponentActions.uploadPlantComponentImageSuccess());

    // Toaster
    toast.success("Uploaded Plant Component Image Successfully");

    // Close modal
    ModalActions.close(ModalKeys.addPlantComponentImageModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.addPlantComponentImageModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.uploadPlantComponentImageFailure({ error }));
  }
}

// Download Plant Component Image By Id
function* downloadPlantComponentImageById(action) {
  const { componentId = "", name = "", apiUrl = "" } = action.payload;

  try {
    const data = yield downloadPlantComponentImageByIdApi(apiUrl);

    // Save Image
    saveFile(name, data);

    // Dispatching Action
    yield put(PlantComponentActions.downloadPlantComponentImageSuccess({ componentId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.downloadPlantComponentImageFailure({ error, componentId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantComponentActions.addPlantComponent.type, addPlantComponentById),
    takeLatest(PlantComponentActions.updatePlantComponent.type, updatePlantComponentById),
    takeLatest(PlantComponentActions.uploadPlantComponentImage.type, uploadPlantComponentImageById),
    takeLatest(PlantComponentActions.downloadPlantComponentImage.type, downloadPlantComponentImageById),
  ]);
}
