import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Constants
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Sections
//

// Page Components
// ----------------------------------------------------------------------------

function PageHeaderSection({}) {
  // Content
  const pageTitle = "Audit Library";
  const pageActions = <div className="btn-cont"></div>;

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

/**
 * Page
 */
export default function AuditLibraryPage() {
  // Page Params
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-8">Sample Page Body</div>
        <div className="col-4"></div>
      </div>
    </div>
  );
}
