/* eslint-disable jsx-a11y/anchor-is-valid */

// Imports
import "./dev-styles.scss";

/**
 * Custome Components Theme Page
 */
export default function CustomComponentsPage(){
    return (
        <div>Components Page</div>
    )
}