// API
import { getOrganizationInfoByIdApi } from "../api/organization/OrganizationAPI";
import { getPlantInfoByIdApi } from "../api/plant/PlantAPI";

export async function plantInfoLoader(orgId, plantId) {
    //TODO: How do we add the loader (Spinner)
    try {
        // TODO:: as these two API's are independent to each other can we call them parallely..
        const plantInfo = await getPlantInfoByIdApi(plantId);
        const plantOrgInfo = await getOrganizationInfoByIdApi(orgId);
        return { plantInfo, plantOrgInfo };
    } catch (error) {
        // TODO :: How do we handle the Errors in the Loaders.
        // dispatch(setErrorInfo({ errorInfo: error, showToaster: true }));
        return {};
    }
};