import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ComponentTypeListActions } from "../../redux-slice/component-type/ComponentTypeListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  getComponentTypeListApi,
  getParameterListByComponentTypeIdApi,
  getRecommendationCodeListByComponentTypeIdApi,
  getObservationCodeListByComponentTypeIdApi,
} from "../../api/component-type/ComponentTypeListAPI";

// Get Component Type List
function* getComponentTypeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getComponentTypeListApi(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      ComponentTypeListActions.getComponentTypeListSuccess({
        componentTypeList: items,
        componentTypeListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeListActions.getComponentTypeListFailure({ error }));
  }
}

// Get Parameters By Component-Type-Id
function* getParameterListByComponentTypeId(action) {
  try {
    const { componentTypeId, parameterType, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getParameterListByComponentTypeIdApi(
      componentTypeId,
      parameterType,
      pageNumber,
      pageSize,
      searchText
    );

    // Dispatching Action
    yield put(
      ComponentTypeListActions.getParameterListSuccess({
        parameterList: items,
        parameterListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeListActions.getParameterListFailure({ error }));
  }
}

// Get Recommendation Code List By Component-Type-Id
function* getRecommendationCodeListByComponentTypeId(action) {
  try {
    const { componentTypeId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getRecommendationCodeListByComponentTypeIdApi(
      componentTypeId,
      pageNumber,
      pageSize,
      searchText
    );

    // Dispatching Action
    yield put(
      ComponentTypeListActions.getRecCodeListSuccess({
        recCodeList: items,
        recommendationCodeListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeListActions.getRecCodeListFailure({ error }));
  }
}

// Get Observation Code List By Component-Type-Id
function* getObservationCodeListByComponentTypeId(action) {
  try {
    const { componentTypeId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getObservationCodeListByComponentTypeIdApi(
      componentTypeId,
      pageNumber,
      pageSize,
      searchText
    );

    // Dispatching Action
    yield put(
      ComponentTypeListActions.getObsCodeListSuccess({
        observationCodeList: items,
        observationCodeListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeListActions.getObsCodeListFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(ComponentTypeListActions.getComponentTypeList.type, getComponentTypeList),
    takeLatest(ComponentTypeListActions.getParameterList.type, getParameterListByComponentTypeId),
    takeLatest(ComponentTypeListActions.getRecCodeList.type, getRecommendationCodeListByComponentTypeId),
    takeLatest(ComponentTypeListActions.getObsCodeList.type, getObservationCodeListByComponentTypeId),
  ]);
}
