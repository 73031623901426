import * as yup from "yup";

// Constants
import { OrgType } from "../../constants/OrgTypeConstants";

// Form Schema
import { UserFormSchema, UserFormValidationSchema } from "../../form-schema/UserFormSchema";

// Constructing options array for select plant input
function constructPlantOptionsArray(plantList = []) {
  return plantList.map((eachPlant) => {
    const { id = "", name = "" } = eachPlant || {};

    return { id, value: name };
  });
}

// Form schema for industry user
export function getIndustryUserFormSchema(orgType = "", plantList = []) {
  // Deep Cloning
  const clonedUserFormInputs = structuredClone(UserFormSchema);

  // For industry, one more field for selecting plant is added
  if (orgType === OrgType.INDUSTRY) {
    // Select input for plant
    const selectPlantFormInput = {
      containerClassName: "row mt-4",
      inputItems: [
        {
          label: "Plants",
          key: "plantId",
          type: "select",
          options: constructPlantOptionsArray(plantList),
          placeholder: "Select plant...",
          isMandotary: true,
        },
      ],
    };

    // Form validation for plant select input
    const selectPlantFormValidation = { plantId: yup.string().required("Please select plant") };

    // Updating form validation
    const updatedUserFormValidation = yup.object({ ...UserFormValidationSchema, ...selectPlantFormValidation });

    // Updating form inputs
    clonedUserFormInputs.splice(2, 0, selectPlantFormInput);

    return { formInputItems: clonedUserFormInputs, formValidationSchema: updatedUserFormValidation };
  }

  return { formInputItems: UserFormSchema, formValidationSchema: yup.object(UserFormValidationSchema) };
}
