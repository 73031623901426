// Constants
import {
  addOrganizationUrl,
  updateOrganizationByIdurl,
  getOrganizationInfoByIdUrl,
  updateOrganizationStatusByIdUrl,
} from "../../urls/api-urls/OrganizationURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Add Organization
export async function addOrganizationApi(organizationData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(addOrganizationUrl, {}, organizationData);
}

// Update Organization
export async function updateOrganizationByIdApi(orgId, organizationData) {
  const url = URLs.format(updateOrganizationByIdurl, { orgId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, organizationData);
}

// Get Organization Info
export async function getOrganizationInfoByIdApi(orgId) {
  const url = URLs.format(getOrganizationInfoByIdUrl, { orgId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update Organization status
export async function updateOrganizationStatusByIdApi(orgId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateOrganizationStatusByIdUrl, { orgId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
