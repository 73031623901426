import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { InstrumentListActions } from "../../redux-slice/instrument/InstrumentListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getAuditInstrumentDataListApi } from "../../api/audit/InstrumentListAPI";

// Get Instrument List
function* getInstrumentList(action) {
  try {
    const { componentId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getAuditInstrumentDataListApi(componentId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      InstrumentListActions.getInstrumentListSuccess({ instrumentList: items, instrumentListPagination: pagination })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentListActions.getInstrumentListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(InstrumentListActions.getInstrumentList.type, getInstrumentList)]);
}
