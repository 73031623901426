import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Add organization
  addOrganizationLoading: false,
  addOrganizationError: "",

  // Update organization
  updateOrganizationLoading: false,
  updateOrganizationError: "",

  // Get organization information
  organizationInfo: {},
  organizationInfoLoading: false,
  organizationInfoError: "",

  // Update organization status
  updateOrganizationStatusLoadingMap: {},
  updateOrganizationStatusError: "",
};

// Organization Slice
const organizationSlice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    // Add organization
    addOrganization: (state) => {
      state.addOrganizationLoading = true;
    },

    addOrganizationSuccess: (state) => {
      state.addOrganizationLoading = false;
    },

    addOrganizationFailure: (state, action) => {
      state.addOrganizationLoading = false;
      state.addOrganizationError = action.payload.error;
    },

    // Update organization
    updateOrganization: (state) => {
      state.updateOrganizationLoading = true;
    },

    updateOrganizationSuccess: (state) => {
      state.updateOrganizationLoading = false;
    },

    updateOrganizationFailure: (state, action) => {
      state.updateOrganizationLoading = false;
      state.updateOrganizationStatusError = action.payload.error;
    },

    // Get organization information
    getOrganizationInfo: (state) => {
      state.organizationInfoLoading = true;
    },

    getOrganizationInfoSuccess: (state, action) => {
      state.organizationInfoLoading = false;
      state.organizationInfo = action.payload.organizationInfo;
    },

    getOrganizationInfoFailure: (state, action) => {
      state.organizationInfoLoading = false;
      state.organizationInfoError = action.payload.error;
    },

    // Update organization status (Enable/Disable)
    updateOrganizationStatus: (state, action) => {
      const { orgId } = action.payload;

      state.updateOrganizationStatusLoadingMap = { ...state.updateOrganizationStatusLoadingMap, [orgId]: true };
    },

    updateOrganizationStatusSuccess: (state, action) => {
      const { orgId } = action.payload;

      //To get previous state
      const clonedUpdateOrganizationStatusLoadingMap = { ...state.updateOrganizationStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateOrganizationStatusLoadingMap[orgId];

      state.updateOrganizationStatusLoadingMap = clonedUpdateOrganizationStatusLoadingMap;
    },

    updateOrganizationStatusFailure: (state, action) => {
      const { orgId, error } = action.payload;

      //To get previous state
      const clonedUpdateOrganizationStatusLoadingMap = { ...state.updateOrganizationStatusLoadingMap };

      //To remove the id from state after successfull loading
      delete clonedUpdateOrganizationStatusLoadingMap[orgId];

      state.updateOrganizationStatusLoadingMap = clonedUpdateOrganizationStatusLoadingMap;
      state.updateOrganizationStatusError = error;
    },
  },
});

// Reducer
export const OrganizationReducer = organizationSlice.reducer;

// Actions
export const OrganizationActions = organizationSlice.actions;
