import {
  getComponentTypesDataUrl,
  getMaterialsDataUrl,
  getMeasurementsAndUnitsDataUrl,
  getObservationCodesDataUrl,
  getProductsDataUrl,
  getRecommendationCodesDataUrl,
  getConstantDataUrl,
} from "../urls/api-urls/DataPreLoadURL";

export const DataPreLoadObj = {
  MEASUREMENTS_AND_UNITS: getMeasurementsAndUnitsDataUrl,
  COMPONENT_TYPES: getComponentTypesDataUrl,
  RECOMMENDATION_CODES: getRecommendationCodesDataUrl,
  MATERIALS: getMaterialsDataUrl,
  PRODUCTS: getProductsDataUrl,
  OBSERVATION_CODES: getObservationCodesDataUrl,
  CONSTANTS: getConstantDataUrl,
};
