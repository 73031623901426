import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditReportsActions } from "../../redux-slice/audit/ReportsSlice";

// Utils
import { blobToBase64 } from "../../utils/FileUtils";

// Components
import { Button } from "../../components/button/Button";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Report Header and Footer Logo URLs
const KISEM_LOGO_URL = "https://ieac-assets.s3.ap-south-1.amazonaws.com/branding/KISEM_LOGO_LITE.png";
const IIT_M_LOGO_URL = "https://ieac-assets.s3.ap-south-1.amazonaws.com/branding/IIT_M_LOGO_LITE.png";
const LOGO_HEIGHT = 55;

// Helper Functions
async function getImageAsBlob(url = "") {
  const httpClient = HttpClientFactory.getInstance();
  const blob = await httpClient.downloadPublicFile(url);

  return blob;
}

async function getBase64ImageTag(url = "", imageHeight = 50) {
  if (!url) {
    return "";
  }

  try {
    const blob = await getImageAsBlob(url);
    const base64 = await blobToBase64(blob);

    return `<img src="${base64}" alt="Image" height=${imageHeight} />`;
  } catch (error) {
    return "";
  }
}

async function getHeaderHtmlString() {
  const logo1 = await getBase64ImageTag(KISEM_LOGO_URL, LOGO_HEIGHT);
  const logo2 = await getBase64ImageTag(IIT_M_LOGO_URL, LOGO_HEIGHT);

  return `<div style = "padding: 0px 10px;width: 100%;display: flex;justify-content:space-between;align-items: center;">
            ${logo1} ${logo2}
          </div>`;
}

function getFooterHtmlString() {
  return `<div style="font-size: 12px;text-align: center; width: 100%;"><span class="pageNumber"></span></div>`;
}

/**
 * Audit Report PDF Download Btn
 */
export default function AuditReportPDFDownloadBtn({ reportId = "", auditId = "", btnSize = "sm", btnClassName = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Download Report Selector State
  const downloadReportPDFLoading = useSelector((state) => state.auditReports.downloadReportPDFLoading);

  // Loading Status
  const downloadBtnLoading = downloadReportPDFLoading[reportId];

  // Download Report PDF Function
  async function downloadReportPDF() {
    // Header Content
    const headerContent = await getHeaderHtmlString();

    // Footer Content
    const footerContent = getFooterHtmlString();

    // Report Data
    const reportData = {
      headerContent,
      footerContent,
      excludeHeaderFooterPageRange: "1",
    };

    // Dispatch
    dispatch(AuditReportsActions.downloadReportPDF({ auditId, reportId, reportData }));
  }

  return (
    <Button
      label="Download"
      loading={downloadBtnLoading}
      disabled={downloadBtnLoading}
      onClick={downloadReportPDF}
      size={btnSize}
      color="dark"
      className={btnClassName}
    >
      <i className="fas fa-download" />
    </Button>
  );
}
