import { createSlice } from "@reduxjs/toolkit";

// Initial Slice
const initialState = {
  // Get plant component list
  plantComponentList: [],
  plantComponentListLoading: false,
  plantComponentListError: "",
};

const plantComponentListSlice = createSlice({
  name: "PlantComponentList",
  initialState,
  reducers: {
    // Get plant component list
    getPlantComponentList: (state) => {
      state.plantComponentListLoading = true;
    },
    getPlantComponentListSuccess: (state, action) => {
      state.plantComponentListLoading = false;
      state.plantComponentList = action.payload.plantComponentList;
    },
    getPlantComponentListFailure: (state, action) => {
      state.plantComponentListLoading = false;
      state.plantComponentListError = action.payload.error;
    },
  },
});

// Reducer
export const PlantComponentListReducer = plantComponentListSlice.reducer;

// Actions
export const PlantComponentListActions = plantComponentListSlice.actions;
