// Constants
import { DataPreLoadKeys } from "../../constants/GeneralConstants";

// Utils
import {
  constructFormDataObject,
  getPrametersByComponentType,
  getParametersFormInputs,
} from "../../utils/ComponentUtils";
import { getItem } from "../../app/LocalStorage";

// Contructing component type parameters form inputs
// Default Config
const DEFAULT_TABLE_CONFIG = {
  propertyTable: {
    showUnits: false,
    isInputPresent: false,
    isUnitConversionPresent: false,
    titleKey: "",
  },
  measurementTable: {
    showUnits: false,
    isInputPresent: false,
    isUnitConversionPresent: false,
    titleKey: "",
  },
  calculationTable: {
    showUnits: false,
    isInputPresent: false,
    isUnitConversionPresent: false,
    titleKey: "",
  },
};

export function constructParametersFormInputs(auditComponentInfo = {}, tableConfig = DEFAULT_TABLE_CONFIG) {
  // Getting component information
  const { component = {}, properties = [], measurements = [], calculations = [] } = auditComponentInfo;
  const { id = "", componentTypeId = "" } = component;

  // Getting measurements and property array from local storage for component type
  const { PROPERTY = [], MEASUREMENT = [], CALCULATION = [] } = getPrametersByComponentType(componentTypeId);

  // Configuration
  const { propertyTable = {}, measurementTable = {}, calculationTable = {} } = tableConfig || {};

  // Constructing form inputs for component type (property,measurement and calculation)
  const propertyInputs = getParametersFormInputs(PROPERTY, properties, propertyTable, id);
  const measurementInputs = getParametersFormInputs(MEASUREMENT, measurements, measurementTable, id);
  const calculationInputs = getParametersFormInputs(CALCULATION, calculations, calculationTable, id);

  // Constructing form inputs for parts (property,measurement and calculation)
  const {
    partsPropertyInputs = [],
    partsMeasurementInputs = [],
    partsCalculationInputs = [],
  } = constructPartsPrameterFormInputs(auditComponentInfo, tableConfig);

  // Merging parts parameter with component type parameter
  const updatedPropertyInputs = [...propertyInputs, ...partsPropertyInputs];
  const updatedMeasurementInputs = [...measurementInputs, ...partsMeasurementInputs];
  const updatedCalculationInputs = [...calculationInputs, ...partsCalculationInputs];

  return {
    propertyInputs: updatedPropertyInputs,
    measurementInputs: updatedMeasurementInputs,
    calculationInputs: updatedCalculationInputs,
  };
}

// Constructing parts parameter form inputs
export function constructPartsPrameterFormInputs(auditComponentInfo, tableConfig = DEFAULT_TABLE_CONFIG) {
  // Configuration
  const { propertyTable = {}, measurementTable = {}, calculationTable = {} } = tableConfig || {};

  // Getting parts added to the component
  const { parts = [] } = auditComponentInfo;

  // Local Storage Data
  const { parametersByCompType = {} } = JSON.parse(getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  return parts.reduce((acc, eachPart) => {
    // Part Information
    const {
      component: partComponent = {},
      properties: partProperties = [],
      measurements: partMeasurements = [],
      calculations: partCalculations = [],
    } = eachPart || {};

    const { id = "", componentTypeId = "", name = "", internalId = "" } = partComponent || {};
    const { PROPERTY = [], MEASUREMENT = [], CALCULATION = [] } = parametersByCompType[componentTypeId] || {};

    // Title
    const title = `${internalId} (${name})`;

    // Constructing form inputs for parts (property,measurement and calculation)
    const partsPropertyInputs = getParametersFormInputs(PROPERTY, partProperties, propertyTable, id, true) || [];
    const partsMeasurementInputs =
      getParametersFormInputs(MEASUREMENT, partMeasurements, measurementTable, id, true) || [];
    const partsCalculationInputs =
      getParametersFormInputs(CALCULATION, partCalculations, calculationTable, id, true) || [];

    // Merging the parameters of all the parts
    // Title is added to show the part name(entire row), under which corresponding form input is shown
    // Then, Form inputs of the previous parts is merged with the new parts form inputs
    acc["partsPropertyInputs"] = [...(acc["partsPropertyInputs"] || []), { title }, ...partsPropertyInputs];
    acc["partsMeasurementInputs"] = [...(acc["partsMeasurementInputs"] || []), { title }, ...partsMeasurementInputs];
    acc["partsCalculationInputs"] = [...(acc["partsCalculationInputs"] || []), { title }, ...partsCalculationInputs];

    return acc;
  }, {});
}

// Constructing form data object
export function getMeasurementFormDataObject(auditComponentInfo = {}) {
  // Component Information
  const { component = {}, measurements: componentMeasurement = [], parts = [] } = auditComponentInfo;
  const { id: componentId = "", componentTypeId = "" } = component;

  // Local Storage Data
  const { parametersByCompType = {} } = JSON.parse(getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  // Getting measurements and property array from local storage for component type
  const { MEASUREMENT = [] } = parametersByCompType[componentTypeId] || {};

  const partsFormData = parts.reduce((acc, eachPart) => {
    // Part Information
    const { component = {}, measurements: partMeasurement = [] } = eachPart || {};
    const { id: partId = "", componentTypeId = "" } = component; // Part Component Type Id

    // Getting measurements and property array from local storage for component type
    const { MEASUREMENT = [] } = parametersByCompType[componentTypeId] || {};

    return { ...acc, ...constructFormDataObject(MEASUREMENT, partMeasurement, partId) };
  }, {});

  return { ...constructFormDataObject(MEASUREMENT, componentMeasurement, componentId), ...partsFormData };
}

// Getting measurements for all parts
export function getPartsMeasurementsArray(componentTypeId) {
  // Local Storage Data
  const { parametersByCompType = {}, partsByCompType = {} } = JSON.parse(
    getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}"
  );

  // Getting measurements and property array from local storage for parts
  const parts = partsByCompType[componentTypeId] || [];

  // Combining all parts measurements
  return parts.reduce((acc, eachPart) => {
    const { id = "" } = eachPart || {};

    const { MEASUREMENT = [] } = parametersByCompType[id] || [];

    return [...acc, ...MEASUREMENT];
  }, []);
}
