// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import {
  getComponentTypeListUrl,
  getParameterListByComponentTypeIdUrl,
  getRecommendationCodeListByComponentTypeIdUrl,
  getObservationCodeListByComponentTypeIdUrl,
} from "../../urls/api-urls/ComponentTypeURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Component list by Component-Type Id
export async function getComponentTypeListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getComponentTypeListUrl, params);
}

// Get Parameter list by Component-Type Id
export async function getParameterListByComponentTypeIdApi(
  componentTypeId,
  parameterType,
  pageNumber,
  pageSize,
  searchText
) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem_type: "COMPONENT",
    elem_type_id: componentTypeId,
    type: parameterType,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getParameterListByComponentTypeIdUrl, params);
}

// Get Recommendation Code list by Component-Type Id
export async function getRecommendationCodeListByComponentTypeIdApi(componentTypeId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem_type: "COMPONENT",
    elem_type_id: componentTypeId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getRecommendationCodeListByComponentTypeIdUrl, params);
}

// Get Observation Code list by Component-Type Id
export async function getObservationCodeListByComponentTypeIdApi(componentTypeId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem_type: "COMPONENT",
    elem_type_id: componentTypeId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getObservationCodeListByComponentTypeIdUrl, params);
}
