import { useState } from "react";

// URLs
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Layouts
import Layout from "../../app/layout/Layout";
import SidebarMenu from "./SidebarMenu";
import SiderbarFooter from "./SidebarFooter";

// Sidebar Images
import LOGO_KISEM from "../../assests/images/branding/KISEM_LOGO_1.png";
import LOGO_KISEM_TEXT from "../../assests/images/branding/KISEM_LOGO_2.png";

/**
 * Layout Page
 */
export default function MainPageLayout() {
  // State
  const [pageHeader, setPageHeader] = useState(<></>);

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: <div className="d-flex align-items-center justify-content-between">{pageHeader}</div>,
  };

  // Sidebar Config
  const sidebarConfig = {
    menuComponent: <SidebarMenu />,
    footerComponent: <SiderbarFooter />,
    headerLogo: { height: 40, logoURL: LOGO_KISEM_TEXT },
    collapsedHeaderLogo: { height: 30, logoURL: LOGO_KISEM },
    redirectURL: RedirectTo.DashboardPageUrl,
    title: "Energy Audit Tool",
  };

  return (
    <Layout
      sidebarCollapsible={true}
      sidebarConfig={sidebarConfig}
      topbarConfig={topbarConfig}
      outletProps={[setPageHeader]}
    />
  );
}
