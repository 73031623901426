import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Component Information
  auditComponentInfo: {},
  auditComponentInfoLoading: false,
  auditComponentInfoError: "",

  // Get Components Detail
  componentsDetail: {},
  componentsDetailLoading: false,
  componentsDetailError: "",

  // Add Walkthrough Remarks
  addWalkthroughRemarksLoading: false,
  addWalkthroughRemarksError: "",

  // Add Walkthrough Remarks Image
  addWalkthroughImageLoading: false,
  addWalkthroughImageError: "",

  // Add Component Remarks
  addComponentRemarksLoading: {},
  addComponentRemarksError: "",
};

// Audit Component Slice
const auditComponentSlice = createSlice({
  // Name
  name: "AuditComponent",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Get Audit Component Information
    getAuditComponentInfo: (state) => {
      state.auditComponentInfoLoading = true;
    },

    getAuditComponentInfoSuccess: (state, action) => {
      state.auditComponentInfoLoading = false;
      state.auditComponentInfo = action.payload.auditComponentInfo;
      state.auditComponentInfoError = "";
    },

    getAuditComponentInfoFailure: (state, action) => {
      state.auditComponentInfoLoading = false;
      state.auditComponentInfoError = action.payload.error;
    },

    // Get Components Detail
    getComponentsDetail: (state) => {
      state.componentsDetailLoading = true;
    },

    getComponentsDetailSuccess: (state, action) => {
      state.componentsDetailLoading = false;
      state.componentsDetail = action.payload.componentsDetail;
      state.componentsDetailError = "";
    },

    getComponentsDetailFailure: (state, action) => {
      state.componentsDetailLoading = false;
      state.componentsDetailError = action.payload.error;
    },

    // Add Walkthrough Remarks
    addWalkthroughRemarks: (state) => {
      state.addWalkthroughRemarksLoading = true;
    },

    addWalkthroughRemarksSuccess: (state, action) => {
      state.addWalkthroughRemarksLoading = false;
      state.addWalkthroughRemarksError = "";
    },

    addWalkthroughRemarksFailure: (state, action) => {
      state.addWalkthroughRemarksLoading = false;
      state.addWalkthroughRemarksError = action.payload.error;
    },

    // Add Walkthrough Remarks Image
    addWalkthroughImage: (state) => {
      state.addWalkthroughImageLoading = true;
    },

    addWalkthroughImageSuccess: (state, action) => {
      state.addWalkthroughImageLoading = false;
      state.addWalkthroughRemarksError = "";
    },

    addWalkthroughImageFailure: (state, action) => {
      state.addWalkthroughImageLoading = false;
      state.addWalkthroughImageError = action.payload.error;
    },

    // Add Component Remarks
    addComponentRemarks: (state, action) => {
      const { componentId = "" } = action.payload;

      state.addComponentRemarksLoading = {
        ...state.addComponentRemarksLoading,
        [componentId]: true,
      };
    },

    addComponentRemarksSuccess: (state, action) => {
      const { componentId = "" } = action.payload;

      //To get previous state
      const clonedAddComponentRemarksLoading = { ...state.addComponentRemarksLoading };

      //To remove the id from state after successfull loading
      delete clonedAddComponentRemarksLoading[componentId];

      state.addComponentRemarksLoading = clonedAddComponentRemarksLoading;
    },

    addComponentRemarksFailure: (state, action) => {
      const { componentId = "", error = {} } = action.payload;

      //To get previous state
      const clonedAddComponentRemarksLoading = { ...state.addComponentRemarksLoading };

      //To remove the id from state after successfull loading
      delete clonedAddComponentRemarksLoading[componentId];

      state.addComponentRemarksLoading = clonedAddComponentRemarksLoading;
      state.addComponentRemarksError = error;
    },

    // Clear Audit Component Info
    clearAuditComponentInfo: (state) => {
      state.auditComponentInfo = {};
    },
  },
});

// Reducer
export const AuditComponentReducer = auditComponentSlice.reducer;

// Actions
export const AuditComponentActions = auditComponentSlice.actions;
