import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get plant Electricity bill list
  plantElectricityBillList: [],
  plantElectricityBillListPagination: {},
  plantElectricityBillListLoading: false,
  plantElectricityBillListError: "",
};

// Plant Electricity Bill List Slice
const plantElectricityBillList = createSlice({
  name: "PlantElectrictyBillList",
  initialState,
  reducers: {
    // Get plant Electricity bill list
    getPlantElectricityBillList: (state) => {
      state.plantElectricityBillListLoading = true;
    },

    getPlantElectricityBillListSuccess: (state, action) => {
      state.plantElectricityBillListLoading = false;
      state.plantElectricityBillList = action.payload.plantElectricityBillList;
      state.plantElectricityBillListPagination = action.payload.plantElectricityBillListPagination;
    },

    getPlantElectricityBillListFailure: (state, action) => {
      state.plantElectricityBillListLoading = false;
      state.plantElectricityBillListError = action.payload.error;
    },
  },
});

// Reducer
export const PlantElectricityBillListReducer = plantElectricityBillList.reducer;

// Actions
export const PlantElectricityBillListActions = plantElectricityBillList.actions;
