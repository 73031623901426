import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditFileListActions } from "../../redux-slice/audit/FileListSlice";
import { AuditFileActions } from "../../redux-slice/audit/FileSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// Component Actions
import { ModalActions } from "../../components/modal/Modal";

// APIs
import {
  createAuditFileApi,
  updateAuditFileByIdApi,
  downloadAuditFileByIdApi,
  deleteAuditFileByIdApi,
} from "../../api/audit/FileAPI";

import { getAuditFileListApi } from "../../api/audit/FileListAPI";

// Create Audit File
function* createAuditFile(action) {
  const { auditId, pageNumber, pageSize, formData, type = "", modalId = "" } = action.payload;

  try {
    yield createAuditFileApi(auditId, formData);

    const { items, pagination } = yield getAuditFileListApi(auditId, pageNumber, pageSize, type);

    // Dispatch Action
    yield put(
      AuditFileListActions.getAuditFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    // Dispatch Action
    yield put(AuditFileActions.createAuditFileSuccess());

    // Close Modal
    ModalActions.close(modalId);
  } catch (error) {
    // Close Modal
    ModalActions.close(modalId);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.createAuditFileFailure({ error }));
  }
}

// Update Audit File By Id
function* updateAuditFileById(action) {
  const { auditId, fileId, pageNumber, pageSize, formData, type = "", modalId = "" } = action.payload;

  try {
    yield updateAuditFileByIdApi(auditId, fileId, formData);

    const { items, pagination } = yield getAuditFileListApi(auditId, pageNumber, pageSize, type);

    // Dispatch Action
    yield put(
      AuditFileListActions.getAuditFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    // Dispatch Action
    yield put(AuditFileActions.updateAuditFileByIdSuccess());

    // Close Modal
    ModalActions.close(modalId);
  } catch (error) {
    // Close Modal
    ModalActions.close(modalId);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.updateAuditFileByIdFailure({ error }));
  }
}

// Download Audit File By Id
function* downloadAuditFileById(action) {
  const { fileId, apiUrl = "", fileName = "" } = action.payload;

  try {
    const data = yield downloadAuditFileByIdApi(apiUrl);

    // Save File
    saveFile(fileName, data);

    // Dispatch Action
    yield put(AuditFileActions.downloadAuditFileByIdSuccess({ fileId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.downloadAuditFileByIdFailure({ fileId, error }));
  }
}

// Delete Audit File By Id
function* deleteAuditFileById(action) {
  const { auditId, fileId, pageNumber, pageSize, type = "" } = action.payload;

  try {
    yield deleteAuditFileByIdApi(auditId, fileId);

    const { items, pagination } = yield getAuditFileListApi(auditId, pageNumber, pageSize, type);

    // Dispatch Action
    yield put(
      AuditFileListActions.getAuditFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    // Dispatch Action
    yield put(AuditFileActions.deleteAuditFileByIdSuccess({ fileId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.deleteAuditFileByIdFailure({ fileId, error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(AuditFileActions.createAuditFile.type, createAuditFile),
    takeLatest(AuditFileActions.updateAuditFileById.type, updateAuditFileById),
    takeLatest(AuditFileActions.downloadAuditFileById.type, downloadAuditFileById),
    takeLatest(AuditFileActions.deleteAuditFileById.type, deleteAuditFileById),
  ]);
}
