// Urls
import {
  addPlantComponentPartByIdUrl,
  deletePlantComponentPartByIdUrl,
  updatePlantComponentPartByIdUrl,
} from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Add Plant Component Part
export async function addPlantComponentPartByIdApi(partData, plantId, componentId) {
  const url = URLs.format(addPlantComponentPartByIdUrl, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, partData);
}

// Update Plant Component Part
export async function updatePlantComponentPartByIdApi(partData, plantId, componentId, partId) {
  const url = URLs.format(updatePlantComponentPartByIdUrl, { plantId, componentId, partId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, partData);
}

// Delete Plant Component Part
export async function deletePlantComponentPartByIdApi(plantId, componentId, partId) {
  const url = URLs.format(deletePlantComponentPartByIdUrl, { plantId, componentId, partId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.delete(url);
}
