import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantElectricityBillListActions } from "../../redux-slice/plant/ElectricityBillListSlice";
import { PlantElectricityBillActions } from "../../redux-slice/plant/ElectricityBillSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// Utils
import { saveFile } from "../../utils/FileUtils";

// Components
import { ModalActions } from "../../components/modal/Modal";

// APIs
import {
  createPlantElectricityBillApi,
  downloadPlantElectricityBillByIdApi,
  updatePlantElectricityBillByIdApi,
  uploadPlantElectricityBillByIdApi,
} from "../../api/plant/ElectricityBillAPI";

import { getPlantElectricityBillListByIdApi } from "../../api/plant/ElectricityBillListAPI";

// Create Plant Electricity Bill
function* createPlantElectricityBill(action) {
  try {
    const { formData, plantId } = action.payload;

    yield createPlantElectricityBillApi(formData, plantId);

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId);

    // Dispatching Action to refresh plant electricity bill list
    yield put(
      PlantElectricityBillListActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(PlantElectricityBillActions.createPlantElectricityBillSuccess());

    // Toaster
    toast.success("Electricity Bill Added Successfully");

    // Close modal
    ModalActions.close(ModalKeys.addPlantElectricityBillModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.addPlantElectricityBillModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.createPlantElectricityBillFailure({ error }));
  }
}

// Update Plant Electricity Bill
function* updatePlantElectricityBillById(action) {
  try {
    const { formData, plantId, electricityBillId } = action.payload;

    yield updatePlantElectricityBillByIdApi(formData, plantId, electricityBillId);

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId);

    // Dispatching Action to refresh plant electricity bill list
    yield put(
      PlantElectricityBillListActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(PlantElectricityBillActions.updatePlantElectricityBillSuccess());

    // Toaster
    toast.success("Electricity Bill Updated Successfully");
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.viewPlantElectricityBillModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.updatePlantElectricityBillFailure({ error }));
  }
}

// Upload Plant Electricity Bill By Id
function* uploadPlantElectricityBillById(action) {
  const { formData, plantId, electricityBillId } = action.payload;

  try {
    yield uploadPlantElectricityBillByIdApi(formData, plantId, electricityBillId);

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId);

    // Dispatching action to refresh thermal bill list table
    yield put(
      PlantElectricityBillListActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(PlantElectricityBillActions.uploadPlantElectricityBillSuccess({ electricityBillId }));

    // Toaster
    toast.success("Electricity Bill Uploaded Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.uploadPlantElectricityBillFailure({ electricityBillId, error }));
  }
}

// Download Plant Electricity Bill By Id
function* downloadPlantElectricityBillById(action) {
  const { apiUrl = "", electricityBillId = "" } = action.payload;

  try {
    const data = yield downloadPlantElectricityBillByIdApi(apiUrl);

    saveFile("Electricity Bill", data);

    // Dispatching Action
    yield put(PlantElectricityBillActions.downloadPlantElectricityBillSuccess({ electricityBillId }));

    // Toaster
    toast.success("Electricity Bill Downloaded Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.downloadPlantElectricityBillFailure({ electricityBillId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantElectricityBillActions.createPlantElectricityBill.type, createPlantElectricityBill),
    takeLatest(PlantElectricityBillActions.updatePlantElectricityBill.type, updatePlantElectricityBillById),
    takeLatest(PlantElectricityBillActions.uploadPlantElectricityBill.type, uploadPlantElectricityBillById),
    takeLatest(PlantElectricityBillActions.downloadPlantElectricityBill.type, downloadPlantElectricityBillById),
  ]);
}
