/**
 * Check Box
 * @param {*} containerClassName
 * @param {*} label : Label
 * @param {*} inputBoxStyle : ClassName For Input
 * @param {*} onChange : Function
 * @param {*} disabled : Boolean Value
 * @param {*} checked : Boolean Value
 */
export default function CheckBox({
  containerClassName = "",
  id = "",
  label = "",
  inputBoxStyle = "",
  onChange = () => {},
  disabled = false,
  checked = false,
}) {
  return (
    <div className={`form-check ${containerClassName}`}>
      <input
        id={id}
        type="checkbox"
        className={`form-check-input shadow-none ${inputBoxStyle}`}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {label && (
        <label className="form-check-label px-1" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
}
