import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";
import { UserListActions } from "../../../redux-slice/user/UserListSlice";
import { UserActions } from "../../../redux-slice/user/UserSlice";
import { RoleListActions } from "../../../redux-slice/role/RoleListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import { getOrgType } from "../../../utils/AuthOrgUtil";
import { bcForUsersListPage } from "../../../utils/BreadcrumbUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import AutoComplete from "../../../components/auto-complete/AutoComplete";
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";
import Modal from "../../../components/modal/Modal";

// Sections
import UserListTable from "./UserListTable.jsx";

// Page Component
function AssignRoleAction({ userId, orgId }) {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [error, setError] = useState("");

  // Role List Selector State
  const roleList = useSelector((state) => state.roleList.roleList);

  // Assign Role Selector State
  const assignRoleToUserLoading = useSelector((state) => state.user.assignRoleToUserLoading);

  // Getting organization Type
  const orgType = getOrgType();

  // Construct Options Array
  function constructOptions(roleList = []) {
    const optionArray = roleList.reduce((acc, role) => {
      const { id, name } = role;

      return [...acc, { id, value: name }];
    }, []);

    return optionArray;
  }

  // Handle Submit
  function handleSubmit() {
    // If id is not present
    if (!selectedRoleId) {
      return setError("Please Select Role!!!");
    }

    // Clearing errors
    setError("");

    // Dispatch
    dispatch(UserActions.assignUserRole({ orgId, orgType, userId, roleId: selectedRoleId }));
  }

  return (
    <>
      {/* Auto Complete */}
      <AutoComplete
        id="role-list"
        options={constructOptions(roleList)}
        placeholder="Search Role..."
        onChange={({ target }) => {
          setSelectedRoleId(target.value);
        }}
        loading={assignRoleToUserLoading}
        disabled={assignRoleToUserLoading}
        error={error}
      />

      <div className="mt-2">
        {/* Button */}
        <Button label="Assign" size="sm" color="warning" onClick={handleSubmit} disabled={assignRoleToUserLoading} />
      </div>
    </>
  );
}

function PageHeaderSection() {
  // Navigate
  const navigate = useNavigate();

  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Org info
  const { id: orgId = "", orgType = "", name = "" } = orgInfo;

  // Navigate to user create page
  function navigateToUserCreatePage() {
    const redirectUrl = URLs.format(RedirectTo.UserCreatePageUrl, { orgId });
    navigate(redirectUrl);
  }

  // Content
  const pageActions = (
    <div className="btn-cont">
      <Button label="Add User" onClick={navigateToUserCreatePage}>
        <i className="fas fa-plus"></i>
      </Button>
    </div>
  );

  return <PageHeader breadcrumbList={bcForUsersListPage(name, orgType)} actions={pageActions} className="w-100 mt-2" />;
}

function TableActionsSection({}) {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search Users...`} isSearchButton={true} />
    </div>
  );
}

/**
 * User List Page
 */
export default function UserListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const { orgId = "", plantId = "" } = useParams();

  // Update Query Params Hook
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent = () => {}] = useOutletContext();

  // State
  const [userId, setUserId] = useState("");

  // User List Selector State
  const userList = useSelector((state) => state.userList.userList);
  const userListLoading = useSelector((state) => state.userList.userListLoading);
  const userListPagination = useSelector((state) => state.userList.userListPagination);

  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(RoleListActions.getRoleList({}));
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(UserListActions.getUserList({ orgId, pageNumber, pageSize, searchText }));
  }, [dispatch, orgId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  // use Memo
  const filteredUserList = useMemo(() => {
    // For plant specific users, first, the list of all users for that industry is taken
    // Form that users only to the selected plant is filtered out
    if (plantId) {
      return userList.filter((user) => parseInt(user?.plantId || 0) === parseInt(plantId)) || [];
    }

    return userList;
  }, [plantId, userList]);

  return (
    <>
      {/* Page Content */}
      <div className="page-content">
        {/* Table Actions Section */}
        <TableActionsSection orgInfo={orgInfo} />

        {/* User List Table */}
        <UserListTable
          userList={filteredUserList}
          userListLoading={userListLoading}
          userListPagination={userListPagination}
          pageNumber={pageNumber}
          setUserId={setUserId}
        />
      </div>

      {/* Modal */}
      <Modal id={ModalKeys.assignRoleComponentModal} title="Assign Role" className="modal-dialog-centered">
        {/* Assign Role Action */}
        <AssignRoleAction userId={userId} orgId={orgId} />
      </Modal>
    </>
  );
}
