// Page Components
function DataColumnMappingsTableTitleRow({ eachKey }) {
  return (
    <tr className="tr-sec-header">
      <td colSpan={3}>{eachKey}</td>
    </tr>
  );
}

function DataColumnMappingsTableHeaderRow() {
  return (
    <tr className="table-primary text-center fw-semibold">
      <td>Parameter Name</td>
      <td>Column Name</td>
    </tr>
  );
}

function DataColumnMappingsTable({ eachKey, columnMappings }) {
  const componentMappings = columnMappings[eachKey];

  return (
    <div className="table-responsive mt-4">
      <table className="table table-compact">
        <tbody>
          {/* Data Column Mappings Table Title Row */}
          <DataColumnMappingsTableTitleRow eachKey={eachKey} />

          {/* Data Column Mappings Table Header Row */}
          <DataColumnMappingsTableHeaderRow />

          {componentMappings.map((mapping, index) => {
            const { parameterName, parameterUnit, dataColumnDefName, dataColumnDefUnit } = mapping;

            return (
              <tr key={index} className="text-center">
                <td>
                  {parameterName} ({parameterUnit})
                </td>
                <td>
                  {dataColumnDefName} ({dataColumnDefUnit})
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Instrument Data Column Mappings Card
 */
export default function InstrumentDataColumnMappingsCard({ columnMappings }) {
  const columnMappingsKeys = Object.keys(columnMappings);

  // Checking whether data is present or not
  const isDataAvailable = columnMappingsKeys.length === 0;

  return (
    <div className="card p-3">
      <div className="card-body">
        <h5 className="card-title">Output File Column to Component Parameter Mapping</h5>

        {isDataAvailable && <div>No mappings data present</div>}

        {!isDataAvailable &&
          columnMappingsKeys.map((eachKey, index) => {
            return <DataColumnMappingsTable key={index} eachKey={eachKey} columnMappings={columnMappings} />;
          })}
      </div>
    </div>
  );
}
