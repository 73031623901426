/**
 * Table Loader Component
 * @param {*} colSpan : Number
 */
export default function TableLoader({ colSpan }) {
  return (
    <tr className="text-center">
      <td colSpan={colSpan}>
        <div className="d-flex justify-content-center mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </td>
    </tr>
  );
}
