// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getJobListUrl } from "../../urls/api-urls/JobURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Job List
export async function getJobListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getJobListUrl, params);
}
