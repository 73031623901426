// Urls
import {
  createPlantElectricityBillUrl,
  updatePlantElectricityBillByIdUrl,
  uploadPlantElectricityBillByIdUrl,
} from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// App Constants
import { ContentType } from "../../app/http/HttpConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Create Plant Electricity Bill
export async function createPlantElectricityBillApi(formData, plantId) {
  const url = URLs.format(createPlantElectricityBillUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData);
}

// Update Plant Electricity Bill By Id
export async function updatePlantElectricityBillByIdApi(formData, plantId, electricityBillId) {
  const url = URLs.format(updatePlantElectricityBillByIdUrl, { plantId, electricityBillId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}

// Upload Plant Electricity Bill By Id
export async function uploadPlantElectricityBillByIdApi(formData, plantId, electricityBillId) {
  const url = URLs.format(uploadPlantElectricityBillByIdUrl, { plantId, electricityBillId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

// Download Plant Electricity Bill By Id
export async function downloadPlantElectricityBillByIdApi(url) {
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadPublicFile(url);
}
