import { useSelector } from "react-redux";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";

// Components
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Parent Code",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
];

// Page Components
function NICCodeListTableRow({ serialNo, nicCode }) {
  const { code, description, parentCode } = nicCode;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{parentCode}</td>
      <td>{code}</td>
      <td>{description}</td>
    </tr>
  );
}

function NICCodeListTableBody({ nicCodeListLoading = false, nicCodeList = [], pageSize, pageNumber }) {
  //Loader
  if (nicCodeListLoading) {
    return <TableLoader colSpan={5} />;
  }

  //if there is no nic list
  if (nicCodeList.length === 0) {
    return <TableDataNotFound message="No nic codes present" colSpan={5} />;
  }

  //NIC list table row
  return (
    <>
      {nicCodeList.map((nicCode, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return <NICCodeListTableRow key={nicCode.id} serialNo={serialNo} nicCode={nicCode} />;
      })}
    </>
  );
}

/**
 * NIC Code List Table
 */
export default function NICCodeListTable() {
  // Selector States
  const nicCodeList = useSelector((state) => state.nicCodeList.nicCodeList);
  const nicCodeListLoading = useSelector((state) => state.nicCodeList.nicCodeListLoading);
  const nicCodeListPagination = useSelector((state) => state.nicCodeList.nicCodeListPagination);

  const { totalCount, pageNo, pageSize } = nicCodeListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* NIC Code List Table Body */}
          <NICCodeListTableBody
            nicCodeListLoading={nicCodeListLoading}
            nicCodeList={nicCodeList}
            pageSize={pageSize}
            pageNumber={pageNo}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
