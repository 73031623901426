// API
import { getAuditInfoApi } from "../api/audit/AuditAPI";

export async function auditInfoLoader(auditId) {
  //TODO: How do we add the loader (Spinner)
  try {
    const auditInfo = await getAuditInfoApi(auditId);
    return { auditInfo };
  } catch (error) {
    // TODO :: How do we handle the Errors in the Loaders.
    // dispatch(setErrorInfo({ errorInfo: error, showToaster: true }));
    return {};
  }
}
