// Contruct formik initial values
function constructFormikValues({ formInputItems = [], data }) {
  // Getting all the input items
  const flattenFormInputItemsArray = formInputItems.reduce((acc, formGroup) => {
    const { inputItems = [] } = formGroup || {};

    return [...acc, ...inputItems];
  }, []);

  // Form Input
  const initialValues = flattenFormInputItemsArray.reduce((acc, inputItem) => {
    const { key, defaultInputValue = "" } = inputItem;

    // Creates a new key value pair
    acc[key] = `${data[key] ?? defaultInputValue}`;

    // Adds the created key value pair to intial object
    return acc;
  }, {});

  return initialValues;
}

const FormUtils = { constructFormikValues };

export default FormUtils;
