// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getUserListUrl } from "../../urls/api-urls/UserURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get User List
export async function getUserListApi(orgId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    org: orgId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getUserListUrl, params);
}
