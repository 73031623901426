import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Instrument Info By Id
  instrumentInfo: {},
  instrumentInfoLoading: false,
  instrumentInfoError: "",

  // Update Instrument Status (Enable/Disable)
  updateInstrumentStatusLoading: {},
  updateInstrumentStatusError: "",

  // Get Data Column Info By Id
  dataColumnInfo: [],
  dataColumnInfoLoading: false,
  dataColumnInfoError: "",
};

// Instrument Slice
const instrumentSlice = createSlice({
  name: "Instrument",
  initialState,
  reducers: {
    // Get Instrument Info
    getInstrumentInfo: (state) => {
      state.instrumentInfoLoading = true;
    },

    getInstrumentInfoSuccess: (state, action) => {
      state.instrumentInfoLoading = false;
      state.instrumentInfo = action.payload.instrumentInfo;
    },

    getInstrumentInfoFailure: (state, action) => {
      state.instrumentInfoLoading = false;
      state.instrumentInfoError = action.payload.error;
    },

    // Update Instrument Status (Enable/Disable)
    updateInstrumentStatus: (state, action) => {
      const { instrumentId } = action.payload;

      state.updateInstrumentStatusLoading = { ...state.updateInstrumentStatusLoading, [instrumentId]: true };
    },

    updateInstrumentStatusSuccess: (state, action) => {
      const { instrumentId } = action.payload;

      //To get previous state
      const clonedUpdateInstrumentStatusLoading = { ...state.updateInstrumentStatusLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateInstrumentStatusLoading[instrumentId];

      state.updateInstrumentStatusLoading = clonedUpdateInstrumentStatusLoading;
    },

    updateInstrumentStatusFailure: (state, action) => {
      const { instrumentId, error } = action.payload;

      //To get previous state
      const clonedUpdateInstrumentStatusLoading = { ...state.updateInstrumentStatusLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateInstrumentStatusLoading[instrumentId];

      state.updateInstrumentStatusLoading = clonedUpdateInstrumentStatusLoading;
      state.updateInstrumentStatusError = error;
    },

    // Get Data Column Info
    getDataColumnInfo: (state) => {
      state.dataColumnInfoLoading = true;
    },

    getDataColumnInfoSuccess: (state, action) => {
      state.dataColumnInfoLoading = false;
      state.dataColumnInfo = action.payload.dataColumnInfo;
    },

    getDataColumnInfoFailure: (state, action) => {
      state.dataColumnInfoLoading = false;
      state.dataColumnInfoError = action.payload.error;
    },
  },
});

// Reducer
export const InstrumentReducer = instrumentSlice.reducer;

// Actions
export const InstrumentActions = instrumentSlice.actions;
