import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { RoleActions } from "../../redux-slice/role/RoleSlice";
import { RoleListActions } from "../../redux-slice/role/RoleListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// APIs
import { getRoleListApi } from "../../api/role/RoleListAPI";
import { getRoleInfoByIdApi, createRoleByIdApi, updateRoleByIdApi } from "../../api/role/RoleAPI";

// Get Role Information By Id
function* getRoleInfoById(action) {
  try {
    const { roleId } = action.payload;

    const roleInfo = yield getRoleInfoByIdApi(roleId);

    yield put(RoleActions.getRoleInfoSuccess({ roleInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.getRoleInfoFailure({ error }));
  }
}

// Create Role By Id
function* createRoleById(action) {
  try {
    const { roleData, navigate } = action.payload;

    yield createRoleByIdApi(roleData);

    const { items, pagination } = yield getRoleListApi();

    // Dispatching action
    yield put(RoleListActions.getRoleListSuccess({ roleList: items, roleListPagination: pagination }));

    yield put(RoleActions.createRoleSuccess());

    // Toaster
    toast.success("Role Created Successfully");

    // Navigate
    navigate(RedirectTo.RoleListPageUrl);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.createRoleFailure({ error }));
  }
}

// Update Role By Id
function* updateRoleById(action) {
  try {
    const { roleId, roleData, navigate } = action.payload;

    yield updateRoleByIdApi(roleId, roleData);

    const { items, pagination } = yield getRoleListApi();

    // Dispatching action
    yield put(RoleListActions.getRoleListSuccess({ roleList: items, roleListPagination: pagination }));

    yield put(RoleActions.updateRoleSuccess());

    // Toaster
    toast.success("Role Updated Successfully");

    // Navigate
    navigate(RedirectTo.RoleListPageUrl);
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.updateRoleFailure());
  }
}

export default function* root() {
  yield all([
    takeLatest(RoleActions.getRoleInfo.type, getRoleInfoById),
    takeLatest(RoleActions.createRole.type, createRoleById),
    takeLatest(RoleActions.updateRole.type, updateRoleById),
  ]);
}
