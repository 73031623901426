import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect } from "react";

// Actions
import { MeasurementTypeListActions } from "../../../redux-slice/measurement-type/MeasurementTypeListSlice";
import { MeasurementTypeActions } from "../../../redux-slice/measurement-type/MeasurementTypeSlice";

// Components
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import MeasurementTypeInfoTable from "./MeasurementTypeInfoTable";
import MeasurementUnitsTable from "./MeasurementUnitsTable";

// Page Components
function MeasurementTypeInfoCard({ measurementTypeInfo, measurementTypeInfoLoading }) {
  return (
    <div className="card p-3">
      <div className="card-body">
        <h5 className="card-title">Measurement Type Info</h5>

        {/* Measurement Type Information Table */}
        <MeasurementTypeInfoTable
          measurementTypeInfo={measurementTypeInfo}
          measurementTypeInfoLoading={measurementTypeInfoLoading}
        />
      </div>
    </div>
  );
}

function MeasurementUnitsCard() {
  return (
    <div className="card p-3">
      <div className="card-body">
        <h5 className="card-title">Measurement Units</h5>

        {/* Units Table */}
        <MeasurementUnitsTable />
      </div>
    </div>
  );
}

/**
 * Measurement Type View Page
 */
export default function MeasurementTypeViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { measurementTypeId } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Measurement Type Info Selector State
  const measurementTypeInfo = useSelector((state) => state.measurementType.measurementTypeInfo);

  // Data
  const { name: measurementTypeName = "" } = measurementTypeInfo;

  // useEffect
  useEffect(() => {
    dispatch(MeasurementTypeActions.getMeasurementTypeInfo({ measurementTypeId }));
    dispatch(MeasurementTypeListActions.getMeasurementUnitsList({ measurementTypeId }));
  }, [dispatch, measurementTypeId]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={`Measurement Type :: ${measurementTypeName}`} />);
  }, [measurementTypeName]);

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-md-5">
          {/* Measurement Type Information Card */}
          <MeasurementTypeInfoCard />
        </div>
        <div className="col-md-7">
          {/* Measurement Units Card */}
          <MeasurementUnitsCard />
        </div>
      </div>
    </div>
  );
}
