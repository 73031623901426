import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantThermalBillActions } from "../../redux-slice/plant/ThermalBillSlice";
import { PlantThermalBillListActions } from "../../redux-slice/plant/ThermalBillListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// Utils
import { saveFile } from "../../utils/FileUtils";

// Components
import { ModalActions } from "../../components/modal/Modal";

// APIs
import {
  createPlantThermalBillApi,
  downloadPlantThermalBillByIdApi,
  updatePlantThermalBillByIdApi,
  uploadPlantThermalBillByIdApi,
} from "../../api/plant/ThermalBillAPI";

import { getPlantThermalBillListByIdApi } from "../../api/plant/ThermalBillListAPI";

// Create Plant Thermal Bill
function* createPlantThermalBill(action) {
  try {
    const { formData, plantId } = action.payload;

    yield createPlantThermalBillApi(formData, plantId);

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId);

    // Dispatching Action to refresh plant component list
    yield put(
      PlantThermalBillListActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(PlantThermalBillActions.createPlantThermalBillSuccess());

    // Toaster
    toast.success("Thermal Bill Added Successfully");

    // Close modal
    ModalActions.close(ModalKeys.addPlantThermalBillModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.addPlantThermalBillModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.createPlantThermalBillFailure({ error }));
  }
}

// Update Plant Thermal Bill
function* updatePlantThermalBillById(action) {
  try {
    const { formData, plantId, thermalBillId } = action.payload;

    yield updatePlantThermalBillByIdApi(formData, plantId, thermalBillId);

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId);

    // Dispatching Action to refresh plant component list
    yield put(
      PlantThermalBillListActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(PlantThermalBillActions.updatePlantThermalBillSuccess());

    // Toaster
    toast.success("Thermal Bill Updated Successfully");
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.viewPlantThermalBillModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.updatePlantThermalBillFailure({ error }));
  }
}

// Upload Plant Thermal Bill By Id
function* uploadPlantThermalBillById(action) {
  const { formData, plantId, thermalBillId } = action.payload;

  try {
    yield uploadPlantThermalBillByIdApi(formData, plantId, thermalBillId);

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId);

    // Dispatching action to refresh thermal bill list table
    yield put(
      PlantThermalBillListActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(PlantThermalBillActions.uploadPlantThermalBillSuccess({ thermalBillId }));

    // Toaster
    toast.success("Thermal Bill Uploaded Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.uploadPlantThermalBillFailure({ thermalBillId, error }));
  }
}

// Download Plant Thermal Bill By Id
function* downloadPlantThermalBillById(action) {
  const { apiUrl = "", thermalBillId } = action.payload;

  try {
    const data = yield downloadPlantThermalBillByIdApi(apiUrl);

    saveFile("Thermal Bill", data);

    // Dispatching Action
    yield put(PlantThermalBillActions.downloadPlantThermalBillSuccess({ thermalBillId }));

    // Toaster
    toast.success("Thermal Bill Downloaded Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.downloadPlantThermalBillFailure({ thermalBillId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantThermalBillActions.createPlantThermalBill.type, createPlantThermalBill),
    takeLatest(PlantThermalBillActions.updatePlantThermalBill.type, updatePlantThermalBillById),
    takeLatest(PlantThermalBillActions.uploadPlantThermalBill.type, uploadPlantThermalBillById),
    takeLatest(PlantThermalBillActions.downloadPlantThermalBill.type, downloadPlantThermalBillById),
  ]);
}
