// Urls
import { getAuditReportsListUrl } from "../../urls/api-urls/AuditURL";

// Utils
import URLs from "../../app/utils/URLs";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Audit File List
export async function getReportsListApi(auditId) {
  // Api Url
  const apiUrl = URLs.format(getAuditReportsListUrl, { auditId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiUrl);
}
