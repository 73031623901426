import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditDataActions } from "../../../../redux-slice/audit/DataSlice";

// Components
import { Button } from "../../../../components/button/Button";
import TableHeaders from "../../../../components/table/TableHeader";

// Page Constants
const dataTableBody = [
  {
    description: "Download Performance Analysis Excel",
    btnName: "Download Data",
    btnColor: "success",
    iconClassName: "fa-download",
    loadingState: (state) => state.auditData.downloadPerfAnalysisLoading,
    dispatchAtn: AuditDataActions.downloadPerfAnalysisData,
  },
  {
    description: "Download Recommendation Excel",
    btnName: "Download Data",
    btnColor: "success",
    iconClassName: "fa-download",
    loadingState: (state) => state.auditData.downloadRecommendationLoading,
    dispatchAtn: AuditDataActions.downloadRecommendationData,
  },
];

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function AuditReportsAndDataTableRow({ eachBtn = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Each row
  const {
    description = "",
    loadingState = () => {},
    btnName = "",
    btnColor = "",
    iconClassName = "",
    dispatchAtn = () => {},
  } = eachBtn || {};

  // Loading Selector State
  const loadingStatus = useSelector(loadingState) || false;

  return (
    <tr>
      <td>{description}</td>
      <td>
        {/* Download Button */}
        <Button
          label={btnName}
          loading={loadingStatus}
          disabled={loadingStatus}
          onClick={() => {
            dispatch(dispatchAtn({ auditId }));
          }}
          className="me-3"
          size="sm"
          color={btnColor}
        >
          {iconClassName && <i className={`fa-solid ${iconClassName}`}></i>}
        </Button>
      </td>
    </tr>
  );
}

/**
 * Audit Data Page
 */
export default function AuditDataPage() {
  return (
    <div className="page-content">
      <div className="table-responsive">
        <h3>Data</h3>

        <table className="table table-bordered border-end mt-3">
          {/* Table Headers */}
          <TableHeaders tableHeaders={tableHeaders} />

          <tbody>
            {dataTableBody.map((eachBtn, index) => {
              return <AuditReportsAndDataTableRow key={index} eachBtn={eachBtn} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
