import * as yup from "yup";

// Validation Schema
export const PlantFormValidationSchema = yup.object({
  name: yup.string().required("Please fill the plant name"),
  internalId: yup.string().required("Please fill the internal Id"),
  areaInSqFt: yup.number().required("Please fill the area").moreThan(0, "Area should not be zero"),
  estYear: yup
    .number()
    .required("Please fill the established year")
    .moreThan(1000, "Year should be four digit")
    .max(new Date().getFullYear(), "Exceeds present year"),
  // workHrsPerDay: yup
  //   .number()
  //   .required("Please fill the working hours per day")
  //   .moreThan(0, "Hours should not be zero")
  //   .max(24, "It should not be more than 24 hrs"),
  // employeesCount: yup
  //   .number()
  //   .required("Please fill the employee count")
  //   .moreThan(0, "Employee count should not be zero"),
  addrLine1: yup.string().required("Please fill the address"),
  city: yup.string().required("Please fill the city"),
  state: yup.string().required("Please fill the state"),
  pincode: yup
    .string()
    .required("Please fill the pincode")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

// Form Inputs
export const PlantFormSchema = [
  {
    title: "Plant Info",
    titleClassName: "fw-bold",
    containerClassName: "row",
    inputItems: [
      {
        label: "Name",
        key: "name",
        placeholder: "Enter plant name...",
        isMandotary: true,
      },
      {
        label: "Internal Id",
        key: "internalId",
        placeholder: "Enter internal Id...",
        isMandotary: true,
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Area (Sq ft)",
        key: "areaInSqFt",
        type: "number",
        placeholder: "Enter area...",
        isMandotary: true,
      },
      {
        label: "Established Year",
        key: "estYear",
        type: "number",
        placeholder: "Select established year...",
        isMandotary: true,
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Working Hours / Day",
        key: "workHrsPerDay",
        type: "number",
        placeholder: "Enter working hours per day...",
      },
      {
        label: "Working Days / Week",
        key: "workDaysPerWeek",
        type: "select",
        options: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }],
      },
      {
        label: "Employee Count",
        key: "employeesCount",
        type: "number",
        placeholder: "Enter total employee count...",
      },
    ],
  },
  {
    title: "Address",
    titleClassName: "fw-bold",
    containerClassName: "row",
    inputItems: [
      {
        label: "line 1",
        key: "addrLine1",
        placeholder: "Enter first line...",
        isMandotary: true,
      },
      {
        label: "line 2",
        key: "addrLine2",
        placeholder: "Enter second line...",
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "City",
        key: "city",
        placeholder: "Enter city name...",
        isMandotary: true,
      },
      {
        label: "State",
        key: "state",
        placeholder: "Enter state name...",
        isMandotary: true,
      },
      {
        label: "Pincode",
        key: "pincode",
        type: "number",
        placeholder: "Enter pincode...",
        isMandotary: true,
      },
      {
        label: "Geo Location",
        key: "geoLocation",
        placeholder: "Enter geo location of the plant...",
      },
    ],
  },
];
