//Page Component

/**
 * Header
 *@param {*}  header:object
 *
 */
 function Header({ header }) {
    if (header.element) {
      return header.element;
    }
    const { title, icon = false } = header;
  
    // If No Icon
    if (!icon) {
      return <th className={title?.className}>{title?.displayName}</th>;
    }
  
    // If Icon is there
    return (
      <th className={title.className}>
        <span>{title.displayName}</span>
        <i className="fa-solid fa-sort fa-sm ps-2"></i>
      </th>
    );
  }
  
  /**
   * Table Headers
   * @param {*} tableHeaders :array
   *
   */
  export default function TableHeaders({ tableHeaders }) {
    return (
      <thead>
        <tr>
          {tableHeaders.map((header, index) => (
            <Header header={header} key={index} />
          ))}
        </tr>
      </thead>
    );
  }
  