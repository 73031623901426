import { useNavigate } from "react-router-dom";

// Utils
import { getUserInfo } from "../../utils/AuthOrgUtil";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Sidebar Footer
export default function SiderbarFooter({ showSideBar }) {
  // Navigate
  const navigate = useNavigate();

  // Icon ClassName
  const iconClassName = showSideBar ? "fa-xl me-3" : "menu-icons mt-2";

  // Auth User Information
  const { firstName = "", lastName = "", org = {} } = getUserInfo();
  const { name = "" } = org;

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        navigate(RedirectTo.ProfilePageUrl);
      }}
    >
      <div className="d-flex align-items-center">
        <div>
          <i className={`fa fa-gear fa-xl ${iconClassName}`}></i>
        </div>

        {showSideBar && (
          <div className="ps-3 border-start">
            {/* User Information */}
            <div className="fs-5 fw-semibold">
              {firstName} {lastName}
            </div>

            {/* Organization Information */}
            <div>{name}</div>
          </div>
        )}
      </div>
    </div>
  );
}
