/**
 * fix Decimal
 * @param {*} number : Value
 * @param {*} noOfDecimals : Number of decimals to be shown
 * @returns number
 */
function fixDecimal(number, noOfDecimals = 2) {
  // If its not an number
  if (!number || typeof number !== "number") {
    return number;
  }

  return number.toFixed(noOfDecimals);
}

const DecimalUtils = {
  //
  fixDecimal,
};

export default DecimalUtils;
