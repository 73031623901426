import { useMemo, useState } from "react";

// Component
import Loader from "../loader/Loader";
import ErrorMessage from "../error-message/ErrorMessage";

// Convert string to upper case
function convertStringToUpperCase(text = "") {
  // If its a number, then it is converted to string
  if (typeof text === "number") {
    return `${text}`;
  }

  return text.toUpperCase();
}

// Page Components
function DropdownButtonLoading({ loading }) {
  // Loader
  if (loading) {
    return <Loader spinnerClassName="spinner-border-sm" />;
  }

  return <div className="dropdown-toggle"></div>;
}

function DropdownButton({ placeholder, value, loading, disabled, error }) {
  // use Memo
  const placeholderText = useMemo(() => {
    // If there is no value selected, then placeholder is shown
    if (!value) {
      return placeholder;
    }

    return value;
  }, [value, placeholder]);

  return (
    <div data-bs-toggle={`${disabled ? "disabled" : "dropdown"}`} aria-expanded="false">
      <div
        className={`d-flex justify-content-between ${disabled ? "bg-body-secondary" : ""} ${
          error ? "border-danger" : ""
        } form-control`}
      >
        <div>{placeholderText}</div>

        {/* Dropdown Button Loading */}
        <DropdownButtonLoading loading={loading} />
      </div>
    </div>
  );
}

function DropdownSearchInput({ searchText = "", setSearchText }) {
  // Handle Search
  function handleSearch(e) {
    const searchedText = e.target?.value || "";
    setSearchText(searchedText);
  }

  return (
    <li className="mx-2">
      <input
        value={searchText}
        className="form-control shadow-none"
        type="text"
        placeholder="Search.."
        id="myInput"
        onChange={handleSearch}
      />
    </li>
  );
}

function DropdownOptions({ optionsArray, onChange }) {
  return optionsArray.map((eachOption) => {
    const { value = "" } = eachOption || {};

    return (
      <li className="dropdown-item" key={value} onClick={() => onChange(eachOption)}>
        {value}
      </li>
    );
  });
}

/**
 * Search Dropdown
 * @param {*} options : Array of objects
 * @param {*} onChange : Function
 * @param {*} placeholder : Text
 * @param {*} containerClassName : Class name
 * @param {*} loading : Boolean
 * @param {*} disabled : Boolean
 */
export default function SearchDropdown({
  value = "",
  options = [],
  onChange = () => {},
  placeholder = "Select...",
  containerClassName = "",
  loading = false,
  disabled = false,
  error = "",
}) {
  // State
  const [searchText, setSearchText] = useState("");

  // use Memo
  const optionsArray = useMemo(() => {
    // If search text, then all options are returned
    if (!searchText) {
      return options;
    }

    // Based on the search, the options are filtered
    const filteredOptions = options.reduce((acc, eachOption) => {
      const { value = "" } = eachOption || {};

      // Converting to upper cases
      const selectedValue = convertStringToUpperCase(value);
      const searchedText = convertStringToUpperCase(searchText);

      if (selectedValue.indexOf(searchedText) > -1) {
        return [...acc, eachOption];
      }

      return acc;
    }, []);

    return filteredOptions;
  }, [searchText, options]);

  // Reset Search
  function resetSearch() {
    setSearchText("");
  }

  return (
    <div onClick={resetSearch} className={`dropdown cursor-pointer ${containerClassName}`}>
      {/* Dropdown Button */}
      <DropdownButton placeholder={placeholder} value={value} loading={loading} disabled={disabled} error={error} />

      <ul className="dropdown-menu col-12">
        {/* Dropdown Search Input */}
        <DropdownSearchInput searchText={searchText} setSearchText={setSearchText} />

        <li className="mx-2 mt-2">{optionsArray.length === 0 ? "No search results found" : ""}</li>

        {/* Dropdown Options */}
        <DropdownOptions onChange={onChange} optionsArray={optionsArray} />
      </ul>

      {/* To show error message */}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}
