import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Audit Plant Component List
  auditPlantComponentList: [],
  auditPlantComponentListLoading: false,
  auditPlantComponentListError: "",
};

const auditComponentListSlice = createSlice({
  name: "AuditComponentList",
  initialState,
  reducers: {
    // Get Audit Plant Component List
    getAuditPlantCompList: (state) => {
      state.auditPlantComponentListLoading = true;
    },

    getAuditPlantCompListSuccess: (state, action) => {
      state.auditPlantComponentListLoading = false;
      state.auditPlantComponentList = action.payload.auditPlantComponentList;
    },

    getAuditPlantCompListFailure: (state, action) => {
      state.auditPlantComponentListLoading = false;
      state.auditPlantComponentListError = action.payload.error;
    },
  },
});

// Reducer
export const AuditComponentListReducer = auditComponentListSlice.reducer;

// Actions
export const AuditComponentListActions = auditComponentListSlice.actions;
