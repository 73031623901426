import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { ComponentTypeListActions } from "../../../redux-slice/component-type/ComponentTypeListSlice";
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Input from "../../../components/input/Input";

// Sections
import RecommendationCodeListTable from "./RecommendationCodeListTable";

// Page Components
function RecommendationCodeListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search shadow-none" placeholder={`Search Components...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Recommendation Code List page
 */
export default function RecommendationCodeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { componentTypeId } = params;

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // Recommendation Code List Selector State
  const recCodeList = useSelector((state) => state.componentTypeList.recCodeList);
  const recommendationCodeListLoading = useSelector((state) => state.componentTypeList.recommendationCodeListLoading);
  const recommendationCodeListPagination = useSelector(
    (state) => state.componentTypeList.recommendationCodeListPagination
  );

  // Component Type Info Selector State
  const componentTypeInfo = useSelector((state) => state.componentType.componentTypeInfo);
  const componentTypeInfoLoading = useSelector((state) => state.componentType.componentTypeInfoLoading);

  // Page Header
  const { name = "" } = componentTypeInfo;
  const pageTitle = !componentTypeInfoLoading ? `${name} :: Recommendation Codes` : "";

  // use Effect
  useEffect(() => {
    dispatch(ComponentTypeActions.getComponentTypeInfo({ componentTypeId }));
    dispatch(ComponentTypeListActions.getRecCodeList({ componentTypeId, pageNumber, pageSize, searchText }));
  }, [dispatch, componentTypeId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={pageTitle} />);
  }, [name]);

  return (
    <div className="page-content">
      {/* Recommendation Code List Table Actions Section */}
      <RecommendationCodeListTableActionsSection />

      {/* Recommendation Code List Table */}
      <RecommendationCodeListTable
        recCodeList={recCodeList}
        recommendationCodeListLoading={recommendationCodeListLoading}
        recommendationCodeListPagination={recommendationCodeListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
