/**
 * Error Message Component
 * @param {*} error : Object
 */
export default function ErrorMessage({ error }) {
  //No error message
  if (!error) {
    return null;
  }

  return <div className="text-danger form-text">{error}</div>;
}
