// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Urls
import { createAuditFileUrl, updateAuditFileByIdUrl, deleteAuditFileByIdUrl } from "../../urls/api-urls/AuditURL";

// Utils
import URLs from "../../app/utils/URLs";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Audit File
export async function createAuditFileApi(auditId, formData) {
  // Api Url
  const apiUrl = URLs.format(createAuditFileUrl, { auditId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiUrl, {}, formData, ContentType.MULTIPART_FORM_DATA);
}

// Update Audit File By Id
export async function updateAuditFileByIdApi(auditId, fileId, formData) {
  // Api Url
  const apiUrl = URLs.format(updateAuditFileByIdUrl, { auditId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiUrl, {}, formData);
}

// Download Audit File By Id
export async function downloadAuditFileByIdApi(apiUrl) {
  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.downloadPublicFile(apiUrl);
}

// Delete Audit File By Id
export async function deleteAuditFileByIdApi(auditId, fileId) {
  // Api Url
  const apiUrl = URLs.format(deleteAuditFileByIdUrl, { auditId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiUrl);
}
