import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditComponentListActions } from "../../redux-slice/audit/ComponentListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getAuditPlantComponentListApi } from "../../api/audit/ComponentListAPI";

// Get Audit Plant Component List By Id
function* getAuditPlantComponentListById(action) {
  try {
    const { auditId } = action.payload;

    const auditPlantComponentList = yield getAuditPlantComponentListApi(auditId);

    // Dispatching Action
    yield put(AuditComponentListActions.getAuditPlantCompListSuccess({ auditPlantComponentList }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentListActions.getAuditPlantCompListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(AuditComponentListActions.getAuditPlantCompList.type, getAuditPlantComponentListById)]);
}
