import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { HSNCodeListActions } from "../../redux-slice/hsn-code/HSNCodeListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getHSNCodeListApi } from "../../api/hsn-code/HSNCodeListAPI";

// Get HSN Code List
function* getHSNCodeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield getHSNCodeListApi(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      HSNCodeListActions.getHSNCodeListSuccess({
        hsnCodeList: items,
        hsnCodeListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(HSNCodeListActions.getHSNCodeListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(HSNCodeListActions.getHSNCodeList.type, getHSNCodeList)]);
}
