import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

//Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { AuditListActions } from "../../../redux-slice/audit/AuditListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Utils
import { getIndustryOrAuditOrgId } from "../../../utils/AuthOrgUtil";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Page Components
import AuditListTable from "../../../page-components/audit-list/AuditListTable";
import AuditInitiateButton from "../../../page-components/audit-list/AuditInitiateButton";

// Page Components
function PageHeaderSection() {
  return <PageHeader title={"Audits Dashboard"} actions={<AuditInitiateButton />} className="w-100" />;
}

function AuditListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search Audits...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Audit List Page
 */
export default function AuditListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Selector State
  const auditList = useSelector((state) => state.auditList.auditList);
  const auditListLoading = useSelector((state) => state.auditList.auditListLoading);
  const auditListPagination = useSelector((state) => state.auditList.auditListPagination);

  // query params
  const orgId = getIndustryOrAuditOrgId();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  //Page number from url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);

  //use Effect
  useEffect(() => {
    dispatch(AuditListActions.getAuditList({ orgId, pageNumber, pageSize }));
  }, [dispatch, orgId, pageNumber, pageSize]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  return (
    <div className="page-content">
      {/* audit list page actions section */}
      <AuditListTableActionsSection />

      {/* audit list table */}
      <AuditListTable
        items={auditList}
        pagination={auditListPagination}
        loading={auditListLoading}
        showPagination={true}
      />
    </div>
  );
}
