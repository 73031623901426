// Urls
import { updateMaterialStatusByIdUrl } from "../../urls/api-urls/MaterialURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Update Material Status By ID
export async function updateMaterialStatusByIdApi(materialId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(updateMaterialStatusByIdUrl, { materialId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}
