/**
 * Label
 * @param {*} title : Text for label
 * @param {*} isMandatory : Boolean Value
 * @param {*} className
 */
export default function Label({ title = "", isMandatory = false, className = "" }) {
  return (
    <label className={`form-label ${className}`}>
      {title} {isMandatory ? <span className="text-danger">*</span> : ""}
    </label>
  );
}
