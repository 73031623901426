// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getPlantListUrl } from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Plant List
export async function getPlantListApi(orgId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    org: orgId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getPlantListUrl, params);
}
