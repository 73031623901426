import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentListActions } from "../../redux-slice/plant/ComponentListSlice";
import { PlantComponentPartsActions } from "../../redux-slice/plant/PartsSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// Components
import { ModalActions } from "../../components/modal/Modal";

// APIs
import {
  addPlantComponentPartByIdApi,
  deletePlantComponentPartByIdApi,
  updatePlantComponentPartByIdApi,
} from "../../api/plant/PartsAPI";

import { getPlantComponentListApi } from "../../api/plant/ComponentListAPI";

// Function
function* updatePlantComponentListFn(plantId = "", componentTypeId = "", pageNumber = "", pageSize = "") {
  const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

  // Dispatching Action to refresh plant component list
  yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));
}

// Add Plant Component Part By Id
function* addPlantComponentPartById(action) {
  try {
    const { partData, plantId, componentId, componentTypeId, pageNumber, pageSize } = action.payload;

    yield addPlantComponentPartByIdApi(partData, plantId, componentId);

    yield updatePlantComponentListFn(plantId, componentTypeId, pageNumber, pageSize);

    // Dispatching Action
    yield put(PlantComponentPartsActions.addPlantComponentPartSuccess());

    // Toaster
    toast.success("Added Plant Component Part Successfully");

    // Close modal
    ModalActions.close(ModalKeys.addPlantComponentPartModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.addPlantComponentPartModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentPartsActions.addPlantComponentPartFailure({ error }));
  }
}

// Update Plant Component Part By Id
function* updatePlantComponentPartById(action) {
  try {
    const {
      componentData: partData,
      plantId,
      componentId,
      partId,
      componentTypeId,
      pageNumber,
      pageSize,
    } = action.payload;

    yield updatePlantComponentPartByIdApi(partData, plantId, componentId, partId);

    yield updatePlantComponentListFn(plantId, componentTypeId, pageNumber, pageSize);

    // Dispatching Action
    yield put(PlantComponentPartsActions.updatePlantComponentPartSuccess());

    // Toaster
    toast.success("Updated Plant Component Part Successfully");

    // Close modal
    ModalActions.close(ModalKeys.viewPlantComponentPartModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.viewPlantComponentPartModal);

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentPartsActions.updatePlantComponentPartFailure({ error }));
  }
}

// Delete Plant Component Part By Id
function* deletePlantComponentPartById(action) {
  const { plantId, componentId, partId, componentTypeId, pageNumber, pageSize } = action.payload;

  try {
    yield deletePlantComponentPartByIdApi(plantId, componentId, partId);

    yield updatePlantComponentListFn(plantId, componentTypeId, pageNumber, pageSize);

    // Dispatching Action
    yield put(PlantComponentPartsActions.deletePlantComponentPartSuccess({ partId }));

    // Toaster
    toast.success("Deleted Plant Component Part Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentPartsActions.deletePlantComponentPartFailure({ error, partId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantComponentPartsActions.addPlantComponentPart.type, addPlantComponentPartById),
    takeLatest(PlantComponentPartsActions.updatePlantComponentPart.type, updatePlantComponentPartById),
    takeLatest(PlantComponentPartsActions.deletePlantComponentPart.type, deletePlantComponentPartById),
  ]);
}
