import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";
import { PlantListActions } from "../../../redux-slice/plant/PlantListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Utils
import { bcForPlantListPage } from "../../../utils/BreadcrumbUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import PlantListTable from "./PlantListTable";

// Page Component
function PageHeaderSection() {
  // Navigate
  const navigate = useNavigate();

  // Orgnization Info Selector State
  const organizationInfo = useSelector((state) => state.organization.organizationInfo);

  // Organization Info
  const orgId = organizationInfo?.id;
  const { name = "" } = organizationInfo;

  // Navigatee to plant create page
  function navigateToPlantCreatePage() {
    const redirectUrl = URLs.format(RedirectTo.PlantCreatePageUrl, { orgId });
    navigate(redirectUrl);
  }

  // Content
  const pageActions = (
    <div className="btn-cont">
      <Button label="Add Plant" onClick={navigateToPlantCreatePage}>
        <i className="fas fa-plus"></i>
      </Button>
    </div>
  );

  return <PageHeader actions={pageActions} breadcrumbList={bcForPlantListPage(name)} className="w-100 mt-2" />;
}

function TableActionsSection({ organizationInfo }) {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search Plants...`} isSearchButton={true} />
    </div>
  );
}

/**
 * Plant List Page
 */
export default function PlantListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { orgId } = params;

  // Search Params
  const [searchParams, setSearchParams] = useSearchParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Plant List Selector State
  const plantList = useSelector((state) => state.plantList.plantList);
  const plantListLoading = useSelector((state) => state.plantList.plantListLoading);
  const plantListPagination = useSelector((state) => state.plantList.plantListPagination);

  // Orgnization Info Selector State
  const organizationInfo = useSelector((state) => state.organization.organizationInfo);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(PlantListActions.getPlantList({ pageNumber, pageSize, searchText, orgId }));
  }, [dispatch, orgId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  return (
    <div className="page-content">
      <TableActionsSection organizationInfo={organizationInfo} />

      {/* Plant List Table */}
      <PlantListTable
        plantList={plantList}
        plantListLoading={plantListLoading}
        plantListPagination={plantListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
