import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Error
  errorInfo: {},
  showToaster: false,
  showPageError: false,
};

const errorSlice = createSlice({
  name: "Error",
  initialState,
  reducers: {
    // Set Error Info
    setErrorInfo: (state, action) => {
      const { errorInfo, showToaster, showPageError } = action.payload;

      state.errorInfo = errorInfo;
      state.showToaster = showToaster;
      state.showPageError = showPageError;
    },

    // Clear Error Info
    clearErrorInfo: () => initialState,
  },
});

// Actions
export const ErrorActions = errorSlice.actions;

// Reducer
export const ErrorReducer = errorSlice.reducer;
