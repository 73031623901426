//Measurement Type List URL
export const getMeasurementTypeListUrl = "/api/v1/defs/measurement_type/search";

//Measurement Type URL
export const getMeasurementTypeInfoByIdUrl = "/api/v1/defs/measurement_type/:measurementTypeId";

// Update Measurement-Type Status (Enable/Disable)
export const updateMeasurementTypeStatusByIdUrl = "/api/v1/defs/measurement_type/:measurementTypeId/status";

// Units List URL
export const getMeasurementUnitsByIdUrl = "/api/v1/defs/unit/search";
