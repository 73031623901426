// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getNICCodeListUrl } from "../../urls/api-urls/NICCodeURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get NIC-Code List
export async function getNICCodeListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getNICCodeListUrl, params);
}
