import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Component Type Info
  componentTypeInfo: {},
  componentTypeInfoLoading: false,
  componentTypeInfoError: "",

  // Update ComponentType Status (Enable/Disable)
  updateComponentTypeStatusLoadingMap: {},
  updateComponentTypeStatusError: "",

  // Update Observation Code Status (Enable/Disable)
  updateObservationCodeStatusLoadingMap: {},
  updateObservationCodeStatusError: "",

  // Update Recommnedation Code Status (Enable/Disable)
  updateRecommendationCodeStatusLoadingMap: {},
  updateRecommendationCodeStatusError: "",

  // Update Parameter Status(Enable/Disable)
  updateParameterStatusLoadingMap: {},
  updateParameterStatusError: "",
};

// Component Type Slice
const componetnTypeSlice = createSlice({
  name: "ComponentType",
  initialState,
  reducers: {
    // Component Type Info
    getComponentTypeInfo: (state) => {
      state.componentTypeInfoLoading = true;
    },

    getComponentTypeInfoSuccess: (state, action) => {
      state.componentTypeInfoLoading = false;
      state.componentTypeInfo = action.payload.componentTypeInfo;
      state.componentTypeInfoError = "";
    },

    getComponentTypeInfoFailure: (state, action) => {
      state.componentTypeInfoLoading = false;
      state.componentTypeInfoError = action.payload.error;
    },

    // Update ComponentType Status (Enable/Disable) By Id
    updateComponentTypeStatus: (state, action) => {
      const { componentTypeId } = action.payload;

      state.updateComponentTypeStatusLoadingMap = {
        ...state.updateComponentTypeStatusLoadingMap,
        [componentTypeId]: true,
      };
    },

    updateComponentTypeStatusSuccess: (state, action) => {
      const { componentTypeId } = action.payload;

      //To get previous state
      const clonedUpdateComponentTypeStatusLoadingMap = {
        ...state.updateComponentTypeStatusLoadingMap,
      };

      //To remove the id from state after successfull loading
      delete clonedUpdateComponentTypeStatusLoadingMap[componentTypeId];

      state.updateComponentTypeStatusLoadingMap = clonedUpdateComponentTypeStatusLoadingMap;
    },

    updateComponentTypeStatusFailure: (state, action) => {
      const { componentTypeId } = action.payload;

      //To get previous state
      const clonedUpdateComponentTypeStatusLoadingMap = {
        ...state.updateComponentTypeStatusLoadingMap,
      };

      //To remove the id from state after successfull loading
      delete clonedUpdateComponentTypeStatusLoadingMap[componentTypeId];

      state.updateComponentTypeStatusLoadingMap = clonedUpdateComponentTypeStatusLoadingMap;
      state.updateComponentTypeStatusError = action.payload.error;
    },

    // Update Observation Code Status (Enable/Disable) By Id
    updateObservationCodeStatus: (state, action) => {
      const { obsCodeId } = action.payload;

      state.updateObservationCodeStatusLoadingMap = {
        ...state.updateObservationCodeStatusLoadingMap,
        [obsCodeId]: true,
      };
    },

    updateObservationCodeStatusSuccess: (state, action) => {
      const { obsCodeId } = action.payload;

      // To get previous state
      const clonedUpdateObservationCodeStateLoadingMap = {
        ...state.updateObservationCodeStatusLoadingMap,
      };

      // To remove the id from state after successfull loading
      delete clonedUpdateObservationCodeStateLoadingMap[obsCodeId];

      state.updateObservationCodeStatusLoadingMap = clonedUpdateObservationCodeStateLoadingMap;
    },

    updateObservationCodeStatusFailure: (state, action) => {
      const { obsCodeId } = action.payload;

      // To get previous state
      const clonedUpdateObservationCodeStateLoadingMap = {
        ...state.updateObservationCodeStatusLoadingMap,
      };

      // To remove the id from state after successfull loading
      delete clonedUpdateObservationCodeStateLoadingMap[obsCodeId];

      state.updateObservationCodeStatusLoadingMap = clonedUpdateObservationCodeStateLoadingMap;
      state.updateObservationCodeStatusError = action.payload.error;
    },

    // Update Recommnedation Code Status (Enable/Disable) By Id
    updateRecommendationCodeStatus: (state, action) => {
      const { recCodeId } = action.payload;

      state.updateRecommendationCodeStatusLoadingMap = {
        ...state.updateRecommendationCodeStatusLoadingMap,
        [recCodeId]: true,
      };
    },

    updateRecommendationCodeStatusSuccess: (state, action) => {
      const { recCodeId } = action.payload;

      // To get previous state
      const clonedUpdateRecommendationCodeStateLoadingMap = {
        ...state.updateRecommendationCodeStatusLoadingMap,
      };

      // To remove the id from state after successfull loading
      delete clonedUpdateRecommendationCodeStateLoadingMap[recCodeId];

      state.updateRecommendationCodeStatusLoadingMap = clonedUpdateRecommendationCodeStateLoadingMap;
    },

    updateRecommendationCodeStatusFailure: (state, action) => {
      const { recCodeId } = action.payload;

      // To get previous state
      const clonedUpdateRecommendationCodeStateLoadingMap = {
        ...state.updateRecommendationCodeStatusLoadingMap,
      };

      // To remove the id from state after successfull loading
      delete clonedUpdateRecommendationCodeStateLoadingMap[recCodeId];

      state.updateRecommendationCodeStatusLoadingMap = clonedUpdateRecommendationCodeStateLoadingMap;
      state.updateRecommendationCodeStatusError = action.payload.error;
    },

    // Update Parameter Status (Enable/Disable) By Id
    updateParameterTypeStatus: (state, action) => {
      const { parameterId } = action.payload;

      state.updateParameterStatusLoadingMap = {
        ...state.updateParameterStatusLoadingMap,
        [parameterId]: true,
      };
    },

    updateParameterTypeStatusSuccess: (state, action) => {
      const { parameterId } = action.payload;

      //To get previous state
      const clonedUpdateParameterStatusLoadingMap = {
        ...state.updateParameterStatusLoadingMap,
      };

      //To remove the id from state after successfull loading
      delete clonedUpdateParameterStatusLoadingMap[parameterId];

      state.updateParameterStatusLoadingMap = clonedUpdateParameterStatusLoadingMap;
    },

    updateParameterTypeStatusFailure: (state, action) => {
      const { parameterId, error } = action.payload;

      //To get previous state
      const clonedUpdateParameterStatusLoadingMap = {
        ...state.updateParameterStatusLoadingMap,
      };

      //To remove the id from state after successfull loading
      delete clonedUpdateParameterStatusLoadingMap[parameterId];

      state.updateParameterStatusLoadingMap = clonedUpdateParameterStatusLoadingMap;
      state.updateParameterStatusError = error;
    },
  },
});

// Reducer
export const ComponentTypeReducer = componetnTypeSlice.reducer;

// Actions
export const ComponentTypeActions = componetnTypeSlice.actions;
