import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get NIC Code - List
  nicCodeList: [],
  nicCodeListPagination: {},
  nicCodeListLoading: false,
  nicCodeListError: "",
};

// NIC Code List
const NICCodeListSlice = createSlice({
  name: "NICCodeList",
  initialState,
  reducers: {
    // Get NIC Code - List
    getNICCodeList: (state) => {
      state.nicCodeListLoading = true;
    },

    getNICCodeListSuccess: (state, action) => {
      state.nicCodeListLoading = false;
      state.nicCodeList = action.payload.nicCodeList;
      state.nicCodeListPagination = action.payload.nicCodeListPagination;
    },

    getNICCodeListFailure: (state, action) => {
      state.nicCodeListLoading = false;
      state.nicCodeListError = action.payload.error;
    },
  },
});

// Reducer
export const NICCodeListReducer = NICCodeListSlice.reducer;

// Actions
export const NICCodeListActions = NICCodeListSlice.actions;
