// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import { getProductListUrl } from "../../urls/api-urls/ProductURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Product List
export async function getProductListApi(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getProductListUrl, params);
}
