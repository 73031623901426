import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { MeasurementTypeActions } from "../../../redux-slice/measurement-type/MeasurementTypeSlice";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Utils
import URLs from "../../../app/utils/URLs";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Id",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Symbol",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function MeasurementTypeListTableCellAction({ id, isActive, pageNumber, pageSize }) {
  // Navigate
  const navigate = useNavigate();

  //Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateMeasurementTypeStatusLoadingMap = useSelector(
    (state) => state.measurementType.updateMeasurementTypeStatusLoadingMap
  );

  // Changing Status
  const status = !isActive;

  // Loading Status
  const loading = updateMeasurementTypeStatusLoadingMap[id];

  // Redirect to measurement type view page
  function navigateToMeasurementTypeViewPage() {
    const redirectUrl = URLs.format(RedirectTo.MeasurementTypeViewPageUrl, { measurementTypeId: id });
    navigate(redirectUrl);
  }

  return (
    <td className="col-2">
      {/* Button */}
      <Button
        label={isActive ? "Disable" : "Enable"}
        color={isActive ? "danger" : "success"}
        size="sm"
        loading={loading}
        disabled={loading}
        onClick={() =>
          dispatch(
            MeasurementTypeActions.updateMeasurementTypeStatus({ measurementTypeId: id, status, pageNumber, pageSize })
          )
        }
      />

      {/* Button */}
      <Button label="View" size="sm" className="ms-2" onClick={navigateToMeasurementTypeViewPage}>
        <i className="fas fa-eye"></i>
      </Button>
    </td>
  );
}

function MeasurementTypeListTableRow({ measurement, pageNumber, pageSize }) {
  const { id, name, description, symbol, isActive } = measurement;

  return (
    <tr>
      <td className="col-1">{id}</td>
      <td className="col-1">{name}</td>
      <td className="">{description}</td>
      <td className="col-1">{symbol}</td>

      {/* Measurement type list table cell action */}
      <MeasurementTypeListTableCellAction
        id={id}
        name={name}
        isActive={isActive}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </tr>
  );
}

function MeasurementTypeListTableBody({ measurementTypeList, measurementTypeListLoading, pageNumber, pageSize }) {
  //Loader
  if (measurementTypeListLoading) {
    return <TableLoader colSpan={5} />;
  }

  //if no measurement type present
  if (measurementTypeList.length === 0) {
    return <TableDataNotFound message="No measurement type present" colSpan={5} />;
  }

  return (
    <>
      {measurementTypeList.map((measurement) => (
        // Measurement type list table row
        <MeasurementTypeListTableRow
          key={measurement.id}
          measurement={measurement}
          pageNumber={pageNumber}
          pageSize={pageSize}
        />
      ))}
    </>
  );
}

// Measurement type list table
export default function MeasurementTypeListTable({
  measurementTypeList = [],
  measurementTypeListLoading = false,
  measurementTypeListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize } = measurementTypeListPagination;
  return (
    <>
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Measurement Type list Table body */}
          <MeasurementTypeListTableBody
            measurementTypeList={measurementTypeList}
            measurementTypeListLoading={measurementTypeListLoading}
            pageNumber={pageNumber}
            pageSize={pageSize}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
