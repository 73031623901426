/**
 * Show Date and Time
 * @param {*} object : timestamp
 * @returns DD-MM-YYYY HH:MM
 */
export default function ShowDateAndTime({ timeStamp }) {
  function formatDateAndTime(value) {
    const result = value < 10 ? "0" + value : value;
    return result;
  }

  const date = new Date(timeStamp);

  //Date
  const day = formatDateAndTime(date.getDate());
  const month = formatDateAndTime(date.getMonth() + 1);
  const year = date.getFullYear();

  //Time
  const hour = formatDateAndTime(date.getHours());
  const minutes = formatDateAndTime(date.getMinutes());

  return `${day}-${month}-${year} ${hour}:${minutes}`;
}
