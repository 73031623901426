// Urls
import {
  getAuditComponentInfoByIdUrl,
  getAuditComponentsDetailUrl,
  addAuditComponentRemarksUrl,
  addAuditWalkthroughImageUrl,
  addAuditWalkthroughRemarksUrl,
} from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Get Audit Component Info
export async function getAuditComponetInfoApi(auditId, componentId) {
  const url = URLs.format(getAuditComponentInfoByIdUrl, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Audit Components Detail
export async function getAuditComponentsDetailApi(auditId) {
  const url = URLs.format(getAuditComponentsDetailUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Add Audit Walkthorugh Remarks
export async function addAuditWalkthroughRemarksApi(auditId, componentId, formData) {
  const url = URLs.format(addAuditWalkthroughRemarksUrl, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}

// Add Audit Walkthrough Remarks Image
export async function addAuditWalkthroughImageApi(auditId, componentId, formData) {
  const url = URLs.format(addAuditWalkthroughImageUrl, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.MULTIPART_FORM_DATA);
}

// Add Audit Component Remarks
export async function addAuditComponentRemarksApi(auditId, componentId, formData) {
  const url = URLs.format(addAuditComponentRemarksUrl, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}
