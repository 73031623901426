import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditObservationAndRecommendationListActions } from "../../redux-slice/audit/ObservationAndRecommendationListSlice";
import { AuditObservationAndRecommendationActions } from "../../redux-slice/audit/ObservationAndRecommendationSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// APIs
import {
  updateRecommendationInvestmentApi,
  updateAuditObservationAndRecommendationApi,
} from "../../api/audit/ObservationAndRecommendationAPI";

import { getAuditObsAndRecListApi } from "../../api/audit/ObservationAndRecommendationListAPI ";

// Component Action
import { ModalActions } from "../../components/modal/Modal";

// Update Audit Observation And Recommendation
function* updateAuditObservationAndRecommendation(action) {
  const { observationData, observationId } = action.payload;

  try {
    const { auditInfoId, elementId } = observationData;

    yield updateAuditObservationAndRecommendationApi(auditInfoId, observationData);

    const { items, pagination } = yield getAuditObsAndRecListApi(auditInfoId, elementId);

    // Dispatching action to refresh table
    yield put(
      AuditObservationAndRecommendationListActions.getAuditObsAndRecListSuccess({
        auditObsAndRecList: items,
        auditObsAndRecListPagination: pagination,
      })
    );

    yield put(AuditObservationAndRecommendationActions.updateAuditObsAndRecSuccess({ observationId }));

    // Toaster
    toast.success("Observations Updated Successfully");

    // Close modal
    ModalActions.close(ModalKeys.addRecommendationsModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.addRecommendationsModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditObservationAndRecommendationActions.updateAuditObsAndRecFailure({ observationId, error }));
  }
}

// Update Recommendation Investment
function* updateAuditRecommendationInvestment(action) {
  const {
    auditId = "",
    recommendationId = "",
    investment = "",
    componentId = "",
    pageNumber = "",
    pageSize = "",
  } = action.payload;

  try {
    yield updateRecommendationInvestmentApi(auditId, recommendationId, investment);

    const { items, pagination } = yield getAuditObsAndRecListApi(auditId, componentId, pageNumber, pageSize);

    // Dispatching action to refresh table
    yield put(
      AuditObservationAndRecommendationListActions.getAuditObsAndRecListSuccess({
        auditObsAndRecList: items,
        auditObsAndRecListPagination: pagination,
      })
    );

    yield put(AuditObservationAndRecommendationActions.updateAuditRecInvestmentSuccess({ recommendationId }));

    // Toaster
    toast.success("Investment Updated Successfully");
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditObservationAndRecommendationActions.updateAuditRecInvestmentFailure({ recommendationId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(
      AuditObservationAndRecommendationActions.updateAuditObsAndRec.type,
      updateAuditObservationAndRecommendation
    ),
    takeLatest(
      AuditObservationAndRecommendationActions.updateAuditRecInvestment.type,
      updateAuditRecommendationInvestment
    ),
  ]);
}
