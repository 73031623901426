import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditObservationAndRecommendationListActions } from "../../../../redux-slice/audit/ObservationAndRecommendationListSlice";
import { AuditParameterValuesActions } from "../../../../redux-slice/audit/ParameterValuesSlice";

// Utils
import { getComponentTypeParameters } from "../../../../utils/DataPreLoadUtils";
import {
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../../utils/ComponentUtils";

// Component
import ComponentSelect from "../ComponentSelect";
import TabularForm from "../../../../components/form/TabularForm";

// Section
import AuditSavingsRecommendationListTable from "./AuditSavingsRecommendationListTable";

// Page Constants
const savingsTableHeaders = [
  {
    title: {
      displayName: "Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Components
function SavingsCalculationTabularForm() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Audit Component Info Selector State
  const { component: componentInfo = {}, ...auditComponentInfo } = useSelector(
    (state) => state.auditComponent.auditComponentInfo
  );

  // Selector State
  const upsertParameterValuesLoading = useSelector((state) => state.auditParameterValues.upsertParameterValuesLoading);

  // Component Info
  const { id: selectedComponentId = "", componentTypeId: selectedComponentTypeId = "" } = componentInfo;

  // Getting component information
  const { savings = [] } = auditComponentInfo;

  // Getting savings parameter list for the component
  const savingsParameterList = getComponentTypeParameters(selectedComponentTypeId, "SAVINGS") || [];

  // Form Inputs
  const savingsFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = {
      showUnits: false,
      isInputPresent: true,
      isUnitConversionPresent: true,
    };

    return getParametersFormInputs(savingsParameterList, savings, tableConfig, selectedComponentId) || [];
  }, [selectedComponentId, selectedComponentTypeId, auditComponentInfo]);

  // Form Data for savings
  const savingsFormData = useMemo(() => {
    return constructFormDataObject(savingsParameterList, savings, selectedComponentId);
  }, [auditComponentInfo]);

  // Savings Parameters Form Submit
  function formSubmit(formData) {
    // Constructing savings array
    const savingsArray = constructParameterValuesArray(savingsParameterList, formData, selectedComponentId);

    // Dispatch
    dispatch(
      AuditParameterValuesActions.upsertParameterValues({
        auditId,
        componentId: selectedComponentId,
        parameterValuesData: savingsArray,
        type: "savings",
      })
    );
  }

  return (
    <TabularForm
      tableHeaders={savingsTableHeaders}
      formInputItems={savingsFormInputs}
      data={savingsFormData}
      loading={upsertParameterValuesLoading}
      formSubmit={formSubmit}
    />
  );
}

/**
 * Audit Savings Calculation Page
 */
export default function AuditSavingsCalculationPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Get Path  Params
  const { auditId } = useParams();

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Component Info
  const { id: selectedComponentId = "", componentTypeId: selectedComponentTypeId = "" } = componentInfo;

  // use Effect
  useEffect(() => {
    if (selectedComponentId) {
      dispatch(
        AuditObservationAndRecommendationListActions.getAuditObsAndRecList({
          componentId: selectedComponentId,
          auditId,
        })
      );
    }
  }, [dispatch, auditId, selectedComponentId]);

  return (
    <div className="page-content">
      {/* Component Select */}
      <ComponentSelect />

      {!selectedComponentTypeId && <div>Please select a component !!</div>}

      {selectedComponentTypeId && (
        <>
          <h3 className="mb-3">Savings</h3>

          <div className="row">
            <div className="col-4">
              {/* Savings Calculation Tabular Form */}
              <SavingsCalculationTabularForm />
            </div>

            <div className="col-8">
              {/* Audit Savings Recommendation List Table */}
              <AuditSavingsRecommendationListTable />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
