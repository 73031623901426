import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  // Get Plant File List
  fileList: [],
  fileListPagination: {},
  fileListLoading: false,
  fileListError: "",
};

// Plant File List Slice
const plantFileListSlice = createSlice({
  name: "PlantFileList",
  initialState,
  reducers: {
    // Get Plant File List
    getPlantFileList: (state) => {
      state.fileListLoading = true;
    },

    getPlantFileListSuccess: (state, action) => {
      state.fileListLoading = false;
      state.fileList = action.payload.fileList;
      state.fileListPagination = action.payload.fileListPagination;
    },

    getPlantFileListFailure: (state, action) => {
      state.fileListLoading = false;
      state.fileListError = action.payload.error;
    },
  },
});

// Reducer
export const PlantFileListReducer = plantFileListSlice.reducer;

// Actions
export const PlantFileListActions = plantFileListSlice.actions;
