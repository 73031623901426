import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ConstantListActions } from "../../redux-slice/constant/ConstantListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getConstantListApi } from "../../api/constant/ConstantAPI";

// Get Constant List
function* getConstantList() {
  try {
    const constantList = yield getConstantListApi();

    // Dispatching Action
    yield put(ConstantListActions.getConstantListSuccess({ constantList }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ConstantListActions.getConstantListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(ConstantListActions.getConstantList.type, getConstantList)]);
}
