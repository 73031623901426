import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

//Utils
import tableSerialNumber from "../../../utils/TableUtils";

// Components
import { Button } from "../../../components/button/Button";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableLoader from "../../../components/table/TableLoader";

//Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Id",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ObservationCodeListActionsCell({ obsCodeId, isActive }) {
  // Params
  const params = useParams();
  const { componentTypeId } = params;

  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateObservationCodeStatusLoadingMap = useSelector(
    (state) => state.componentType.updateObservationCodeStatusLoadingMap
  );

  // Observation Code List Selector State
  const observationCodeListPagination = useSelector((state) => state.componentTypeList.observationCodeListPagination);

  const { pageNo, pageSize } = observationCodeListPagination;

  // Changing Status
  const status = !isActive;

  // Loading Status
  const loading = updateObservationCodeStatusLoadingMap[obsCodeId];

  // Button Style
  const buttonLabel = isActive ? "Disable" : "Enable";
  const buttonColor = isActive ? "danger" : "success";

  return (
    <>
      <td>
        <div className="btn-cont">
          {/* Button */}
          <Button
            label={buttonLabel}
            color={buttonColor}
            size="sm"
            loading={loading}
            disabled={loading}
            onClick={() =>
              dispatch(
                ComponentTypeActions.updateObservationCodeStatus({
                  componentTypeId,
                  obsCodeId,
                  status,
                  pageNumber: pageNo,
                  pageSize,
                })
              )
            }
          />
        </div>
      </td>
    </>
  );
}

function ObservationCodeListTableRow({ serialNo, observationCode }) {
  const { id, code, description, isActive } = observationCode;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{code}</td>
      <td>{description}</td>

      {/* Observation Code List Table cell */}
      <ObservationCodeListActionsCell obsCodeId={id} isActive={isActive} />
    </tr>
  );
}

function ObservationCodeListTableBody({ pageNumber, pageSize, observationCodeList, observationCodeListLoading }) {
  // Loader
  if (observationCodeListLoading) {
    return <TableLoader colSpan={5} />;
  }

  // If no observation codes present
  if (observationCodeList.length === 0) {
    return <TableDataNotFound message="No observation codes present" colSpan={5} />;
  }

  return (
    <>
      {observationCodeList.map((observationCode, index) => {
        // Serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        // Observation Code List Table Row
        return (
          <ObservationCodeListTableRow serialNo={serialNo} key={observationCode.id} observationCode={observationCode} />
        );
      })}
    </>
  );
}

/**
 * Observation Code List Table
 */
export default function ObservationCodeListTable({ pageNumber }) {
  // Observation Code List Selector State
  const observationCodeList = useSelector((state) => state.componentTypeList.observationCodeList);
  const observationCodeListLoading = useSelector((state) => state.componentTypeList.observationCodeListLoading);
  const observationCodeListPagination = useSelector((state) => state.componentTypeList.observationCodeListPagination);

  // Pagination
  const { totalCount, pageNo, pageSize } = observationCodeListPagination;

  return (
    <>
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Observation Code List Table body */}
          <ObservationCodeListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            observationCodeList={observationCodeList}
            observationCodeListLoading={observationCodeListLoading}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
