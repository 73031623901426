// Urls
import {
  getAuditInstrumentDataInfoByIdUrl,
  selectAuditInstrumentDataByIdApiUrl,
  uploadAuditInstrumentDataFileUrl,
} from "../../urls/api-urls/AuditURL";

// App Constants
import { ContentType } from "../../app/http/HttpConstants";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Get Audit Instrument Data Info
export async function getAuditInstrumentDataInfoApi(instrumentDataId) {
  const url = URLs.format(getAuditInstrumentDataInfoByIdUrl, { instrumentDataId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// select Audit Instrument Data
export async function selectAuditInstrumentDataApi(instrumentDataId, sheetNo, lineNo) {
  const params = {
    sheetNo,
    lineNo,
  };

  const url = URLs.format(selectAuditInstrumentDataByIdApiUrl, { instrumentDataId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, params);
}

// Upload Audit Instrument Data File Api
export async function uploadAuditInstrumentDataFileApi(formData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(uploadAuditInstrumentDataFileUrl, {}, formData, ContentType.APP_FORM_URL_ENCODED);
}
